const getPurePrice = (price) => {
	if (!price) {
		return
	}
	// if (price.toLowerCase() === 'free') {
	// 	return 0
	// }

	if (price.toLowerCase() === '0') {
		return 0
	}

	price = price.replace('$', '')
	return price
}

export default getPurePrice
