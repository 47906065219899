// Core
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

// Styles
//import styles from './PopupContent.module.scss'
import "../../styles/popup.scss";

// GSAP
import { TimelineLite } from "gsap/all";
// import { useTranslation } from 'react-i18next'

const PopupContent = forwardRef((props, childRef) => {
  // const { t } = useTranslation('')
  const [isOpen, setIsOpen] = useState(false);

  let PopupContentEl = null;
  let shadowEl = null;
  let containerEl = null;
  const [tl] = useState(new TimelineLite({ paused: true }));

  useEffect(() => {
    tl.from(PopupContentEl, 0.1, { autoAlpha: 0 })
      .from(shadowEl, 0.5, { autoAlpha: 0 })
      .from(
        containerEl,
        0.5,
        {
          autoAlpha: 0,
          y: props.isFromTop ? -50 : 50,
        },
        "-=.3"
      );
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(childRef, () => ({
    handleToggle: handleToggle
  }));

  const handleToggle = (e) => {
    e.preventDefault();
    if (isOpen) {
      document.body.classList.remove("overflow-y-hidden");
      tl.reverse();
    } else {
      document.body.classList.add("overflow-y-hidden");
      tl.play();
    }
    setIsOpen(!isOpen);
  };
  if (props.isbtn) {
    return (
      <>
        <div className="popup-btn">
          <a href="#" onClick={handleToggle}>
            {props.text}
            <div className="arrow"></div>
          </a>
        </div>
        <div className="popup" ref={(e) => (PopupContentEl = e)}>
          <div
            className="shadow"
            ref={(e) => (shadowEl = e)}
            onClick={handleToggle}
          ></div>
          <div
            className="container"
            ref={(e) => (containerEl = e)}
            data-is-top={props.isTop}
            data-is-full-width={props.isFullWidth}
          >
            <div className="btnClose" onClick={handleToggle}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.61659 0.000173188L19.938 18.3784L18.3214 20L0 1.62178L1.61659 0.000173188Z"
                  fill="#C8215D"
                />
                <path
                  d="M20 1.62161L1.67864 19.9998L0.0620451 18.3782L18.3834 0L20 1.62161Z"
                  fill="#C8215D"
                />
              </svg>
            </div>

            <div className="content">{props.content}</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <a href="#" onClick={handleToggle}>
        {props.text}
      </a>
      <div className="popup" ref={(e) => (PopupContentEl = e)}>
        <div
          className="shadow"
          ref={(e) => (shadowEl = e)}
          onClick={handleToggle}
        ></div>
        <div
          className="container"
          ref={(e) => (containerEl = e)}
          data-is-top={props.isTop}
          data-is-full-width={props.isFullWidth}
        >
          <div className="btnClose" onClick={handleToggle}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.61659 0.000173188L19.938 18.3784L18.3214 20L0 1.62178L1.61659 0.000173188Z"
                fill="#C8215D"
              />
              <path
                d="M20 1.62161L1.67864 19.9998L0.0620451 18.3782L18.3834 0L20 1.62161Z"
                fill="#C8215D"
              />
            </svg>
          </div>

          <div className="content">{props.content}</div>
        </div>
      </div>
    </>
  );
});

export default PopupContent;
