// Core
import { useCallback, useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet-async'
import { useToggle } from 'react-use'

import style from './Revitalization.module.scss'

// Components
import PageTitle from '../../components/PageTitle/PageTitle'
import SVG from 'react-inlinesvg'
import NavArrow from '../../components/NavArrow/NavArrow'

// Swiper
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// i18n
import { useTranslation } from 'react-i18next'

// Axios
// import axios from 'axios'
import axios from '../../config/axios'
import { trimHtml } from '../../utils/htmldecode'

SwiperCore.use([Navigation, Pagination])

const Revitalization = (props) => {
	// const { query } = useParams()
	const { t, i18n } = useTranslation()

	const tabContainerEl = useRef()
	const tabDropContainerEl = useRef()

	const [tabIndex, setTabIndex] = useState(0)
    const [subTabIndex, setSubTabIndex] = useState(0)
    const [enableDropDown, toggleDropDown] = useToggle(false)
    const [enableMobileDropDown, toggleMobileDropDown] = useToggle(false)
    const [enableMobileSubDropDown, toggleMobileSubDropDown] = useToggle(false)
	const [data, setData] = useState({
		tabList: [],
		arrowimgdown: '/images-local/ico-arrow-down.svg',
		arrowimgup: '/images-local/ico-arrow-up.svg',
		arrowimgplus: '/images-local/ico-plus.svg',
		arrowimgmin: '/images-local/ico-min.svg',
		dpdownStatus: {
			status: false,
			arrow: '/images-local/ico-arrow-down.svg',
		},
	})

	useEffect(() => {
		async function getData() {
			try {
				const result = await axios.get(i18n.language + '/revitalizationV2.json')
				setData({
                    ...data,
					tabList: result.data
				})
			}
			catch(error) {
				console.log('error', error)
			}
		}
		getData()
	}, [])

	const changeTab = useCallback((index) => {
        if(tabIndex === index) {
            if(data.tabList[index].subtab && data.tabList[index].subtab.length) {
                toggleDropDown()
                toggleMobileSubDropDown()
            }
            else {
                return
            }
        }
        else {
            setTabIndex(index)
            toggleDropDown(false)
            toggleMobileDropDown(false)
            toggleMobileSubDropDown(false)
            if(data.tabList[index].subtab && data.tabList[index].subtab.length) {
                setSubTabIndex(0)
                toggleDropDown(true)
                toggleMobileDropDown(true)
                toggleMobileSubDropDown(true)
            }

        }
	}, [tabIndex, data])

	const changeSubTab = (index) => {
		setSubTabIndex(index)
        toggleDropDown(false)
        toggleMobileDropDown(false)
        toggleMobileSubDropDown(false)
	}

	const subtabItems = (subTabList) => {
		return subTabList.map((item, index) => (
			<a key={'subitem' + index} className={`${style.subtabItem} ${item.active === true ? style.subtabItemActive : ''}`} onClick={() => changeSubTab(index)}>
				{item.name}
			</a>
		))
	}
	const arrowImg = (status) => {
		if (status && status === true) {
			return <SVG className={style.svgIcon} src={data.arrowimgup} alt="" />
		} else {
			return <SVG className={style.svgIcon} src={data.arrowimgdown} alt="" />
		}
	}
	const arrowImgMb = (status) => {
		if (status && status === true) {
			return <SVG className={style.svgIcon} src={data.arrowimgmin} alt="" />
		} else {
			return <SVG className={style.svgIcon} src={data.arrowimgplus} alt="" />
		}
	}

	const assignNav = (swiper, nav) => {
		swiper.params.navigation = {
			...swiper.params.navigation,
			prevEl: nav.prev.current,
			nextEl: nav.next.current,
		}
		swiper.navigation.update()
	}

	let imgSwiperNav = {
		prev: useRef(),
		next: useRef(),
	}

	const tabDetailsImage = (content_images) => {
		if (content_images.length < 1) {
			return
		}
		if (content_images.length > 1) {
			return (
				<div className={style.tabDetailsImgSwiper}>
					<div className={style.imgSwiperNav}>
						<NavArrow ref={imgSwiperNav} />
					</div>
					<div className={style.imageSwiper}>
						<Swiper
							className={style.imageSwiperPadding}
							loop={true}
							spaceBetween={30}
							slidesPerView={1}
							navigation={{ prevNav: '', nextEl: '' }}
							pagination={{
								modifierClass: 'normal-pagination-',
								clickable: true,
								renderBullet: function (index, className) {
									return `<span class="${className}"></span>`
								},
							}}
							onInit={(swiper) => {
								assignNav(swiper, imgSwiperNav)
							}}
						>
							{content_images.map((imgItem, i) => {
								return (
									<SwiperSlide key={i} className={style.i}>
										<img className={style.sliderDetailsImg} src={imgItem.img_path} alt="" />
										<div className={style.sliderRecap}>{parse(imgItem.recap)}</div>
									</SwiperSlide>
								)
							})}
						</Swiper>
					</div>
				</div>
			)
		} else {
			return (
				<div>
					<img className={style.detailsImg} src={content_images[0].img_path} alt="" />
					<div className={style.imgRecap}>{parse(content_images[0].recap)}</div>
				</div>
			)
		}
	}
	const tabItem = data.tabList.map((item, index) =>
		(
			<div key={index} className={`${index === tabIndex ? style.active : ''} ${style.tabItem}`} >
				<div className={style.tabIndex}> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </div>
				<div className={`${style.tabName} ${item.subtab && item.subtab.length > 0 && enableDropDown ? style.haveSubItem : ''}`} onClick={() => changeTab(index)}>
					<span>{item.name}</span> {item.subtab && item.subtab.length > 0 ? arrowImg(item.showSubTab) : ''}
				</div>
				<div className={`${style.subTabContainer} ${enableDropDown && tabIndex === index ? style.subTabactive : ''}`}>
					{item.subtab && item.subtab.length > 0 ? subtabItems(item.subtab, item.link_id) : ''}
				</div>
			</div>
		)
	)

	const tabSubItemDropDown = (sudItemList) => {
		return sudItemList.map((item, index) => (
			<div className={`${style.subDropDownitem} ${index === subTabIndex ? style.dpactive : ''}`} onClick={() => changeSubTab(index)}>
				<p className={style.suditemName}>{item.name}</p>
			</div>
		))
	}

	const tabItemDropDown = data.tabList.map((item, index) => (
		<div key={index} className={`${index === tabIndex ? style.active : ''} ${style.tabItem}`} onClick={() => changeTab(index)}>
			<div className={style.tabItemDetails}>
				<p className={style.tabIndex}> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </p>
				<p className={style.tabName}> {item.name} </p>
			</div>
			<div className={style.sudDroDownico}>{item.subtab && item.subtab.length > 0 ? arrowImgMb(item.showSubTab) : ''}</div>
			<div className={`${style.subDropDown} ${enableMobileSubDropDown === true ? style.subTabDpactive : ''}`}>
				{item.subtab && item.subtab.length > 0 ? tabSubItemDropDown(item.subtab) : ''}
			</div>
		</div>
	))

	const tabDetailsSection = data.tabList.length ?
        data.tabList[tabIndex].subtab && data.tabList[tabIndex].subtab.length ?
            data.tabList[tabIndex].subtab[subTabIndex].content.map((item, index) => (
                <div key={index} className={`${style.contentDetails} ${!item.title || item.title === '' ? style.notitle : ''}`}>
                    <div className={style.contentDetailsTitle}>{item.title}</div>
                    <div className={style.contentDetailsContent}>{parse(item.content)}</div>
                    <div className={`${style.imageSection} ${item.content_images[0].img_path === '' ? style.noimage : ''}`}>{tabDetailsImage(item.content_images)}</div>
                </div>
            ))
        :
            data.tabList[tabIndex].content.map((item, index) => (
                <div key={index} className={`${style.contentDetails} ${!item.title || item.title === '' ? style.notitle : ''}`}>
                    <div className={style.contentDetailsTitle}>{item.title}</div>
                    <div className={style.contentDetailsContent}>{parse(item.content)}</div>
                    <div className={`${style.imageSection} ${item.content_images[0].img_path === '' ? style.noimage : ''}`}>{tabDetailsImage(item.content_images)}</div>
                </div>
            )) : null

	return (
		<div className={`page-Revitalization page ${style.revitalization}`}>
			<Helmet>
				<title>
					{trimHtml(t('Central Market Revitalization'))} - {t('Central Market')}
				</title>
			</Helmet>
            <PageTitle title={t("Central Market Revitalization")} withBr/>
			<div className="container-boxed half-padding">
				<div className={style.container}>
					<div className={style.containerTabBar} ref={tabContainerEl}>
						{tabItem}
					</div>
					<div className={style.containerTabDropdown} ref={tabDropContainerEl}>
						<div className={style.activeItem} onClick={toggleMobileDropDown}>
							<div className={`${style.activeItemDetails} ${enableMobileDropDown === true ? style.active : ''}`}>
								<p className={style.tabIndex}>{data.tabList && data.tabList[tabIndex] ? data.tabList[tabIndex].id : ''} </p>
								<p className={style.tabName}>{data.tabList && data.tabList[tabIndex] ? data.tabList[tabIndex].name : ''} </p>
							</div>
							<img src={data.dpdownStatus.arrow} alt="" />
						</div>
						<div className={`${style.dropdownList} ${enableMobileDropDown === true ? style.active : ''}`}>{tabItemDropDown}</div>
					</div>
					<div className={style.containerTabDetails}>{tabDetailsSection}</div>
				</div>
			</div>
		</div>
	)
}

export default Revitalization
