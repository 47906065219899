import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";
// Swiper
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./AboutChinaChemGroup.scss";

import PageTitle from "../../components/PageTitle/PageTitle";

//i18n
import { useTranslation } from "react-i18next";

// Utils
import getData from "../../utils/getData";

const AboutChinaChemGroup = () => {
    const { t } = useTranslation(["translation", "menu"]);
  
    const [data, setData] = useState(null);
  
    let imgSwiperNav = {
      prev: useRef(),
      next: useRef(),
    };
  
    // Fetch data
    useEffect(() => {
      async function fetchData() {
        try {
          const data = await getData("chinachemgroup");
          console.log(data);
          setData(data);
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
    }, []);
  
    return (
      <div className="page-vision">
        <Helmet>
          <title>
            {t("vision_title")} - {t("Central Market")}
          </title>
        </Helmet>
        <PageTitle title={t("menu:About Chinachem Group")} />
        <div className="container-boxed half-padding">
          <div className="content">
            {data &&
              data.ChinaChemGroupContent.map((item, i) => {
                return (
                  <div key={i} className={item.type}>
                    {parse(item.content)}
                  </div>
                );
              })}
          </div>
          <div className="content">
            {data &&
              data.CorporateGovernanceContent.map((item, i) => {
                return (
                  <div key={i} className={item.type}>
                    {parse(item.content)}
                  </div>
                );
              })}
          </div>
          <div className="content">
            {data &&
              data.OurPurposeContent.map((item, i) => {
                const { type, content } = item;
                if (type !== "flex") {
                  return (
                    <div key={i} className={item.type}>
                      {parse(item.content)}
                    </div>
                  );
                } else if (type === "flex") {
                  let flexContent = content;
                  var flexCompiledHtml = flexContent.map(
                    (flexItem, flexItemIndex) => {
                      let flexItemContent = flexItem.content;
                      console.log(flexItem);
                      return (
                        <div
                          key={flexItemIndex}
                          className={"flex-container-" + flexItem.ImageDirection}
                        >
                          <div className="flex-item-2">
                            {parse(flexItem.ImageContent)}
                          </div>
                          <div className="flex-item-2">
                            <div className="content">
                              {flexItemContent.map(
                                (
                                  flexItemContentDetail,
                                  flexItemContentDetailIndex
                                ) => {
                                  if (
                                    flexItemContentDetail.type !== "innericon"
                                  ) {
                                    return (
                                      <div
                                        key={flexItemContentDetailIndex}
                                        className={flexItemContentDetail.type}
                                      >
                                        {parse(flexItemContentDetail.content)}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="innericon flex-container">
                                        {flexItemContentDetail.content.map(
                                          (iconContent, iconContentIndex) => {
                                            return (
                                              <div key={iconContentIndex} className="flex-item-3">
                                                {
                                                  iconContent.content.map((iconContentDetail, iconContentDetailIndex) => {
                                                      return (
                                                          <>{parse(iconContentDetail.content)}</>
                                                      )
                                                  })
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  );
                  return flexCompiledHtml;
                } else {
                  return null;
                }
              })}
          </div>
        </div>
      </div>
    );
  };
  
  export default AboutChinaChemGroup;