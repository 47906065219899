import React from 'react'
import { useEffect, useRef, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useToggle } from 'react-use'

//i18n
import { useTranslation } from 'react-i18next'

// Utils
import getData from '../../utils/getData'
import { isFireFox } from '../../utils/'

import './PreservedPillarListing.scss'
import PageTitle from '../../components/PageTitle/PageTitle'
import Loader from '../../components/Loader/Lodaer'


const PreservedPillarListing = (props) => {
    const { t, i18n } = useTranslation('')
	const navigate = useNavigate()

    const [tabIndex, setTabIndex] = useState(props.section === 'conservation' ? 0 : 1)
    const [isLoading, setIsLoading] = useState(false)
    const [enableDropDown, toggleDropDown] = useToggle(false)

    const [data, setData] = useState({
        category: [],
        banner:[],
        tabList:[],
		emptyDetailCount: 0,
		arrowimgdown: '/images-local/ico-arrow-down.svg',
		arrowimgup: '/images-local/ico-arrow-up.svg',
		dpdownStatus: {
			status: false,
			arrow: '/images-local/ico-arrow-down.svg',
		},
	})

    useEffect(() => {
	 	setIsLoading(true)

		async function fetchData() {
			try {
                let data_category = await getData('preserved_pillar_categoryV2')
                let data_banner = await getData('preserved_pillar_landing')

				setData((prevState) => ({
					...prevState,
                    banner: data_banner,
                    tabList: data_category,
				}))

			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	const changeTab = (index) => {
        if(index === tabIndex) return
        setTabIndex(index)
        toggleDropDown(false)

		switch (index) {
			case 0:
				navigate('/conservation', { replace: true })
				break;
			case 1:
				navigate('/blueprint', { replace: true })
				break;
		}
	}
	const tabNum = 100 / data.tabList.length
	const tabItem = data.tabList.map((item, index) => (
		<div key={index} className={`${tabIndex === index ?  'active' : ''} tabItem`} style={{ width: tabNum + '%' }} onClick={() => changeTab(index)}>
			<p className='tabIndex'> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </p>
			<p className='tabName'> {item.name} </p>
		</div>
	))

	const tabItemDropDown = data.tabList.map((item, index) => (
		<div key={index} className={`${tabIndex === index ? 'active' : ''} tabItem`} onClick={() => changeTab(index)}>
			<p className='tabIndex'> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </p>
			<p className='tabName'> {item.name} </p>
		</div>
	))

	const handlelistingClick = (link) =>{
		navigate(link)

		if(isFireFox){
			window.location.reload();
		}
	}


    return (
        <div className="PreservedPillarListing">
			<PageTitle title={tabIndex === 0 ? t("Heritage and Revitalisation") : t('Legacy Hall Tour')}/>
            <div className="container-boxed">
                <div className="map-section">
                    <div className="landing">
                        {data.banner && data.banner[tabIndex] ?
                             (
                                <div>
                                    <img src={data.banner[tabIndex].pic} alt=""></img>
                                    <div className="title-block">
                                        <span className="title">{ data.banner[tabIndex].title }</span>
                                    </div>
                                </div>
                            )
							:
							(
								<Loader />
							)
                        }
                    </div>
                </div>
                <div className="category">
               		<div className='containerTabBar'>{tabItem}</div>
					<div className={`containerTabDropdown`}>
						<div className={`activeItem`} onClick={() => toggleDropDown()}>
							<div className={`activeItemDetails ${enableDropDown === true ? `active` : ''}`}>
								<p className={`tabIndex`}>{`0${tabIndex+1}`} </p>
								<p className={`tabName`}>{data.tabList && data.tabList[tabIndex] ? data.tabList[tabIndex].name : ''} </p>
							</div>
							<img src={enableDropDown ? data.arrowimgup : data.arrowimgdown} alt="" />
						</div>
						<div className={`dropdownList ${enableDropDown === true ? `active` : ''}`}>{tabItemDropDown}</div>
					</div>
					<div className={`containerTabDetails`}>
						{data && data.tabList && data.tabList[tabIndex] &&
							data.tabList[tabIndex].categories.map((subCategory, index) => {
								return(
									<div key={index}>
										<div className="title">{subCategory.name}</div>
										<div className="subCategoryCointainer">
											{subCategory.items && subCategory.items.map((item, index) => {
												return(
													<div key={index} className={`index boxItem`} data-status={`${index === tabIndex ? 'active' : ''}`}>
														<a className="listing__link" onClick={()=>{handlelistingClick(item.link)}}>
															<img src={item.pic} alt={item.title}/>
															<span>{item.title}</span>
														</a>
													</div>
												)
											})}
											{/* temp category */}
											{subCategory.items && [...Array(5 - subCategory.items.length % 5 )].map((tempItem, index) => {
												return (
													<div key={subCategory.items.length + index} className={`index boxItem tempBoxItem`} data-status={`${index === tabIndex ? 'active' : ''}`}>
													</div>
												)
											})}
										</div>
									</div>
								)
							})}
					</div>
                </div>
            </div>
        </div>
    )
}

export default PreservedPillarListing
