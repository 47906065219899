// Core

// Styles
//import styles from './PopupContent.module.scss'
import "../../styles/popup.scss";

// GSAP
import { useTranslation } from "react-i18next";

const PopupContent = () => {
  const { t } = useTranslation("");

  return (
    <>
      <div className="title">
        {t("PERSONAL INFORMATION COLLECTION STATEMENT")}
      </div>
      <p>
        {t(
          "The personal data collected by this organisation through this application form is only used for registration and communication matters in the Central Market Staging Ground @ Central. The personal data of the applicant will be destroyed as soon as possible after the end of the programme."
        )}
      </p>
      <p>
        {t(
          "Subject to exemptions under the Personal Data (Privacy) Ordinance, and in accordance with Sections 18 and 22 and Principle 6 of Schedule 1 of the same ordinance, the applicant has the right to access and correct the personal data provided in this form."
        )}
      </p>
      <p>
        {t(
          "Personal data furnished in this form are provided by the applicant and the related performer(s) on a voluntary basis. Approval for the application may possibly be delayed or not be considered or processed in the case of insufficient information provided by the applicant."
        )}
      </p>
    </>
  );
};

export default PopupContent;
