// Core
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// Compoents
import Card from '../../components/Card/Card'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import Loader from '../../components/Loader/Lodaer'
import SVG from 'react-inlinesvg'
import ButtonUnderline from '../../components/ButtonUnderline/ButtonUnderline'

// material-ui
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import ListSubheader from '@material-ui/core/ListSubheader'

// Styles
import styles from './FloorDirectory.module.scss'

// i18n
import { useTranslation } from 'react-i18next'

// Utils
import getData from '../../utils/getData.js'

// Lodash
import _ from 'lodash'

// jQuery
import $ from 'jquery'

const FloorDirectory = () => {
	const { t, i18n } = useTranslation('')
	const { page } = useParams()

	const perPage = 9

	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingPage, setIsLoadingPage] = useState(false)

	const [search, setSearch] = useState()

	const [data, setData] = useState({
		items: [],
		itemsFiltered: [],
		itemsCurrent: [],
	})

	const [config, setConfig] = useState({
		categories: [],
		floors: ['G/F', '1/F', '2/F'],
	})

	const [filter, setFilter] = useState({
		currentPage: page || 1,
		category: '',
		search: '',
		floor: config.floors[0],
	})

	const [enableFloor, setEnableFloor] = useState(true)

	// Fetch data
	useEffect(() => {
		setIsLoading(true)

		async function fetchData() {
			try {
				const data = await getData('shop')

				setData((prevState) => ({
					...prevState,
					items: data,
				}))

				const categories = await getCategories(data)

				setConfig((prevState) => ({
					...prevState,
					categories: categories,
				}))
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	const getCategories = async (data) => {
		// const categories = await _.map(_.uniqBy(data, 'category_id'), 'category_id')

		const categoriesGroups = [
			{
				name: t('category_title_food'),
				categories: [1, 5, 6],
			},
			{
				name: t('category_title_shopping'),
				categories: [2, 4, 3, 7],
			},
		]
		const availableCategories = _.cloneDeep(categoriesGroups)

		categoriesGroups.map((group, i) => {
			availableCategories[i].categories = []

			group.categories.map((categoryId) => {
				// console.log('getCateogryName categoryId', categoryId)
				// console.log('getCateogryName categoryName', categoryName)
				let categoryName = getCateogryName(categoryId, data)
				if (categoryName) {
					availableCategories[i].categories.push(categoryName)
				}
			})
		})

		return availableCategories
	}

	const getCateogryName = (id, data) => {
		const shop = _.find(data, { category_id: id })
		if (shop) {
			return shop.category
		}
	}

	// Filter data
	/* useEffect(() => {
		setEnableFloor(true)
		if (filter.search || filter.category) {
			setEnableFloor(false)
		}
	}, [data.items, filter]) */

	// Filter data
	useEffect(() => {
		let itemsFiltered = data.items.filter((item) => {
			if (filter.category && filter.category !== item.category) {
				return false
			}

			if (filter.search && (item.title == null || !item.title.toLowerCase().includes(filter.search.toLowerCase())) && (item.shop_no == null || !item.shop_no.toLowerCase().includes(filter.search.toLowerCase()))) {
				return false
			}

			if (enableFloor) {
				if (filter.floor && item.floor !== filter.floor) {
					return false
				}
			}

			return true
		})

		// console.log(itemsFiltered)

		setData((prevState) => ({
			...prevState,
			itemsFiltered: itemsFiltered,
		}))
	}, [data.items, filter, enableFloor])

	// Get current pages data
	useEffect(() => {
		let itemsCurrent = data.itemsFiltered.slice(0, filter.currentPage * perPage)

		setData((prevState) => ({
			...prevState,
			itemsCurrent: itemsCurrent,
		}))

		setTimeout(() => {
			setIsLoading(false)
			setIsLoadingPage(false)
			if (scrollToTarget) {
				scrollTo(scrollToTarget)
			}
		}, 200)
		// eslint-disable-next-line
	}, [data.itemsFiltered, filter.currentPage])

	const handleChangeCategory = (e) => {
		handleUpdateFilter({ category: e.target.value })
	}

	const handleChangeSearch = (e) => {
		setSearch(e.target.value)
		handleUpdateFilter({ search: e.target.value })
	}

	const handleSearch = (e) => {
		e.preventDefault()
		handleUpdateFilter({ search: search })
	}

	// Load more
	const handleLoadMore = () => {
		const page = filter.currentPage + 1
		handleChangePage(page)
	}

	const handleChangePage = (page) => {
		setIsLoadingPage(true)
		setFilter((prevState) => ({
			...prevState,
			currentPage: page || 1,
		}))
	}

	const handleChangeFloor = (floor) => {
		if (floor === filter.floor) return false
		handleUpdateFilter({ floor: floor })
		resetActiveItem()
	}

	const handleUpdateFilter = (updatedFilter) => {
		setIsLoading(true)
		setFilter((prevState) => ({
			...prevState,
			...updatedFilter,
			currentPage: 1,
		}))
		resetActiveItem()
	}

	const resetFilter = () => {
		if (filter.category || filter.search) {
			setIsLoading(true)
			setFilter((prevState) => ({
				...prevState,
				currentPage: 1,
				category: '',
				search: '',
			}))
		}
	}

	const handleClearSearch = () => { }

	const [activeId, setActiveId] = useState(null)
	const [scrollToTarget, setScrollToTarget] = useState(null)

	useEffect(() => {
		$(document).on('click', '[data-shop-id]', function () {
			const id = $(this).attr('data-shop-id')
			resetActiveItem()
			resetFilter()
			setActiveItem(id)
		})
		return () => {
			$(document).off('click', '[data-shop-id]')
		}
		// eslint-disable-next-line
	}, [data])

	const resetActiveItem = () => {
		setActiveId()
		$(`[data-shop-id].active`).removeClass('active')
	}

	const setActiveItem = (id) => {
		setActiveId(id)
		$(`[data-shop-id="${id}"]`).addClass('active')

		const targetIndex = _.findIndex(data.itemsFiltered, function (item) {
			return item.shop_no === id
		})

		let targetPage = Math.ceil((targetIndex + 1) / perPage)
		if (targetPage <= filter.currentPage) {
			targetPage = filter.currentPage
		}

		setScrollToTarget(id)
		handleChangePage(targetPage)
	}

	const scrollTo = (target) => {
		const targetEl = $(`[data-component="card"][data-id="${target}"]`)
		if (targetEl.offset()) {
			const targetOffset = targetEl.offset().top
			const offset = 20
			const headerHeight = $('[class^=Header_nav]').height()

			$([document.documentElement, document.body]).animate(
				{
					scrollTop: targetOffset - headerHeight - offset,
				},
				200
			)
			setScrollToTarget(null)
		}
	}

	/* useEffect(() => {
		$(window).on('scroll', function () {
			// desktop
			var windowTop = $(window).scrollTop()
			var footerAboveThirty = $('footer').offset().top - 30
			var headerBelowFifty = $('#headerContainer').height() + 50
			if (windowTop > footerAboveThirty) {
				$('.desktopFloorPlan').css({ position: 'absolute', top: footerAboveThirty })
			} else {
				//$('.desktopFloorPlan').css({ position: 'fixed', top: 100 })
			}

			if (windowTop < headerBelowFifty) {
				$('.desktopFloorPlan').css({ position: 'absolute', top: headerBelowFifty + 50 })
			} else {
				//$('.desktopFloorPlan').css({ position: 'fixed', top: 100 })
			}
		})
	}, [$('.desktopFloorPlan')]) */

	/* useEffect(() => {
		$(window).on('scroll', function () {
			// mobile
			var windowTop = $(window).scrollTop()
			var titleHeight = $('.floorDirectoryTitle').height()
			var filterHeight = $('.floorDirectoryFilter').height()
			var floorDirectoryFloors = $('.floorDirectoryFloors').height()
			var headerBelowFifty = $('#headerContainer').height() + 50

			//console.log(windowTop, floorDirectoryFloors + filterHeight + titleHeight + headerBelowFifty)
			if (windowTop < floorDirectoryFloors + filterHeight + titleHeight + headerBelowFifty) {
				$('.desktopFloorPlan').css({ position: 'absolute', top: headerBelowFifty + 50 })
			} else {
				//$('.desktopFloorPlan').css({ position: 'fixed', top: 100 })
			}
		})
	}, [$('.mobileFloorPlan')]) */

	return (
		<div className={styles.FloorDirectory} data-category={filter.category}>
			{/* <div className={styles.bg}>
				<div className={styles.images}>
					<img src="/images-local/block-tb-bs.png" alt="" />
					<img src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-3-h.png" alt="" />
					<img src="/images-local/block-bs-tb.png" alt="" />
				</div>
			</div> */}

			<div className="container-boxed">
				<div className={styles.main}>

					<div className={`${styles.floorPlan} desktopFloorPlan`}>
						<div className="container-boxed">
							<div className={styles.inner}>
								<SVG className={filter.floor === '2/F' ? styles.active : ''} src="/images-local/floor-2.svg" uniquifyIDs={true} />
								<SVG className={filter.floor === '1/F' ? styles.active : ''} src="/images-local/floor-1.svg" uniquifyIDs={true} />
								<SVG className={filter.floor === 'G/F' ? styles.active : ''} src="/images-local/floor-g.svg" uniquifyIDs={true} />
							</div>
							<div className={styles.link}>
								<a href="https://www.centralmarket.hk/data/data-local/download/s22051_shopping_guide_v18B.pdf" target="_blank" className="links">{t('Click here')}</a>
								<span>{t('floor_directory_link')}</span>
							</div>
						</div>
					</div>



					<div className={styles.list}>
						<div className={`${styles.title} floorDirectoryTitle`}>
							<TextBorderFill>{t('Floor directory')}</TextBorderFill>
						</div>

						<div className={`colored ${styles.filter} floorDirectoryFilter`}>
							<div className={styles.category}>
								{/* Dropdown category */}
								<Select value={filter.category} onChange={handleChangeCategory} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
									<MenuItem value="">{t('All shop')}</MenuItem>
									{config.categories.map((group, index) => {
										const items = group.categories.map((category, index) => {
											return (
												<MenuItem classes={{ root: 'floorDirectoryFilterItem' }} key={index} value={category}>
													{category}
												</MenuItem>
											)
										})
										const groupItems = group.categories.length ? [<ListSubheader>{group.name}</ListSubheader>, items] : ''
										return groupItems
									})}
								</Select>
							</div>
							<div className={styles.search}>
								<form onSubmit={handleSearch}>
									<FormControl>
										{/* Input search */}
										<TextField onChange={handleChangeSearch} className={styles.half} id="search" label={t('Search')} />
										<IconButton type="button" aria-label={t('Search')} onClick={handleClearSearch}>
											<img src="/images-local/ico-close.svg" alt="" />
										</IconButton>
										<IconButton type="submit" aria-label={t('Search')} onClick={handleSearch}>
											<img src="/images-local/ico-search.svg" alt="" />
										</IconButton>
									</FormControl>
								</form>
							</div>
						</div>


						{enableFloor ? (
							<ul className={`${styles.floors} floorDirectoryFloors`}>
								{config.floors.map((floor) => {
									return (
										<li className={filter.floor === floor ? styles.active : ''}>
											<a onClick={() => handleChangeFloor(floor)}>{floor}</a>
										</li>
									)
								})}
							</ul>
						) : (
							''
						)}


						<div>
							{/* mobile floormap*/}
							<div className={styles.mobileFloorPlan}>
								<div className={styles.inner}>
									<SVG className={filter.floor === '2/F' ? styles.active : styles.inactive} src="/images-local/floor-2.svg" onClick={() => handleChangeFloor('2/F')} uniquifyIDs={true} />
									<SVG className={filter.floor === '1/F' ? styles.active : styles.inactive} src="/images-local/floor-1.svg" onClick={() => handleChangeFloor('1/F')} uniquifyIDs={true} />
									<SVG className={filter.floor === 'G/F' ? styles.active : styles.inactive} src="/images-local/floor-g.svg" onClick={() => handleChangeFloor('G/F')} uniquifyIDs={true} />
								</div>
								<div className={styles.link}>
									<a href="https://www.centralmarket.hk/data/data-local/download/shopping_guide_v13.pdf" target="_blank" className="links">{t('Click here')}</a>
									<span>{t('floor_directory_link')}</span>
								</div>
							</div>

							{/* Items */}
							<div className={styles.items}>
								{isLoading ? (
									<Loader />
								) : data.itemsCurrent.length ? (
									data.itemsCurrent.map((item, index) => {
										return <Card key={index} id={item.shop_no} category={item.category} title={item.title} location={item.shop_no} phone={item.telephone ? item.telephone.match(/.{1,4}/g).join(' ') : ''} pic={item.logo} url={/*item.description ? `/shop-detail/${item.slug}` : ""*/ ''} isSquare isActive={activeId === item.shop_no ? true : false} isFeatured={/*item.description ? true : false*/ false} />
									})
								) : (
									<div className={styles.noResult}>{t('No result')}</div>
								)}

								{/* Load mroe */}
								{isLoadingPage ? (
									<Loader />
								) : filter.currentPage * perPage >= data.itemsFiltered.length || isLoading ? (
									''
								) : (
									<div onClick={handleLoadMore} className={styles.btnLoadMore}>
										<ButtonUnderline>{t('Load more shops')}</ButtonUnderline>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FloorDirectory
