import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";
// Swiper
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "./Vision.scss";
import "./slider.scss";

// Components
import PageTitle from "../../components/PageTitle/PageTitle";
import NavArrow from "../../components/NavArrow/NavArrow";

//i18n
import { useTranslation } from "react-i18next";

// Utils
import getData from "../../utils/getData";

SwiperCore.use([EffectFade, Navigation, Pagination, Autoplay]);
const assignNav = (swiper, nav) => {
  swiper.params.navigation = {
    ...swiper.params.navigation,
    prevEl: nav.prev.current,
    nextEl: nav.next.current,
  };
  swiper.navigation.update();
};

const Vision = () => {
  const { t } = useTranslation(["translation", "menu"]);

  const [data, setData] = useState(null);

  let imgSwiperNav = {
    prev: useRef(),
    next: useRef(),
  };

  // Fetch data
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getData("vision");
        console.log(data);
        setData(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="page-vision">
      <Helmet>
        <title>
          {t("vision_title")} - {t("Central Market")}
        </title>
      </Helmet>
      <PageTitle title={t("menu:Our Concept")} />
      <div className="container-boxed half-padding">
        {/* <div className="page-title">
					<TextBorderFill>{t('vision_title')}</TextBorderFill>
				</div> */}
        <div className="content">
          {data &&
            data.content.map((item, i) => {
              const { type, content } = item;

              if (type !== "slider") {
                return (
                  <div key={i} className={item.type}>
                    {parse(item.content)}
                  </div>
                );
              } else if (type === "slider") {
                return (
                  <div className="slider__section" key={i}>
                    <div className="slider__container">
                      {/* <div className="slider__nav">
												<NavArrow ref={imgSwiperNav} />
											</div> */}
                      <div className="">
                        <Swiper
                          className="slider__swiper"
                          loop={true}
                          effect={"fade"}
                          speed={500}
                          slidesPerView={1}
                          navigation={false}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[EffectFade, Autoplay, Pagination]}
                          autoplay={{
                            delay: 5000,
                            stopOnLastSlide: false,
                            pauseOnMouseEnter: false,
                            disableOnInteraction: false,
                          }}
                        >
                          {content.map((imgItem, i) => {
                            return (
                              <SwiperSlide key={i} className={i}>
                                <img
                                  className=""
                                  src={imgItem.img_path}
                                  alt=""
                                />
                                <div className="">{parse(imgItem.recap)}</div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default Vision;
