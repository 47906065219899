// Core
import { useEffect, useRef, useState } from 'react'
import {useMedia} from 'react-use';
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import useMobileDetect from 'use-mobile-detect-hook';

// Styles
import styles from './Home.module.scss'

// Components
import Button from '../../components/Button/Button'
import BannerCTA from '../../components/BannerCTA/BannerCTA'
import { GeneralCard } from '../../components/Card/Card'
import { SliderArrow } from '../../components/NavArrow/NavArrow'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import TextLoop from '../../components/TextLoop/TextLoop'
import ButtonUnderline from '../../components/ButtonUnderline/ButtonUnderline'
import Image from '../../components/Image/Image'
import ScrollShow from '../../components/ScrollShow/ScrollShow'

// Swiper
import SwiperCore, { EffectFade, Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/components/effect-fade/effect-fade.min.css'

// AOS
import AOS from 'aos'

//i18n
import { useTranslation } from 'react-i18next'

// Utils
import getData from '../../utils/getData'
import { checkIsPeriod, checkIsOngoing } from '../../utils/getDate'
import skewOnScroll from '../../utils/skewOnScroll'

// Lodash
import _ from 'lodash'

SwiperCore.use([EffectFade, Navigation, Pagination, Autoplay])

const HomeSlider = ({ sliders }) => {

    return (
        <>
        <Swiper
            effect={"fade"}
            speed={500}
            navigation={false}
            pagination={{
                clickable: true,
            }}
            modules={[EffectFade, Autoplay, Pagination]}
            autoplay={{ delay: 5000, stopOnLastSlide: false, pauseOnMouseEnter: false, disableOnInteraction: false }}
        >
			{sliders.map(({ id, name, image, image_m, image_atl, action_url },index) => (
				<SwiperSlide key={id}>
					<a href={action_url} target="_blank">
						<Image ratio={1900/600} src={image} mobileSrc={image_m} alt={image_atl} fullHeight/>
					</a>
				</SwiperSlide>
			))}
        </Swiper>
        </>
    )
}

const Home = () => {
	const { t, i18n } = useTranslation()

	const eventsEl = useRef()
	const shopsEl = useRef()
	const promotionsEl = useRef()
	const feedsEl = useRef()
	const isMobile = useMedia('(max-width: 768px)');
	const detectDevice = useMobileDetect();
	const dataSets = ['banner', 'hero', 'event', 'promotion',  'shop', 'feeds']
	const [data, setData] = useState(
		// eslint-disable-next-line
		dataSets.reduce((acc, curr) => ((acc[curr] = []), acc), {})
	)

	const aboutCmIcons = [{
		icon: 'about-cm-1.svg',
		slug: 'our-concept',
		name: t('Our Concept'),
	}, {
		icon: 'about-cm-2.svg',
		slug: 'pet-friendly',
		name: t('Pet Friendly Space'),
	}, {
		icon: 'about-cm-3.svg',
		slug: 'location-transportation',
		name: t('Location & Transportation'),
	}, {
		icon: 'about-cm-4.svg',
		slug: 'contact-us',
		name: t('Contact Us'),
	}, {
		icon: 'about-cm-5.svg',
		slug: 'venue-applications',
		name: t('Venue applications'),
	}]
	useEffect(() => {
		dataSets.forEach((key) => {
			async function getHomeData() {
				try {
					let homeData = await getData(key)
					switch (key) {
						case 'banner':
								homeData = homeData.filter((item) => checkIsPeriod(item) )
								homeData = _.orderBy(homeData, [
									function (o) {
										return o.display_order
									},
								])
							break
						case 'promotion':
							homeData = homeData.filter((el) => el.is_featured === true && checkIsOngoing(el))
							homeData = _.orderBy(homeData, [
								function (o) {
									return o.is_featured_order
								},
							])
							break
						case 'event':
							console.log('homeData', homeData)
							homeData = homeData.filter((el) => el.is_featured === true && checkIsOngoing(el))
							homeData = _.orderBy(homeData, [
								function (o) {
									return o.is_featured_order
								},
							])
							break
						case 'shop':
							homeData = homeData.filter((el) => el.is_featured === true)
							homeData = _.orderBy(homeData, [
								function (o) {
									return o.is_featured_order
								},
							])
							break
						case 'shop_offer':
							homeData = homeData.filter((el) => el.is_featured === true)
							homeData = _.orderBy(homeData, [
								function (o) {
									return o.is_featured_order
								},
							])
							break
					}
					setData((prevState) => ({ ...prevState, [key]: homeData }))
				} catch (error) {
					console.log(error)
				}
			}
			getHomeData()
		})
		// eslint-disable-next-line
	}, [i18n.language])

	console.log('data', data)

	const [activeEventSlide, setActiveEventSlide] = useState(0)

	const assignNav = (swiper, nav) => {
		swiper.params.navigation = {
			...swiper.params.navigation,
			prevEl: nav.prev.current,
			nextEl: nav.next.current,
		}
		swiper.navigation.update()
		swiper.update(true)
		swiper.slideToLoop(0, 0)
	}

	let eventsNav = {
		prev: useRef(),
		next: useRef(),
	}
	let shopHighlightNav = {
		prev: useRef(),
		next: useRef(),
	}
	let promotionNav = {
		prev: useRef(),
		next: useRef(),
	}

	useEffect(() => {
		skewOnScroll()

		// AOS Setting
		AOS.init({
			once: true,
			duration: 800,
		})

		// window.dispatchEvent(new Event('resize'));
		var resizeEvent = window.document.createEvent('UIEvents')
		resizeEvent.initUIEvent('resize', true, false, window, 0)
		window.dispatchEvent(resizeEvent)
	}, [data])

	return (
		<div>
			<Helmet>
				<title>
					{t('Home')} - {t('Central Market')}
				</title>
			</Helmet>
			<div className={styles.home}>
				<section id="heroSection" className={styles.hero}>
					<div className={styles.slider}>
						<HomeSlider sliders={data.banner}/>
					</div>
				</section>
				{/* Events */}
				<section className={`home-events ${styles.events}`} ref={eventsEl}>
					<div className={styles.bg}>
						<div className={styles.images}>
							<img src="/images-local/block-bs-tb.png" alt="" />
							<img className="bg-cloud" src="/images-local/cloud_home.png" alt="" />
						</div>
					</div>
					<div className="container-boxed much-padding">
						<div className={styles.container}>
							<div className={styles.sectionInfo}>
								<ScrollShow effect="fade-up" trigger={eventsEl} >
									<div className={`${styles.title} ${styles.borderTitle}`}>
										<TextBorderFill fontSize="70">{t('Events')}</TextBorderFill>
									</div>
								</ScrollShow>
							</div>
							<div id="event-info-container" className={styles.infoContainer}>
								<Link to="/happenings?type=event">
									<div className={styles.btnViewAll}>
										<div data-aos="fade" data-aos-delay="1200">
											<ButtonUnderline>{t('View all')}</ButtonUnderline>
										</div>
									</div>
								</Link>
							</div>
							<div className={styles.slides}>
								{!isMobile && <SliderArrow ref={eventsNav} />}
								<Swiper
									id="event-swiper"
									effect="fade"
									speed={700}
									slidesPerView={1}
									loop={true}
									pagination={{
										modifierClass: 'cm-pagination-',
										clickable: true,
										renderBullet: function (index, className) {
											let page = index + 1
											let delay = 1450 + index * 100
											if (page < 10) {
												page = '0' + page
											}
											return `<span class="${className}"><div data-aos="fade-up" data-aos-anchor=".home-events" data-aos-delay="${delay}">${page}</div></span>`
										},
									}}
									navigation={{ prevNav: '', nextEl: '' }}
									onInit={(swiper) => {
										assignNav(swiper, eventsNav)
									}}
									onSlideChange={(swiper) => {
										setActiveEventSlide(swiper.realIndex)
									}}
									autoplay={{ delay: 5000, stopOnLastSlide: false, pauseOnMouseEnter: true, disableOnInteraction: false }}
								>
									{data.event.length
										? data.event.map(({ slug, landing_page_image, landing_page_image_alt_tag, title, description, link }, i) => {
											return (
												<SwiperSlide key={i}>
													<div className={styles.slideContainer}>
														<div className={styles.picContainer}>
															<ScrollShow effect="skew-fade" trigger={eventsEl}>
																<Image src={landing_page_image} alt={landing_page_image_alt_tag} />
															</ScrollShow>
														</div>
														<div className={styles.info}>
															<div className={styles.subTitle}>
																{t('Today’s highlight')}
															</div>
															<div className={styles.name}>{title}</div>
															<div className="text-sm lg:text-md">
																<Button href={`/event/${slug}`} text={t('Discover')} white hoverForceRed/>
															</div>
														</div>
													</div>
												</SwiperSlide>
											)
										  })
										: ''}
								</Swiper>
							</div>
						</div>
					</div>
				</section>
				
				{/* Promotion */}
				{data.promotion.length > 0 ?
					<section className={styles.promotion} ref={promotionsEl}>
						<div className={styles.bg}>
							<div className={styles.images}>
								<img src="/images-local/block.png" alt="" />
								<img src="/images-local/block-3-v.png" alt="" />
								<img className="bg-cloud" src="/images-local/cloud_home.png" alt="" />
								{/* <img src="/images-local/block-tb-bs.png" alt="" /> */}
								<img src="/images-local/block.png" alt="" />
								<img src="/images-local/block-ts-bb.png" alt="" />
								<img className="bg-cloud" src="/images-local/cloud-dark_home.png" alt="" />
							</div>
						</div>
						<div className="container-boxed much-padding">
							<div className={styles.container}>
								<ScrollShow effect="fade-up" trigger={promotionsEl}>
									<div className={styles.sectionInfo}>
											<div className={`${styles.title} ${styles.borderTitle}`}>
												<TextBorderFill fontSize="70">{t('Promotions')}</TextBorderFill>
											</div>
									</div>
									<div id="promotion-info-container" className={styles.infoContainer}>
										<Link to="/happenings?type=promotion">
											<div className={styles.btnViewAll}>
												<div data-aos="fade" data-aos-delay="1200">
													<ButtonUnderline>{t('View all')}</ButtonUnderline>
												</div>
											</div>
										</Link>
									</div>
								</ScrollShow>
								<div className={styles.items}>
									{!isMobile && <SliderArrow ref={promotionNav} />}
									<Swiper
										modules={[Navigation]}
										breakpoints={{
											0: {
												initialSlide:0,
												slidesPerView: 1,
												centeredSlides: false,
												centeredSlidesBounds: false,
												spaceBetween: 10,
												loop: data.promotion.length > 1
											},
											768: {
												initialSlide:1,
												slidesPerView: 2,
												centeredSlides: false,
												centeredSlidesBounds: false,
												spaceBetween: 40,
												loop: data.promotion.length > 1
											},
										}}
										navigation={{ prevNav: '', nextEl: '' }}
										loop={data?.promotion && data.promotion.length > 1}
										onInit={(swiper) => {
											assignNav(swiper, promotionNav)
										}}
									>
										{
												data.promotion.map(({ id, landing_page_image, title, slug, start_date, end_date }, index) => (
													<SwiperSlide className={styles.item} key={id}>
														<ScrollShow effect="fade-up" trigger={promotionsEl} delay={500 * 1}>
															<GeneralCard
																id={1}
																title={title}
																pic={landing_page_image}
																url={`/promotion/${slug}`}
																startDate={start_date}
																endDate={end_date}
																isPromotion
															/>
														</ScrollShow>
												</SwiperSlide>
												))
										}
									</Swiper>
								</div>
							</div>
						</div>
					</section>: ""
				}

				{/* Shop highlight */}
				{data.shop.length > 0 ?
						<section className={styles.shopHighlight} ref={shopsEl}>
						<div className={styles.bg}>
							<div className={styles.images}>
								<img src="/images-local/block.png" alt="" />
								<img src="/images-local/block-tb-bs.png" alt="" />
								<img className="bg-cloud" src="/images-local/cloud-dark_home.png" alt="" />
							</div>
						</div>
						<div className="container-boxed much-padding">
							<div className={styles.container}>
								<div className={styles.sectionInfo}>
									<ScrollShow effect="fade-up" trigger={shopsEl} delay={1000}>
										<div className={`${styles.title} ${styles.borderTitle}`}>
											<TextBorderFill fontSize="70">{t('Shops')}</TextBorderFill>
										</div>
									</ScrollShow>
								</div>
								<div id="shop-info-container" className={styles.infoContainer}>
									<Link to="/floor-directory">
										<div className={styles.btnViewAll}>
											<div data-aos="fade" data-aos-delay="1200">
												<ButtonUnderline>{t('View all')}</ButtonUnderline>
											</div>
										</div>
									</Link>
								</div>
								<div className={styles.items}>
									{!isMobile && <SliderArrow ref={shopHighlightNav} shopHighlight/>}
									<Swiper
										id="shop-highlight-swiper"
										initialSlide={0}
										breakpoints={{
											0: {
												spaceBetween: 10,
												slidesPerView: 2,
												centeredSlides: false,
												centeredSlidesBounds: false,
												loop:data.shop.length > 1
											},
											768: {
												spaceBetween: 30,
												slidesPerView: 3,
												centeredSlides: true,
												centeredSlidesBounds: true,
												loop:data.shop.length > 1
											},
										}}
										navigation={{ prevNav: '', nextEl: '' }}
										loop={data.shop.length > 1}
										loopedSlidesLimit={false}
										onInit={(swiper) => {
											assignNav(swiper, shopHighlightNav)
										}}
									>
										{data.shop.length
											? data.shop.map((item, i) => {
												return (
													<SwiperSlide key={i} className={styles.item}>
														<img src={item['logo']} alt={item['logo_alt_tag']} />
														<div className={styles.info}>
															<div className={styles.name}>{item.title}</div>
															<div className={styles.location}>
																<SVG className={styles.fill} src="/images-local/ico-location.svg" alt="" />
																{item.shop_no}
															</div>
														</div>
													</SwiperSlide>
												)
											  })
											: ''}
									</Swiper>
								</div>
							</div>
						</div>
					</section>	: ""
				}


				{/* About CM */}
				<section className={styles.aboutCM} style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/about-cm-banner.jpg` }}>
					<div className={styles.sectionInfo}>
						<div className={`${styles.title} ${styles.center} ${styles.mobileForcecenter}`}>
							<TextBorderFill fontSize="70" white>{t('About cm')}</TextBorderFill>
						</div>
					</div>
					<div className={styles.iconGroup}>
						{aboutCmIcons.map(({ slug, icon, name },index) => (
							<div key={index} className={styles.iconChild}>
								<Link to={slug}>
									<img src={`${process.env.PUBLIC_URL}/images-local/${icon}`} />
									<div className={styles.name}>{name}</div>
								</Link>
							</div>
						))}
					</div>
				</section>
				{/* App */}
				<div className={styles.app}>
					<div className={styles.bg}>
						<div className={styles.images}>
							<img className="bg-cloud" src="/images-local/cloud_home.png" alt="" />
							<img src="/images-local/block-s.png" alt="" />
							<img src="/images-local/block-bs-tb.png" alt="" />
							<img src="/images-local/block-bb-ts.png" alt="" />
							<img src="/images-local/block-s.png" alt="" />
						</div>
					</div>
					<BannerCTA title={t('Mobile app title')} image="/images/about-cm-banner2.jpg" mobileSrc="/images-local/mobile_app.jpg" buttonText={t('Join now')} buttonUrl={"/app"} isReverse/>
				</div>

				{/* ig feed */}
				<section className={styles.feeds} ref={feedsEl}>
					<div className={styles.bg}>
						<div className={styles.images}>
							<img src="/images-local/block-bs-tb.png" alt="" />
						</div>
					</div>
					<TextLoop />

					<div className={styles.container}>
						<div className={styles.items}>
							{data.feeds.length
								? data.feeds.map((item, i) => {
										return (
											<div className={styles.item} key={i}>
												<ScrollShow effect="skew-fade" trigger={feedsEl} delay={i * 500}>
													<Link to={item.link}>
														<Image ratio={360 / 360} src={item.pic} />
														{/* <div className="text-center text-primary font-medium text-lg mt-2">{item.title}</div> */}
													</Link>
												</ScrollShow>
											</div>
										)
								  })
								: ''}
						</div>
					</div>
				</section>
				<div className={styles.figure} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images-local/CM_KV_Play-version_home.png`}}></div>
			</div>
		</div>
	)
}

export default Home
