// Core
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Compoents
import Card from '../../components/Card/Card'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import ButtonUnderline from '../../components/ButtonUnderline/ButtonUnderline'
import Loader from '../../components/Loader/Lodaer'

// material-ui
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'

// Styles
import styles from './MembersOffer.module.scss'

// i18n
import { useTranslation } from 'react-i18next'

// Utils
import getData from '../../utils/getData'

// Lodash
import _ from 'lodash'

const EventOffer = () => {
	const { t, i18n } = useTranslation('')
	const { page } = useParams()

	const perPage = 9

	const [search, setSearch] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingPage, setIsLoadingPage] = useState(false)

	const [data, setData] = useState({
		items: [],
		itemsFiltered: [],
		itemsCurrent: [],
	})

	const [filter, setFilter] = useState({
		currentPage: page || 1,
		categories: [],
		category: '',
		search: '',
		date: new Date(),
	})

	// Fetch data
	useEffect(() => {
		setIsLoading(true)

		async function fetchData() {
			try {
				let dataRaw = await getData('event_offer')
				console.log(dataRaw)
				const availableCategories = _.map(_.uniqBy(dataRaw, 'category'), 'category')

				const data = _.sortBy(dataRaw, [
					function (o) {
						return o.display_orde
					},
				])

				setData((prevState) => ({
					...prevState,
					items: data,
				}))

				setFilter((prevState) => ({
					...prevState,
					categories: availableCategories,
				}))
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	// Filter data
	useEffect(() => {
		let itemsFiltered = data.items

		if (filter.category) {
			itemsFiltered = itemsFiltered.filter((item) => item.category === filter.category)
		}

		if (filter.search) {
			console.log(filter.search)
			itemsFiltered = itemsFiltered.filter((item) => item.title.toLowerCase().includes(filter.search.toLowerCase()))
		}

		setData((prevState) => ({
			...prevState,
			itemsFiltered: itemsFiltered,
		}))
	}, [data.items, filter])

	// Get current pages data
	useEffect(() => {
		let itemsCurrent = data.itemsFiltered.slice(0, filter.currentPage * perPage)

		setData((prevState) => ({
			...prevState,
			itemsCurrent: itemsCurrent,
		}))

		setTimeout(() => {
			setIsLoading(false)
			setIsLoadingPage(false)
		}, 200)
	}, [data.itemsFiltered, filter.currentPage])

	// Change category
	const handleChangeCategory = (e) => {
		setIsLoading(true)
		setFilter((prevState) => ({
			...prevState,
			category: e.target.value,
		}))
	}

	// Serach typing
	const handleChangeSearch = (e) => {
		setSearch(e.target.value)
	}

	// Search submit
	const handleSearch = (e) => {
		setIsLoading(true)
		e.preventDefault()
		setFilter((prevState) => ({
			...prevState,
			search: search,
		}))
	}

	// Load more
	const handleLoadMore = () => {
		setIsLoadingPage(true)
		setFilter((prevState) => ({
			...prevState,
			currentPage: filter.currentPage + 1,
		}))
	}

	return (
		<div className={styles.EventOffer}>
			<Helmet>
				<title>
					{t('Privileges')} - {t('Central Market')}
				</title>
			</Helmet>
			<div className={styles.bg}>
				<div className="bg-elements overflow-hidden">
					<img src="/images-local/block-3-h.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-s.png" style={{ opacity: 0 }} alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-ts-bb.png" alt="" />
					<img src="/images-local/block-bs-tb.png" alt="" />
					<img src="/images-local/CM_KV_Play-version.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
				</div>
			</div>

			<div className="container-boxed py-16">
				<div className={styles.title}>
					<TextBorderFill>{t('Privileges')}</TextBorderFill>
				</div>

				<div className={`colored ${styles.filter}`}>
					<div className={styles.category}>
						{/* Dropdown category */}
						<Select value={filter.category} onChange={handleChangeCategory} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
							<MenuItem value="">{t('Category')}</MenuItem>
							{filter.categories.map((category, index) => {
								return (
									<MenuItem key={index} value={category}>
										{category}
									</MenuItem>
								)
							})}
						</Select>
					</div>
					<div className={styles.search}>
						<form onSubmit={handleSearch}>
							<FormControl>
								{/* Input search */}
								<TextField onChange={handleChangeSearch} className={styles.half} id="search" label={t('Search')} />
								<IconButton type="submit" aria-label={t('Search')} onClick={handleSearch}>
									<img src="/images-local/ico-search.svg" alt="" />
								</IconButton>
							</FormControl>
						</form>
					</div>
				</div>

				{/* Items */}
				{isLoading ? (
					<Loader />
				) : (
					<div className={styles.items}>
						{data.itemsCurrent.map((item, index) => {
							return <Card key={index} title={item.title} pic={item.slider[0].image} url={`/privileges/${item.slug}`} />
						})}
					</div>
				)}

				{/* Load mroe */}
				{isLoadingPage ? (
					<Loader />
				) : filter.currentPage * perPage >= data.itemsFiltered.length || isLoading ? (
					''
				) : (
					<div onClick={handleLoadMore} className={styles.btnLoadMore}>
						<ButtonUnderline>{t('Load more events')}</ButtonUnderline>
					</div>
				)}
			</div>
		</div>
	)
}

export default EventOffer
