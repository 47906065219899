// Core
import { useEffect, useState, useRef } from 'react'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet-async'

import style from './History.module.scss'

// Components
import PageTitle from '../../components/PageTitle/PageTitle'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import { HashLink } from 'react-router-hash-link'
import Button from '../../components/Button/Button'

// i18n
import { useTranslation } from 'react-i18next'

// Axios
// import axios from 'axios'
import axios from '../../config/axios'
// import { ContextExclusionPlugin } from 'webpack';

const History = () => {
	const { t, i18n } = useTranslation()

	const [addfixedClass, showFooter] = useState(false)
	const [addnoneClass, showFooternone] = useState(false)
	const [canvalineItems, updateCanva] = useState({
		pt4_line: 0,
		pt5_line: 0,
		pt5_line_w: 0,
		pt5a_line: 0,
		pt5a_line_w: 0,
		pt6_line: 0,
		pt6_line_w: 0,
		pt7a_line: 0,
		pt7a_line_w: 0,
		pt7_line_style: { top: 'calc(35%-18px)' },
		pt7_line: 0,
		pt7_line_w: 0,
		pt8_line: 0,
		pt8_line_w: 0,
		pt8b_line: 0,
		pt8b_line_w: 0,
		pt8c_line: 0,
		pt8c_line_w: 0,
		pt9_line: 0,
		pt9_line_w: 0,
		pt9b_line: 0,
		pt9b_line_w: 0,
		pt10_line: 0,
		pt10_line_w: 0,
		pt11_line: 0,
		pt11_line_w: 0,
		pt11a_line: 0,
		pt11a_line_w: 0,
		pt12_line: 0,
		pt12_line_w: 0,
		pt13_line: 0,
		pt13_line_w: 0,
		pt14_line: 0,
		pt14_line_w: 0,
		pt15_line: 0,
		pt15_line_w: 0,
		pt16_line: 0,
		pt16_line_w: 0,
	})

	const [data, setData] = useState({
		title_desp: '',
		timeline: {
			yr1842: {},
			yr1850: {},
			yr1895: {},
			yr1939: {},
			yr1941: {},
			yr1989: {},
			yr1993: {},
			yr1994: {},
			yr2003: {},
			yr2009: {},
			yr2021: {},
		},
		showFooter: false,
		arrowimgdown: '/images-local/ico-arrow-down.svg',
		arrowimgup: '/images-local/ico-arrow-up.svg',
		dropDownActive: false,
	})

	const [cline, setClineConterhigh] = useState({
		clineContainersHeigh: {
			pt5_container: { height: '0px', width: '2px' },
			pt5a_container: { height: '0px' },
			pt6_container: { height: '0px' },
			pt7a_container: { height: '0px' },
			pt7_container: { height: '0px' },
			pt8_container: { height: '0px' },
			pt8b_container: { height: '0px' },
			pt8c_container: { height: '0px' },
			pt9_container: { height: '0px' },
			pt9b_container: { height: '0px' },
			pt10_container: { height: '0px' },
			pt11_container: { height: '0px' },
			pt11a_container: { height: '0px' },
			pt12_container: { height: '0px' },
			pt13_container: { height: '0px' },
			pt14_container: { height: '0px' },
			pt15_container: { height: '0px' },
			pt16_container: { height: '0px' },
		},
	})

	const [yeardiv, setActiveYear] = useState({
		yr1842: false,
		yr1850s: false,
		yr1895: false,
		yr1939: false,
		yr1941: false,
		yr1989: false,
		yr1993: false,
		yr1994: false,
		yr2003: false,
		yr2009: false,
		yrnow: false,
	})

	const drawDashedLine = (lineid, pattern, linearrow) => {
		const canvas = document.getElementById(lineid)
		const ctx = canvas.getContext('2d')
		const screen_width = window.innerWidth
		ctx.clearRect(0, 0, canvas.width, canvas.height)
		ctx.beginPath()
		ctx.setLineDash(pattern)
		if (lineid === 'pt6') {
			screen_width >= 1024 ? ctx.moveTo(document.getElementById('1842').offsetWidth - 80, 1) : ctx.moveTo(document.getElementById('1842').offsetWidth + 18, 1)
		} else if (lineid === 'pt7a' && screen_width < 1024) {
			ctx.moveTo(100, 1)
		} else if (lineid === 'pt8') {
			screen_width >= 1024 ? ctx.moveTo(document.getElementById('1895').offsetWidth - 80, 1) : ctx.moveTo(document.getElementById('1895').offsetWidth, 1)
		} else if (lineid === 'pt8b' && screen_width < 1024) {
			ctx.moveTo(document.getElementById('1939').offsetWidth / 2 - 20, 1)
		} else if (lineid === 'pt9' && screen_width < 1024) {
			ctx.moveTo(40, 1)
		} else if (lineid === 'pt9b' && screen_width < 1024) {
			ctx.moveTo(document.getElementById('1941-details').offsetWidth + 20, 1)
		} else if (lineid === 'pt10' && screen_width >= 1024) {
			ctx.moveTo(document.getElementById('1941').offsetWidth / 2 + 10, 1)
		} else if (lineid === 'pt11' && screen_width < 1024) {
			ctx.moveTo(document.getElementById('1993').offsetWidth, 1)
		} else if (lineid === 'pt12') {
			screen_width >= 1024 ? ctx.moveTo(document.getElementById('1993').offsetWidth - 60, 1) : ctx.moveTo(document.getElementById('1993').offsetWidth, 1)
		} else if (lineid === 'pt13') {
			screen_width >= 1024 ? ctx.moveTo(1, 1) : ctx.moveTo(1, 1)
		} else if (lineid === 'pt14') {
			screen_width >= 1024 ? ctx.moveTo(document.getElementById('2003').offsetWidth - 60, 1) : ctx.moveTo(document.getElementById('2003').offsetWidth - 40, 1)
		} else if (lineid === 'pt16' && screen_width < 1024) {
			ctx.moveTo(document.getElementById('2009').offsetWidth + 20, 1)
		} else {
			ctx.moveTo(1, 1)
		}
		linearrow.forEach((item) => {
			ctx.lineTo(item.w, item.h)
		})
		// ctx.lineTo(width, height);
		// ctx.lineTo(100, height);
		// if(lineid === 'pt5'){
		//    ctx.lineTo(100, height);
		// }
		ctx.lineWidth = 2
		ctx.strokeStyle = '#C8215D'
		ctx.stroke()

		// requestAnimationFrame(() => draw(ctx));
	}

	// Fetch data
	useEffect(() => {
		axios.get(i18n.language + '/history.json').then((result) => {
			const res = result.data
			console.log(res)
			Object.keys(res.timeline).forEach((itemkey) => {
				const yrdetails = res.timeline[itemkey]
				yrdetails.year_title = parse(yrdetails.year_title)
				yrdetails.year_qouat = parse(yrdetails.year_qouat)
			})

			setData((prevState) => ({
				...prevState,
				title_desp: res.title_desp,
				timeline: res.timeline,
			}))
			//   console.log(data.timeline["yr1842"].year_title)
			setTimeout(() => {
				const screen_width = window.innerWidth

				const canvas_lines_Mb = {
					pt4: [{ w: document.getElementById('1842').offsetWidth / 2, h: 1 }],
					pt5: [
						{
							w: 1,
							h: document.getElementsByClassName('img-18421')[0].height,
						},
						{
							w: document.getElementById('1842').offsetWidth - 80,
							h: document.getElementsByClassName('img-18421')[0].height,
						},
						{
							w: document.getElementById('1842').offsetWidth - 80,
							h: document.getElementById('1842').offsetHeight - 75 + 150,
						},
					],
				}

				const canvas_lines_lg = {
					pt4: [{ w: document.getElementById('1842').offsetWidth / 2 + 30, h: 1 }],
					pt5: [
						{
							w: 1,
							h: document.getElementById('1842').offsetHeight,
						},
						{
							w: document.getElementById('1842').offsetWidth + 18,
							h: document.getElementById('1842').offsetHeight,
						},
					],
					pt6: [
						{
							w: document.getElementById('1842').offsetWidth + 18,
							h: document.getElementById('1850s').offsetHeight,
						},
						{
							w: 100,
							h: document.getElementById('1850s').offsetHeight,
						},
						{
							w: 100,
							h: document.getElementById('1850s').offsetHeight + 25,
						},
					],
					pt7a: [
						{
							w: 100,
							h: document.getElementById('1895').offsetHeight * 0.3,
						},
						{
							w: 1,
							h: document.getElementById('1895').offsetHeight * 0.3,
						},
					],
					pt7: [
						{
							w: 1,
							h: document.getElementById('1895').offsetHeight * 0.7,
						},
						{
							w: document.getElementById('1895').offsetWidth,
							h: document.getElementById('1895').offsetHeight * 0.7,
						},
						{
							w: document.getElementById('1895').offsetWidth,
							h: document.getElementById('1895').offsetHeight + 50,
						},
					],
					pt8: [
						{
							w: document.getElementById('1895').offsetWidth,
							h: document.getElementById('1939').offsetHeight * 0.4,
						},
						{
							w: document.getElementById('1939').offsetWidth / 2 - 20,
							h: document.getElementById('1939').offsetHeight * 0.4,
						},
					],
					pt8b: [
						{
							w: document.getElementById('1939').offsetWidth / 2 - 20,
							h: 90,
						},
						{
							w: 40,
							h: 90,
						},
					],
					pt8c: [
						{
							w: 1,
							h: document.getElementById('1939').offsetHeight * 0.6 - 90,
						},
					],
					pt9: [
						{
							w: 40,
							h: document.getElementById('1941').offsetHeight * 0.5,
						},
						{
							w: document.getElementById('1941-details').offsetWidth + 20,
							h: document.getElementById('1941').offsetHeight * 0.5,
						},
					],
					pt9b: [
						{
							w: document.getElementById('1941-details').offsetWidth + 20,
							h: document.getElementById('1941').offsetHeight * 0.5 + 15,
						},
						{
							w: 1,
							h: document.getElementById('1941').offsetHeight * 0.5 + 15,
						},
					],
					pt10: [
						{
							w: 1,
							h: document.getElementById('1989').offsetHeight,
						},
						{
							w: document.getElementById('1941-details').offsetWidth + 20,
							h: document.getElementById('1989').offsetHeight,
						},
					],
					pt11a: [
						{
							w: 1,
							h: document.getElementById('1993').offsetHeight / 3,
						},
						{
							w: document.getElementById('1993').offsetWidth - document.getElementById('1941-details').offsetWidth + 20,
							h: document.getElementById('1993').offsetHeight / 3,
						},
					],
					pt11: [
						{
							w: document.getElementById('1993').offsetWidth,
							h: (document.getElementById('1993').offsetHeight / 3) * 2 + 20,
						},
					],
					pt12: [
						{
							w: document.getElementById('1993').offsetWidth,
							h: document.getElementById('1994').offsetHeight * 0.6,
						},
						{
							w: document.getElementById('1993').offsetWidth / 2,
							h: document.getElementById('1994').offsetHeight * 0.6,
						},
						{
							w: document.getElementById('1993').offsetWidth / 2,
							h: document.getElementById('1994').offsetHeight + 20,
						},
					],
					pt13: [
						{
							w: 1,
							h: document.getElementById('2003-details').offsetTop,
						},
						{
							w: document.getElementById('1993').offsetWidth / 2 - 20,
							h: document.getElementById('2003-details').offsetTop,
						},
						{
							w: document.getElementById('1993').offsetWidth / 2 - 20,
							h: document.getElementById('2003').offsetHeight + 20,
						},
					],
					pt14: [
						{
							w: document.getElementById('2003').offsetWidth - 40,
							h: document.getElementById('2009').offsetHeight * 0.5,
						},
						{
							w: 1,
							h: document.getElementById('2009').offsetHeight * 0.5,
						},
					],
					pt15: [
						{
							w: 1,
							h: document.getElementById('2009').offsetHeight / 2 + 20,
						},
						{
							w: document.getElementById('2009').offsetWidth + 20,
							h: document.getElementById('2009').offsetHeight / 2 + 20,
						},
					],
					pt16: [
						{
							w: document.getElementById('2009').offsetWidth + 20,
							h: document.getElementById('now').offsetHeight * 0.6,
						},
						{
							w: 40,
							h: document.getElementById('now').offsetHeight * 0.6,
						},
					],
					pt17: [
						{
							w: 1,
							h: document.getElementById('now').offsetHeight * 0.4 + 170,
						},
					],
				}

				const canvas_lines = {
					pt1: [{ w: 1, h: 50 }],
					pt2: [{ w: 1, h: 50 }],
					pt3: [{ w: 1, h: 75 }],
					pt4: [{ w: document.getElementById('1842').offsetWidth / 2 - 40, h: 1 }],
					pt5: [
						{
							w: 1,
							h: document.getElementsByClassName('img-18421')[0].height,
						},
						{
							w: document.getElementById('1842').offsetWidth - 80,
							h: document.getElementsByClassName('img-18421')[0].height,
						},
						{
							w: document.getElementById('1842').offsetWidth - 80,
							h: document.getElementById('1842').offsetHeight - 75,
						},
					],
					pt6: [
						{
							w: document.getElementById('1842').offsetWidth - 80,
							h: document.getElementById('1850s').offsetHeight,
						},
						{
							w: 1,
							h: document.getElementById('1850s').offsetHeight,
						},
					],
					pt7: [
						{
							w: 1,
							h: document.getElementById('1895').offsetHeight - 25,
						},
						{
							w: document.getElementById('1895').offsetWidth - 80,
							h: document.getElementById('1895').offsetHeight - 25,
						},
					],
					pt8: [
						{
							w: document.getElementById('1895').offsetWidth - 80,
							h: document.getElementById('1939').offsetHeight - 25,
						},
						{
							w: document.getElementById('1895').offsetWidth / 2 - 40,
							h: document.getElementById('1939').offsetHeight - 25,
						},
						{
							w: document.getElementById('1895').offsetWidth / 2 - 40,
							h: document.getElementById('1939').offsetHeight + 25,
						},
						{
							w: 1,
							h: document.getElementById('1939').offsetHeight + 25,
						},
					],
					pt9: [
						{
							w: 1,
							h: document.getElementById('1941').offsetHeight * 0.8,
						},
						{
							w: document.getElementById('1941').offsetWidth / 2 + 10,
							h: document.getElementById('1941').offsetHeight * 0.8,
						},
						{
							w: document.getElementById('1941').offsetWidth / 2 + 10,
							h: document.getElementById('1941').offsetHeight + 2,
						},
					],
					pt10: [
						{
							w: document.getElementById('1941').offsetWidth / 2 + 10,
							h: document.getElementById('1989').offsetHeight - 50,
						},
						{
							w: 1,
							h: document.getElementById('1989').offsetHeight - 50,
						},
						{
							w: 1,
							h: document.getElementById('1989').offsetHeight,
						},
					],
					pt11: [
						{
							w: 1,
							h: document.getElementById('1993').offsetHeight * 0.8,
						},
						{
							w: document.getElementById('1993').offsetWidth / 2 - 10,
							h: document.getElementById('1993').offsetHeight * 0.8,
						},
						{
							w: document.getElementById('1993').offsetWidth / 2 - 10,
							h: document.getElementById('1993').offsetHeight + 2,
						},
						{
							w: document.getElementById('1993').offsetWidth - 60,
							h: document.getElementById('1993').offsetHeight + 2,
						},
					],
					pt12: [
						{
							w: document.getElementById('1993').offsetWidth - 60,
							h: document.getElementById('1994').offsetHeight,
						},
						{
							w: document.getElementById('1994').offsetWidth / 2 - 60,
							h: document.getElementById('1994').offsetHeight,
						},
					],
					pt13: [
						{
							w: 1,
							h: document.getElementById('2003').offsetHeight,
						},
						{
							w: document.getElementById('2003').offsetWidth - 60,
							// w: document.getElementById('2003').offsetWidth - 60,
							h: document.getElementById('2003').offsetHeight,
						},
					],
					pt14: [
						{
							w: document.getElementById('2003').offsetWidth - 60,
							h: document.getElementById('2009').offsetHeight - 50,
						},
						{
							w: 1,
							h: document.getElementById('2009').offsetHeight - 50,
						},
					],
					pt16: [
						{
							w: 1,
							h: document.getElementById('now').offsetHeight - 75,
						},
						{
							w: document.getElementById('now').offsetWidth / 2 - 40,
							h: document.getElementById('now').offsetHeight - 75,
						},
						{
							w: document.getElementById('now').offsetWidth / 2 - 40,
							h: document.getElementById('now').offsetHeight + 40,
						},
					],
				}

				updateCanva((prevState) => ({
					...prevState,
					pt4_line: screen_width >= 1024 ? document.getElementsByClassName('img-18421')[0].width - 40 : document.getElementsByClassName('img-18421')[0].width + 10,
					// pt5_line: screen_width >= 1280 ? document.getElementsByClassName('img-18421')[0].height + 2 : document.getElementById('1842').offsetHeight - 75 + 150,
					pt5_line: screen_width >= 1280 ? document.getElementById('1842').offsetHeight + 2 : document.getElementById('1842').offsetHeight - 75 + 150,
					pt5_line_w: screen_width >= 1024 ? document.getElementById('1842').offsetWidth - 80 + 2 : document.getElementById('1842').offsetWidth + 20,
					// pt5a_line: screen_width >= 1280 ? document.getElementById('1842').offsetHeight - document.getElementsByClassName('img-18421')[0].height - 75 : document.getElementById('1842').offsetHeight - 75 + 150,
					// pt5a_line_w: screen_width >= 1024 ? document.getElementById('1842').offsetWidth - 80 + 2 : document.getElementById('1842').offsetWidth + 20,
					pt6_line: screen_width >= 1024 ? document.getElementById('1850s').offsetHeight + 2 : document.getElementById('1850s').offsetHeight + 50 + 4,
					pt6_line_w: screen_width >= 1024 ? document.getElementById('1842').offsetWidth - 80 + 2 : document.getElementById('1842').offsetWidth + 22,
					pt7a_line: screen_width >= 1024 ? 0 : document.getElementById('1895').offsetHeight * 0.3 + 2,
					pt7a_line_w: screen_width >= 1024 ? 0 : 102,
					pt7_line: screen_width >= 1024 ? document.getElementById('1895').offsetHeight + 2 : document.getElementById('1895').offsetHeight * 0.7 + 52,
					pt7_line_w: screen_width >= 1024 ? document.getElementById('1895').offsetWidth - 80 + 2 : document.getElementById('1895').offsetWidth + 2,
					pt8_line: screen_width >= 1024 ? document.getElementById('1939').offsetHeight + 25 + 2 : document.getElementById('1939').offsetHeight * 0.4 + 2,
					pt8_line_w: screen_width >= 1024 ? document.getElementById('1939').offsetWidth - 80 + 2 : document.getElementById('1939').offsetWidth + 2,
					pt8b_line: screen_width >= 1024 ? 0 : 92,
					pt8b_line_w: screen_width >= 1024 ? 0 : document.getElementById('1939').offsetWidth / 2 - 18,
					pt8c_line: screen_width >= 1024 ? 0 : document.getElementById('1939').offsetHeight * 0.6 - 90,
					pt8c_line_w: screen_width >= 1024 ? 0 : 6,
					pt9_line: screen_width >= 1024 ? document.getElementById('1941').offsetHeight + 2 : document.getElementById('1941').offsetHeight * 0.5 + 42,
					pt9_line_w: document.getElementById('1941').offsetWidth + 2,
					pt9b_line: screen_width >= 1024 ? 0 : document.getElementById('1941').offsetHeight * 0.5 + 42,
					pt9b_line_w: screen_width >= 1024 ? 0 : document.getElementById('1941-details').offsetWidth + 22,
					pt10_line: document.getElementById('1989').offsetHeight + 2,
					pt10_line_w: document.getElementById('1941-details').offsetWidth + 12,
					pt11a_line: screen_width >= 1024 ? 0 : document.getElementById('1993').offsetHeight / 3 + 2,
					pt11a_line_w: document.getElementById('1993').offsetWidth - document.getElementById('1941-details').offsetWidth - 18,
					pt11_line: screen_width >= 1024 ? document.getElementById('1993').offsetHeight + 27 : document.getElementById('1993').offsetHeight + 22,
					pt11_line_w: document.getElementById('1993').offsetWidth + 2,
					pt12_line: screen_width >= 1024 ? document.getElementById('1994').offsetHeight + 2 : document.getElementById('1994').offsetHeight + 22,
					pt12_line_w: screen_width >= 1024 ? document.getElementById('1994').offsetWidth - 60 + 2 : document.getElementById('1994').offsetWidth + 2,
					pt13_line: screen_width >= 1024 ? document.getElementById('2003').offsetHeight + 2 : document.getElementById('2003').offsetHeight + 22,
					pt13_line_w: document.getElementById('1994').offsetWidth / 2 + 2,
					pt14_line: screen_width >= 1024 ? document.getElementById('2009').offsetHeight + 2 : document.getElementById('2009').offsetHeight * 0.5 + 2,
					pt14_line_w: screen_width >= 1024 ? document.getElementById('2009').offsetWidth + 2 : document.getElementById('2003').offsetWidth - 38,
					pt15_line: screen_width >= 1024 ? 0 : document.getElementById('2009').offsetHeight / 2 + 22,
					pt15_line_w: screen_width >= 1024 ? 0 : document.getElementById('2009').offsetWidth + 22,
					pt16_line: screen_width >= 1024 ? document.getElementById('now').offsetHeight + 42 : document.getElementById('now').offsetHeight * 0.7 + 2,
					pt16_line_w: screen_width >= 1024 ? document.getElementById('now').offsetWidth + 2 : document.getElementById('2009').offsetWidth + 24,
					pt17_line: document.getElementById('now').offsetHeight * 0.3 + 170,
					pt17_line_w: screen_width >= 1024 ? 0 : document.getElementById('now').offsetWidth + 22,
				}))

				drawDashedLine('pt1', [5, 5], canvas_lines.pt1)
				drawDashedLine('pt2', [5, 5], canvas_lines.pt2)
				drawDashedLine('pt3', [5, 5], canvas_lines.pt3)
				drawDashedLine('pt4', [5, 5], screen_width >= 1024 ? canvas_lines.pt4 : canvas_lines_lg.pt4)
				drawDashedLine('pt5', [5, 5], screen_width >= 1280 ? canvas_lines.pt5 : screen_width >= 1024 ? canvas_lines_Mb.pt5 : canvas_lines_lg.pt5)
				drawDashedLine('pt6', [5, 5], screen_width >= 1024 ? canvas_lines.pt6 : canvas_lines_lg.pt6)
				drawDashedLine('pt7a', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt7a : canvas_lines_lg.pt7a)
				drawDashedLine('pt7', [5, 5], screen_width >= 1024 ? canvas_lines.pt7 : canvas_lines_lg.pt7)
				drawDashedLine('pt8', [5, 5], screen_width >= 1024 ? canvas_lines.pt8 : canvas_lines_lg.pt8)
				drawDashedLine('pt8b', [5, 5], canvas_lines_lg.pt8b)
				drawDashedLine('pt8c', [5, 5], canvas_lines_lg.pt8c)
				drawDashedLine('pt9', [5, 5], screen_width >= 1024 ? canvas_lines.pt9 : canvas_lines_lg.pt9)
				drawDashedLine('pt9b', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt9b : canvas_lines_lg.pt9b)
				drawDashedLine('pt10', [5, 5], screen_width >= 1024 ? canvas_lines.pt10 : canvas_lines_lg.pt10)
				drawDashedLine('pt11a', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt11a : canvas_lines_lg.pt11a)
				drawDashedLine('pt11', [5, 5], screen_width >= 1024 ? canvas_lines.pt11 : canvas_lines_lg.pt11)
				drawDashedLine('pt12', [5, 5], screen_width >= 1024 ? canvas_lines.pt12 : canvas_lines_lg.pt12)
				drawDashedLine('pt13', [5, 5], screen_width >= 1024 ? canvas_lines.pt13 : canvas_lines_lg.pt13)
				drawDashedLine('pt14', [5, 5], screen_width >= 1024 ? canvas_lines.pt14 : canvas_lines_lg.pt14)
				drawDashedLine('pt15', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt15 : canvas_lines_lg.pt15)
				drawDashedLine('pt16', [5, 5], screen_width >= 1024 ? canvas_lines.pt16 : canvas_lines_lg.pt16)
				drawDashedLine('pt17', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt17 : canvas_lines_lg.pt17)

				const isElementXPercentInViewport = function (elrect, percentVisible) {
					let rect = elrect,
						windowHeight = window.innerHeight || document.documentElement.clientHeight

					return !(Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) < percentVisible || Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible)
				}

				let containerCheckList = ['pt5', 'pt6', 'pt7a', 'pt7', 'pt8', 'pt8b', 'pt8c', 'pt9', 'pt9b', 'pt10', 'pt11a', 'pt11', 'pt12', 'pt13', 'pt14', 'pt15', 'pt16', 'pt17']

				containerCheckList.forEach((item, idx) => {
					const itemContainer = item + '_container'
					const targetEle = document.getElementById(itemContainer)
					const rect = targetEle.getBoundingClientRect()
					let elementHeigh = 0
					let elementwidth = item === 'pt16' && window.innerWidth < 1024 ? 7 : 3
					let widthunit = 'px'
					if (isElementXPercentInViewport(rect, document.getElementById(item).offsetTop)) {
						let theTotalH = document.getElementById(item).offsetHeight
						let theTotalw = document.getElementById(item).offsetWidth
						const winBottom = window.pageYOffset + window.innerHeight * 1.55
						const eleOffsetTop = rect.top + window.pageYOffset + window.innerHeight
						var eleHpercentage = (winBottom - eleOffsetTop) / theTotalH
						if (eleHpercentage > 0.9) {
							eleHpercentage = 1
						}
						let totalHeight = (theTotalH + theTotalw) * eleHpercentage

						if (totalHeight <= theTotalH) {
							elementHeigh = totalHeight
						} else {
							elementHeigh = theTotalH
							elementwidth = totalHeight - theTotalH >= 3 ? totalHeight - theTotalH : 3
						}

						if (elementHeigh < 0) {
							elementHeigh = 0
						}
					} else if (rect.top <= 0) {
						elementHeigh = document.getElementById(item).offsetHeight
						elementwidth = document.getElementById(item).offsetWidth
					}

					cline.clineContainersHeigh[itemContainer] = { height: elementHeigh + 'px', width: elementwidth + widthunit }
				})

				setClineConterhigh({ ...cline })

				const activeYearArray = ['1842', '1850s', '1895', '1939', '1941', '1989', '1993', '1994', '2003', '2009', 'now']

				let haveactive = false
				for (let i = 0; i < activeYearArray.length; i++) {
					const targetDiv = document.getElementById(activeYearArray[i])
					const targetName = 'yr' + activeYearArray[i]
					const divrect = targetDiv.getBoundingClientRect()
					if (divrect.top >= 0 && haveactive === false) {
						haveactive = true
						yeardiv[targetName] = true
					} else {
						yeardiv[targetName] = false
					}
				}
				setActiveYear({ ...yeardiv })

				var resizeEvent = window.document.createEvent('UIEvents')
				resizeEvent.initUIEvent('resize', true, false, window, 0)
				window.dispatchEvent(resizeEvent)
			}, 300)
		})
		// eslint-disable-next-line
	}, [i18n.language])

	// resize
	useEffect(() => {
		const onResize = (e) => {
			const screen_width = window.innerWidth
			const canvas_lines_Mb = {
				pt4: [{ w: document.getElementById('1842').offsetWidth / 2, h: 1 }],
				pt5: [
					{
						w: 1,
						h: document.getElementsByClassName('img-18421')[0].height,
					},
					{
						w: document.getElementById('1842').offsetWidth - 80,
						h: document.getElementsByClassName('img-18421')[0].height,
					},
					{
						w: document.getElementById('1842').offsetWidth - 80,
						h: document.getElementById('1842').offsetHeight - 75 + 150,
					},
				],
			}

			const canvas_lines_lg = {
				pt4: [{ w: document.getElementById('1842').offsetWidth / 2 + 30, h: 1 }],
				pt5: [
					{
						w: 1,
						h: document.getElementById('1842').offsetHeight,
					},
					{
						w: document.getElementById('1842').offsetWidth + 18,
						h: document.getElementById('1842').offsetHeight,
					},
				],
				pt6: [
					{
						w: document.getElementById('1842').offsetWidth + 18,
						h: document.getElementById('1850s').offsetHeight,
					},
					{
						w: 100,
						h: document.getElementById('1850s').offsetHeight,
					},
					{
						w: 100,
						h: document.getElementById('1850s').offsetHeight + 25,
					},
				],
				pt7a: [
					{
						w: 100,
						h: document.getElementById('1895').offsetHeight * 0.3,
					},
					{
						w: 1,
						h: document.getElementById('1895').offsetHeight * 0.3,
					},
				],
				pt7: [
					{
						w: 1,
						h: document.getElementById('1895').offsetHeight * 0.7,
					},
					{
						w: document.getElementById('1895').offsetWidth,
						h: document.getElementById('1895').offsetHeight * 0.7,
					},
					{
						w: document.getElementById('1895').offsetWidth,
						h: document.getElementById('1895').offsetHeight + 50,
					},
				],
				pt8: [
					{
						w: document.getElementById('1895').offsetWidth,
						h: document.getElementById('1939').offsetHeight * 0.4,
					},
					{
						w: document.getElementById('1939').offsetWidth / 2 - 20,
						h: document.getElementById('1939').offsetHeight * 0.4,
					},
				],
				pt8b: [
					{
						w: document.getElementById('1939').offsetWidth / 2 - 20,
						h: 90,
					},
					{
						w: 40,
						h: 90,
					},
				],
				pt8c: [
					{
						w: 1,
						h: document.getElementById('1939').offsetHeight * 0.6 - 90,
					},
				],
				pt9: [
					{
						w: 40,
						h: document.getElementById('1941').offsetHeight * 0.5,
					},
					{
						w: document.getElementById('1941-details').offsetWidth + 20,
						h: document.getElementById('1941').offsetHeight * 0.5,
					},
				],
				pt9b: [
					{
						w: document.getElementById('1941-details').offsetWidth + 20,
						h: document.getElementById('1941').offsetHeight * 0.5 + 15,
					},
					{
						w: 1,
						h: document.getElementById('1941').offsetHeight * 0.5 + 15,
					},
				],
				pt10: [
					{
						w: 1,
						h: document.getElementById('1989').offsetHeight,
					},
					{
						w: document.getElementById('1941-details').offsetWidth + 20,
						h: document.getElementById('1989').offsetHeight,
					},
				],
				pt11a: [
					{
						w: 1,
						h: document.getElementById('1993').offsetHeight / 3,
					},
					{
						w: document.getElementById('1993').offsetWidth - document.getElementById('1941-details').offsetWidth + 20,
						h: document.getElementById('1993').offsetHeight / 3,
					},
				],
				pt11: [
					{
						w: document.getElementById('1993').offsetWidth,
						h: (document.getElementById('1993').offsetHeight / 3) * 2 + 20,
					},
				],
				pt12: [
					{
						w: document.getElementById('1993').offsetWidth,
						h: document.getElementById('1994').offsetHeight * 0.6,
					},
					{
						w: document.getElementById('1993').offsetWidth / 2,
						h: document.getElementById('1994').offsetHeight * 0.6,
					},
					{
						w: document.getElementById('1993').offsetWidth / 2,
						h: document.getElementById('1994').offsetHeight + 20,
					},
				],
				pt13: [
					{
						w: 1,
						h: document.getElementById('2003-details').offsetTop,
					},
					{
						w: document.getElementById('1993').offsetWidth / 2 - 20,
						h: document.getElementById('2003-details').offsetTop,
					},
					{
						w: document.getElementById('1993').offsetWidth / 2 - 20,
						h: document.getElementById('2003').offsetHeight + 20,
					},
				],
				pt14: [
					{
						w: document.getElementById('2003').offsetWidth - 40,
						h: document.getElementById('2009').offsetHeight * 0.5,
					},
					{
						w: 1,
						h: document.getElementById('2009').offsetHeight * 0.5,
					},
				],
				pt15: [
					{
						w: 1,
						h: document.getElementById('2009').offsetHeight / 2 + 20,
					},
					{
						w: document.getElementById('2009').offsetWidth + 20,
						h: document.getElementById('2009').offsetHeight / 2 + 20,
					},
				],
				pt16: [
					{
						w: document.getElementById('2009').offsetWidth + 20,
						h: document.getElementById('now').offsetHeight * 0.6,
					},
					{
						w: 40,
						h: document.getElementById('now').offsetHeight * 0.6,
					},
				],
				pt17: [
					{
						w: 1,
						h: document.getElementById('now').offsetHeight * 0.4 + 170,
					},
				],
			}

			const canvas_lines = {
				pt1: [{ w: 1, h: 50 }],
				pt2: [{ w: 1, h: 50 }],
				pt3: [{ w: 1, h: 75 }],
				pt4: [{ w: document.getElementById('1842').offsetWidth / 2 - 40, h: 1 }],
				pt5: [
					{
						w: 1,
						h: document.getElementsByClassName('img-18421')[0].height,
					},
					{
						w: document.getElementById('1842').offsetWidth - 80,
						h: document.getElementsByClassName('img-18421')[0].height,
					},
					{
						w: document.getElementById('1842').offsetWidth - 80,
						h: document.getElementById('1842').offsetHeight - 75,
					},
				],
				pt6: [
					{
						w: document.getElementById('1842').offsetWidth - 80,
						h: document.getElementById('1850s').offsetHeight,
					},
					{
						w: 1,
						h: document.getElementById('1850s').offsetHeight,
					},
				],
				pt7: [
					{
						w: 1,
						h: document.getElementById('1895').offsetHeight - 25,
					},
					{
						w: document.getElementById('1895').offsetWidth - 80,
						h: document.getElementById('1895').offsetHeight - 25,
					},
				],
				pt8: [
					{
						w: document.getElementById('1895').offsetWidth - 80,
						h: document.getElementById('1939').offsetHeight - 25,
					},
					{
						w: document.getElementById('1895').offsetWidth / 2 - 40,
						h: document.getElementById('1939').offsetHeight - 25,
					},
					{
						w: document.getElementById('1895').offsetWidth / 2 - 40,
						h: document.getElementById('1939').offsetHeight + 25,
					},
					{
						w: 1,
						h: document.getElementById('1939').offsetHeight + 25,
					},
				],
				pt9: [
					{
						w: 1,
						h: document.getElementById('1941').offsetHeight * 0.8,
					},
					{
						w: document.getElementById('1941').offsetWidth / 2 + 10,
						h: document.getElementById('1941').offsetHeight * 0.8,
					},
					{
						w: document.getElementById('1941').offsetWidth / 2 + 10,
						h: document.getElementById('1941').offsetHeight + 2,
					},
				],
				pt10: [
					{
						w: document.getElementById('1941').offsetWidth / 2 + 10,
						h: document.getElementById('1989').offsetHeight - 50,
					},
					{
						w: 1,
						h: document.getElementById('1989').offsetHeight - 50,
					},
					{
						w: 1,
						h: document.getElementById('1989').offsetHeight,
					},
				],
				pt11: [
					{
						w: 1,
						h: document.getElementById('1993').offsetHeight * 0.8,
					},
					{
						w: document.getElementById('1993').offsetWidth / 2 - 10,
						h: document.getElementById('1993').offsetHeight * 0.8,
					},
					{
						w: document.getElementById('1993').offsetWidth / 2 - 10,
						h: document.getElementById('1993').offsetHeight + 2,
					},
					{
						w: document.getElementById('1993').offsetWidth - 60,
						h: document.getElementById('1993').offsetHeight + 2,
					},
				],
				pt12: [
					{
						w: document.getElementById('1993').offsetWidth - 60,
						h: document.getElementById('1994').offsetHeight,
					},
					{
						w: document.getElementById('1994').offsetWidth / 2 - 60,
						h: document.getElementById('1994').offsetHeight,
					},
				],
				pt13: [
					{
						w: 1,
						h: document.getElementById('2003').offsetHeight,
					},
					{
						w: document.getElementById('2003').offsetWidth - 60,
						h: document.getElementById('2003').offsetHeight,
					},
				],
				pt14: [
					{
						w: document.getElementById('2003').offsetWidth - 60,
						h: document.getElementById('2009').offsetHeight - 50,
					},
					{
						w: 1,
						h: document.getElementById('2009').offsetHeight - 50,
					},
				],
				pt16: [
					{
						w: 1,
						h: document.getElementById('now').offsetHeight - 75,
					},
					{
						w: document.getElementById('now').offsetWidth / 2 - 40,
						h: document.getElementById('now').offsetHeight - 75,
					},
					{
						w: document.getElementById('now').offsetWidth / 2 - 40,
						h: document.getElementById('now').offsetHeight + 40,
					},
				],
			}

			updateCanva((prevState) => ({
				...prevState,
				pt4_line: screen_width >= 1024 ? document.getElementsByClassName('img-18421')[0].width - 40 : document.getElementsByClassName('img-18421')[0].width + 10,
				// pt5_line: screen_width >= 1280 ? document.getElementsByClassName('img-18421')[0].height + 2 : document.getElementById('1842').offsetHeight - 75 + 150,
				pt5_line: screen_width >= 1280 ? document.getElementById('1842').offsetHeight + 2 : document.getElementById('1842').offsetHeight - 75 + 150,
				pt5_line_w: screen_width >= 1024 ? document.getElementById('1842').offsetWidth - 80 + 2 : document.getElementById('1842').offsetWidth + 20,
				// pt5a_line: screen_width >= 1280 ? document.getElementById('1842').offsetHeight - document.getElementsByClassName('img-18421')[0].height - 75 : document.getElementById('1842').offsetHeight - 75 + 150,
				// pt5a_line_w: screen_width >= 1024 ? document.getElementById('1842').offsetWidth - 80 + 2 : document.getElementById('1842').offsetWidth + 20,
				pt6_line: screen_width >= 1024 ? document.getElementById('1850s').offsetHeight + 2 : document.getElementById('1850s').offsetHeight + 50 + 4,
				pt6_line_w: screen_width >= 1024 ? document.getElementById('1842').offsetWidth - 80 + 2 : document.getElementById('1842').offsetWidth + 22,
				pt7a_line: screen_width >= 1024 ? 0 : document.getElementById('1895').offsetHeight * 0.3 + 2,
				pt7a_line_w: screen_width >= 1024 ? 0 : 102,
				pt7_line: screen_width >= 1024 ? document.getElementById('1895').offsetHeight + 2 : document.getElementById('1895').offsetHeight * 0.7 + 52,
				pt7_line_w: screen_width >= 1024 ? document.getElementById('1895').offsetWidth - 80 + 2 : document.getElementById('1895').offsetWidth + 2,
				pt8_line: screen_width >= 1024 ? document.getElementById('1939').offsetHeight + 25 + 2 : document.getElementById('1939').offsetHeight * 0.4 + 2,
				pt8_line_w: screen_width >= 1024 ? document.getElementById('1939').offsetWidth - 80 + 2 : document.getElementById('1939').offsetWidth + 2,
				pt8b_line: screen_width >= 1024 ? 0 : 92,
				pt8b_line_w: screen_width >= 1024 ? 0 : document.getElementById('1939').offsetWidth / 2 - 18,
				pt8c_line: screen_width >= 1024 ? 0 : document.getElementById('1939').offsetHeight * 0.6 - 90,
				pt8c_line_w: screen_width >= 1024 ? 0 : 6,
				pt9_line: screen_width >= 1024 ? document.getElementById('1941').offsetHeight + 2 : document.getElementById('1941').offsetHeight * 0.5 + 42,
				pt9_line_w: document.getElementById('1941').offsetWidth + 2,
				pt9b_line: screen_width >= 1024 ? 0 : document.getElementById('1941').offsetHeight * 0.5 + 42,
				pt9b_line_w: screen_width >= 1024 ? 0 : document.getElementById('1941-details').offsetWidth + 22,
				pt10_line: document.getElementById('1989').offsetHeight + 2,
				pt10_line_w: document.getElementById('1941-details').offsetWidth + 12,
				pt11a_line: screen_width >= 1024 ? 0 : document.getElementById('1993').offsetHeight / 3 + 2,
				pt11a_line_w: document.getElementById('1993').offsetWidth - document.getElementById('1941-details').offsetWidth - 18,
				pt11_line: screen_width >= 1024 ? document.getElementById('1993').offsetHeight + 27 : document.getElementById('1993').offsetHeight + 22,
				pt11_line_w: document.getElementById('1993').offsetWidth + 2,
				pt12_line: screen_width >= 1024 ? document.getElementById('1994').offsetHeight + 2 : document.getElementById('1994').offsetHeight + 22,
				pt12_line_w: screen_width >= 1024 ? document.getElementById('1994').offsetWidth - 60 + 2 : document.getElementById('1994').offsetWidth + 2,
				pt13_line: screen_width >= 1024 ? document.getElementById('2003').offsetHeight + 2 : document.getElementById('2003').offsetHeight + 22,
				pt13_line_w: document.getElementById('1994').offsetWidth / 2 + 2,
				pt14_line: screen_width >= 1024 ? document.getElementById('2009').offsetHeight + 2 : document.getElementById('2009').offsetHeight * 0.5 + 2,
				pt14_line_w: screen_width >= 1024 ? document.getElementById('2009').offsetWidth + 2 : document.getElementById('2003').offsetWidth - 38,
				pt15_line: screen_width >= 1024 ? 0 : document.getElementById('2009').offsetHeight / 2 + 22,
				pt15_line_w: screen_width >= 1024 ? 0 : document.getElementById('2009').offsetWidth + 22,
				pt16_line: screen_width >= 1024 ? document.getElementById('now').offsetHeight + 42 : document.getElementById('now').offsetHeight * 0.7 + 2,
				pt16_line_w: screen_width >= 1024 ? document.getElementById('now').offsetWidth + 2 : document.getElementById('now').offsetWidth + 25,
				pt17_line: document.getElementById('now').offsetHeight * 0.3 + 170,
				pt17_line_w: screen_width >= 1024 ? 0 : document.getElementById('now').offsetWidth + 22,
			}))

			drawDashedLine('pt4', [5, 5], screen_width >= 1024 ? canvas_lines.pt4 : canvas_lines_lg.pt4)
			drawDashedLine('pt5', [5, 5], screen_width >= 1280 ? canvas_lines.pt5 : screen_width >= 1024 ? canvas_lines_Mb.pt5 : canvas_lines_lg.pt5)
			drawDashedLine('pt6', [5, 5], screen_width >= 1024 ? canvas_lines.pt6 : canvas_lines_lg.pt6)
			drawDashedLine('pt7a', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt7a : canvas_lines_lg.pt7a)
			drawDashedLine('pt7', [5, 5], screen_width >= 1024 ? canvas_lines.pt7 : canvas_lines_lg.pt7)
			drawDashedLine('pt8', [5, 5], screen_width >= 1024 ? canvas_lines.pt8 : canvas_lines_lg.pt8)
			drawDashedLine('pt8b', [5, 5], canvas_lines_lg.pt8b)
			drawDashedLine('pt8c', [5, 5], canvas_lines_lg.pt8c)
			drawDashedLine('pt9', [5, 5], screen_width >= 1024 ? canvas_lines.pt9 : canvas_lines_lg.pt9)
			drawDashedLine('pt9b', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt9b : canvas_lines_lg.pt9b)
			drawDashedLine('pt10', [5, 5], screen_width >= 1024 ? canvas_lines.pt10 : canvas_lines_lg.pt10)
			drawDashedLine('pt11a', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt11a : canvas_lines_lg.pt11a)
			drawDashedLine('pt11', [5, 5], screen_width >= 1024 ? canvas_lines.pt11 : canvas_lines_lg.pt11)
			drawDashedLine('pt12', [5, 5], screen_width >= 1024 ? canvas_lines.pt12 : canvas_lines_lg.pt12)
			drawDashedLine('pt13', [5, 5], screen_width >= 1024 ? canvas_lines.pt13 : canvas_lines_lg.pt13)
			drawDashedLine('pt14', [5, 5], screen_width >= 1024 ? canvas_lines.pt14 : canvas_lines_lg.pt14)
			drawDashedLine('pt15', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt15 : canvas_lines_lg.pt15)
			drawDashedLine('pt16', [5, 5], screen_width >= 1024 ? canvas_lines.pt16 : canvas_lines_lg.pt16)
			drawDashedLine('pt17', [5, 5], screen_width >= 1024 ? canvas_lines_lg.pt17 : canvas_lines_lg.pt17)
		}
		window.addEventListener('resize', onResize, {
			passive: true,
		})

		window.addEventListener('resize', onResize, {
			passive: true,
		})

		return () => window.removeEventListener('resize', onResize)
	}, [canvalineItems])

	useEffect(() => {
		const onScroll = (e) => {
			var footerElement = document.querySelector('footer')
			var position = footerElement.getBoundingClientRect()
			if (position.top < window.innerHeight && position.bottom >= 0) {
				if (position.top < window.innerHeight / 2) {
					showFooternone(true)
				} else {
					showFooternone(false)
				}

				if (addfixedClass === false) {
					showFooter(true)
				}
			} else {
				if (addfixedClass === true) {
					showFooter(false)
				}
			}

			const isElementXPercentInViewport = function (elrect, percentVisible) {
				let rect = elrect,
					windowHeight = window.innerHeight || document.documentElement.clientHeight

				return !(Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) < percentVisible || Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible)
			}

			let containerCheckList = ['pt5', 'pt6', 'pt7a', 'pt7', 'pt8', 'pt8b', 'pt8c', 'pt9', 'pt9b', 'pt10', 'pt11a', 'pt11', 'pt12', 'pt13', 'pt14', 'pt15', 'pt16', 'pt17']

			containerCheckList.forEach((item, idx) => {
				const itemContainer = item + '_container'
				const targetEle = document.getElementById(itemContainer)
				const rect = targetEle.getBoundingClientRect()
				let elementHeigh = 0
				let elementwidth = item === 'pt16' && window.innerWidth < 1024 ? 7 : 3
				let widthunit = 'px'
				if (isElementXPercentInViewport(rect, document.getElementById(item).offsetTop)) {
					// if (true) {
					let theTotalH = document.getElementById(item).offsetHeight
					let theTotalw = document.getElementById(item).offsetWidth
					const winBottom = window.pageYOffset + window.innerHeight * 1.55
					const eleOffsetTop = rect.top + window.pageYOffset + window.innerHeight
					var eleHpercentage = (winBottom - eleOffsetTop) / theTotalH
					// console.log("🚀 ~ theTotalH details", item,' : ', winBottom, eleOffsetTop, theTotalH)
					if (eleHpercentage >= 0.9) {
						eleHpercentage = 1
					}
					let totalHeight = (theTotalH + theTotalw) * eleHpercentage

					if (totalHeight <= theTotalH) {
						elementHeigh = totalHeight
					} else {
						elementHeigh = theTotalH
						elementwidth = totalHeight - theTotalH
					}

					if (elementHeigh < 0) {
						elementHeigh = 0
					}
				} else if (rect.top <= 0) {
					// when the element is over after the scroll
					elementHeigh = document.getElementById(item).offsetHeight
					elementwidth = document.getElementById(item).offsetWidth
				}
				// console.log("🚀 ~ file: History.js ~ line 1017 ~ containerCheckList.forEach ~ elementwidth",item,': ', elementwidth)

				cline.clineContainersHeigh[itemContainer] = { height: elementHeigh + 'px', width: elementwidth + widthunit }
			})

			setClineConterhigh({ ...cline })

			const activeYearArray = ['1842', '1850s', '1895', '1939', '1941', '1989', '1993', '1994', '2003', '2009', 'now']

			let haveactive = false
			for (let i = 0; i < activeYearArray.length; i++) {
				const targetDiv = document.getElementById(activeYearArray[i])
				const targetName = 'yr' + activeYearArray[i]
				const divrect = targetDiv.getBoundingClientRect()
				if (divrect.top >= 0 && haveactive === false) {
					haveactive = true
					yeardiv[targetName] = true
				} else {
					yeardiv[targetName] = false
				}
			}
			setActiveYear({ ...yeardiv })
		}
		window.addEventListener('scroll', onScroll, {
			passive: true,
		})

		return () => window.removeEventListener('scroll', onScroll)
	}, [addfixedClass, cline, yeardiv])

	const scroll = (el) => {
		// console.log(el.offsetTop );
		window.scrollTo({ top: el.offsetTop, left: 0, behavior: 'smooth' })

		setData((prevState) => ({
			...prevState,
			dropDownActive: data.dropDownActive === true ? false : false,
		}))
	}

	const toggleDropDown = () => {
		setData((prevState) => ({
			...prevState,
			dropDownActive: data.dropDownActive === true ? false : true,
		}))
	}

	const [isYearNavSticky, setIsYearNavSticky] = useState(false)
	const anchorDropDownSection = useRef()
	useEffect(() => {
		window.addEventListener('scroll', updateYearNav)

		return () => {
			window.removeEventListener('scroll', updateYearNav)
		}
		// eslint-disable-next-line
	}, [])

	const updateYearNav = () => {
		let scrollTop = window.pageYOffset
		if (scrollTop > anchorDropDownSection.current.offsetTop) {
			setIsYearNavSticky(true)
			return
		}

		setIsYearNavSticky(false)
		return
	}

	return (
		<div className={style.history}>
			<Helmet>
				<title>
					{t('History timeline')} - {t('Central Market')}
				</title>
			</Helmet>

			<div className="bg-elements">
				<div className={style.bgleft}>
					<img src="/images-local/block-3-h.png" alt="" />
					<img src="/images-local/block-s.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block.png" alt="" />
					<img src="/images-local/block-3-v.png" alt="" />
					<img src="/images-local/block-bb-ts.png" alt="" />
					<img className="hidden md:block" src="/images-local/CM_KV_Eat-version.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-tb-bs.png" alt="" />
					<img src="/images-local/block-s.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
				</div>
				<div className={style.bgright}>
					<img className="bg-cloud" src="/images-local/cloud-dark.png" alt="" />
					<img src="/images-local/block-bs-tb.png" alt="" />
					<img src="/images-local/blcok-tm-bm.png" alt="" />
					<img className="hidden md:block" src="/images-local/CM_KV_Play-version.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-bs-tb.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-tb-bs.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-bs-tb.png" alt="" />
					<img src="/images-local/block.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud-dark.png" alt="" />
					<img src="/images-local/block-s.png" alt="" />
					<img src="/images-local/block-bs-tb.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/blcok-tm-bm.png" alt="" />
					<img className="hidden md:block" src="/images-local/CM_KV_Shop-version.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
				</div>
			</div>
			<PageTitle title={t("History Timeline")} noPadding withBr/>
			<div className="container-boxed">
				<div className={`${style.anchorSection} ${addfixedClass === true ? style.anchorFix : ''} ${addnoneClass === true ? style.anchorFixnone : ''}`}>
					<HashLink smooth to={'/history-timeline#1842'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem} ${yeardiv.yr1842 === true ? style.activeyear : ''}`}>
							<span className={style.yrline}></span> 1842
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#1850s'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem} ${yeardiv.yr1850s === true ? style.activeyear : ''}`}>
							{' '}
							<span className={style.yrline}></span> 1850s{' '}
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#1895'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem}  ${yeardiv.yr1895 === true ? style.activeyear : ''}`}>
							{' '}
							<span className={style.yrline}></span> 1895{' '}
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#1939'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem}  ${yeardiv.yr1939 === true ? style.activeyear : ''}`}>
							{' '}
							<span className={style.yrline}></span> 1939{' '}
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#1941'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem} ${yeardiv.yr1941 === true ? style.activeyear : ''}`}>
							{' '}
							<span className={style.yrline}></span> 1941{' '}
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#1989'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem} ${yeardiv.yr1989 === true ? style.activeyear : ''}`}>
							{' '}
							<span className={style.yrline}></span> 1989{' '}
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#1993'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem} ${yeardiv.yr1993 === true ? style.activeyear : ''}`}>
							{' '}
							<span className={style.yrline}></span> 1993{' '}
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#1994'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem} ${yeardiv.yr1994 === true ? style.activeyear : ''}`}>
							{' '}
							<span className={style.yrline}></span> 1994{' '}
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#2003'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem} ${yeardiv.yr2003 === true ? style.activeyear : ''}`}>
							{' '}
							<span className={style.yrline}></span> 2003{' '}
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#2009'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem} ${yeardiv.yr2009 === true ? style.activeyear : ''}`}>
							{' '}
							<span className={style.yrline}></span> 2009{' '}
						</div>
					</HashLink>
					<HashLink smooth to={'/history-timeline#now'} scroll={(el) => scroll(el)}>
						<div className={`${style.yearitem} ${yeardiv.yrnow === true ? style.activeyear : ''} ${style.uppercaseYear}`}>
							{' '}
							<span className={style.yrline}></span> {t('Now')}{' '}
						</div>
					</HashLink>
				</div>

				<div className={style.container}>
					<div className={style.title}>
						<canvas width="4" height="50" className={`${style.cline} ${style.pt1}`} id="pt1"></canvas>
					</div>

					<div className={`${style.anchorDropDownSection} ${isYearNavSticky ? style.sticky : ''}`} ref={anchorDropDownSection}>
						<div className={style.dropdownTitle} onClick={() => toggleDropDown()}>
							<span>{t('Year')}</span>
							<img src={data.dropDownActive === true ? data.arrowimgup : data.arrowimgdown} alt="" />
						</div>
						<div className={`${style.yearsItem} ${data.dropDownActive === true ? style.dpactive : ''}`}>
							<HashLink smooth to={'/history-timeline#1842'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									<span className={style.yrline}></span> 1842
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#1850s'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> 1850s{' '}
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#1895'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> 1895{' '}
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#1939'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> 1939{' '}
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#1941'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> 1941{' '}
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#1989'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> 1989{' '}
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#1993'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> 1993{' '}
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#1994'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> 1994{' '}
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#2003'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> 2003{' '}
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#2009'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> 2009{' '}
								</div>
							</HashLink>
							<HashLink smooth to={'/history-timeline#now'} scroll={(el) => scroll(el)}>
								<div className={`${style.yearitem}`}>
									{' '}
									<span className={style.yrline}></span> {t('Now')}{' '}
								</div>
							</HashLink>
						</div>
					</div>
					<div className={style.titleDesp}>
						{data.title_desp && <p dangerouslySetInnerHTML={{__html: data.title_desp}} /> }
						<canvas width="4" height="50" className={`${style.cline} ${style.pt2}`} id="pt2"></canvas>
					</div>
					<div className={`${style.timelineContainer} ${style.year1842}`} id="1842">
						<canvas width="4" height="95" className={`${style.cline} ${style.pt3}`} id="pt3"></canvas>
						<canvas width={canvalineItems.pt4_line} height="4" className={`${style.cline} ${style.pt4}`} id="pt4"></canvas>
						<div className={`${style.cline} ${style.pt5_container}`} id="pt5_container" style={cline.clineContainersHeigh.pt5_container}>
							<canvas width={canvalineItems.pt5_line_w} height={canvalineItems.pt5_line} className={`${style.cline} ${style.pt5}`} id="pt5"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>1842</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<div className={style.yearImgContainer}>
								<img className={`${style.yearContentImg} ${style.img18421} img-18421`} src="/images-local/pic-history-1842-1.jpg" alt="" />
								<img className={`${style.yearContentImg} ${style.img18422}`} src="/images-local/pic-history-1842-2.jpg" alt="" />
							</div>
							<div className={style.yearContentDetails}>
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>1842</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr1842.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr1842.year_desp}</p>
									<span className={style.qoute}>{data.timeline.yr1842.year_qouat}</span>
								</div>
							</div>
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year1850}`} id="1850s">
						<div className={`${style.cline} ${style.pt6_container}`} id="pt6_container" style={cline.clineContainersHeigh.pt6_container}>
							<canvas width={canvalineItems.pt6_line_w} height={canvalineItems.pt6_line} className={`${style.cline} ${style.pt6}`} id="pt6"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>1850s</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<div className={style.yearContentDetails}>
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>1850s</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr1850.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr1850.year_desp}</p>
								</div>
							</div>
							<img className={`${style.yearContentImg} ${style.img18501}`} src="/images-local/pic-history-1850-1.jpg" alt="" />
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year1895}`} id="1895">
						<div className={`${style.cline} ${style.pt7a_container}`} id="pt7a_container" style={cline.clineContainersHeigh.pt7a_container}>
							<canvas width={canvalineItems.pt7a_line_w} height={canvalineItems.pt7a_line} className={`${style.cline} ${style.pt7a}`} id="pt7a"></canvas>
						</div>
						<div className={`${style.cline} ${style.pt7_container}`} id="pt7_container" style={cline.clineContainersHeigh.pt7_container}>
							<canvas width={canvalineItems.pt7_line_w} height={canvalineItems.pt7_line} className={`${style.cline} ${style.pt7}`} id="pt7"></canvas>
						</div>

						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>1895</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<img className={`${style.yearContentImg} ${style.img18951} img-18951`} src="/images-local/pic-history-1895-1.jpg" alt="" />
							<div className={style.yearContentDetails}>
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>1895</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr1895.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr1895.year_desp}</p>
									<span className={style.qoute}>{data.timeline.yr1895.year_qouat}</span>
								</div>
							</div>
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year1939}`} id="1939">
						<div className={`${style.cline} ${style.pt8_container}`} id="pt8_container" style={cline.clineContainersHeigh.pt8_container}>
							<canvas width={canvalineItems.pt8_line_w} height={canvalineItems.pt8_line} className={`${style.cline} ${style.pt8}`} id="pt8"></canvas>
						</div>
						<div className={`${style.cline} ${style.pt8b_container}`} id="pt8b_container" style={cline.clineContainersHeigh.pt8b_container}>
							<canvas width={canvalineItems.pt8b_line_w} height={canvalineItems.pt8b_line} className={`${style.cline} ${style.pt8b}`} id="pt8b"></canvas>
						</div>
						<div className={`${style.cline} ${style.pt8c_container}`} id="pt8c_container" style={cline.clineContainersHeigh.pt8c_container}>
							<canvas width={canvalineItems.pt8c_line_w} height={canvalineItems.pt8c_line} className={`${style.cline} ${style.pt8c}`} id="pt8c"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>1939</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<div className={style.yearContentDetails}>
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>1939</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr1939.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr1939.year_desp}</p>
									<span className={style.qoute}>{data.timeline.yr1939.year_qouat}</span>
								</div>
							</div>
							<div className={style.yearImgContainer}>
								<img className={`${style.yearContentImg} ${style.img19392} img-19392`} src="/images-local/pic-history-1939-2.jpg" alt="" />
								<img className={`${style.yearContentImg} ${style.img19391} img-19391`} src="/images-local/pic-history-1939-1.jpg" alt="" />
							</div>
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year1941}`} id="1941">
						<div className={`${style.cline} ${style.pt9_container}`} id="pt9_container" style={cline.clineContainersHeigh.pt9_container}>
							<canvas width={canvalineItems.pt9_line_w} height={canvalineItems.pt9_line} className={`${style.cline} ${style.pt9}`} id="pt9"></canvas>
						</div>
						<div className={`${style.cline} ${style.pt9b_container}`} id="pt9b_container" style={cline.clineContainersHeigh.pt9b_container}>
							<canvas width={canvalineItems.pt9b_line_w} height={canvalineItems.pt9b_line} className={`${style.cline} ${style.pt9b}`} id="pt9b"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>1941</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<div className={style.yearImgContainer}>
								<img className={`${style.yearContentImg} ${style.img19411}`} src="/images-local/pic-history-1941-1.jpg" alt="" />
								<img className={`${style.yearContentImg} ${style.img19412}`} src="/images-local/pic-history-1941-2.jpg" alt="" />
							</div>
							<div className={style.yearContentDetails} id="1941-details">
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>1941</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr1941.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr1941.year_desp}.</p>
									<span className={style.qoute}>{data.timeline.yr1941.year_qouat}</span>
								</div>
							</div>
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year1989}`} id="1989">
						<div className={`${style.cline} ${style.pt10_container}`} id="pt10_container" style={cline.clineContainersHeigh.pt10_container}>
							<canvas width={canvalineItems.pt10_line_w} height={canvalineItems.pt10_line} className={`${style.cline} ${style.pt10}`} id="pt10"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>1989</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<div className={style.yearContentDetails}>
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>1989</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr1989.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr1989.year_desp}</p>
									<span className={style.qoute}>{data.timeline.yr1989.year_qouat}</span>
								</div>
							</div>
							<img className={`${style.yearContentImg} ${style.img19891}`} src="/images-local/pic-history-1989-1.jpg" alt="" />
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year1993}`} id="1993">
						<div className={`${style.cline} ${style.pt11a_container}`} id="pt11a_container" style={cline.clineContainersHeigh.pt11a_container}>
							<canvas width={canvalineItems.pt11a_line_w} height={canvalineItems.pt11a_line} className={`${style.cline} ${style.pt11a}`} id="pt11a"></canvas>
						</div>
						<div className={`${style.cline} ${style.pt11_container}`} id="pt11_container" style={cline.clineContainersHeigh.pt11_container}>
							<canvas width={canvalineItems.pt11_line_w} height={canvalineItems.pt11_line} className={`${style.cline} ${style.pt11}`} id="pt11"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>1993</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<img className={`${style.yearContentImg} ${style.img19931} img-19931`} src="/images-local/pic-history-1993-1.jpg" alt="" />
							<div className={style.yearContentDetails}>
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>1993</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr1993.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr1993.year_desp}</p>
								</div>
							</div>
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year1994}`} id="1994">
						<div className={`${style.cline} ${style.pt12_container}`} id="pt12_container" style={cline.clineContainersHeigh.pt12_container}>
							<canvas width={canvalineItems.pt12_line_w} height={canvalineItems.pt12_line} className={`${style.cline} ${style.pt12}`} id="pt12"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>1994</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<div className={style.yearContentDetails}>
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>1994</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr1994.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr1994.year_desp}</p>
								</div>
							</div>
							<img className={`${style.yearContentImg} ${style.img19941} img-19941`} src="/images-local/pic-history-1994-1.jpg" alt="" />
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year2003}`} id="2003">
						<div className={`${style.cline} ${style.pt13_container}`} id="pt13_container" style={cline.clineContainersHeigh.pt13_container}>
							<canvas width={canvalineItems.pt13_line_w} height={canvalineItems.pt13_line} className={`${style.cline} ${style.pt13}`} id="pt13"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>2003</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<div className={style.yearImgContainer}>
								<img className={`${style.yearContentImg} ${style.img20031}`} src="/images-local/pic-history-2003-1.jpg" alt="" />
								<img className={`${style.yearContentImg} ${style.img20032}`} src="/images-local/pic-history-2003-2.jpg" alt="" />
							</div>
							<div className={style.yearContentDetails} id="2003-details">
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>2003</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr2003.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr2003.year_desp}</p>
								</div>
							</div>
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year2009}`} id="2009">
						<div className={`${style.cline} ${style.pt14_container}`} id="pt14_container" style={cline.clineContainersHeigh.pt14_container}>
							<canvas width={canvalineItems.pt14_line_w} height={canvalineItems.pt14_line} className={`${style.cline} ${style.pt14}`} id="pt14"></canvas>
						</div>
						<div className={`${style.cline} ${style.pt15_container}`} id="pt15_container" style={cline.clineContainersHeigh.pt15_container}>
							<canvas width={canvalineItems.pt15_line_w} height={canvalineItems.pt15_line} className={`${style.cline} ${style.pt15}`} id="pt15"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>2009</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent}>
							<div className={style.yearContentDetails}>
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>2009</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr2009.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr2009.year_desp}</p>
									<div className="mt-4">
										<Button text={t('Find out more')} href="/revitalization" />
									</div>
								</div>
							</div>
							<img className={`${style.yearContentImg} ${style.img20091} img-20091`} src="/images-local/pic-history-2009-1.jpg" alt="" />
						</div>
					</div>
					<div className={`${style.timelineContainer} ${style.year2021}`} id="now">
						<div className={`${style.cline} ${style.pt16_container}`} id="pt16_container" style={cline.clineContainersHeigh.pt16_container}>
							<canvas width={canvalineItems.pt16_line_w} height={canvalineItems.pt16_line} className={`${style.cline} ${style.pt16}`} id="pt16"></canvas>
						</div>
						<div className={`${style.cline} ${style.pt17_container}`} id="pt17_container" style={cline.clineContainersHeigh.pt17_container}>
							<canvas width={canvalineItems.pt17_line_w} height={canvalineItems.pt17_line} className={`${style.cline} ${style.pt17}`} id="pt17"></canvas>
						</div>
						<div className={style.yearTitle}>
							<div className={style.year}>
								<TextBorderFill>{t('Now')}</TextBorderFill>
							</div>
						</div>
						<div className={style.yearContent} id="yr-now">
							<img className={`${style.yearContentImg} ${style.img20211} img-20211`} src="/images-local/pic-history-2023-1.jpg" alt="" />
							<div className={style.yearContentDetails}>
								<div className={`${style.yearTitle} ${style.mbActive}`}>
									<div className={style.year}>
										<TextBorderFill>{t('Now')}</TextBorderFill>
									</div>
								</div>
								<div className={style.contentTitle}>{data.timeline.yr2021.year_title}</div>
								<div className={style.contentDetail}>
									<p>{data.timeline.yr2021.year_desp}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default History
