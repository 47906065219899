import { useState, useEffect, useRef, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import SwiperCore, { Navigation, Pagination, History } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {useMedia} from 'react-use';
import Draggable from 'react-draggable'
import SVG from 'react-inlinesvg'
import $ from 'jquery'
import classnames from 'classnames'

import PageTitle from '../../components/PageTitle/PageTitle'
import { SliderArrow } from '../../components/NavArrow/NavArrow'
import getData from '../../utils/getData'
import style from './Itinerary.module.scss'

SwiperCore.use([Navigation, Pagination, History])

const Itinerary = () => {
    const { i18n, t } = useTranslation()
    const { t: tMenu } = useTranslation(['menu'])
    const sliderEl = useRef()
    const svgRef = useRef()
	const isMobile = useMedia('(max-width: 768px)');
    const [activeSlide, setActiveSlide] = useState(0)
    const [position, setPosition] = useState({x: 0, y: 0})
    const [data, setData] = useState({
        tabList: [],
        tabDetails: {
            image: '',
            box: [],
        },
        arrowimgdown: '/images-local/ico-arrow-down.svg',
        arrowimgup: '/images-local/ico-arrow-up.svg',
        dpdownStatus: {
            status: false,
            arrow: '/images-local/ico-arrow-down.svg',
        },
    })

    let imgSwiperNav = {
        prev: useRef(),
        next: useRef(),
    }

    const tabNum = 100 / data.tabList.length
    const tabItem = data.tabList.map((item, index) => (
        <div key={index} className={`${item.active === true ? style.active : ''} ${style.tabItem}`} style={{ width: tabNum + '%' }} onClick={() => changeTab(item.id)}>
            <p className={style.tabIndex}> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </p>
            <p className={style.tabName}> {item.name} </p>
        </div>
    ))

    const tabItemDropDown = data.tabList.map((item, index) => (
        <div key={index} className={`${item.active === true ? style.active : ''} ${style.tabItem}`} onClick={() => changeTab(item.id)}>
            <p className={style.tabIndex}> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </p>
            <p className={style.tabName}> {item.name} </p>
        </div>
    ))

    useEffect(() => {
        const fetchData = async () => {
            try {
                const items = await getData('itinerary')
                const activeContentItem = items.tab.find((item) => item.active === true)
                setData((prevState) => ({
                    ...prevState,
                    tabList: items.tab,
                    tabContentarr: items.tab_content,
                    tabDetails: items.tab_content[activeContentItem.id],
                    activeTabItem: activeContentItem,
                }))
                setPosition(isMobile ? activeContentItem.mobilePosition : activeContentItem.position)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [i18n.language])

    useEffect(() => {
		$(document).on('click', '[data-map-btn]', function () {
			const id = parseInt($(this).attr('data-map-btn'))
			setActiveSlide(id-1)
			sliderEl.current?.swiper.slideToLoop(id-1, 400)
		})
	}, [])

    useEffect(() => {
        if(svgRef.current) {
            setTimeout(() => {
                activePin()
            }, 1000)
        }
    }, [svgRef])

    useEffect(() => {
        //TweenMax.to($(`*[data-map-btn]`), 0.1, { ease: Bounce.easeOut, scale: 1, transformOrigin: '50% 100%' })
        activePin()
    }, [activeSlide])

    const activePin = useCallback(() => {
        $(`*[data-map-btn]`).css({
            stroke: 'none',
        })
        $(`*[data-map-btn="${activeSlide+1}"]`).css({
            stroke: 'yellow',
            strokeWidth: 1,
            strokeLinejoin: 'round',
        })
    }, [activeSlide])

    const assignNav = (swiper, nav) => {
        swiper.params.navigation = {
            ...swiper.params.navigation,
            prevEl: nav.prev.current,
            nextEl: nav.next.current,
        }
        swiper.navigation.update()
        swiper.update(true)
    }

    const changeTab = useCallback((targetid) => {
        setActiveSlide(0)

        $('[data-compoent="locationItem"]').scrollTop(0)
        sliderEl.current?.swiper.slideTo(0)

        let tabList = []
        let activeTabItem
        data.tabList.forEach((item, index) => {
            if (item.id === targetid) {
                item.active = true
                activeTabItem = item
            } else {
                item.active = false
            }
            tabList.push(item)
        })
        let updateDetails = data.tabContentarr[targetid]

        if (data.dpdownStatus.status === true) {
            let dptest = {
                status: data.dpdownStatus.status === true ? false : true,
                arrow: data.dpdownStatus.status === true ? data.arrowimgdown : data.arrowimgup,
            }

            setData((prevState) => ({
                ...prevState,
                dpdownStatus: dptest,
            }))
        }

        setPosition(isMobile ? activeTabItem.mobilePosition : activeTabItem.position)

        setData((prevState) => ({
            ...prevState,
            tabList: tabList,
            tabDetails: updateDetails,
            activeTabItem: activeTabItem,
        }))

        sliderEl.current?.swiper.slideTo(0)
        sliderEl.current?.swiper.slideToLoop(0, 0)

        setTimeout(() => {
            activePin()
        }, 1000)
    }, [data, setActiveSlide])

    const toggleDropDown = () => {
        let dptest = {
            status: data.dpdownStatus.status === true ? false : true,
            arrow: data.dpdownStatus.status === true ? data.arrowimgdown : data.arrowimgup,
        }

        setData((prevState) => ({
            ...prevState,
            dpdownStatus: dptest,
        }))
    }

    const handleLeft = () => {
        let index = activeSlide
        index--
        if(index <= 0) index = data.tabDetails.box.length
        setActiveSlide(index)
    }

    const handleRight = () => {
        console.log('right')
        let index = activeSlide
        index++
        if(index >= data?.tabDetails?.box.length) index = 0;
        setActiveSlide(index)
    }

    const controlledDrag = (e, {x, y}) => {
        setPosition({x, y})
    }

    return (
        <div className="page">
            <Helmet>
                <title>
                    {tMenu('Itinerary')} - {t('Central Market')}
                </title>
            </Helmet>
            <PageTitle title={tMenu("Itinerary")} />
            <div className="container-boxed much-padding">
                <section className={style.hub}>
                    {data &&
                        <div className={style.content}>
                            <div className={style.containerTabBar}>{tabItem}</div>
                            <div className={style.containerTabDropdown}>
                                <div className={style.activeItem} onClick={() => toggleDropDown()}>
                                    <div className={`${style.activeItemDetails} ${data.dpdownStatus.status === true ? style.active : ''}`}>
                                        <p className={style.tabIndex}>{data?.activeTabItem?.item} </p>
                                        <p className={style.tabName}>{data?.activeTabItem?.name} </p>
                                    </div>
                                    <img src={data.dpdownStatus.arrow} alt="" />
                                </div>
                                <div className={`${style.dropdownList} ${data.dpdownStatus.status === true ? style.active : ''}`}>{tabItemDropDown}</div>
                            </div>
                            <div className={style.desc}>{data?.activeTabItem?.desc}</div>
                            <div className={style.containerTabDetails}>
                                {/* <div className="block md:hidden">
                                    <SVG ref={svgRef} className={`${style.mapImage}`} src={data.tabDetails.image_mobile} uniquifyIDs={true} />
                                </div> */}

                                <div className={style.viewer}>
                                <Draggable position={{x: position?.x, y: position?.y}} onDrag={controlledDrag}>
                                    <div>
                                        <div className={style.bg} style={{ transform: isMobile ? 'scale(2)' : `scale(1.3)` }}>
                                            <SVG ref={svgRef} className={`${style.mapImage}`} src={data.tabDetails.image} uniquifyIDs={true} />
                                        </div>
                                    </div>
                                </Draggable>
                                </div>
                                <div className={style.placeDetailsBoxes}>
                                    <div className={style.box}>
                                        <SliderArrow ref={imgSwiperNav} isLeftRight isCenter onClickLeft={handleLeft} onClickRight={handleRight}/>
                                        <Swiper
                                            className={classnames(style.imageSwiperPadding, "find--us__slider")}
                                            spaceBetween={5}
                                            loop={true}
                                            initialSlide={0}
                                            navigation={{ prevNav: '', nextEl: '' }}
                                            breakpoints={{
                                                768: {
                                                    slidesPerView: 1,
                                                },
                                            }}
                                            ref={sliderEl}
                                            onInit={(swiper) => {
                                                assignNav(swiper, imgSwiperNav)
                                                swiper.slideToLoop(1, 0)
                                            }}
                                        >
                                            {data.tabDetails.box.map((item, i) => (
                                                <SwiperSlide key={i} className={`${style.i} ${style.boxItem}`} data-status={`${i === activeSlide ? 'active' : ''}`}>
                                                    <div className={style.boxDetails}>
                                                        <div className={style.boxDetailsImg}>
                                                            <div className="block md:hidden">
                                                                {/*<img className={style.placeImage} src={item.image} alt="" />*/}
                                                                <img className={style.placeImage} src={`${item.image.replace('.png', '-large.png')}`} alt="" />
                                                            </div>
                                                            <div className="hidden md:block">
                                                                <img className={style.placeImage} src={`${item.image.replace('.png', '-large.png')}`} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className={style.boxDetailsItem}>
                                                            <p className={style.placeName}>{item.title}</p>
                                                            <p data-compoent="locationItem" className={style.placeDetail}>
                                                                {item.details}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </section>
            </div>
        </div>
    )
}

export default Itinerary