// Core
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import style from './Sitemap.module.scss'

// Components
import { menu } from '../../components/Header/HeaderV2'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'

// i18n
import { useTranslation } from 'react-i18next'

const Sitemap = () => {
	const { i18n, t } = useTranslation(['translation', 'menu'])
	const { t: tMenu } = useTranslation(['menu'])

	const [data, setData] = useState({ siteList: menu })

	const subsiteList = (subList) => {
		return subList.map(({ name, link }, index) => (
			<div className={style.subSiteItem}>
				<Link to={link}>{tMenu(`menu:${name}`)}</Link>
			</div>
		))
	}

	const siteDetails = data.siteList.map(({ name, childs }, index) => (
		<div key={index} className={style.siteListContainer}>
			<Helmet>
				<title>
					{t('Sitemap')} - {t('Central Market')}
				</title>
			</Helmet>
			<div className={style.siteListName}>{tMenu(`menu:${name}`)}</div>
			{subsiteList(childs)}
		</div>
	))

	return (
		<div className={style.sitemap}>
			<div className="container-boxed half-padding">
				<div className={style.container}>
					<div className={style.title}>
						<TextBorderFill>{t('SiteMap')}</TextBorderFill>
					</div>
					<div className={style.detailsContainer}>{siteDetails}</div>

					<div className={style.updateDate}>{data.updatedDate}</div>
				</div>
			</div>
		</div>
	)
}

export default Sitemap
