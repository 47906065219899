import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'
import axios from 'axios'

const DevTool = () => {
	const dataArray = ['devtool', 'mode']
	const [buildTime, setBuildTime] = useState()
	const [data, setData] = useState(
		// eslint-disable-next-line
		dataArray.reduce((acc, curr) => ((acc[curr] = ''), acc), {})
	)

	// Get values from sessionStorage
	useEffect(() => {
		dataArray.forEach((key) => {
			setData((prevState) => ({ ...prevState, [key]: sessionStorage.getItem(key) }))
		})
		axios.get('/metadata.json').then((result) => {
			setBuildTime(result.data.buildTime)
		})
		// eslint-disable-next-line
	}, [])

	const handleChange = (e) => {
		const key = e.target.name
		setData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const handleSubmit = () => {
		dataArray.forEach((key) => {
			sessionStorage.setItem(key, data[key])
		})
	}

	return (
		<div className="fixed bottom-10 right-10 z-50 bg-white rounded-lg p-5 shadow-md text-primary opacity-75 hover:opacity-100 transition-all duration-200 ease-in-out">
			<form onSubmit={handleSubmit}>
				<table>
					{dataArray.map((key) => {
						return (
							<tr>
								<td className="py-1">{key}: </td>
								<td className="py-1">
									<input onChange={handleChange} type="text" className="px-2 border border-grey rounded-sm" name={key} value={data[key]} />
								</td>
							</tr>
						)
					})}
				</table>

				<button type="submit" className="hover:bg-primary hover:text-white transition-all duration-200 ease-in-out rounded-md px-4 py-1 mt-2 float-right border border-primary">
					Update
				</button>
			</form>

			<div className="w-full clear-both mt-5 text-xs text-grey">Build Date: {buildTime}</div>
		</div>
	)
}

export default DevTool
