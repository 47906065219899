// Core
import { Helmet } from 'react-helmet-async'

// Styles
import styles from './NotFound.module.scss'

// Components
import Button from '../../components/Button/Button'

//i18n
import { useTranslation } from 'react-i18next'

const NotFound = () => {
	let { t } = useTranslation()
	return (
		<div>
			<Helmet>
				<title>404 Not Found - {t('Central Market')}</title>
			</Helmet>
			<div className={styles.main}>
				<div className={styles.title}>404 Not Found</div>
				<Button text="Back to home" url="/" />
			</div>
		</div>
	)
}

export default NotFound
