// Core
import React, { useState, useRef } from 'react'

// i18n
import { useTranslation } from 'react-i18next'

// Validator
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import TextareaValidator from '../../components/TextareaValidator/TextareaValidator'
import CheckboxValidatorElement from '../../components/CheckboxValidatorElement/CheckboxValidatorElement'
import KeyboardDatePickerValidator from '../../components/KeyboardDatePickerValidator/KeyboardDatePickerValidator'

// Components
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import Loader from '../../components/Loader/Lodaer'

// jQuery
import $ from 'jquery'

// Axios
import axios from 'axios'

// Utils
import parseObjectToFormData from '../../utils/parseObjectToFormData'
import getSession from '../../utils/getSession'

const BuskingPermitForget = (props) => {
	const { t, i18n } = useTranslation()

	const formRef = useRef(null)
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)

	const [formData, setFormData] = useState({ email_1st: '', mobile_1st: '' })
	const handleChangeFormData = (event) => {
		setFormData((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}))
	}

	const handleSubmit = (event) => {
		setIsLoading(true)
		event.preventDefault()
		console.log(formData)
		console.log(parseObjectToFormData(formData))
		axios
			.post(
				// 'https://webhook.site/b6c2f0fc-bc77-4d32-9cde-6d330ae287f1',
				// 'http://dev.centralmarket.cpt.com/api/Busking/BuskingResendPasscode',
				'/api/Busking/BuskingResendPasscode',
				formData,
				{ headers: { 'Accept-Language': i18n.language } }
			)
			.then((response) => {
				console.log(response)
				setIsLoading(false)
				if (response.data.status_code === 200) {
					props.onSuccess()
				} else {
					setErrorMessage(response.data.errmsg)
				}
			})
			.catch((error) => {
				setIsLoading(false)
				setErrorMessage(t(error.toJSON().message))
			})
	}

	const handleSampleData = () => {
		const sampleData = {
			email_1st: 'jackylee336@yahoo.com',
			mobile_1st: '12345678',
		}

		setFormData((prevState) => ({
			...prevState,
			...sampleData,
		}))
	}

	const scrollToError = () => {
		$([document.documentElement, document.body]).animate(
			{
				scrollTop: $('.Mui-error').eq(0).parent().offset().top - $('[class*=Header_nav_]').eq(0).height(),
			},
			1000
		)
	}

	return (
		<section className={`busking-permit-forget ${isLoading ? 'loading' : ''}`}>
			{window.location.href.includes('www.centralmarket.hk') || window.location.href.includes('www-staging.centralmarket.hk') ? (
				''
			) : getSession('devtool') === 'on' ? (
				<div className="w-full border border-primary border-dashed p-10">
					<div className="text-primary mb-2">Dev tool:</div>
					<a className="inline-block border border-primary text-primary transition-all duration-400 py-2 px-5 cursor-pointer hover:bg-primary hover:text-white" onClick={handleSampleData}>
						Autofill
					</a>
				</div>
			) : (
				''
			)}

			<ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={scrollToError}>
				<div className="title">{t('Forget my permit no.')}</div>
				<div className="text-primary mb-10">
					{t('To get back your permit code, please enter your application’s email address and mobile number below. We will send you back the permit code to your email address for login.')}
				</div>
				<div className="fields">
					<div className="row boxed">
						<div className="field half">
							<TextValidator
								label={t('Main applicant’s Registered email') + '*'}
								onChange={handleChangeFormData}
								name="email_1st"
								value={formData.email_1st}
								validators={['required', 'isEmail']}
								errorMessages={[t('Cannot leave it blank'), t('The format is incorrect')]}
								fullWidth
							/>
						</div>
					</div>
					<div className="row boxed">
						<div className="field half">
							<TextValidator
								label={t('Main applicant’s Registered mobile') + '*'}
								onChange={handleChangeFormData}
								name="mobile_1st"
								value={formData.mobile_1st}
								validators={['required', 'isNumber']}
								errorMessages={[t('Cannot leave it blank'), t('The format is incorrect')]}
								fullWidth
							/>
						</div>
					</div>
					{errorMessage ? <div className="row my-6 text-primary">{errorMessage}</div> : ''}
				</div>
				{isLoading ? <Loader /> : <SubmitButton text={t('Submit')} />}
			</ValidatorForm>
		</section>
	)
}

export default BuskingPermitForget
