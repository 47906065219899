import React from 'react'

// Utils
import getQueryString from './getQueryString'

const getSession = (key) => {
	let queryString = getQueryString(key)
	if (queryString) {
		sessionStorage.setItem(key, queryString)
	}

	return sessionStorage.getItem(key)
}

export default getSession
