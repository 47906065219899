// IE polyfill
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// Facebook pixel
// import ReactPixel from 'react-facebook-pixel'

// Google tag manager
import TagManager from 'react-gtm-module'

TagManager.initialize({
	gtmId:process.env.REACT_APP_GTM_CODE,
	events:{
		'gtm.start': new Date().getTime(), 
		event:'gtm.js'
	}
})

// // Facebook pixel
// const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// const options = {
// 	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
// 	debug: true, // enable logs
// }
// ReactPixel.init('986747628761707', advancedMatching, options)

// ReactPixel.pageView() // For tracking page view1

ReactDOM.render(
	<Suspense fallback={''}>
		<App />
	</Suspense>,
	document.getElementById('root')
)
