import React from "react";
import red from "@material-ui/core/colors/red";
import Checkbox from "@material-ui/core/Checkbox";
import { FormHelperText } from "@material-ui/core";
import {
  ValidatorComponent,
  TextValidator,
} from "react-material-ui-form-validator";
import { KeyboardDatePicker } from "@material-ui/pickers";
import $ from "jquery";
const red300 = red["500"];

const style = {
  right: 0,
  fontSize: "12px",
  color: red300,
  position: "absolute",
  marginTop: "-25px",
};

class KeyboardDatePickerValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      onClick,
      name,
      value,
      text,

      ...rest
    } = this.props;

    return (
      <div class="KeyboardDatePickerValidator-container">
        <KeyboardDatePicker
          value={value}
          name={name}
          {...rest}
          ref={(r) => {
            this.input = r;
          }}
          onClick={onClick}
        />

        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div class="KeyboardDatePickerValidator-error validator-error Mui-error">
        {this.getErrorMessage()}
      </div>
    );
  }
}

export default KeyboardDatePickerValidator;
