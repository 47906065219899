// Core
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { Helmet } from 'react-helmet-async'
import parse from 'html-react-parser'
// import { Link } from 'react-router-dom'

// Styles
import styles from './MembersOfferDetail.module.scss'

// Components
// import Button from '../../components/Button/Button'
import ButtonBack from '../../components/ButtonBack/ButtonBack'
// import BannerCTA from '../../components/BannerCTA/BannerCTA'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import Card from '../../components/Card/Card'
// import Image from '../../components/Image/Image'
import DetailSlider from '../../components/DetailSlider/DetailSlider'
import Loader from '../../components/Loader/Lodaer'

//i18n
import { useTranslation } from 'react-i18next'

// Utils
import getData from '../../utils/getData'
import getPeriod from '../../utils/getPeriod'
import getPurePrice from '../../utils/getPurePrice'

const MembersOfferDetail = () => {
	const { id } = useParams()
	const { t, i18n } = useTranslation()

	const [data, setData] = useState({
		all: [],
		current: {},
		related: [],
	})

	// Fetch data
	useEffect(() => {
		async function fetchData() {
			try {
				const data = await getData('event_offer')
				setData((prevState) => ({
					...prevState,
					all: data,
				}))
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	// Filter data
	useEffect(() => {
		const current = data.all.find((el) => el['slug'] === id)
		if (current) {
			getPeriod(current.start_date, current.end_date)

			let related

			data.all.map((item) => {
				console.log(item['slug'].toLowerCase())
				console.log(current['slug'].toLowerCase())
				console.log(item['slug'].toLowerCase() !== current['slug'].toLowerCase())
			})

			related = data.all.filter((el) => el['slug'].toLowerCase() !== current['slug'].toLowerCase() && el['category'] === current['category']).slice(0, 3)

			if (!related.length) {
				related = data.all.filter((el) => el['slug'].toLowerCase() !== current['slug'].toLowerCase()).slice(0, 3)
			}

			console.log(current)

			setData((prevState) => ({
				...prevState,
				current: current,
				related: related,
			}))
		}
	}, [data.all, id, i18n.language])

	function htmldecode(str) {
		var txt = document.createElement('textarea')
		txt.innerHTML = str
		return txt.value
	}

	return (
		<>
			<Helmet>
				<title>
					{data.current['title'] || ''} - {t('Central Market')}
				</title>
			</Helmet>
			<div className={styles.main}>
				<div className={styles.ButtonBack}>
					<ButtonBack url="/privileges" />
				</div>
				<section className={styles.mainContent}>
					<div className={styles.bg}>
						<img src="/images-local/block-3-h.png" alt="" />
						<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
						<img src="/images-local/block-tb-bs.png" style={{ opacity: 0 }} alt="" />
						<img src="/images-local/block-bs-tb.png" alt="" />
					</div>
					{data.current['title'] ? (
						<div className={styles.content}>
							<div className={styles.detail}>
								<div className={styles.info}>
									<div className={styles.title}>{data.current['title']}</div>
									<div className={styles.description}>{parse(htmldecode(data.current['description']))}</div>
								</div>
								<div className={styles.pic}>
									<DetailSlider data={data.current['slider']} />
								</div>
							</div>

							{data.current['is_ngo'] ? (
								<div className=" text-grey">
									<p>{t('event_remind_1')}</p>
								</div>
							) : (
								''
							)}
						</div>
					) : (
						<div className="my-20">
							<Loader />
						</div>
					)}
				</section>

				{/* {data.current['title'] === 'Staging Ground' ? (
					<section className={styles.banner}>
						<div className={styles.bg}>
							<img src="/images-local/block-s.png" alt="" />
							<img src="/images-local/block-tb-bs.png" alt="" />
							<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
							<img src="/images-local/block-ts-bb.png" alt="" />
						</div>
						<div className={styles.content}>
							<BannerCTA
								subTitle={t('Central market’s highlight')}
								title={t('Join our staging ground special')}
								image="/images-local/pic-banner-1.png"
								buttonText={t('Apply now')}
								buttonUrl="#"
							/>
						</div>
					</section>
				) : (
					''
				)} */}

				<section className={styles.related}>
					<div className={styles.bg}>
						<img src="/images-local/block-3-v.png" alt="" />
						<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
						<img src="/images-local/block-bb-ts.png" alt="" />
					</div>
					<div className={styles.content}>
						<div className={styles.title}>
							<TextBorderFill>{t('You might also like')}</TextBorderFill>
						</div>
						<div className={styles.items}>
							{data.related.map((item, index) => {
								return <Card key={item.slug} title={item.title} pic={item.slider[0].image} url={`/privileges/${item.slug}`} />
							})}
						</div>
					</div>
				</section>
			</div>
		</>
	)
}

export default MembersOfferDetail
