import moment from 'moment'

export const checkIsPeriod = ({ date = new Date(), start_date, end_date }) => {
    const isToday = new Date(date).setHours(0, 0, 0, 0) >= new Date(start_date).setHours(0, 0, 0, 0) && new Date().setHours(0, 0, 0, 0) <= new Date(end_date).setHours(0, 0, 0, 0)
    return isToday
}

export const checkIsOngoing = ({ date = new Date(), type, start_date, end_date }) => {
    const start = moment(start_date, 'DD/MM/YYYY')
    const end = moment(end_date, 'DD/MM/YYYY')
    //return moment(date).isBetween(start, end) || (moment(date).isSameOrAfter(start) && moment(date).isSameOrBefore(end, 'day'));
    return moment(date).isSameOrBefore(end, 'day')
}