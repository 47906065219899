// Core
import React, { useState, useRef, useEffect } from 'react'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import { Box, Checkbox, Select, RadioGroup, FormControlLabel, FormControl, FormLabel, FormHelperText, InputLabel, TextareaAutosize, MenuItem, Radio } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { addDays, setDate } from 'date-fns'
import CircularProgress from '@material-ui/core/CircularProgress';

// Validator
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import KeyboardDatePickerValidator from '../../components/KeyboardDatePickerValidator/KeyboardDatePickerValidator'
import CheckboxValidatorElement from '../../components/CheckboxValidatorElement/CheckboxValidatorElement'

// Components
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import Loader from '../../components/Loader/Lodaer'

// jQuery
import $ from 'jquery'

// Axios
import axios from 'axios'
import { API } from '../../config/constants'

// Utils
import getData from '../../utils/getData'
import getSession from '../../utils/getSession'
import format from 'date-fns/format'
import enLocale from "date-fns/locale/en-US";
import tcLocale from "date-fns/locale/zh-TW";
import scLocale from "date-fns/locale/zh-CN";

// PopUp
import Popup from '../../components/Popup/Popup'
import PopupBuskingRegisterFormMap from '../../components/PopupContent/PopupBuskingRegisterFormMap'
import PopupTerms from '../../components/PopupContent/PopupTerms'


const BuskingRegisterForm = (props) => {
	const { t, i18n } = useTranslation()

	const formRef = useRef(null)
	const [date, setDate] = useState(new Date(Date.now() + (31 * 24 * 60 * 60 * 1000)));

	const [data, setData] = useState({
		locations: [],
	})

	const localeMap = {
		en: enLocale,
		tc: tcLocale,
		sc: scLocale,
	};

	const [declaration1, setDeclaration1] = useState(false)

	const [formData, setFormData] = useState({
		date: null,
		permit: {
			permit_code: sessionStorage.getItem('permit_code'),
			token: sessionStorage.getItem('token'),
		},
		time_slot: [

		]
	})
	const [availableTimeSlots, setAvailableTimeSlots] = useState([])
	const [permitAlreadyAppliedMessage, setPermitAlreadyAppliedMessage] = useState(false)
	const [timeslots, setTimeslots] = useState([])
	const [locations, setLocations] = useState([])
	const [availableLocation, setAvailableLocation] = useState([])
	const [isEmptyLocation, setIsEmptyLocation] = useState(null)
	const [openDate, setOpenDate] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)

	const closeDialog = () => {
		setOpenDate(false)
	}

	const handleChangeFormData = (event) => {
		setFormData((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}))
	}

	//Fetch data
	useEffect(() => {
		async function fetchData() {
			try {
				const data_locations = await getData('busking_register_form_location')
				console.log(data_locations)

				var tempDate = date
				setDate(null)
				setData((prevState) => ({
					...prevState,
					locations: data_locations,
				}))
				setDate(tempDate)
				console.log(date)

			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	const handleForgetPermit = () => {
		props.onForget()
	}

	useEffect(() => {
		ValidatorForm.addValidationRule('isChecked', (value) => {
			if (value !== true) {
				return false
			}
			return true
		})
	})

	const handleSubmit = (event) => {
		setIsLoading(true)
		event.preventDefault()
		console.log(event)
		if(!declaration1 || !checkTimeslot()){
			setIsLoading(false)
			return
		}
		console.log(formData)

		axios
			.post(API.busking.registerSubmit, formData, { headers: { 'Accept-Language': i18n.language } })
			.then((response) => {
				console.log(response)
				setIsLoading(false)
				if (response.data.status_code === 200) {
					//sessionStorage.removeItem('permit_code')
					//sessionStorage.removeItem('token')
					props.onSuccess()
				} else {
					if(response.data.status_code == 508 || response.data.status == 504){
						updateActiveTimeslot(response.data.record, response.data.errmsg, true)
					}

					setErrorMessage(response.data.errmsg)
				}
			})
			.catch((error) => {
				setIsLoading(false)
				setErrorMessage(t(error.toJSON().message))
			})
	}

	const checkTimeslot = () => {
		if (!formData.time_slot || formData.time_slot.length == 0) {
			setErrorMessage(t('Must select at least 1 hour'))
			return false
		}

		if (formData.time_slot.length < 1 || formData.time_slot.length > 2){
			setErrorMessage(t('Each Busker can only apply for a maximum of 2 hours per session and at most two sessions per week.'))
			return false
		}

		// cannot be same timeslot
		var existTimeSlot = []
		var validTimeSlot = true
		formData.time_slot.forEach(c => {
			if(existTimeSlot.length > 0 && existTimeSlot.includes(c.start_time)){
				setErrorMessage(t('Can only select 1 location for the same timeslot'))
				validTimeSlot = false
				return
			}else{
				existTimeSlot.push(c.start_time)
			}
		})
		if(!validTimeSlot){
			return false
		}
		console.log(validTimeSlot)

		return true
	}

	const handleLocation = (id) => {
		setFormData((prevState) => ({
			...prevState,
			location: id,
		}))
	}

	const [windowWidth, setWindowWidth] = useState(0)
	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth)
		})
	})

	const scrollToError = () => {
		$([document.documentElement, document.body]).animate(
			{
				scrollTop: $('.Mui-error').eq(0).parent().offset().top - $('[class*=Header_nav_]').eq(0).height(),
			},
			1000
		)
	}

	const handleTimeSlotChange = (event, locationId, timeslot) => {
		if(availableTimeSlots == null || availableTimeSlots.length == 0){
			return
		}
		var dataTimeslot = formData.time_slot
		var requestTimeslot = {
			start_time : timeslot.start_time,
			end_time : timeslot.end_time,
			location : locationId
		}
		var ele = event.target
		var existingItemIndex = -1
		var existingItemStartTime = ""
		var existingItemEndTime = ""
		var existingItemLocation = ""
		existingItemIndex = dataTimeslot.findIndex(c => c.start_time == requestTimeslot.start_time 
											&&  c.end_time == requestTimeslot.end_time 
											&&  c.location == requestTimeslot.location)
		for(var i = 0; i < dataTimeslot.length; i++) {
			if (dataTimeslot[i].start_time === requestTimeslot.start_time
				&&  dataTimeslot[i].end_time == requestTimeslot.end_time 
				&&  dataTimeslot[i].location == requestTimeslot.location) {
				existingItemIndex = i;
				break;
			}
		}

		var tempLocations = data.locations
		var isDisabledNeighbor = false
		if(existingItemIndex >= 0){

			existingItemStartTime = dataTimeslot[existingItemIndex].start_time
			existingItemEndTime = dataTimeslot[existingItemIndex].end_time
			existingItemLocation = dataTimeslot[existingItemIndex].location

			// remove old
			dataTimeslot.splice(existingItemIndex, 1)

			// update html element
			$(ele).removeClass("item-selected")
		}else{
			if(dataTimeslot.length < 2){
				// add new
				dataTimeslot.push(requestTimeslot)

				// update html element
				$(ele).addClass("item-selected")

				existingItemStartTime = requestTimeslot.start_time
				existingItemEndTime = requestTimeslot.end_time
				existingItemLocation = requestTimeslot.location
				isDisabledNeighbor = true
			}
		}

		// see if disabled/reopen it's neighbour
		tempLocations.forEach((c) => {
			if(c.id != existingItemLocation){
				c.timeslots.forEach((timeslot) => {
					if(timeslot.start_time == existingItemStartTime 
						&& timeslot.end_time == existingItemEndTime
						&& timeslot['is_occupied'] == false){
						timeslot['is_active'] = isDisabledNeighbor ? false : true
					}
				})
			}
		})

		console.log(formData)
		setData((prevState) => ({
			...prevState,
			locations : tempLocations
		}))
		setFormData((prevState) => ({
			...prevState,
			time_slot: dataTimeslot,
		}))
	}

	const handleScroll = () => {
		closeDialog()
		document.activeElement.blur()
	}
	window.addEventListener('scroll', handleScroll)

	const updateActiveTimeslot = (latestTimeslot, msg, isResetTimeslot) => {
		setAvailableTimeSlots([])
		if (latestTimeslot) {
			updateActiveTimeslot()
			// update available time slots
			var tempDataLocations = data.locations
			tempDataLocations.forEach(location => {
				// active timeslot
				var relativeTimeSlot = latestTimeslot.find(c => c.location == location.id)
				if(relativeTimeSlot && relativeTimeSlot.time_slot && location && location.timeslots){
					location.timeslots.forEach(timeslot => {
						var activeTimeSlot = relativeTimeSlot.time_slot.find(c => c == timeslot.start_time)
						timeslot['is_active'] = activeTimeSlot != null ? true : false
						timeslot['is_occupied'] = activeTimeSlot != null ? false : true
					})
				}

				// selected timeslot
				var selectTimeSlotList = formData.time_slot.filter(c => c.location == location.id)
				if(selectTimeSlotList && location && location.timeslots){
					location.timeslots.forEach(timeslot => {
						var activeTimeSlot = selectTimeSlotList.find(c => c.start_time == timeslot.start_time && c.end_time == timeslot.end_time)
						timeslot['is_selected'] = activeTimeSlot != null ? true : false
						
						// reset if true
						if(isResetTimeslot){
							timeslot['is_selected'] = false
						}
					})
				}
			})
			
			console.log(tempDataLocations)
			setData((prevState) => ({
				...prevState,
				locations: tempDataLocations
			}))
			setAvailableTimeSlots([formData.time_slot])
			setFormData((prevState) => ({
				...prevState,
				date: format(date, 'yyyy-MM-dd'),
				time_slot: isResetTimeslot ? [] : formData.time_slot
			}))
		}
	}
	useEffect(() => {
		// change loading size
		var timeSlotTotalWidth = 0
		$('.time-slot-container > .index').each(function(){timeSlotTotalWidth += $(this).width(); })
		var loadingWidth = timeSlotTotalWidth * 0.15
		var loadingMarginLeft = (timeSlotTotalWidth - loadingWidth ) / 2
		var loadingMarginTop = ($(".time-slot-container").height() - loadingWidth ) /2

		$(".timeslot-loading-container").css({
			'width' : loadingWidth + 'px',
			'margin-left' : loadingMarginLeft + 'px',
			'margin-top' : loadingMarginTop + 'px'
		 });

		// align time slot header height
		var maxHeight = 0
		$(".busking-select-header").each(function(){
			$(this).css('height', '');
		})		
		$(".busking-select-header").each(function(){
			maxHeight = maxHeight < $(this).height() ? $(this).height() : maxHeight
		})		
		$(".busking-select-header").each(function(){
			$(this).height(maxHeight)
		})

	}, [$('.time-slot-container').width(),$('.time-slot-container').height()])
	

	// Get TimeSlot
	useEffect(() => {
		if (date && data && data.locations && data.locations.length > 0) {
			console.log('Get TimeSlot')
			const requestDate = format(date, 'yyyy-MM-dd')
			var requestFormData = {
				"date" : requestDate,
				"permit" : {
					"permit_code" : formData.permit ? formData.permit.permit_code : '',
					"token" : formData.permit ? formData.permit.token : ''
				}
			}

			axios
				.post(API.busking.getTimeLocationSlot, requestFormData, { headers: { 'Accept-Language': i18n.language } })
				.then((response) => {
					console.log(response)
					if(response.data){
						response.data.status_code != 200 ? setPermitAlreadyAppliedMessage(response.data.errmsg) : setPermitAlreadyAppliedMessage("")
						updateActiveTimeslot(response.data.record, response.data.errmsg, true)
					}
				})
				.catch((error) => {
					if(error){
						setIsLoading(false)
						setErrorMessage(t(error.toJSON().message))
					}
				})
		}
	}, [date])

	// // Check if booked in same day
	// useEffect(() => {
	// 	if (formData.date) {
	// 		const date = format(formData.date, 'yyyy-MM-dd')
	// 		const postData = {
	// 			date: date,
	// 			permit: {
	// 				permit_code: formData.permit.permit_code,
	// 				// permit_code: 'BP-FMAOCG',
	// 			},
	// 		}
	// 		axios
	// 			.post(`/api/Busking/CheckBookingOver2HourPerDay`, postData, { headers: { 'Accept-Language': i18n.language } })
	// 			.then((response) => {
	// 				console.log(response)
	// 				setIsLoading(false)
	// 				if (response.data.status_code !== 200) {
	// 					setErrorMessage(response.errmsg)
	// 					setFormData((prevState) => ({
	// 						...prevState,
	// 						date: null,
	// 					}))
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				setIsLoading(false)
	// 				setErrorMessage(t(error.toJSON().message))
	// 			})
	// 	}
	// }, [formData.date])

	return (
		<section className={`busking-register-form ${isLoading ? 'loading' : ''}`}>
			<ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={scrollToError}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
					<div className="title">{t('A. PICK A DATE')}</div>
					<div className="fields">
						<DatePicker
								disablePast={true}
								minDate={new Date(Date.now() + (31 * 24 * 60 * 60 * 1000))}
								autoOk
								orientation={windowWidth > 960 ? "landscape" : "portrait"}
								variant="static"
								openTo="date"
								value={date}
								onChange={setDate}
							/>
					</div>

					<div className="title">{t('B. SELECT Location and timeSLOT')}</div>
					<div className="fields">
						<div className="map-img-container">
							{data.locations && data.locations.map((item, i) => {
								return (
									<div className="map-img" key={i}>
										<div className="map-img-group">
											<img src={item.landing} />
											<img className="map-img-floor" src={item.landing_floor} />
											<span className="map-img-name">{item.name}</span>
											<span className="map-img-size">{item.size}</span>
										</div>
										<div  className="view-map">
											<Popup text={t('View map')} content={<PopupBuskingRegisterFormMap title={item.floor + " " + item.name} mapImg={item.map}/>} />
										</div>
									</div>
								)
							})}
						</div>
						<span className="busking-reminder">{t('Each Busker can only apply for a maximum of 2 hours per session and at most two sessions per week.')}</span>
						{permitAlreadyAppliedMessage ? <div className="row my-6 text-primary">{permitAlreadyAppliedMessage}</div> : ''}
						<div className="busking-select-container time-slot-container" style={availableTimeSlots && availableTimeSlots.length > 0 ? {}: {opacity: 0.4}}>
							{data.locations ? 
								data.locations.map((item, i) => {
									return (
										<div className="index boxItem" key={i}>
											<div className="busking-select-header">{availableTimeSlots && availableTimeSlots.length > 0 ? item.floor + ' ' + item.name : ''}</div>
											{
												item.timeslots.map((timeslot, i) => {
													return(
														<div key={i} className={availableTimeSlots && availableTimeSlots.length > 0 ?
															timeslot.is_active ? 
																timeslot.is_selected ? "busking-select-item item-selected"
															: "busking-select-item item-available" : "busking-select-item item-disabled"
															: "busking-select-item"}
															onClick={timeslot.is_active ? (e) => handleTimeSlotChange(e, item.id, timeslot) : undefined}>
															{availableTimeSlots && availableTimeSlots.length > 0  ? timeslot.start_time + '-' + timeslot.end_time : ''}
														</div>
													)
												})
											}
										</div>
									)
								}) 
							: ''
							}
							<div className="timeslot-loading-container" style={availableTimeSlots && availableTimeSlots.length > 0 ? {display: "none"}: {}}><CircularProgress style={{width: "100%", height: "100%"}}/></div>			
						</div>
						<div className="busking-select-container">
							<div className="legend-container">
								<table className="legend-table">
									<tbody>
										<tr>
											<td className="legend-label"> <ul><li className="selected" style={{backgroundImage: "url(/images-local/busking-register-form-selected-square.svg)"}} >{t('Selected')}</li></ul></td>
											<td className="legend-label"> <ul><li className="disabled" style={{backgroundImage: "url(/images-local/busking-register-form-occupied-square.svg)"}}>{t('Occupied')}</li></ul></td>
											<td className="legend-label"> <ul><li className="available" style={{backgroundImage: "url(/images-local/busking-register-form-available-square.svg)"}} >{t('Available')}</li></ul></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="title">{t('C. Declaration')}</div>
					<div className="fields">
						<div className="row">
							<div className="field">
								<div className="text">{t('I hereby declare that the above venue is hired by our organization for organizing non-profit-making activities only.  I undertake to observe the conditions of use or regulations on the use of the venue and shall indemnify the Central Market against all actions, claims and demands by any person who suffers or sustains any loss, damage, injury or death arising out of or as a result of the use of the venue by me due to my negligence or on the part of the person authorized by me during the Hire Period.  I shall also meet the cost of repair needed for any damage caused and for repair or reinstatement or replacement of any equipment, fixture, fittings, facilities or other property damaged or destroyed, stolen or removed during the activities.  My right to use the venue shall be liable to suspension if I breach the conditions of use or regulations.')}</div>
							
								<CheckboxValidatorElement
									validators={['isChecked']}
									errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
									name="declaration1"
									value={declaration1}
									onClick={(event) => {
										setDeclaration1(!declaration1)
									}}
								>
									{t('I have read the ')}
									<div className="linkText">
										<Popup text={t('terms and conditions')} content={<PopupTerms />} />
									</div>
									{t(' and conditions and agree to abide by it.')}
								</CheckboxValidatorElement>
							</div>
						</div>
					</div>
					{errorMessage ? <div className="row my-6 text-primary">{errorMessage}</div> : ''}
					{isLoading ? <Loader /> : <SubmitButton text={t('Submit')} />}
				</MuiPickersUtilsProvider>
			</ValidatorForm>
		</section>
	)
}

export default BuskingRegisterForm
