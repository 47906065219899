// Core
import { useNavigate , Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";

//Components
import LanguageSwitcher from "./LanguageSwitcher";
import MenuDropDown from "../MenuDropDown/MenuDropDown";
import PopupSearch from "../PopupSearch/PopupSearch";
import Image from '../Image/Image';

// Utils
import getData from "../../utils/getData";

import { useGlobalSetting } from "../../hooks/GlobalSetting";

import styles from "./Header.module.scss";

// Lodash
import _ from "lodash";

export const menu = [
  {
    name: "About Central Market",
    childs: [
      {
        name: "About Chinachem Group",
        link: "/about-chinachem-group"
      },
      {
        name: "Our Concept",
        link: "/our-concept"
      },
      {
        name: "Pet Friendly Space",
        link: "/pet-friendly"
      },
      {
        name: "Location & Transportation",
        link: "/location-transportation"
      },
      {
        name: "Contact Us",
        link: "/contact-us"
      },
      {
        name: "Venue applications",
        link: "/venue-applications"
      },
    ],
    singular: ['/venue-booking', '/staging-ground', '/busking']
  },
  {
    name: "Our Heritage",
    childs: [
      {
        name: "History timeline",
        link: "/history-timeline"
      },
      {
        name: "Heritage and Revitalisation",
        link: "/conservation"
      },
      {
        name: "Heritage Tour",
        link: "/heritage-tour"
      }
    ],
    singular: ['/conservation', '/blueprint']
  },
  {
    name: "What’s On",
    childs: [
      {
        name: "Happenings",
        link: "/happenings"
      },
      {
        name: "Event Highlights",
        link: "/event-highlights"
      },
      {
        name: "Press",
        link: "/press"
      }
    ]
  },
  {
    name: "Retail-tainment",
    childs: [
      {
        name: "Shops & Restaurant",
        link: "/shops-directory"
      },
      {
        name: "VR Tour",
        link: "/vr-tour"
      },
    ]
  },
  {
    name: "Attractions",
    childs: [
      {
        name: "Central Hub",
        link: "/central-hub"
      },
      {
        name: "Itinerary",
        link: "/itinerary"
      },
    ]
  }
];

const NavigationBar = () => {
  const { t, i18n } = useTranslation(['menu']);
  const { pathname } = useLocation();

  return (
    <ul className={`${styles.desktopMenu} ${i18n.language}`}>
      {_.map(menu, ({ name, childs, link, currentPage, singular }, index) => {
        let active = pathname === link;
        if (childs && childs.length) {
          active = childs.filter(item => item.link === pathname).length > 0;
        }

        if(singular) {
          _.map(singular, (single) => {
            if(pathname.includes(single)) return active = true
          })
        }

        if (currentPage) {
          active = currentPage;
        }

        return childs && childs.length ? (
          <li key={index} className={active ? styles.active : ""}>
            <MenuDropDown trigger="hover" childs={childs} title={t(`menu:${name}`)} />
          </li>
        ) : (
          <li key={index} className={active ? styles.active : ""}>
            <Link to={link} title={t(`menu:${name}`)} className={styles.main}>
              {t(`menu:${name}`)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

const Header = () => {
  const { t, i18n } = useTranslation();
  const [menuStatus, setMenuStatus] = useState("close");
  const [isOpenSearch, setIsOpenSearch] = useState(0);
  const navContainerEl = useRef();
  const { pathname } = useLocation();
  const { lockScreen, unlockScreen } = useGlobalSetting();

  const socialMedia = [
    {
      name: "Facebook",
      image: "/images-local/ico-fb.svg",
      link: "https://www.facebook.com/centralmarkethk"
    },
    {
      name: "Instagram",
      image: "/images-local/ico-ig.svg",
      link: "https://www.instagram.com/centralmarkethk/"
    },
    {
      name: "Youtube",
      image: "/images-local/ico-youtube.svg",
      link: "https://www.youtube.com/channel/UCzsDAjAR2mfOZxI-WLG2hOA"
    }
  ];

  /* useEffect(() => {
    return history.listen(location => {
      closeMenu();
    });
  }, [history]); */
  useEffect(() => {
    closeMenu()
  }, [pathname])

  const toggleMenu = e => {
    menuStatus === "open" ? closeMenu() : openMenu();
  };

  const closeMenu = e => {

    setMenuStatus("close");
    unlockScreen()
  };
  const openMenu = e => {
    // document.body.classList.add('overflow-y-hidden')

    // set z-index footer below menu
    // document.querySelector('footer').style = 'z-index:-1'
    // navContainerEl.current.style = 'z-index:102'
    setMenuStatus("open");
    lockScreen()
  };

  const SearchButton = () => {
    return (
      <a onClick={handleOpenSearch} title={t("Search")}>
        <SVG className={styles.stroke} src="/images-local/ico-search.svg" />
      </a>
    );
  };

  const handleOpenSearch = () => {
    setIsOpenSearch(isOpenSearch + 1);
  };
  // useEffect(() => {
  // 	updateNavTransparent()
  // 	// eslint-disable-next-line
  // }, [])

  // useEffect(() => {
  // 	window.addEventListener('scroll', updateNavTransparent)

  // 	return () => {
  // 		window.removeEventListener('scroll', updateNavTransparent)
  // 	}
  // 	// eslint-disable-next-line
  // }, [])

  const [isHideAnnouncement, setIsHideAnnouncement] = useState(
    sessionStorage.getItem("isHideAnnouncement")
  );
  const [announcements, setAnnouncements] = useState([]);

  // Fetch data
  useEffect(() => {
    async function fetchData() {
      try {
        // Get data
        let dataRaw = await getData("announcement");

        //console.log('announcements: dataRaw', dataRaw)

        // Sort data
        const data = _.filter(dataRaw, function(item) {
          const now = new Date().setHours(0, 0, 0, 0);
          const start_date = new Date(item.start_date).setHours(0, 0, 0, 0);
          const end_date = new Date(item.end_date).setHours(0, 0, 0, 0);

          if (start_date > now) {
            return false;
          }

          if (end_date < now) {
            return false;
          }

          return true;
        });

        // Set data
        setAnnouncements(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [i18n.language]);

  const handleCloseAnnouncement = () => {
    sessionStorage.setItem("isHideAnnouncement", true);
    setIsHideAnnouncement(true);
  };

  function htmldecode(str) {
    var txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }

  const [navMargin, setNavMargin] = useState(0);
  const announcementBar = useRef();

  useEffect(() => {
    if (announcementBar.current) {
      console.log(
        "announcementBar.current.clientHeight",
        announcementBar.current.clientHeight
      );
      setNavMargin(announcementBar.current.clientHeight);
    }
  }, [announcements]);

  return (
    <div id={`headerContainer`}>
      <div
        className={styles.navSpacer}
        style={{ marginBottom: navMargin + "px" }}
      ></div>
      <div
        className={styles.navContainer}
        ref={navContainerEl}
        data-status={menuStatus}
      >
        {!isHideAnnouncement && announcements.length ? (
          <div ref={announcementBar} className={styles.announcement}>
            {announcements.length > 1 ? (
              <Swiper
                slidesPerView={1}
                loop={true}
                spaceBetween={20}
                direction="horizontal"
                autoplay={{
                  delay: 5000,
                  stopOnLastSlide: false,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false
                }}
              >
                {announcements.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      {parse(htmldecode(item.content))}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <div className="content">
                {parse(htmldecode(announcements[0].content))}
              </div>
            )}

            <div className={styles.close} onClick={handleCloseAnnouncement}>
              <SVG src="/images-local/close.svg" />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className={styles.nav} data-menu-status={menuStatus}>
          <div className={styles.menuWrapper}>
            <div className={styles.logo}>
              <Link to="/" title={t("Central Market")}>
                <img
                  className={styles.cmLogo}
                  src="/images-local/ccg-ura-cm-logo0.png"
                  alt=""
                />
              </Link>
            </div>
            <NavigationBar />
          </div>
          <ul className={styles.ssn}>
            <li>
              <SearchButton />
            </li>
            <li className={styles.smallIcon}>
              <LanguageSwitcher />
            </li>
            <li>
              <Link to="/contact-us" title={t("Contact Us")}>
                <SVG
                  className={styles.stroke}
                  src="/images-local/ico-map.svg"
                />
              </Link>
            </li>
            {/* <li>
							<Link to="/event-calendar" title={t('Event Calendar')}>
								<SVG className={styles.stroke} src="/images-local/ico-calendar.svg" />
							</Link>
						</li> */}
            <li>
              <Link to="/app" title={t("Central Club")}>
                <SVG
                  className={styles.stroke}
                  src="/images-local/ico-member.svg"
                />
              </Link>
            </li>
            <li
              className={`${styles.btnMenu} ${styles.showInMobile} `}
              data-status={menuStatus}
            >
              <a onClick={toggleMenu} title={t("Menu")}>
                <div className={styles.icoBurger}>
                  <div className={styles.bun}></div>
                  <div className={styles.bun}></div>
                  <div className={styles.bun}></div>
                </div>
              </a>
            </li>
          </ul>

          <div className={styles.menu}>
            <div className={styles.nav}>
              <div className={styles.logo}>
                <Link to="/" title={t("Central Market")}>
                  <img
                    className={styles.fill}
                    src="/images-local/logo-2.png"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* <ul className={styles.iconMenu}>
              <li className={styles.location}>
                <Link to="/find-us">
                  <SVG
                    className={styles.stroke}
                    src="/images-local/ico-map.svg"
                  />
                </Link>
              </li>
              <li className={styles.member}>
                <Link to="/app">
                  <SVG
                    className={styles.stroke}
                    src="/images-local/ico-member.svg"
                  />
                </Link>
              </li>
              <li>
                <SearchButton />
              </li>
              <li>
                <Link to="/event-calendar">
                  <SVG
                    className={styles.stroke}
                    src="/images-local/ico-calendar.svg"
                  />
                </Link>
              </li>

              <li className={styles.language}>
                <LanguageSwitcher />
              </li>
            </ul> */}
            <div className={styles.links}>
              <div className={styles.linksInner}>
                <div>
                  {menu.map(({ name, childs }, i) => {
                    return (
                      <ul key={i}>
                        <h3>{t(name)}</h3>
                        {childs.map(({ link, name: childName }, i) => {
                          return (
                            <li key={i}>
                              <Link to={link || "#"}>{t(childName)}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  })}
                </div>
              </div>

              {/* <div className={styles.socialMobile}>
                {socialMedia.map((item, i) => {
                  return (
                    <a
                      href={item.link}
                      target="_blank"
                      title={item.name}
                      key={i}
                      rel="noreferrer"
                    >
                      <SVG className={styles.fill} src={item.image} />
                    </a>
                  );
                })}
              </div> */}
            </div>
            <div className={styles.side}>
              <div className={styles.socialMedia}>
                {socialMedia.map((item, i) => {
                  return (
                    <a
                      href={item.link}
                      target="_blank"
                      title={item.name}
                      key={i}
                      rel="noreferrer"
                    >
                      <SVG className={styles.fill} src={item.image} />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupSearch isClicked={isOpenSearch} />
    </div>
  );
};

export default Header;
