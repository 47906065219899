import React from 'react'

const getQueryString = (key) => {
	const url_string = window.location.href
	const url = new URL(url_string)

	return url.searchParams.get(key)
}

export const setQueryString = (key, value) => {
	const url = new URL(window.location);
	return url.searchParams.set(key, value)
}

export default getQueryString
