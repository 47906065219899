import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import SVG from 'react-inlinesvg'

// Styles
import './BuskingRegister.scss'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import { Checkbox, Select, RadioGroup, FormControlLabel, FormControl, FormLabel, FormHelperText, InputLabel, TextareaAutosize, MenuItem, Radio } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, TrainRounded } from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import { addMonths } from 'date-fns'

// Validator
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import TextareaValidator from '../../components/TextareaValidator/TextareaValidator'
import CheckboxValidatorElement from '../../components/CheckboxValidatorElement/CheckboxValidatorElement'
import KeyboardDatePickerValidator from '../../components/KeyboardDatePickerValidator/KeyboardDatePickerValidator'

// Components
import Popup from '../../components/Popup/Popup'
import PopupPermitCode from '../../components/PopupContent/PopupPermitCode'
import PopupPriceVForm from '../../components/PopupContent/PopupPriceVForm'
import BannerCTA from '../../components/BannerCTA/BannerCTA'
import PageTitle from '../../components/PageTitle/PageTitle'

// Utils
import getQueryString from '../../utils/getQueryString'

// Form components
import BuskingPermitRegisterForm from './BuskingPermitRegisterForm'
import BuskingRegisterForm from './BuskingRegisterForm'
import BuskingPermitValidation from './BuskingPermitValidation'
import BuskingPermitForget from './BuskingPermitForget'

// jQuery
import $ from 'jquery'

const BuskingRegister = () => {
	const formRef = useRef(null)
	const { t, i18n } = useTranslation('')
	let navigate = useNavigate()
	const location = useLocation()

	const [hasPermit, setHasPermit] = useState(null)
	const [isPermitValid, setIsPermitValid] = useState(false)
	const [section, setSection] = useState('')
	const [continueRegister, setContinueRegister] = useState(getQueryString('s'))
	const [buskingPermitData, setBuskingPermitData] = useState(null)
	const [buskingPermitDataReReg, setBuskingPermitDataReReg] = useState(null)

	useEffect(() => {
		if (continueRegister == '1' && sessionStorage.getItem('permit_code') != null && sessionStorage.getItem('permit_code') != '' && sessionStorage.getItem('token') != null && sessionStorage.getItem('token') != '') {
			// continue register
			handleSuccessValidation()
		} else {
			sessionStorage.removeItem('permit_code')
			sessionStorage.removeItem('token')
		}
	}, [section])

	useEffect(() => {
		if (location && location.state) {
			const buskingPermitData = location.state.buskingPermitData

			if (buskingPermitData) {
				setHasPermit('yes')
				setBuskingPermitDataReReg(buskingPermitData)
				setSection('BuskingPermitValidation')

				//clear state if consumed the state
				window.history.replaceState({}, document.title)
			}
		}
	}, [location])

	const handleChangeHasPermit = (e) => {
		setHasPermit(e.target.value)
		if (e.target.value === 'yes') {
			if (isPermitValid) {
				setSection('BuskingRegisterForm')
			}
			setSection('BuskingPermitValidation')
		} else {
			setSection('BuskingPermitRegisterForm')
		}
	}

	const handleForgetPermit = (e) => {
		setSection('BuskingPermitForget')
	}

	const handleSuccessValidation = (val) => {
		setBuskingPermitData(val)

		setSection('BuskingRegisterForm')
		setIsPermitValid(true)
	}

	useEffect(() => {
		if(section && formRef) {
			const { offsetTop } = formRef.current
			setTimeout(() => {
				let top = offsetTop - $('[class*=Header_nav_]').eq(0).height() - 40
				if(offsetTop < 200) top = 400
				$([document.documentElement, document.body]).animate(
					{
						scrollTop: top
					},
					350
				)
			}, 500)
		}
	}, [section, formRef])

	const handleSubmited = () => {
		navigate('/thankyou', { buskingPermitData: buskingPermitData })
	}

	const handleSubmitedForget = () => {
		navigate('/thankyou/forget-permit-code', { buskingPermitData: buskingPermitData })
	}

	const handleSubmitedPermitRegister = () => {
		navigate('/thankyou/busking-permit-approval', { buskingPermitData: buskingPermitData })
	}

	return (
		<div className="cm-form page-busking-register">
			<PageTitle title={t('Busking Application Form')} left/>
			{/* <div className="bg">
				<div className="bg-elements overflow-hidden">
					<img src="/images-local/block-tb-bs.png" alt="" />
					<img src="/images-local/block-bs-tb.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud_home.png" alt="" />
					<img src="/images-local/block-ts-bb.png" alt="" />
					<img src="/images-local/block-3-h.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud_home.png" alt="" />
					<img src="/images-local/block-tb-bs.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud_home.png" alt="" />
					<img src="/images-local/block-bb-ts.png" alt="" />
				</div>
			</div> */}
			<div className={`hidden xl:block`}>
				<BannerCTA title={t('A street performance at Central Market provides an open stage for all buskers in Hong Kong')} image="/images-local/busking-register-form-landing.png" buttonText={t('View Guideline')} actualUrl={'/data/data-local/download/General Busking Guidelines_' + i18n.language + '.pdf'} />
			</div>
			<div className={`block xl:hidden`}>
				<BannerCTA title={t('A street performance at Central Market provides an open stage for all buskers in Hong Kong')} image="/images-local/busking-register-form-landing-mobile.png" buttonText={t('View Guideline')} actualUrl={'/data/data-local/download/General Busking Guidelines_' + i18n.language + '.pdf'} />
			</div>
			<div className="container-boxed half-padding" ref={formRef}>
				{section !== 'BuskingRegisterForm' && (
					<section className="permit">
						<div className="title">
							{t('Do you have a permit no.?')}
							<div className="inline-block">
								<Popup text={<SVG src="/images-local/ico-info.svg" />} content={<PopupPermitCode />} />
							</div>
						</div>
						<div className="fields">
							<div className="row boxed">
								<div className="field m-0">
									<RadioGroup aria-label="nature-of-event-1" name="event_nature" value={hasPermit} onChange={handleChangeHasPermit} required>
										<FormControlLabel value="yes" control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />} label={t('Yes')} />
										<FormControlLabel value="no" control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />} label={t('No')} />
									</RadioGroup>
								</div>
							</div>
						</div>
					</section>
				)}

				<div className="form-container">
					{section === 'BuskingPermitValidation' ? <BuskingPermitValidation onForget={handleForgetPermit} onSuccess={handleSuccessValidation} initData={buskingPermitDataReReg} /> : ''}
					{section === 'BuskingPermitForget' ? <BuskingPermitForget onSuccess={handleSubmitedForget} /> : ''}
					{section === 'BuskingRegisterForm' ? <BuskingRegisterForm onSuccess={handleSubmited} /> : ''}
					{section === 'BuskingPermitRegisterForm' ? <BuskingPermitRegisterForm onSuccess={handleSubmitedPermitRegister} /> : ''}
				</div>
			</div>
		</div>
	)
}

export default BuskingRegister
