// Styles
import '../../styles/popup.scss'
// Core
import { useState, useRef, useEffect } from "react";


// GSAP
import { TimelineLite } from "gsap/all";
import { useTranslation } from "react-i18next";

// Axios
import axios from '../../config/axios'

const PopupContent = () => {
	const { t, i18n } = useTranslation('popup-busking-terms')
	const [popupPriceHtmlStr, setPopupPriceHtmlStr] = useState();
	
	useEffect(() => {

		axios.get(i18n.language + '/busking-tnc-popup.html').then((result) => {
			const resHtml = result.data
      
			setPopupPriceHtmlStr(resHtml)
		})
	}, [i18n.language])

	return (
		<>
		{/* Read from HTML string */}
		<div dangerouslySetInnerHTML={{ __html: popupPriceHtmlStr }}></div>

		{/*
			<div className="title">{t('Terms and Conditions')}</div>
			<p>
				{t('Central Market reserves the right to amend and/or terminate the terms and conditions without prior notice. In the event of dispute, the decision of Central Market will be final.')}
				<br />
				{t(
					'All information and contents made available on any of our websites are for reference only. Central Market does not guarantee the quality, accuracy, completeness, timeliness and reliability of the information or contents, or that they are suitable for a particular purpose. Central Market accepts no liability, whether in tort or contract or otherwise, for any loss or damage arising from or in reliance upon any information or contents made available on any of our websites.'
				)}
			</p>
				*/}
		</>
	)
}

export default PopupContent
