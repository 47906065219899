import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import styles from './Filter.module.scss'

const Filter = ({ value, childs, handleChange }) => {

    const onChange = (e) => {
        if(handleChange) handleChange(e.target.value)
	}

    return (
        <div className={`colored ${styles.filter}`}>
            <div className={styles.category}>
                <Select value={value} onChange={onChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    {childs.map((item, index) => (
                        <MenuItem key={index} value={index}>
                            {item}
                        </MenuItem>
                    )
                    )}
                </Select>
            </div>
        </div>
    )
}

export default Filter