// Core
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import classnames from 'classnames'

//i18n
import { useTranslation } from 'react-i18next'

// Styles
import styles from './Search.module.scss'

// Material-ui
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

// Lodash
import _ from 'lodash'

// Utils
import getData from '../../utils/getData'

// Components
import SiteSearch from '../../components/SiteSearch/SiteSearch'

const Search = () => {
	// const { query } = useParams()
	const [query, setQuery] = useState(useParams().query)
	const { t, i18n } = useTranslation()
	const dataType = [
		{
			fileName: 'event',
			displayName: 'Event',
			path: '/event-calendar/',
		},
		{
			fileName: 'shop',
			displayName: 'Shop',
			path: '/shop-detail/',
		},
		{
			fileName: 'history',
			displayName: 'History',
			path: '/history-timeline/',
		},
		{
			fileName: 'preserved_pillar',
			displayName: 'Conservation',
			path: '/preserved_pillar/',
		},
		// {
		// 	fileName: 'PromotionOffers',
		// 	displayName: 'Privileges',
		// 	path: '/privileges/',
		// },
		// {
		// 	fileName: 'Pages',
		// 	displayName: 'Page',
		// },
	]

	const [rawData, setRawData] = useState({})
	const [filteredData, setFilteredData] = useState([])
	const [data, setData] = useState([])

	// Fetch data
	useEffect(() => {
		const allLangs = i18n.options.whitelist.filter((item) => item !== 'cimode')

		//get all lang pages links
		const pages = {}
		allLangs.forEach((lang) => {
			pages[lang] = [
				{
					title: t('Event Calendar', { lng: lang }),
					description: '',
					url: '/event-calendar',
					type: 'page',
				},
				// {
				// 	title: t('Venue Booking Register'),
				// 	description: '',
				// 	url: '/venue-booking',
				//	type: 'page'
				// },
				{
					title: t('History', { lng: lang }),
					description: '',
					url: '/history-timeline',
					type: 'page',
				},
				{
					title: t('Floor Directory', { lng: lang }),
					description: '',
					url: '/floor-directory',
					type: 'page',
				},
				// {
				// 	title: t('Privileges'),
				// 	description: '',
				// 	url: '/privileges',
				//	type: 'page'
				// },
				{
					title: t('Shop Offer', { lng: lang }),
					description: '',
					url: '/shop-offer',
					type: 'page',
				},
				{
					title: t('Revitalization', { lng: lang }),
					description: '',
					url: '/revitalization',
					type: 'page',
				},
				// {
				// 	title: t('Staging Ground Register'),
				// 	description: '',
				// 	url: '/staging-ground/register',
				//	type: 'page'
				// },
				{
					title: t('FAQ', { lng: lang }),
					description: '',
					url: '/faq',
					type: 'page',
				},
				{
					title: t('Data Privacy Policy', { lng: lang }),
					description: '',
					url: '/privacy',
					type: 'page',
				},
				{
					title: t('Sitemap', { lng: lang }),
					description: '',
					url: '/sitemap',
					type: 'page',
				},
				{
					title: t('Disclaimer', { lng: lang }),
					description: '',
					url: '/disclaimer',
					type: 'page',
				},
				{
					title: t('Conservation Story', { lng: lang }),
					description: '',
					url: '/conservation',
					type: 'page',
				},
			]
		})

		//fetch data with key, index, lang
		const fetchData = (key, index, lang) => {
			return new Promise((resolve, reject) => {
				getData(key.fileName, lang)
					.then((newData) => {
						if (key.fileName === 'history') newData = newData.timeline
						_.map(newData, (item) => {
							item.url = item.url || key.path + item.slug
							item.type = key.displayName

							if (key.fileName === 'shop') {
								// console.log(key)
								item.url = '/floor-directory'
							} else if (key.fileName === 'history') {
								item.url = '/history-timeline'
							} else if (key.fileName === 'preserved_pillar') {
								item.url = '/conservation'
							}
							return item
						})

						resolve({ lang, index, result: newData })
					})
					.catch((error) => {
						console.log(error)
						reject(error)
					})
			})
		}

		//prepare promise list
		const all__fetch = []
		allLangs.forEach((lang) => {
			dataType.forEach((key, index) => {
				all__fetch.push(fetchData(key, index, lang))
			})
		})

		Promise.all(all__fetch).then((results) => {
			// console.log(results)

			// reorder the result as index order
			const allResults = {}
			results.forEach((res) => {
				// console.log({res})
				if (!allResults[res.lang]) {
					allResults[res.lang] = {}
				}
				allResults[res.lang][res.index] = res.result
			})

			// combine all result
			const rawData = { en: [], tc: [], sc: [] }
			Object.entries(allResults).forEach(([lang, item]) => {
				Object.entries(item).forEach(([key, value]) => {
					// console.log(lang, key, value);
					if (value instanceof Array) rawData[lang] = [...rawData[lang], ...value]
					else {
						rawData[lang] = [...rawData[lang], ...Object.values(value)]
					}
				})
			})

			allLangs.forEach((lang) => {
				rawData[lang] = [...rawData[lang], ...pages[lang]]
			})

			// console.log('rawData: ', rawData)
			setRawData(rawData)
		})

		// eslint-disable-next-line
	}, [])

	// Filter data
	useEffect(() => {
		const allLangs = i18n.options.whitelist.filter((item) => item !== 'cimode')

		console.log('filter data rawData', { rawData, query, alen: allLangs.length })

		if (!query || !allLangs || !rawData || !rawData[i18n.language]) {
			setFilteredData([])
			console.log('no query or ')
			return
		}

		const fliterData = (filterD) => {
			if (!Array.isArray(filterD)) {
				return []
			}

			const result = []
			console.log(filterD)
			filterD.forEach((item) => {
				const { title, description, type, id, url, name, year_title, year_desp, year_name, year_qouat } = item

				const checkTitle = title && title.toLowerCase().includes(query.toLowerCase())
				var checkDescp = false
				var checkDescpArrayIndex = -1
				if (type == 'Conservation' && description) {
					description.forEach((desc, index) => {
						if (desc.content.toLowerCase().includes(query.toLowerCase())) {
							checkDescpArrayIndex = index
							checkDescp = true
						}
					})
				} else {
					checkDescp = description && description.toLowerCase().includes(query.toLowerCase())
				}
				const checktype = type && type.toLowerCase().includes(query.toLowerCase())
				const checkYearTitle = year_title && year_title.toLowerCase().includes(query.toLowerCase())
				const checkYearDesp = year_desp && year_desp.toLowerCase().includes(query.toLowerCase())
				const checkYearName = year_name && year_name.toLowerCase().includes(query.toLowerCase())
				const checkYearQouat = year_qouat && year_qouat.toLowerCase().includes(query.toLowerCase())

				if (checkTitle || checkDescp || checktype) {
					if (type == 'Conservation') {
						result.push(url + '#' + name + '' + '?' + checkDescpArrayIndex)
					} else {
						result.push(type === 'page' ? url : `${type.toLowerCase()}_${id}`)
					}
				} else if (checkYearTitle || checkYearDesp || checkYearName || checkYearQouat) {
					result.push(url + '#' + year_name)
				}
			})

			return result
		}

		const filtered = {}
		allLangs.forEach((lang) => {
			filtered[lang] = fliterData(rawData[lang])
		})

		let filteredCombine = []
		Object.entries(filtered).forEach(([lang, filtered]) => {
			filteredCombine = _.merge(filteredCombine, filtered)
		})

		console.log({ filtered, filteredCombine })

		const filteredDataFinal = rawData[i18n.language].filter((item) => {
			const { title, description, type, name, id, url, year_title, year_desp, year_name, year_qouat } = item
			if (filteredCombine.includes(`${type.toLowerCase()}_${id}`)) {
				return true
			}

			if (type == 'Conservation') {
				var itemFound = false
				filteredCombine.forEach((obj) => {
					if (obj.includes(url + '#' + name + '')) {
						item['desc_list_id'] = obj.split('?').length > 1 ? obj.split('?')[1] : 0
						itemFound = true
					}
				})
				if (itemFound) return true
			}

			if (filteredCombine.includes(url) && type != 'Conservation') {
				return true
			}

			// special for history
			if (filteredCombine.includes(url + '#' + year_name)) {
				return true
			}

			return false
		})
		console.log(filteredDataFinal, i18n.language)

		setFilteredData(filteredDataFinal)
	}, [rawData, query, i18n.language, i18n.options.whitelist])

	// Get current page data
	useEffect(() => {
		// console.log('filteredData', filteredData)
		setData(filteredData)
	}, [filteredData])

	function htmldecode(str) {
		var txt = document.createElement('textarea')
		txt.innerHTML = str
		return txt.value
	}

	const handleChange = (e) => {
		setQuery(e.target.value)
	}

	return (
		<div className={styles.main}>
			<div className={styles.bg}>
				<div className="bg-elements overflow-hidden">
					<img src="/images-local/block-3-h.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-s.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-ts-bb.png" alt="" />
					<img src="/images-local/block-bs-tb.png" alt="" />
					<img src="/images-local/CM_KV_Play-version.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
				</div>
			</div>
			<div className="container-main">
				{/* <SiteSearch query={query} /> */}
				<div className={`colored ${styles.search}`}>
					<form>
						<FormControl fullWidth>
							<TextField value={query} onChange={handleChange} label={t('Search')} fullWidth />
							<IconButton type="submit" aria-label={t('Search')}>
								<img src="/images-local/ico-search.svg" alt="" />
							</IconButton>
						</FormControl>
					</form>
				</div>

				<div className={styles.results}>
					{data.length ? (
						data.map((item, index) => {
							if (item.type == 'History') {
								return (
									<div className={styles.result} key={index}>
										<Link to={item.url + '#' + item.year_name}>
											<span className={classnames(styles.title, 'block')}>{parse(htmldecode(item.year_title).replace(/<[^>]*>?/gm, ''))}</span>
											{item.year_desp && <span className={classnames(styles.description, 'block')}>{parse(htmldecode(item.year_desp).replace(/<[^>]*>?/gm, ''))}</span>}
										</Link>
									</div>
								)
							} else if (item.type == 'Conservation') {
								return (
									<div className={styles.result} key={index}>
										<Link to={item.url + '#' + item.name + ''}>
											<span className={classnames(styles.title, 'block')}>{item.title}</span>
											{item.description && <span className={classnames(styles.description, 'block')}>{item.description[item.desc_list_id] && item.description[item.desc_list_id].length > 0 && item.desc_list_id != -1 ? parse(htmldecode(item.description[item.desc_list_id].content).replace(/<[^>]*>?/gm, '')) : ''}</span>}
										</Link>
									</div>
								)
							} else {
								return (
									<div className={styles.result} key={index}>
										<Link to={item.url}>
											<span className={classnames(styles.title, 'block')}>{item.title}</span>
											{item.description && <span className={classnames(styles.description, 'block')}>{parse(htmldecode(item.description).replace(/<[^>]*>?/gm, ''))}</span>}
										</Link>
									</div>
								)
							}
						})
					) : (
						<div className="w-full text-primary text-3xl font-bold">{t('No result')}</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Search
