import { useMedia } from "react-use";
// Style
import styles from "./BannerCTA.module.scss";

// Compoents
import Button from "../Button/Button";
import Popup from "../../components/Popup/Popup";

//i18n
// import { useTranslation } from 'react-i18next'

const BannerCTA = (props) => {
	const isMobile = useMedia('(max-width: 768px)');
  // const { t } = useTranslation()
  if (props.isPopup) {
    return (
      <div className={styles.banner} data-reverse={props.isReverse}>
        <div className={styles.info}>
          <div
            className={styles.pic}
            style={{ backgroundImage: isMobile && props.mobileSrc ? `url(${props.mobileSrc})` : `url(${props.image})` }}
          ></div>
          <div className={styles.text}>
            <div className={styles.subTitle}>{props.subTitle}</div>
            <div className={styles.title}>{props.title}</div>
            <Popup text={props.buttonText} content={props.popupcontent} isbtn />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.banner} data-reverse={props.isReverse}>
      <div className={styles.info}>
        <div
          className={styles.pic}
          style={{ backgroundImage: isMobile && props.mobileSrc ? `url(${props.mobileSrc})` : `url(${props.image})` }}
        ></div>
        <div className={styles.text}>
          <div className={styles.subTitle}>{props.subTitle}</div>
          <div className={styles.title}>{props.title}</div>
            <Button text={props.buttonText} href={props.buttonUrl} actualUrl={props.actualUrl} />
        </div>
      </div>
    </div>
  );
};

export default BannerCTA;
