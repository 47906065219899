import styles from './NavArrow.module.scss'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const navArrow = React.forwardRef((props, ref) => {
	const { prev, next } = ref
	return (
		<div className={styles.navBtns}>
			<a href="#" ref={prev} className={styles.prev}>
				<div className={styles.arrow}></div>
			</a>

			<a href="#" ref={next} className={styles.next}>
				<div className={styles.arrow}></div>
			</a>
		</div>
	)
})

export const SliderArrow = React.forwardRef((props, ref) => {
	const { isLeftRight, isCenter, shopHighlight, onClickLeft, onClickRight } = props
	const { prev, next } = ref

	const handleClickLeft = () => {
		if(onClickLeft) onClickLeft()
	}

	const handleClickRight = () => {
		if(onClickRight) onClickRight()
	}

	if(isLeftRight || isCenter) {
		return (
			<>
				<div className={`${styles.sliderNav} ${isLeftRight ? styles.left : ''} ${isCenter ? styles.center : ''}`} onClick={handleClickLeft}>
					<button ref={prev} className={styles.prev}>
						<FontAwesomeIcon icon="fa-solid fa-chevron-left" color="#c8215d"/>
					</button>
				</div>


				<div className={`${styles.sliderNav} ${isLeftRight ? styles.right : ''} ${isCenter ? styles.center : ''}`} onClick={handleClickRight}>
				<button ref={next} className={styles.next}>
						<FontAwesomeIcon icon="fa-solid fa-chevron-right" color="#c8215d" />
					</button>
				</div>
			</>
		)
	}
	return (
		<div className={`${styles.sliderNav} ${shopHighlight ? styles.shopHighlight : ''}`}>
			<button ref={prev} className={styles.prev} onClick={handleClickLeft}>
				<FontAwesomeIcon icon="fa-solid fa-chevron-left" color="#c8215d"/>
				{/* <div className={styles.arrow}></div> */}
			</button>

			<button ref={next} className={styles.next} onClick={handleClickRight}>
				<FontAwesomeIcon icon="fa-solid fa-chevron-right" color="#c8215d" />
				{/* <div className={styles.arrow}></div> */}
			</button>
		</div>
	)
})

export default navArrow
