import { Link, useLocation } from 'react-router-dom'
import Image from '../Image/Image'
import styles from './Footer.module.scss'

//i18n
import { useTranslation } from 'react-i18next'
const Footer = () => {
	const { t, i18n } = useTranslation()
	const { pathname } = useLocation()
	const specificPages = ['/', '/history-timeline']

	const linkH6 = {
		en: 'https://h6.ura-vb.org.hk/en',
		tc: 'https://h6.ura-vb.org.hk/tc',
		sc: 'https://h6.ura-vb.org.hk/tc',
	}

	const linkH18 = {
		en: 'https://h18.ura.org.hk/en',
		tc: 'https://h18.ura.org.hk/tc',
		sc: 'https://h18.ura.org.hk/sc',
	}

	const linkCcg = {
		en: 'https://www.chinachemgroup.com/en',
		tc: 'https://www.chinachemgroup.com/zh-hk',
		sc: 'https://www.chinachemgroup.com/zh-hk',
	}

	const linkUra = {
		en: 'https://www.ura.org.hk/en',
		tc: 'https://www.ura.org.hk/tc',
		sc: 'https://www.ura.org.hk/sc',
	}
	return (
		<footer className={`${styles.footer} ${specificPages.includes(pathname) ? styles.home : ''}`}>
			{specificPages.includes(pathname) &&
			<>
				<div className={styles.bg}>
					<img className="bg-cloud" src="/images-local/cloud-dark_home.png" alt="" />
						<img src="/images-local/block-s.png" alt="" />
						<img className="bg-cloud" src="/images-local/cloud_home.png" alt="" />
						<img src="/images-local/block-bs-tb.png" alt="" />
						<img className="bg-cloud" src="/images-local/cloud-dark_home.png" alt="" />
					</div>
			</>
			}
			<div className={styles.socialMedia}>
				<a title="Facebook" href="https://www.facebook.com/centralmarkethk" target="_blank" rel="noreferrer">
					<img src="/images-local/ico-fb.svg" alt="" />
				</a>
				<a title="Instagram" href="https://www.instagram.com/centralmarkethk/" target="_blank" rel="noreferrer">
					<img src="/images-local/ico-ig.svg" alt="" />
				</a>
				<a title="Youtube" href="https://www.youtube.com/channel/UCzsDAjAR2mfOZxI-WLG2hOA" target="_blank" rel="noreferrer">
					<img src="/images-local/ico-youtube.svg" alt="" />
				</a>
			</div>
			<div className={styles.appContainer}>
				<a href="https://play.google.com/store/apps/details?id=com.chinachemgroup.centralmarket&hl=zh_HK&gl=US" target="_blank">
					<img src="/images-local/google-play-svg.png" alt="" />
				</a>
				<a href="https://apps.apple.com/us/app/central-market-%E4%B8%AD%E7%92%B0%E8%A1%97%E5%B8%82/id1571666102" target="_blank">
					<img src="/images-local/app-store-svg.png" alt="" />
				</a>
			</div>
			<ul className={`${styles.links} flex w-full flex-row md:w-1/2`}>
				<ul className="w-1/2">
					<li>
						<Link to="/press">{t('Press')}</Link>
					</li>
					{/* <li>
						<Link to="/faq">{t('FAQ')}</Link>
					</li> */}
					<li>
						<a href={linkH6[i18n.language]} target="_blank" rel="noreferrer">
							{t('H6 Conet')}
						</a>
					</li>
					<li>
						<a href={linkH18[i18n.language]} target="_blank" rel="noreferrer">
							{t('H18 Conet')}
						</a>
					</li> 
					{/* <li>
						<Link to="/pet-friendly">{t('Instruction for Pet Owners')}</Link>
					</li> */}
				</ul>
				<ul className="w-1/2">
					<li>
						<Link to="/disclaimer">{t('Terms of use')}</Link>
					</li>
					<li>
						<Link to="/privacy-policy">{t('Privacy & Policy')}</Link>
					</li>
					<li>
						<Link to="/sitemap">{t('Sitemap')}</Link>
					</li>
				</ul>
			</ul>
			{/* <label>{t('Strategic Partners')}</label>
			<div className={styles.partners}>
				<Image src="/images-local/h6.png" />
				<Image src="/images-local/h8.png" />
			</div> */}
			<div className={styles.logos}>
				<a title={t('Chinachem Group')} target="_blank" rel="noreferrer" href={linkCcg[i18n.language]}>
					<img src="/images-local/logo-ccg.svg" alt="" />
				</a>

				<a title={t('Urban Renewal Authority')} target="_blank" rel="noreferrer" href={linkUra[i18n.language]}>
					<img src="/images-local/logo-ura.svg" alt="" />
				</a>
			</div>
			<div className={styles.copyright}>© {new Date().getFullYear()} {t('footer_copyright')}</div>
		</footer>
	)
}

export default Footer
