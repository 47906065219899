export const API_BASE = process.env.REACT_APP_API_BASE


export const API = {
    busking:{
        permitValidate:`${API_BASE}/Busking/BuskingPermitValidation`,
        permitSubmit:`${API_BASE}/Busking/BuskingPermitSubmit`,
        registerSubmit:`${API_BASE}/Busking/BuskingBookingSubmit`,
        getTimeLocationSlot:`${API_BASE}/Busking/GetTimeSlotAndLocationWithPermit`
    }
}