// Core
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet-async'

import style from './Privacy.module.scss'

// Components
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'

// i18n
import { useTranslation } from 'react-i18next'

// Axios
// import axios from 'axios'
import axios from '../../config/axios'

const Privacy = () => {
	const { i18n, t } = useTranslation()

	const [data, setData] = useState({
		disclaimerDetails: [],
		updatedDate: '',
	})

	// Fetch data
	useEffect(() => {
		axios.get(i18n.language + '/privacy.json').then((result) => {
			const res = result.data
			console.log('Privacy res ~ ', res)
			setData((prevState) => ({
				...prevState,
				disclaimerDetails: res.content_details,
				updatedDate: res.update_date,
			}))
		})
	}, [i18n.language])

	const PageDetails = data.disclaimerDetails.map((item, index) => (
		<div key={index}>
			<div className={style.title}>{item.title}</div>
			<div className={style.details}>{parse(item.details)}</div>
		</div>
	))

	return (
		<div className={style.privacy}>
			<Helmet>
				<title>
					{t('Privacy & Security')} - {t('Central Market')}
				</title>
			</Helmet>
			<div className="container-boxed">
				<div className={style.container}>
					<div className={style.title}>
						<TextBorderFill>{t('Privacy & Security')}</TextBorderFill>
					</div>
					<div className={style.detailsContainer}>{PageDetails}</div>

					<div className={style.updateDate}>{data.updatedDate}</div>
				</div>
			</div>
		</div>
	)
}

export default Privacy
