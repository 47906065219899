// Core
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";

// Compoents
import Card from "../../components/Card/Card";
import TextBorderFill from "../../components/TextBorderFill/TextBorderFill";
import ButtonUnderline from "../../components/ButtonUnderline/ButtonUnderline";
import Loader from "../../components/Loader/Lodaer";

// material-ui
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";

// Styles
import styles from "./ShopOffer.module.scss";

// i18n
import { useTranslation } from "react-i18next";

// Utils
import getData from "../../utils/getData";

// Lodash
import _ from "lodash";

const ShopOffer = () => {
  const { t, i18n } = useTranslation("");
  const { page } = useParams();

  const perPage = 9;

  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [haveData, setHaveData] = useState();
  const [data, setData] = useState({
    items: [],
    itemsFiltered: [],
    itemsCurrent: [],
  });

  const [filter, setFilter] = useState({
    currentPage: page || 1,
    categories: [],
    category: "",
    search: "",
    date: new Date(),
  });

  // Fetch data
  useEffect(() => {
    setIsLoading(true);

    async function fetchData() {
      try {
        let dataRaw = await getData("shop_offer");
        let shopDataRaw = await getData("shop");

        var availableShopOffers = [];
        var availableData = [];

        dataRaw.map((element) => {
          var existValidOffer = false;
          element.offer.map((offer) => {
            // valid offer time interval & valid category
            if (
              new Date(offer.start_time.replace(/-/g, "/")).getTime() <
              Date.now() &&
              new Date(offer.end_time.replace(/-/g, "/")).getTime() >
              Date.now() &&
              offer.category != null &&
              offer.category != ""
            ) {
              availableShopOffers.push(offer);
              existValidOffer = true;
            }
          });
          // check if valid shop offers exist
          if (existValidOffer) {
            // append shop detail
            element["shop"] = shopDataRaw.find((obj) => {
              return obj.id == element.shop_id;
            });
            availableData.push(element);
          }
        });

        console.log(availableShopOffers);
        const availableCategories = _.map(
          _.uniqBy(availableShopOffers, "category"),
          "category"
        );

        const data = _.sortBy(availableData, [
          function (o) {
            return o.shop_display_order;
          },
        ]);

        setData((prevState) => ({
          ...prevState,
          items: data,
        }));

        setFilter((prevState) => ({
          ...prevState,
          categories: availableCategories,
        }));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [i18n.language]);

  // Filter data
  useEffect(() => {
    let itemsFiltered = data.items;
    setHaveData(itemsFiltered);
    const date_today = dayjs();

    if (filter.category) {
      itemsFiltered = itemsFiltered.filter((item) => {
        var categoryFound = false;
        item.offer.forEach((offer) => {
          const date_start = dayjs(offer.start_time);
          const date_end = dayjs(offer.end_time);

          if (
            offer.category === filter.category &&
            date_start.isBefore(date_today) &&
            date_end.isAfter(date_today)
          ) {
            categoryFound = true;
          }
        });
        return categoryFound;
      });
    }

    if (filter.search) {
      console.log(filter.search);
      itemsFiltered = itemsFiltered.filter(
        (item) =>
          item.shop_offer_title
            .toLowerCase()
            .includes(filter.search.toLowerCase()) ||
          item.offer.filter(
            (offer) =>
              (offer.title != null &&
                offer.title
                  .toLowerCase()
                  .includes(filter.search.toLowerCase())) ||
              (offer.description != null &&
                offer.description
                  .toLowerCase()
                  .includes(filter.search.toLowerCase()))
          ).length > 0
      );
    }

    setData((prevState) => ({
      ...prevState,
      itemsFiltered: itemsFiltered,
    }));
  }, [data.items, filter]);

  // Get current pages data
  useEffect(() => {
    let itemsCurrent = data.itemsFiltered.slice(
      0,
      filter.currentPage * perPage
    );

    setData((prevState) => ({
      ...prevState,
      itemsCurrent: itemsCurrent,
    }));

    setTimeout(() => {
      setIsLoading(false);
      setIsLoadingPage(false);
    }, 200);
  }, [data.itemsFiltered, filter.currentPage]);

  // Change category
  const handleChangeCategory = (e) => {
    setIsLoading(true);
    setFilter((prevState) => ({
      ...prevState,
      category: e.target.value,
    }));
  };

  // Serach typing
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    setIsLoading(true);
    setFilter((prevState) => ({
      ...prevState,
      search: e.target.value,
    }));
  };

  // Search submit
  const handleSearch = (e) => {
    setIsLoading(true);
    e.preventDefault();
    setFilter((prevState) => ({
      ...prevState,
      search: search,
    }));
  };

  // Load more
  const handleLoadMore = () => {
    setIsLoadingPage(true);
    setFilter((prevState) => ({
      ...prevState,
      currentPage: filter.currentPage + 1,
    }));
  };

  return (
    <div className={styles.ShopOffer}>
      <Helmet>
        <title>
          {t("Shop offer")} - {t("Central Market")}
        </title>
      </Helmet>
      <div className={styles.bg}>
        <div className="bg-elements overflow-hidden">
          {/* <img src="/images-local/block-3-h.png" alt="" /> */}
          <img className="bg-cloud" src="/images-local/cloud.png" alt="" />
          <img src="/images-local/block-s.png" style={{ opacity: 0 }} alt="" />
          <img className="bg-cloud" src="/images-local/cloud.png" alt="" />
          {/* <img src="/images-local/block-ts-bb.png" alt="" /> */}
          {/* <img src="/images-local/block-bs-tb.png" alt="" /> */}
          <img src="/images-local/CM_KV_Play-version.png" alt="" />
          <img className="bg-cloud" src="/images-local/cloud.png" alt="" />
        </div>
      </div>

      <div className="container-boxed py-16">
        <div className={styles.title}>
          <TextBorderFill>{t("Shop offer")}</TextBorderFill>
        </div>

        <div className={`colored ${styles.filter}`}>
          <div className={styles.category}>
            {/* Dropdown category */}
            <Select
              value={filter.category}
              onChange={handleChangeCategory}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">{t("Category")}</MenuItem>
              {filter.categories.map((category, index) => {
                return (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={styles.search}>
            <form onSubmit={handleSearch}>
              <FormControl>
                {/* Input search */}
                <TextField
                  onChange={handleChangeSearch}
                  className={styles.half}
                  id="search"
                  label={t("Search")}
                />
                <IconButton
                  type="submit"
                  aria-label={t("Search")}
                  onClick={handleSearch}
                >
                  <img src="/images-local/ico-search.svg" alt="" />
                </IconButton>
              </FormControl>
            </form>
          </div>
        </div>

        {/* Items */}
        {isLoading ? (
          <Loader />
        ) : (
          <div className={styles.items}>
            <div>
              {haveData == 0 ? (
                <div className={styles.title2}> {t("More Offers")}</div>
              ) : (
                <div>
                  {data.itemsCurrent.map((item, index) => {
                    return (
                      <Card
                        key={index}
                        title={
                          item.shop_offer_title ? item.shop_offer_title : ""
                        }
                        location={item.shop.shop_no}
                        pic={item.shop_offer_image ? item.shop_offer_image : ""}
                        url={`/shop-offer/${item.slug ? item.slug : ""}`}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Load mroe */}
        {isLoadingPage ? (
          <Loader />
        ) : filter.currentPage * perPage >= data.itemsFiltered.length ||
          isLoading ? (
          ""
        ) : (
          <div onClick={handleLoadMore} className={styles.btnLoadMore}>
            <ButtonUnderline>{t("Load more offers")}</ButtonUnderline>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopOffer;
