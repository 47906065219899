// Core
import { useState, useEffect, useRef } from 'react'
import { map, first } from 'lodash'
import $ from 'jquery'
import axios from 'axios'

// Styles
import '../../styles/form.scss'
import './VenueBookingRegister.scss'

// Material UI
import { Checkbox, Select, RadioGroup, FormControlLabel, FormControl, FormLabel, FormHelperText, InputLabel, TextareaAutosize, MenuItem, Radio } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import { addMonths } from 'date-fns'

// Validator
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import TextareaValidator from '../../components/TextareaValidator/TextareaValidator'
import CheckboxValidatorElement from '../../components/CheckboxValidatorElement/CheckboxValidatorElement'
import KeyboardDatePickerValidator from '../../components/KeyboardDatePickerValidator/KeyboardDatePickerValidator'
import RadioGroupValidator from '../../components/RadioGroupValidator/RadioGroupValidator'
// Dropzone
import { DropzoneArea } from 'material-ui-dropzone'

// i18n
import { useTranslation } from 'react-i18next'

// Components
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import BannerCTA from '../../components/BannerCTA/BannerCTA'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import Popup from '../../components/Popup/Popup'
import PopupTAC from '../../components/PopupContent/venue_hire_t&c'
import PopupPriceVForm from '../../components/PopupContent/PopupPriceVForm'
import { useNavigate } from 'react-router-dom'
import getSession from '../../utils/getSession'
import getData from '../../utils/getData'
import Loader from '../../components/Loader/Lodaer'
import PageTitle from '../../components/PageTitle/PageTitle'
const VenueBookingRegister = () => {
	const { t, i18n } = useTranslation('')
	let submitFormData = ''
	const DropZoneEl = useRef()
	const navigate = useNavigate()
	const [sampleData, setSampleData] = useState({
		applicant_name: '陳大文',
		mobile: '12345678',
		company_name: 'test 123',
		company_phone: '12345678',
		company_email: 'hello@hello.com',
		company_address: 'test 122222222',
		company_nature: '3',
		company_nature_others: '',
		event_name: 'event 123',
		event_location: 1,
		event_starttime: '7',
		event_endtime: '12',
		event_date: '2023-07-12',
		event_startdate: '',
		event_enddate: '',
		event_nature_1st: '2',
		event_nature_2nd: '6',
		event_nature_others: '',
		event_details: '121212121',
		file_1: null,
		file_2: null,
		file_3: null,
		file_4: null,
		file_5: null,
	})
	const [formData, setFormData] = useState({
		applicant_name: '',
		mobile: '',
		company_name: '',
		company_phone: '',
		company_email: '',
		company_address: '',
		company_nature: '',
		company_nature_others: '',
		event_name: '',
		event_location: '',
		event_date: null,
		event_starttime: '',
		event_endtime: '',

		event_startdate: '',
		event_enddate: '',
		event_nature_1st: '',
		event_nature_2nd: '',
		event_details: '',
		event_nature_others: '',
		file_1: '',
		file_2: '',
		file_3: '',
		file_4: '',
		file_5: '',
	})
	const [isLoading, setIsLoading] = useState(false)
	const [declarationData, setDeclarationData] = useState({
		declaration1: '',
	})
	const openingHour = {
		open: 10,
		close: 22,
	}

	const [venueList, setVenueList] = useState([])

	const [timeOptions, setTimeOptions] = useState({
		start: [],
		end: [],
	})

	useEffect(() => {
		async function fetchData() {
			const result = await getData('venue_booking')
			if(result) {
				setVenueList(result)
			}
		}
		fetchData()
	}, [i18n.language])

	useEffect(() => {
		setTimeOptions((prevState) => ({
			...prevState,
			start: getTimeList(openingHour.open, openingHour.close - 1),
			end: getTimeList(openingHour.open + 1, openingHour.close),
		}))
		// eslint-disable-next-line
	}, [])

	const getTimeList = (start, end) => {
		let list = []
		for (let i = start; i <= end; i++) {
			list.push(i)
		}
		return list
	}
	const handleSampleData = () => {
		setFormData((prevState) => ({
			...prevState,
			...sampleData,
		}))
	}
	const handleChangeFormData = (event) => {
		const name = event.target.name
		const value = event.target.value
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}))

		if (name === 'event_starttime') {
			setTimeOptions((prevState) => ({
				...prevState,
				end: getTimeList(value + 1, openingHour.close),
			}))
		}
	}

	const handleChangeFormDataDate = (date, key) => {
		setFormData((prevState) => ({
			...prevState,
			[key]: date,
		}))
	}

	// const handleChangeDeclaration = () => {
	// 	setTimeOptions((prevState) => ({
	// 		...prevState,
	// 		declaration: !formData.declaration,
	// 	}))
	// }

	const handleUpdateFiles = (files) => {
		// const fileData = new FormData();
		// // console.log(files[0].name);
		// if (DropZoneEl.current.state.fileObjects.length) {
		//   DropZoneEl.current.state.fileObjects.map((file, i) => {
		//     fileData.append("myFile", file.file, file.file.name);

		//     // Details of the uploaded file
		//     // console.log(this.state.selectedFile);

		//     // Request made to the backend api
		//     // Send formData object
		//   });
		// }
		// axios
		//   .post("http://dev.centralmarket.cpt.com/api/Venue/UploadFiles", fileData)
		//   .then(function (response) {
		//     console.log("response", response.data.record);
		//     response.data.record.map((res, i) => {
		//       const id = i + 1;
		//       setFormData((prevState) => ({
		//         ...prevState,
		//         ["file_" + id]: res,
		//       }));
		//       console.log(JSON.stringify(formData));
		//     });
		//   });
		if (files.length >= 5) {
			$('.dropzone-addbtn-container').hide()
		} else {
			$('.dropzone-addbtn-container').show()
		}
	}
	const margeEventDateTime = () => {
		const dateObj = formData.event_date

		let month = ''
		let day = ''
		let year = ''

		if (typeof dateObj == 'string') {
			const arrDateObj = dateObj.split('-')

			year = arrDateObj[0]
			month = arrDateObj[1]
			day = arrDateObj[2]
		} else {
			month = dateObj.getUTCMonth() + 1 //months from 1-12
			day = dateObj.getUTCDate()
			year = dateObj.getUTCFullYear()
		}
		submitFormData = formData
		submitFormData['event_startdate'] = year + '-' + month + '-' + day + ' ' + formData['event_starttime'] + ':00:00'
		submitFormData['event_enddate'] = year + '-' + month + '-' + day + ' ' + formData['event_endtime'] + ':00:00'
	}
	const handleSubmit = (event) => {
		event.preventDefault()
		setIsLoading(true)
		const fileData = new FormData()
		// console.log(files[0].name);
		if (DropZoneEl.current.state.fileObjects && DropZoneEl.current.state.fileObjects.length) {
			DropZoneEl.current.state.fileObjects.map((file, i) => {
				fileData.append('myFile', file.file, file.file.name)

				// Details of the uploaded file
				// console.log(this.state.selectedFile);

				// Request made to the backend api
				// Send formData object
			})
			axios
				.post(
					// "http://dev.centralmarket.cpt.com/api/Venue/UploadFiles",
					'/api/Venue/UploadFiles',
					// 'http://182.198.143.98:8081/api/Venue/UploadFiles',
					fileData
				)
				.then(function (response) {
					let files = {}
					response.data.record.map((res, i) => {
						const id = i + 1
						setFormData((prevState) => ({
							...prevState,
							['file_' + id]: res,
						}))

						files['file_' + id] = res
					})

					margeEventDateTime()
					submitForm(files)
				})
		} else {
			margeEventDateTime()
			submitForm()
		}
	}
	const submitForm = (modifiedData) => {
		// console.log('submitFormDataBefore', submitFormData)
		submitFormData = {
			...submitFormData,
			...modifiedData,
		}

		$.post(
			// "https://webhook.site/a5dd2ff7-13e4-45fa-8a5d-7cf7de530fcb",
			// "http://dev.centralmarket.cpt.com/api/Venue/SubmitVenueBooking",
			'/api/Venue/SubmitVenueBooking',
			// 'http://182.198.143.98:8081/api/Venue/SubmitVenueBooking',
			submitFormData,
			function (response) {
				if (response.status_code == 200) {
					navigate('/thankyou')
				}
			}
		)
	}
	const handleAddFile = (event) => {
		$('.MuiDropzoneArea-root').click()
	}
	const handleChangeDeclaration = (event) => {
		const name = event.target.name
		if (declarationData[name] == 'checked') {
			setDeclarationData((prevState) => ({
				...prevState,
				[name]: '',
			}))
		} else {
			setDeclarationData((prevState) => ({
				...prevState,
				[name]: 'checked',
			}))
		}
	}

	const handlePreviewIcon = (fileObject, classes) => {
		const { type } = fileObject.file

		switch (type) {
			default:
				return <img src="/images-local/ico-preview.png" alt="" />
		}
	}
	return (
		<div className={`${isLoading ? 'loading' : ''}` + ' main cm-form page-VenueBookingRegister'}>
			<PageTitle title={t('Venue Hire')} left/>
			{/* <BannerCTA title={t('Standard Package for Central Market Event Venues')} image="/images-local/pic-banner-1.png" buttonText={t('LEARN MORE')} isPopup popupcontent={<PopupPriceVForm />} /> */}
			<BannerCTA title={t('Standard Package for Central Market Event Venues')} image="/images-local/pic-banner-1.png" buttonText={t('LEARN MORE')} actualUrl={'/data/data-local/download/Venue Booking Form.pdf'} />
			<div className="container-main">
				{window.location.href.includes('www.centralmarket.hk') || window.location.href.includes('www-staging.centralmarket.hk') ? (
					''
				) : getSession('devtool') === 'on' ? (
					<div className="w-full border border-primary border-dashed p-10">
						<div className="text-primary mb-2">Dev tool:</div>
						<a
							className="inline-block border border-primary text-primary transition-all duration-400 py-2 px-5 cursor-pointer hover:bg-primary hover:text-white"
							onClick={handleSampleData}
						>
							Autofill
						</a>
					</div>
				) : (
					''
				)}
				<ValidatorForm
					onSubmit={handleSubmit}
					onError={() =>
						$([document.documentElement, document.body]).animate(
							{
								scrollTop: $('.Mui-error').eq(0).parent().offset().top - $('[class*=Header_nav_]').eq(0).height(),
							},
							1000
						)
					}
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<div className="title">
							<TextBorderFill>{t('Event venue Applicantion form')}</TextBorderFill>
							<div className="desc">{t('venue_booking_form_required_fields')}</div>
						</div>
						<section>
							<div className="title">{t('A. Particulars of the Applicant')}</div>
							<div className="fields">
								<div className="row boxed">
									<div className="field half">
										<TextValidator
											label={t('Name of the Applicant') + '*'}
											onChange={handleChangeFormData}
											name="applicant_name"
											value={formData.applicant_name}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Mobile') + '*'}
											onChange={handleChangeFormData}
											name="mobile"
											value={formData.mobile}
											validators={['required', 'isNumber', 'minNumber:10000000', 'maxNumber:99999999']}
											errorMessages={[t('Cannot leave it blank'), t('The format is incorrect'), t('The format is incorrect'), t('The format is incorrect')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Company Name') + '*'}
											onChange={handleChangeFormData}
											name="company_name"
											value={formData.company_name}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Company Phone no.') + '*'}
											onChange={handleChangeFormData}
											name="company_phone"
											value={formData.company_phone}
											validators={['required', 'isNumber', 'minNumber:10000000', 'maxNumber:99999999']}
											errorMessages={[t('Cannot leave it blank'), t('The format is incorrect'), t('The format is incorrect'), t('The format is incorrect')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Email') + '*'}
											onChange={handleChangeFormData}
											name="company_email"
											value={formData.company_email}
											validators={['required', 'isEmail']}
											errorMessages={[t('Cannot leave it blank'), t('The format is incorrect')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Company Address') + '*'}
											onChange={handleChangeFormData}
											name="company_address"
											value={formData.company_address}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
											fullWidth
										/>
									</div>
								</div>

								<div className="row">
									<div className="field">
										<FormLabel>{t('Nature of Company') + '*'}</FormLabel>
										<FormControl fullWidth>
											<RadioGroupValidator
												aria-label="nature-of-company"
												onChange={handleChangeFormData}
												name="company_nature"
												value={formData.company_nature}
												validators={['required']}
												errorMessages={[t('Cannot leave it blank')]}
											>
												<FormControlLabel
													value="1"
													control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
													label={t('Company / Organization')}
												/>
												<FormControlLabel
													value="2"
													control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
													label={t('Government Departments')}
												/>
												<FormControlLabel
													value="3"
													control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
													label={t('PR Agency / Advertising Agency')}
												/>
												<FormControlLabel
													value="4"
													control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
													label={t('Charity')}
												/>
												<div className="break"></div>
												<FormControlLabel
													className="radio-others"
													value="5"
													control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
													label={t('Others') + ':'}
												/>
												{formData.company_nature === '5' ? (
													<div className="others">
														<TextValidator
															fullWidth
															className="radio-others-input"
															onChange={handleChangeFormData}
															name="company_nature_others"
															value={formData.company_nature_others}
															validators={['maxStringLength:30', 'required']}
															errorMessages={['Max. 30 characters', t('Cannot leave it blank')]}
														/>
														<FormHelperText>{t('(Please specify* Max. 30 characters)')}</FormHelperText>
													</div>
												) : (
													<div className="others">
														<TextValidator
															fullWidth
															className="radio-others-input"
															onChange={handleChangeFormData}
															name="company_nature_others"
															value={formData.company_nature_others}
															validators={['maxStringLength:30']}
															errorMessages={['Max. 30 characters']}
														/>
														<FormHelperText>{t('(Please specify* Max. 30 characters)')}</FormHelperText>
													</div>
												)}
											</RadioGroupValidator>
											<div className={'descBottom'}>{t('venue_booking_form_specify_if_pr')}</div>
										</FormControl>
									</div>
								</div>
							</div>
						</section>
						<section>
							<div className="title">{t('B. Details of Proposed Marketing Campaign')}</div>
							<div className="fields">
								<div className="row boxed">
									<div className="field half">
										<TextValidator
											label={t('Official Name of Event') + '*'}
											onChange={handleChangeFormData}
											name="event_name"
											value={formData.event_name}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<FormControl fullWidth>
											<SelectValidator
												label={t('Location') + '*'}
												labelId="event-location-label"
												name="event_location"
												value={formData.event_location}
												onChange={handleChangeFormData}
												validators={['required']}
												errorMessages={[t('Cannot leave it blank')]}
											>
												{
													map(venueList, ({ location_name, id }, index) => (
														<MenuItem key={id} value={id}>{location_name}</MenuItem>
													))
												}
											</SelectValidator>
										</FormControl>
									</div>
									<div className="field half">
										<KeyboardDatePickerValidator
											disableToolbar
											onChange={(event) => {
												handleChangeFormDataDate(event, 'event_date')
											}}
											variant="inline"
											format="dd.MM.yyyy"
											minDate={addMonths(new Date(), 2)}
											label={t('Event date') + '*'}
											name="event_date"
											value={formData.event_date}
											helperText={t('All applications must be submitted at least 2 months prior to the event date.')}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											views={['year', 'month', 'date']}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
											autoOk={true}
											fullWidth
										/>
									</div>
									<div className="field half time">
										<FormControl fullWidth>
											<SelectValidator
												label={t('Starting time') + '*'}
												name="event_starttime"
												value={formData.event_starttime}
												onChange={handleChangeFormData}
												validators={['required']}
												errorMessages={[t('Cannot leave it blank')]}
											>
												{timeOptions.start.map((time) => (
													<MenuItem value={time} key={time}>
														{time}:00
													</MenuItem>
												))}
											</SelectValidator>
										</FormControl>
										<div className="dash">-</div>
										<FormControl fullWidth>
											<SelectValidator
												label={t('Ending time') + '*'}
												name="event_endtime"
												value={formData.event_endtime}
												onChange={handleChangeFormData}
												validators={['required']}
												errorMessages={[t('Cannot leave it blank')]}
											>
												{timeOptions.end.map((time) => (
													<MenuItem value={time} key={time}>
														{time}:00
													</MenuItem>
												))}
											</SelectValidator>
										</FormControl>
									</div>
								</div>

								<div className="row">
									<div className="field">
										<FormLabel>{t('Nature of Event') + '*'}</FormLabel>
										{/*<div className="label-boxed">{t('1st Level')}</div>*/}
										<RadioGroupValidator
											aria-label="nature-of-event-1"
											name="event_nature_1st"
											value={formData.event_nature_1st}
											onChange={handleChangeFormData}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
										>
											<FormControlLabel
												value="1"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Noncommercial')}
											/>
											<FormControlLabel
												value="2"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Charitable activities')}
											/>
											<FormControlLabel
												value="3"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Commercial activities')}
											/>
										</RadioGroupValidator>
										<br />
										<div className="label-boxed">{t('2nd Level')}</div>
										<RadioGroupValidator
											aria-label="nature-of-event-1"
											name="event_nature_2nd"
											value={formData.event_nature_2nd}
											onChange={handleChangeFormData}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
										>
											<FormControlLabel
												value="4"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Exhibition')}
											/>
											<FormControlLabel
												value="5"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Exhibition cum Ceremony')}
											/>
											<FormControlLabel value="6" control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />} label={t('Ceremony')} />
											<FormControlLabel
												value="7"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Ceremony cum Variety Show')}
											/>
											<FormControlLabel value="9" control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />} label={t('Carnival')} />
											<FormControlLabel
												value="10"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Game Stalls')}
											/>
											<FormControlLabel
												value="11"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Press Conference')}
											/>
											<FormControlLabel
												value="12"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Charity Sale')}
											/>
											<FormControlLabel
												value="13"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Stage Performance')}
											/>
											<FormControlLabel
												value="15"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Sale Exhibition')}
											/>
											<FormControlLabel
												value="16"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Car Show')}
											/>
											<div className="break"></div>
											<FormControlLabel
												className="radio-others"
												value="14"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Others') + ':'}
											/>

											{formData.event_nature_2nd === '14' ? (
												<div className="others">
													<TextValidator
														fullWidth
														onChange={handleChangeFormData}
														name="event_nature_others"
														value={formData.event_nature_others}
														validators={['maxStringLength:30', 'required']}
														errorMessages={['Max. 30 characters', t('Cannot leave it blank')]}
													/>
													<FormHelperText>{t('(Please specify* Max. 30 characters)')}</FormHelperText>
												</div>
											) : (
												<div className="others">
													<TextValidator
														fullWidth
														onChange={handleChangeFormData}
														name="event_nature_others"
														value={formData.event_nature_others}
														validators={['maxStringLength:30']}
														errorMessages={['Max. 30 characters']}
													/>
													<FormHelperText>{t('(Please specify* Max. 30 characters)')}</FormHelperText>
												</div>
											)}
										</RadioGroupValidator>
									</div>
								</div>

								<div className="row boxed">
									<div className="field">
										<FormLabel>{t('Event details')}*</FormLabel>
										<FormHelperText>{t('Please include n words description')}</FormHelperText>

										<TextareaValidator
											aria-label="minimum height"
											rowsMin={5}
											name="event_details"
											value={formData.event_details}
											onChange={handleChangeFormData}
											validators={['required', 'maxStringLength:500']}
											errorMessages={[t('Cannot leave it blank'), t('Max. n characters')]}
										/>
									</div>
									<div className="field">
										<FormLabel>{t('Please attach event proposal')}</FormLabel>
										<FormHelperText>{t('Max. 5 files, each file < 10MB')}</FormHelperText>
										<div class="dropzone-container">
											<DropzoneArea
												getPreviewIcon={handlePreviewIcon}
												showFileNames
												filesLimit={5}
												maxFileSize={5000000}
												onChange={handleUpdateFiles}
												fullWidth
												ref={DropZoneEl}
											/>
											<div class="dropzone-addbtn-container">
												<div class="dropzone-addbtn" onClick={handleAddFile}>
													+
												</div>
											</div>
										</div>
										<div className="descBottom">{t('venue_booking_form_choose_file')}</div>
									</div>
								</div>
							</div>
						</section>
						<section>
							<div className="title">{t('C. Declaration')}</div>
							<div className="fields">
								<div className="row">
									<div className="field">
										<CheckboxValidatorElement
											validators={['required']}
											errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
											name="declaration1"
											value={declarationData.declaration1}
											onClick={(event) => {
												handleChangeDeclaration(event)
											}}
										>
											{t('I confirm that the information herein is true and correct and agree to be bound by the ')}
											<Popup text={t('terms and conditions')} content={<PopupTAC />} />
											{t(' of the use of venues as laid down the Landlord.')}
										</CheckboxValidatorElement>
									</div>
								</div>
							</div>
						</section>
					</MuiPickersUtilsProvider>
					<div className="subbtn-container">{isLoading ? <Loader /> : <SubmitButton text={t('Submit')} />}</div>
				</ValidatorForm>
			</div>
		</div>
	)
}

export default VenueBookingRegister
