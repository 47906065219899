import React, { useState, useRef } from 'react'
import styles from './DetailSlider.module.scss'

import getVideoId from 'get-video-id'
import YouTube from 'react-youtube'

// Components
import NavArrow from '../NavArrow/NavArrow'

// Swiper
// import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

const DetailSlider = (props) => {
	let paginationPosition = props['pagination-position'] ?? 'left'

	let arrowNav = {
		prev: useRef(),
		next: useRef(),
	}

	const assignNav = (swiper, nav) => {
		setTimeout(() => {
			swiper.params.navigation = {
				...swiper.params.navigation,
				prevEl: nav.prev.current,
				nextEl: nav.next.current,
			}
			swiper.navigation.destroy()
			swiper.navigation.init()
			swiper.navigation.update()
			swiper.slideToLoop(0, 0)
		}, 0)
	}

	const [currentSlide, setCurrentSlide] = useState(0)
	const hasContent = (slide) => {
		/* if (slide.video && getVideoId(slide.video).id) {
			return true
		}

		if (slide.image && slide.image.substr(-1) !== '/') {
			return true
		} */

		return false
	}

	const SlideContent = (props) => {
		return props.data.video ? <YouTube className={styles.video} videoId={getVideoId(props.data.video).id} /> : <img src={props.data['image']} alt={props.data['image_alt_tag']} />
	}

	return (
		<div className={styles.slider} data-pagination-position={paginationPosition}>
			{props.data ? (
				hasContent(props.data[1]) ? (
					// Multiple slide
					<>
						<div className={styles.sliderContainer}>
							<Swiper
								className={`detail-slider`}
								slidesPerView={1}
								pagination={{
									el: '.swiper-pagination',
									modifierClass: 'cm-pagination-bullets cm-pagination-' + paginationPosition + '-',
									clickable: true,
									renderBullet: function (index, className) {
										let page = index + 1
										if (page < 10) {
											page = '0' + page
										}
										return `<span class="${className}"><div class="number">${page}</div></span>`
									},
								}}
								navigation={{ prevNav: '', nextEl: '' }}
								onInit={(swiper) => {
									assignNav(swiper, arrowNav)
									// setTimeout(() => {
									// 	console.log('arrowNav.prev.current', arrowNav.prev.current)
									// 	swiper.params.navigation = {
									// 		...swiper.params.navigation,
									// 		prevEl: arrowNav.prev.current,
									// 		nextEl: arrowNav.next.current,
									// 	}
									// 	swiper.navigation.update()
									// 	swiper.update(true)
									// 	assignNav(swiper, arrowNav)
									// }, 0)
								}}
								onSlideChange={(swiper) => {
									setCurrentSlide(swiper.activeIndex)
								}}
							>
								{props.data
									? props.data.map((slide, i) => {
											return hasContent(slide) ? (
												<SwiperSlide key={i} className={styles.slide}>
													<SlideContent data={slide} />
												</SwiperSlide>
											) : (
												''
											)
									  })
									: ''}
							</Swiper>
							{/* {paginationPosition === 'left' ? (
								<div className={styles.sliderNav}>
									<div class={`swiper-pagination ${styles.pagination}`}></div>
									<div className={styles.arrow}>
										<NavArrow ref={arrowNav} />
									</div>
								</div>
							) : (
								<div className={styles.sliderNav}>
									<div class={`swiper-pagination ${styles.pagination}`}></div>
									<div className={styles.arrow}>
										<NavArrow ref={arrowNav} />
									</div>
								</div>
							)} */}
							<div className={styles.sliderNav}>
								<div className={`swiper-pagination ${styles.pagination}`}></div>
								<div className={styles.arrow}>
									<NavArrow ref={arrowNav} />
								</div>
							</div>
						</div>
						<div className={styles.slideInfo}>
							{props.data
								? props.data.map((slide, i) => {
										return hasContent(slide) && currentSlide === i ? (
											<div key={i}>
												<div className={styles.title}>{slide.title}</div>
												<div className={styles.description}>{slide.description}</div>
											</div>
										) : (
											''
										)
								  })
								: ''}
						</div>
					</>
				) : (
					// Single slide
					<div className={styles.sliderContainer}>
						<div className={styles.slide}>
							<SlideContent data={props.data[0]} />
						</div>
						<div className={styles.slideInfo}>
							<div className={styles.title}>{props.data[0].title}</div>
							<div className={styles.description}>{props.data[0].description}</div>
						</div>
					</div>
				)
			) : (
				''
			)}
		</div>
	)
}

export default DetailSlider
