import { dayJs } from "../../../utils/";

export const getValidEventData = (data, current) => {
  let result = [];

  const date_today = dayJs();
  const DATE_FORMAT = "DD/MM/YYYY";
  const UNIT = "date";

  const { slug: cur_slug } = current;

  result = data.filter((el) => {
    const { end_date, slug: data_slug } = el;
    const date_end = dayJs(end_date, DATE_FORMAT, true);
    const valid = date_today.isSameOrBefore(date_end, UNIT);
    const curr_record = data_slug.toLowerCase() === cur_slug.toLowerCase();

    return valid && !curr_record;
  });

  return result;
};
