import { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import PageTitle from '../../components/PageTitle/PageTitle'
import getData from '../../utils/getData'
import styles from './VrTour.module.scss'

const VrTour = () => {
    const { t: tMenu } = useTranslation(['menu'])
    const { t } = useTranslation()
    const [src, setSrc] = useState()

    useEffect(() => {
        const fetchData = async() => {
            const data = await getData('vr_tour')
            if(data) {
                setSrc(data.link)
            }
        }
        fetchData()
    }, [])
    return (
        <div className="page-whatson page">
            <Helmet>
                <title> {tMenu("VR Tour")} - {t('Central Market')} </title>
            </Helmet>
            <PageTitle title={tMenu("VR Tour")} />
            <div className="container-boxed half-padding">
                <h3 className="text-center">{t('vr_tour_title')}</h3>
                <div className={styles.description}>
                    <p dangerouslySetInnerHTML={{ __html: t('vr_tour_description')}} />
                </div>
                <div className={styles.iframe}>
                    <iframe src={src} />
                </div>
            </div>
        </div>
    )
}

export default VrTour