// Core
import { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { Helmet } from 'react-helmet-async'
import parse from 'html-react-parser'
// import { Link } from 'react-router-dom'

// Styles
import styles from './ShopOfferDetail.module.scss'

// Components
// import Button from '../../components/Button/Button'
import ButtonBack from '../../components/ButtonBack/ButtonBack'
// import BannerCTA from '../../components/BannerCTA/BannerCTA'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import Card from '../../components/Card/Card'
// import Image from '../../components/Image/Image'
import DetailSlider from '../../components/DetailSlider/DetailSlider'
import Loader from '../../components/Loader/Lodaer'
import Popup from '../../components/Popup/Popup'
import PopupOfferTNC from '../../components/PopupContent/PopupOfferTNC'

//i18n
import { useTranslation } from 'react-i18next'

// Utils
import getData from '../../utils/getData'
import getPeriod from '../../utils/getPeriod'
import getPurePrice from '../../utils/getPurePrice'

// Lodash
import _ from 'lodash'

import $ from "jquery";

const ShopOfferDetail = () => {
	const popupRef = useRef();
	const { id } = useParams()
	const { t, i18n } = useTranslation()

	const [data, setData] = useState({
		all: [
		],
		current: {
		}
	})

	// Fetch data
	useEffect(() => {
		async function fetchData() {
			try {
				const data = await getData('shop_offer')
				const shopData = await getData('shop')

				data.forEach(shop_offer => {
					shop_offer['shop'] = shopData.find((shop) => {
						if(shop['id'] == shop_offer['shop_id']){
							return true
						}
					})
				});
				setData((prevState) => ({
					...prevState,
					all: data
				}))
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	// Filter data
	useEffect(() => {
		var current = data.all.find((el) => {
			return el && el['slug'] === id
		})
		if (current) {

			if(current['offer'] && current['offer'] instanceof Array && current['offer'].length > 0){
				// filter out non active offer
				var filteredOffer = current['offer'].filter((offer) =>
									new Date(offer['start_time'].replace(/-/g, "/")).getTime() < Date.now() &&
									new Date(offer['end_time'].replace(/-/g, "/")).getTime() > Date.now())

				current['offer'] = _.sortBy(filteredOffer, [
					function (o) {
						return o.offer_display_order
					},
				])
				if(current['offer'].length === 0){
					current = null
				}
			}else{
				current = null
			}
			setData((prevState) => ({
				...prevState,
				current: current
			}))
		}
	}, [data.all, id, i18n.language])


	useEffect(() => {
		// tnc link
		$(".shopOfferTnc").on("click",function(e){
			popupRef.current.handleToggle(e)
		})
		$('.shopOfferTnc').css('cursor', 'pointer');
	}, [data.current])

	function htmldecode(str) {
		var txt = document.createElement('textarea')
		txt.innerHTML = str
		return txt.value
	}

	return (
		<>
			<Helmet>
				<title>
					{data.current && data.current['shop'] ? data.current['shop']['title'] : ''} - {t('Central Market')}
				</title>
			</Helmet>
			<div className={styles.main}>
				<div className={styles.ButtonBack}>
					<ButtonBack url="/shop-offer" />
				</div>
				<section className={styles.mainContent}>
					<div className={styles.bg}>
						{/* <img src="/images-local/block-3-h.png" alt="" /> */}
						<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
						{/* <img src="/images-local/block-tb-bs.png" style={{ opacity: 0 }} alt="" /> */}
						{/* <img src="/images-local/block-bs-tb.png" alt="" /> */}
					</div>
					{data.current && data.current['shop'] && data.current['shop']['title'] ? (
						<div className={styles.content}>
							<div className={styles.detail}>
								<div className={styles.info}>
									<div className={styles.category}>{data.current['shop']['category']}</div>
									<div className={styles.title}>{data.current['shop']['title']}</div>
									<ul className={styles.details}>
										{data.current['shop']['office_hour'] ? (
											<li className={styles.time}>
												<SVG className={styles.fill} src="/images-local/ico-time.svg" alt="" />
												{data.current['shop']['office_hour']}
											</li>
										) : (
											''
										)}
										{data.current['shop']['shop_no'] ? (
											<li className={styles.location}>
												<SVG className={styles.fill} src="/images-local/ico-location.svg" alt="" />
												{data.current['shop']['shop_no']}
											</li>
										) : (
											''
										)}
										{data.current['shop']['telephone'] && data.current['shop']['telephone'].length == 8 ? (
											<li className={styles.phone}>
												<SVG className={styles.fill} src="/images-local/ico-phone.svg" alt="" />
												{data.current['shop']['telephone'].substr(0,4) + " " + data.current['shop']['telephone'].substr(4,8) }
											</li>
										) : (
											''
										)}

										{data.current['shop']['web_site_url'] ? (
											<li className={styles.link}>
												<SVG className={styles.fill} src="/images-local/ico-link.svg" alt="" />
												<a target="_blank" rel="noreferrer" href={data.current['shop']['web_site_url']}>
													{data.current['shop']['web_site_url']}
												</a>
											</li>
										) : (
											''
										)}
									</ul>
								</div>
								{data.current['shop_offer_image'] ? (
									<div className={styles.pic}>
										<img src={data.current['shop_offer_image']} alt="" />
									</div>
								) : (
									''
								)}
							</div>

							{/* Offer Detail */}
							<div className={styles.offer}>
								<div className={styles.title}>
									<TextBorderFill>{t('Offer')}</TextBorderFill>
								</div>

								{data.current && data.current['offer'].map((offer, i) => {
									return  <div className={styles.offerDetail}>
												<div className={styles.title}>{offer.category ? offer.title ? offer.category + ' - ' + offer.title : offer.category : ''}</div>
												<div className={styles.description}>{offer.description ? parse(htmldecode(offer.description)) : ''}</div>
											</div>
								})}
							</div>

							{/* Remark */}
							<div className={styles.remark}>
								{data.current && data.current['general_tnc'] ? (
									data.current['general_tnc']['remark'] ? parse(htmldecode(data.current['general_tnc']['remark'])) : ''
								) : "" }
								{data.current && data.current['general_tnc'] ? (
									<Popup ref={popupRef} text={''} content={<PopupOfferTNC title={data.current['general_tnc']['title']} description={data.current['general_tnc']['description']}/>} />
								) : "" }
							</div>
						</div>
					) : (
						<div className="my-20">
							<Loader />
						</div>
					)}
				</section>

			</div>
		</>
	)
}

export default ShopOfferDetail
