// Core
import { useState, useEffect, useRef } from 'react'

// Styles
import '../../styles/form.scss'
//import styles from './StagingGroundRegister.module.scss'

// Material UI
import { FormControl, FormLabel, FormHelperText, MenuItem } from '@material-ui/core'
import {  MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { addMonths } from 'date-fns'

import PageTitle from '../../components/PageTitle/PageTitle'
// Validator
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import TextareaValidator from '../../components/TextareaValidator/TextareaValidator'
import CheckboxValidatorElement from '../../components/CheckboxValidatorElement/CheckboxValidatorElement'
import KeyboardDatePickerValidator from '../../components/KeyboardDatePickerValidator/KeyboardDatePickerValidator'

// i18n
import { useTranslation } from 'react-i18next'

// Components
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import Popup from '../../components/Popup/Popup'
import PopupRAR from '../../components/PopupContent/staging_form_rules_and_regulations'
import PopupPI from '../../components/PopupContent/staging_form_personal_info'
import { format } from 'date-fns'
import $ from 'jquery'
import getSession from '../../utils/getSession'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader/Lodaer'
function ordinal(number, lang) {
	if (lang == 'en') {
		const english_ordinal_rules = new Intl.PluralRules('en', {
			type: 'ordinal',
		})
		const suffixes = {
			one: 'st',
			two: 'nd',
			few: 'rd',
			other: 'th',
		}
		const suffix = suffixes[english_ordinal_rules.select(number)]
		return number + suffix
	} else {
		const numof = '第'
		if (number == 1) {
			return numof + '一'
		} else if (number == 2) {
			return numof + '二'
		} else if (number == 3) {
			return numof + '三'
		} else if (number == 4) {
			return numof + '四'
		} else if (number == 5) {
			return numof + '五'
		}
	}
}

const StagingGroundRegister = () => {
	const { t } = useTranslation('')
	const [sampleData, setSampleData] = useState({
		chinese_name: '你好',
		english_name: '你好',
		gender: 'M',
		dob: '1995-01-07',
		application: 'Group',
		organization: 'happy group',
		phone: '',
		mobile: '12345678',
		fax: '12345678',
		email: 'jackylee336@yahoo.com',
		address: '2121212113asdsad',
		start_date_1st: '2021-06-23 12:00:00',
		end_date_1st: '2021-06-23 13:00:00',
		start_date_2nd: '2021-06-23 14:00:00',
		end_date_2nd: '2021-06-23 15:00:00',
		start_date_3rd: '2021-06-24 15:00:00',
		end_date_3rd: '2021-06-24 16:00:00',
		start_date_1st_temp: 7,
		end_date_1st_temp: 8,
		start_date_2nd_temp: 9,
		end_date_2nd_temp: 10,
		start_date_3rd_temp: 11,
		end_date_3rd_temp: 12,
		performance_name: 'perform name',
		performance_detail: 'perform detail',
		art_from: 'song 111',
		repertoire: 'song 222',
		/* equipment: 'Flute', */
		experience: '10 years',
		audition_clips_1: 'http://www.google.com',
		audition_clips_2: null,
		audition_clips_3: null,
		audition_clips_4: null,
		audition_clips_5: null,
		performer: [
			{
				chinese_name: '111',
				english_name: '2222',
				mobile: '12345678',
				dob: '1990-01-11',
			},
			{
				chinese_name: '111',
				english_name: '2222',
				mobile: '12345678',
				dob: '1990-01-11',
			},
			{
				chinese_name: '111',
				english_name: '2222',
				mobile: '12345678',
				dob: '1990-01-11',
			},
		],
	})
	const [formData, setFormData] = useState({
		chinese_name: '',
		english_name: '',
		gender: '',
		dob: null,

		application: '',
		organization: '',
		phone: '',
		mobile: '',
		fax: '',
		email: '',
		address: '',
		/* start_date_1st: '',
		end_date_1st: '',
		start_date_2nd: '',
		end_date_2nd: '',
		start_date_3rd: '',
		end_date_3rd: '',
		start_date_1st_temp: '',
		end_date_1st_temp: '',
		start_date_2nd_temp: '',
		end_date_2nd_temp: '',
		start_date_3rd_temp: '',
		end_date_3rd_temp: '', */
		performance_name: '',
		performance_detail: '',
		art_from: '',
		repertoire: '',
		/* equipment: '', */
		experience: '',
		audition_clips_1: '',
		audition_clips_2: '',
		audition_clips_3: '',
		audition_clips_4: '',
		audition_clips_5: '',
		performer: [{ chinese_name: '', english_name: '', mobile: '', dob: null }],
	})
	const [declarationData, setDeclarationData] = useState({
		declaration1: '',
		declaration2: '',
		declaration3: '',
		declaration4: '',
		declaration5: '',
		declaration6: '',
	})
	const [rule, setrule] = useState(['false'])
	const formRef = useRef(null)
	const [clips, setClips] = useState([1])
	const [maxClip, setMaxClip] = useState([5])

	const [performers, setPerformers] = useState([1])
	const [maxPerformer, setPerformer] = useState([5])
	const [selectedDate, handleDateChange] = useState(new Date())
	const [openDatepickerA, setOpenDatepickerA] = useState(false)
	const [openDatepickerB, setOpenDatepickerB] = useState(false)
	const [openDatepickerC, setOpenDatepickerC] = useState(false)
	const [openDatepickerD, setOpenDatepickerD] = useState(false)
	const [openDatepickerE, setOpenDatepickerE] = useState(false)
	const [openDatepickerF, setOpenDatepickerF] = useState(false)

	const showDateY = '2022'
	const showDateM = '10'
	const showDateD = '18'
	const closeDialog = () => {
		setOpenDatepickerA(false)
		setOpenDatepickerB(false)
		setOpenDatepickerC(false)
		setOpenDatepickerD(false)
		setOpenDatepickerE(false)
		setOpenDatepickerF(false)
	}
	let navigate = useNavigate()
	const chackDatePicker = (id) => {
		if (id == 1) {
			return openDatepickerB
		}
		if (id == 2) {
			return openDatepickerC
		}
		if (id == 3) {
			return openDatepickerD
		}
		if (id == 4) {
			return openDatepickerE
		}
		if (id == 5) {
			return openDatepickerF
		}
	}
	const setDatePickerTrue = (id) => {
		if (id == 1) {
			setOpenDatepickerB(true)
		}
		if (id == 2) {
			setOpenDatepickerC(true)
		}
		if (id == 3) {
			setOpenDatepickerD(true)
		}
		if (id == 4) {
			setOpenDatepickerE(true)
		}
		if (id == 5) {
			setOpenDatepickerF(true)
		}
	}
	const handleSampleData = () => {
		console.log(sampleData)

		setFormData((prevState) => ({
			...prevState,
			...sampleData,
		}))
	}
	const handleCloneHtmls = (type) => {
		switch (type) {
			case 'clips':
				if (clips.length < maxClip) {
					setClips((currClips) => [...currClips, currClips.length + 1])
				}

				break
			case 'performers':
				if (performers.length < maxPerformer) {
					formData.performer.push({
						chinese_name: '',
						english_name: '',
						mobile: '',
						dob: null,
					})
					setFormData((prevState) => ({
						...prevState,
						['performer']: formData['performer'],
					}))

					setPerformers((currPerformers) => [...currPerformers, currPerformers.length + 1])
				}
				break
			default:
				break
		}
	}

	const handleDelHtmls = (type) => {
		switch (type) {
			case 'clips':
				if (clips.length > 1) {
					let temp = clips
					temp.pop()
					setClips(() => [...temp])
				}

				break
			case 'performers':
				if (performers.length > 1) {
					let temp = performers
					temp.pop()
					setClips(() => [...temp])
				}
				formData.performer.pop()
				setFormData((prevState) => ({
					...prevState,
					['performer']: formData['performer'],
				}))

				break
			default:
				break
		}
	}

	const openingHour = {
		open: 11,
		close: 18,
	}

	/* const [timeOptions1, setTimeOptions1] = useState({
		start: [],
		end: [],
	})
	const [timeOptions2, setTimeOptions2] = useState({
		start: [],
		end: [],
	})
	const [timeOptions3, setTimeOptions3] = useState({
		start: [],
		end: [],
	}) */
	const [isLoading, setIsLoading] = useState(false)
	/* useEffect(() => {
		setTimeOptions1((prevState) => ({
			...prevState,
			start: getTimeList(openingHour.open, openingHour.close - 1),
			end: getTimeList(openingHour.open + 1, openingHour.close),
		}))
		setTimeOptions2((prevState) => ({
			...prevState,
			start: getTimeList(openingHour.open, openingHour.close - 1),
			end: getTimeList(openingHour.open + 1, openingHour.close),
		}))
		setTimeOptions3((prevState) => ({
			...prevState,
			start: getTimeList(openingHour.open, openingHour.close - 1),
			end: getTimeList(openingHour.open + 1, openingHour.close),
		}))
	}, [])

	const getTimeList = (start, end) => {
		let list = []
		for (let i = start; i <= end; i++) {
			list.push(i)
		}
		return list
	} */
	useEffect(() => {
		$(document).on('click', '.MuiPickersCalendarHeader-transitionContainer', function (e) {
			$('.MuiPickersToolbarButton-toolbarBtn').eq(0).click()
		})
	}, [])
	const handleChangeFormData = (event) => {
		// Auto http://
		if (event && event.target && event.target.name.includes('audition_clips_')) {
			/*if (!event.target.value.includes('://')) {
				event.target.value = 'http://' + event.target.value
			}*/
			if (event.target.value.length > 6) {
				if (event.target.value.substring(0, 7) != 'http://' && event.target.value.substring(0, 8) != 'https://') {
					event.target.value = 'http://' + event.target.value
				}
			}
		}

		const name = event.target.name
		const value = event.target.value
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}))

		/* if (name === 'start_date_1st_temp') {
			setTimeOptions1((prevState) => ({
				...prevState,
				end: getTimeList(value + 1, openingHour.close),
			}))
		}
		if (name === 'start_date_2nd_temp') {
			setTimeOptions2((prevState) => ({
				...prevState,
				end: getTimeList(value + 1, openingHour.close),
			}))
		}
		if (name === 'start_date_3rd_temp') {
			setTimeOptions3((prevState) => ({
				...prevState,
				end: getTimeList(value + 1, openingHour.close),
			}))
		} */
	}

	const handleChangeFormArrayData = (event, id, arrayTitle) => {
		const name = event.target.name
		const value = event.target.value
		const index = id - 1
		formData[arrayTitle][index][name] = value
		setFormData((prevState) => ({
			...prevState,
			[arrayTitle]: formData[arrayTitle],
		}))

		// setFormData((prevState) => ({
		//   ...prevState,
		//   [name]: value,
		// }));
	}
	const handleChangeFormArrayDataDate = (date, key, id, arrayTitle) => {
		const index = id - 1
		// formData[arrayTitle][index][key] = format(date, "yyyy-MM-dd");
		formData[arrayTitle][index][key] = date
		setFormData((prevState) => ({
			...prevState,
			[arrayTitle]: formData[arrayTitle],
		}))
	}
	const handleChangeFormDataDate = (date, key) => {
		setFormData((prevState) => ({
			...prevState,
			[key]: date,
			// [key]: format(date, "yyyy-MM-dd"),
		}))
	}

	const handleChangeDeclaration = (event) => {
		const name = event.target.name
		if (declarationData[name] == 'checked') {
			setDeclarationData((prevState) => ({
				...prevState,
				[name]: '',
			}))
		} else {
			setDeclarationData((prevState) => ({
				...prevState,
				[name]: 'checked',
			}))
		}
	}

	const handleSubmit = (event) => {
		setIsLoading(true)
		event.preventDefault()
		let submitFormData = formData
		Object.keys(formData).map((key) => {
			if (key.includes('_temp') && key.includes('_date_')) {
				let subkey = key.replace('_temp', '')
				let value = `${showDateY}-${showDateM}-${showDateD} ${formData[key]}:00:00`
				submitFormData[subkey] = value
			} else if (key == 'dob' && typeof formData[key] != 'string') {
				let value = format(formData[key], 'yyyy-MM-dd')
				submitFormData[key] = value
			} else if (key == 'performer') {
				formData[key].map((item, i) => {
					if (formData[key][i]['dob'] && typeof formData[key][i]['dob'] != 'string') {
						let value = format(formData[key][i]['dob'], 'yyyy-MM-dd')
						submitFormData[key][i]['dob'] = value
					}
				})
			}
		})

		submitFormData.performer = submitFormData.performer.slice(0, performers.length)

		// console.log(JSON.stringify(formData));
		$.post(
			// "https://webhook.site/a5dd2ff7-13e4-45fa-8a5d-7cf7de530fcb",
			// "http://dev.centralmarket.cpt.com/api/StagingGround/StagingGroundSubmit",
			'/api/StagingGround/StagingGroundSubmit',
			// 'https://webhook.site/b6c2f0fc-bc77-4d32-9cde-6d330ae287f1',

			submitFormData,
			function (response) {
				if (response.status_code == 200) {
					navigate('/thankyou')
				}
			}
		)

		// axios
		//   .post(
		//     // "http://dev.centralmarket.cpt.com/api/StagingGround/StagingGroundSubmit",
		//     // "https://webhook.site/a5dd2ff7-13e4-45fa-8a5d-7cf7de530fcb",
		//     "/api/StagingGround/StagingGroundSubmit",
		//     submitFormData
		//   )
		//   .then(function (response) {
		//     console.log("response", response);
		//   });
	}

	const addfunction = (event) => {
		var curid = event.target.attributes.getNamedItem('cur').value
		var affect = event.target.attributes.getNamedItem('affect').value
		var targetDiv = document.getElementById(affect + '-' + curid)
	}

	const handleScroll = () => {
		closeDialog()
		document.activeElement.blur()
	}

	window.addEventListener('scroll', handleScroll)

	return (
		<div className={`${isLoading ? 'loading' : ''}` + ' main cm-form page-StagingGroundRegister'}>
			<PageTitle title={t('Application form for the Central Market Staging Ground @Central')} left/>
			<div className="container-boxed half-padding">
				{window.location.href.includes('www.centralmarket.hk') || window.location.href.includes('www-staging.centralmarket.hk') ? (
					''
				) : getSession('devtool') === 'on' ? (
					<div className="w-full border border-primary border-dashed p-10">
						<div className="text-primary mb-2">Dev tool:</div>
						<a className="inline-block border border-primary text-primary transition-all duration-400 py-2 px-5 cursor-pointer hover:bg-primary hover:text-white" onClick={handleSampleData}>
							Autofill
						</a>
					</div>
				) : (
					''
				)}
				<ValidatorForm
					ref={formRef}
					onSubmit={handleSubmit}
					onError={() =>
						$([document.documentElement, document.body]).animate(
							{
								scrollTop: $('.Mui-error').eq(0).parent().offset().top - $('[class*=Header_nav_]').eq(0).height(),
							},
							1000
						)
					}
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<section>
							<div className="title">{t('A. Particulars of the Applicant')}</div>
							<div className="fields">
								<div className="row boxed">
									<div className="field half">
										<TextValidator label={t('Name in Chinese') + ' * ' + t('(Same as HKID)')} onChange={handleChangeFormData} name="chinese_name" value={formData.chinese_name} validators={['required']} errorMessages={[t('Required Field')]} fullWidth />
									</div>
									<div className="field half">
										<TextValidator label={t('Name in English') + ' * ' + t('(Same as HKID)')} onChange={handleChangeFormData} name="english_name" value={formData.english_name} validators={['required']} errorMessages={[t('Required Field')]} fullWidth />
									</div>
									<div className="field half">
										<SelectValidator label={t('Gender') + '*'} name="gender" value={formData.gender} onChange={handleChangeFormData} validators={['required']} errorMessages={[t('Required Field')]}>
											<MenuItem value="M">{t('Male')}</MenuItem>
											<MenuItem value="F">{t('Female')}</MenuItem>
										</SelectValidator>
									</div>
									<div className="field half">
										<FormControl fullWidth>
											<KeyboardDatePickerValidator
												onChange={(event) => {
													handleChangeFormDataDate(event, 'dob')
												}}
												variant="inline"
												format="dd/MM/yyyy"
												maxDate={addMonths(new Date(), -216)}
												label={t('Date of birth') + '*'}
												name="dob"
												value={formData.dob}
												helperText={t('date of birth Must be aged 18 or above')}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
												views={['year', 'month', 'date']}
												validators={['required']}
												errorMessages={[t('Required Field')]}
												fullWidth
												onClose={closeDialog}
												open={openDatepickerA}
												onOpen={() => setOpenDatepickerA(true)}
												autoOk={true}
											/>
										</FormControl>
									</div>
									<div className="field half">
										<SelectValidator label={t('Applicant unit') + '*'} name="application" value={formData.application} onChange={handleChangeFormData} validators={['required']} errorMessages={[t('Required Field')]}>
											<MenuItem value=""></MenuItem>
											<MenuItem value="Group">{t('Group')}</MenuItem>
											<MenuItem value="Personal">{t('Personal')}</MenuItem>
										</SelectValidator>
									</div>
									<div className="field half">
										<TextValidator label={t('Organization')} onChange={handleChangeFormData} name="organization" value={formData.organization} fullWidth />
									</div>
									<div className="field half">
										<TextValidator label={t('Mobile') + '*'} onChange={handleChangeFormData} name="mobile" value={formData.mobile} minLength="8" validators={['required', 'isNumber', 'minNumber:10000000', 'maxNumber:99999999']} errorMessages={[t('Required Field'), t('Invalid information'), t('Invalid information'), t('Invalid information')]} fullWidth />
									</div>
									<div className="field half">
										<TextValidator label={t('Email') + '*'} onChange={handleChangeFormData} name="email" value={formData.email} validators={['required', 'isEmail']} errorMessages={[t('Required Field'), t('Invalid information')]} fullWidth />
									</div>
									<div className="field half">
										<TextValidator label={t('Address') + '*'} onChange={handleChangeFormData} name="address" value={formData.address} validators={['required']} errorMessages={[t('Required Field')]} fullWidth />
									</div>
									<div className="field half">
										<TextValidator label={t('Fax')} onChange={handleChangeFormData} name="fax" value={formData.fax} validators={['isNumber', 'minNumber:10000000', 'maxNumber:99999999']} errorMessages={[t('Invalid information'), t('Invalid information'), t('Invalid information')]} fullWidth />
									</div>
								</div>
								<div className="row">
									<ul className="remarksList">
										<li>{t('The applicant should be a Hong Kong resident aged 18 or above, and holder of a valid Hong Kong permanent identity card.')} </li>
										<li>{t('The applicant shall be personally involved in the performance and be responsible for all matters relating to the performance. If the applicant is not one of the performers, he/she should be a key member of the production team and be present on site during the entire performance to coordinate matters relating to the performance and performers.')}</li>
									</ul>
								</div>
							</div>
						</section>

						<section>
							<div className="title">{t('B. Proposed performance')}</div>
							{/* <div className="mb-5">
								<div className="formlabel">{t('Performance date')}</div>
								<div className="showDate">
									{showDateD} / {showDateM} / {showDateY}
								</div>
								<div className="formRemark">
									{t('All applications must be submitted on or before')}
									<span className="text-primary">&nbsp;26/09/2022</span>
									{t('After on or before(tc & sc)')}
								</div>
							</div>
							<div className={`mb-5`}>
								<FormLabel>{t('Proposed Performance Timeslot') + '*'}</FormLabel>
							</div>
							<div className="hideLabelOnFill">
								<div className="fields">
									<div className="formlabel">{t('Option 1') + '*'}</div>
									<div className="row boxed">
										<div className="field half time">
											<SelectValidator label={t('Starting time') + '*'} name="start_date_1st_temp" value={formData.start_date_1st_temp} onChange={handleChangeFormData} validators={['required']} errorMessages={[t('Required Field')]}>
												{timeOptions1.start.map((time) => (
													<MenuItem value={time} key={time}>
														{time}:00
													</MenuItem>
												))}
											</SelectValidator>

											<div className="dash">-</div>
											<SelectValidator label={t('Ending time') + '*'} name="end_date_1st_temp" value={formData.end_date_1st_temp} onChange={handleChangeFormData} validators={['required']} errorMessages={[t('Required Field')]}>
												{timeOptions1.end.map((time) => (
													<MenuItem value={time} key={time}>
														{time}:00
													</MenuItem>
												))}
											</SelectValidator>
										</div>
									</div>
									<div className="formlabel">{t('Option 2') + '*'}</div>
									<div className="row boxed">
										<div className="field half time">
											<SelectValidator label={t('Starting time') + '*'} name="start_date_2nd_temp" value={formData.start_date_2nd_temp} onChange={handleChangeFormData} validators={['required']} errorMessages={[t('Required Field')]}>
												{timeOptions2.start.map((time) => (
													<MenuItem value={time} key={time}>
														{time}:00
													</MenuItem>
												))}
											</SelectValidator>

											<div className="dash">-</div>
											<SelectValidator label={t('Ending time') + '*'} name="end_date_2nd_temp" value={formData.end_date_2nd_temp} onChange={handleChangeFormData} validators={['required']} errorMessages={[t('Required Field')]}>
												{timeOptions2.end.map((time) => (
													<MenuItem value={time} key={time}>
														{time}:00
													</MenuItem>
												))}
											</SelectValidator>
										</div>
									</div>
									<div className="formlabel">{t('Option 3') + '*'}</div>
									<div className="row boxed">
										<div className="field half time">
											<SelectValidator label={t('Starting time') + '*'} name="start_date_3rd_temp" value={formData.start_date_3rd_temp} onChange={handleChangeFormData} validators={['required']} errorMessages={[t('Required Field')]}>
												{timeOptions3.start.map((time) => (
													<MenuItem value={time} key={time}>
														{time}:00
													</MenuItem>
												))}
											</SelectValidator>

											<div className="dash">-</div>
											<SelectValidator label={t('Ending time') + '*'} name="end_date_3rd_temp" value={formData.end_date_3rd_temp} onChange={handleChangeFormData} validators={['required']} errorMessages={[t('Required Field')]}>
												{timeOptions3.end.map((time) => (
													<MenuItem value={time} key={time}>
														{time}:00
													</MenuItem>
												))}
											</SelectValidator>
										</div>
									</div>
								</div>
							</div> */}
							<div className="fields">
								<div className="row boxed">
									<div className="field half">
										<TextValidator label={t('Name of the performance')} onChange={handleChangeFormData} name="performance_name" value={formData.performance_name} fullWidth />
									</div>
									<div className="field half">
										<TextValidator label={t('Art Form') + '*'} onChange={handleChangeFormData} name="art_from" value={formData.art_from} fullWidth validators={['required']} errorMessages={[t('Required Field')]} />
									</div>
									<div className="field textarea-field">
										<FormLabel>{t('Details of the Performance')}*</FormLabel>
										<FormHelperText>{t('Please include n words description')}</FormHelperText>
										<TextareaValidator aria-label="minimum height" rowsMin={5} name="performance_detail" value={formData.performance_detail} onChange={handleChangeFormData} validators={['required', 'maxStringLength:500']} errorMessages={[t('Cannot leave it blank'), t('Max. n characters')]} />
									</div>
									<div className="field half">
										<TextValidator label={t('Repertoire') + '*'} onChange={handleChangeFormData} name="repertoire" value={formData.repertoire} validators={['required']} errorMessages={[t('Required Field')]} fullWidth />
									</div>
									{/* <div className="field textarea-field">
										<FormLabel>{t('Performance Equipment and likely Electricity Consumption')}*</FormLabel>
										<FormHelperText>{t('Please include n words description')}</FormHelperText>
										<TextareaValidator aria-label="minimum height" rowsMin={5} onChange={handleChangeFormData} name="equipment" value={formData.equipment} validators={['required', 'maxStringLength:500']} errorMessages={[t('Cannot leave it blank'), t('Max. n characters')]} />
									</div> */}
									<div className="field textarea-field">
										<FormLabel>{t('Past Performance Experience')}*</FormLabel>
										<FormHelperText>{t('Please include n words description')}</FormHelperText>
										<TextareaValidator aria-label="minimum height" rowsMin={5} onChange={handleChangeFormData} name="experience" value={formData.experience} validators={['required', 'maxStringLength:500']} errorMessages={[t('Cannot leave it blank'), t('Max. n characters')]} />
									</div>
									<div className="field half">
										<FormLabel>{t('Audition Clips') + '*'}</FormLabel>
										<FormHelperText>{t('video link/ social account')}</FormHelperText>

										{clips.map((clip) => {
											return (
												<div className="clips">
													<div className="field">
														<TextValidator label={t('URL ') + clip} onChange={handleChangeFormData} name={'audition_clips_' + clip} value={formData['audition_clips_' + clip]} id={clip} fullWidth placeholder="http://" validators={['required', 'matchRegexp:[a-zA-z]+://[^\\s]*']} errorMessages={[t('Required Field'), t('Invalid information')]} />
													</div>
												</div>
											)
										})}
										{clips.length < maxClip ? (
											<div className="add-btn" onClick={() => handleCloneHtmls('clips')}>
												+
											</div>
										) : null}
										{clips.length > 1 ? (
											<div className="mi-btn" onClick={() => handleDelHtmls('clips')}>
												-
											</div>
										) : null}
									</div>
									<div className="row">
										<ul className="remarksList">
											<li>{t('Performers need to bring their own equipment and secure any wires on the ground to avoid obstructing pedestrians. ')} </li>
											<li>{t('Performers and the content of their performances shall be similar to what has been auditioned. Unauditioned performers shall not take part in the performance.')}</li>
										</ul>
									</div>
								</div>
							</div>
						</section>
						<section>
							<div className="title">{t('C. Particulars of the Performers')}</div>
							<div className="fields">
								<div className="row boxed formMember">
									{performers.map((performer) => {
										return (
											<>
												<div className="title">{ordinal(performer, t('site language')) + t(' Member ')}</div>
												<div className="field half">
													<TextValidator label={t('Name in Chinese') + ' * ' + t('(Same as HKID)')} onChange={(e) => handleChangeFormArrayData(e, performer, 'performer')} name={'chinese_name'} value={formData['performer'][performer - 1]['chinese_name']} validators={['required']} errorMessages={[t('Required Field')]} fullWidth />
												</div>
												<div className="field half">
													<TextValidator label={t('Name in English') + ' * ' + t('(Same as HKID)')} onChange={(e) => handleChangeFormArrayData(e, performer, 'performer')} name="english_name" value={formData['performer'][performer - 1]['english_name']} validators={['required']} errorMessages={[t('Required Field')]} fullWidth />
												</div>
												<div className="field half">
													<TextValidator label={t('Mobile') + '*'} onChange={(e) => handleChangeFormArrayData(e, performer, 'performer')} name="mobile" value={formData['performer'][performer - 1]['mobile']} validators={['required', 'isNumber', 'minNumber:10000000', 'maxNumber:99999999']} errorMessages={[t('Required Field'), t('Invalid information'), t('Invalid information'), t('Invalid information')]} fullWidth />
												</div>
												<div className="field half">
													<FormControl fullWidth>
														<KeyboardDatePickerValidator
															onChange={(event) => {
																handleChangeFormArrayDataDate(event, 'dob', performer, 'performer')
															}}
															variant="inline"
															format="dd/MM/yyyy"
															label={t('Date of birth') + '*'}
															name={`dob`}
															value={formData['performer'][performer - 1]['dob']}
															KeyboardButtonProps={{
																'aria-label': 'change date',
															}}
															views={['year', 'month', 'date']}
															validators={['required']}
															errorMessages={[t('Required Field')]}
															fullWidth
															onClose={closeDialog}
															open={chackDatePicker(performer)}
															onOpen={() => setDatePickerTrue(performer)}
															autoOk={true}
														/>
													</FormControl>
												</div>
											</>
										)
									})}
									{performers.length < maxPerformer ? (
										<div className="add-btn" onClick={() => handleCloneHtmls('performers')}>
											+
										</div>
									) : null}
									{performers.length > 1 ? (
										<div className="mi-btn" onClick={() => handleDelHtmls('performers')}>
											-
										</div>
									) : null}
								</div>
								<div className="row mt-10">
									<ul className="remarksList">
										<li>{t('The total number of performers should not exceed 10. Performers are not restricted in age, but persons aged under 18 must be accompanied by an adult and must hold a valid Hong Kong permanent resident identity document or a valid travel document that proves that they can be legally employed in Hong Kong.')} </li>
									</ul>
								</div>
							</div>
						</section>
						<section>
							<div className="title">{t('D. Declaration')}</div>
							<div className="fields">
								<div className="row">
									<div className="field">
										<CheckboxValidatorElement
											validators={['required']}
											errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
											name="declaration1"
											value={declarationData.declaration1}
											onClick={(event) => {
												handleChangeDeclaration(event)
											}}
										>
											{t('I have read in detail, fully understood, and agree to the content of this application form, the attached ')}
											<div className="linkText">
												<Popup text={t('rules and regulations for the use of the venue')} content={<PopupRAR />} />
											</div>
											{t(', and ')}
											<div className="linkText">
												<Popup text={t('Personal Information Collection Statement')} content={<PopupPI />} />
											</div>
											.
										</CheckboxValidatorElement>
										<CheckboxValidatorElement
											validators={['required']}
											errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
											name="declaration2"
											value={declarationData.declaration2}
											onClick={(event) => {
												handleChangeDeclaration(event)
											}}
										>
											{t('I hereby declare and undertake that all information provided in this application form and its attachments is true and correct.')}
										</CheckboxValidatorElement>

										<CheckboxValidatorElement
											validators={['required']}
											errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
											name="declaration3"
											value={declarationData.declaration3}
											onClick={(event) => {
												handleChangeDeclaration(event)
											}}
										>
											{t('I understand that Central Market will or may record the Staging Ground @ Central by audio and/or video recording, and the performances of myself and the applied organisation will also be included.')}
										</CheckboxValidatorElement>

										<CheckboxValidatorElement
											validators={['required']}
											errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
											name="declaration4"
											value={declarationData.declaration4}
											onClick={(event) => {
												handleChangeDeclaration(event)
											}}
										>
											{t('I agree that after submitting this form, Central Market has the right to record the performances of myself and the applying organisation and use my name, profile, voice and likeness in any related materials they plan in any way.')}
										</CheckboxValidatorElement>

										<CheckboxValidatorElement
											validators={['required']}
											errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
											name="declaration5"
											value={declarationData.declaration5}
											onClick={(event) => {
												handleChangeDeclaration(event)
											}}
										>
											{t('I understand and agree that any intellectual property rights (including copyright) in these records will be owned by Central Market, and Central Market can use these records permanently in any format and in way around the world, and I and the applicant organisation can use this to transfer all the performers’ economic rights (subject to the definition of the Copyright Ordinance) owned by me and the applying organisation in or in relation to such records shall be granted to Central Market.')}
										</CheckboxValidatorElement>
									</div>
								</div>
							</div>
						</section>
					</MuiPickersUtilsProvider>
					<div className="subbtn-container">{isLoading ? <Loader /> : <SubmitButton text={t('Submit')} />}</div>
				</ValidatorForm>
			</div>
		</div>
	)
}

export default StagingGroundRegister
