import i18n from 'i18next'
import changeLanguage from '../../utils/changeLanguage'

const LanguageSwitcher = (props) => {
	const languages = [
		{
			slug: 'en',
			name: 'EN',
		},
		{
			slug: 'tc',
			name: '繁',
		},
		{
			slug: 'sc',
			name: '简',
		},
	]

	const handleChange = (lang) => {
		changeLanguage(lang, true)
	}

	return (
		<ul>
			{languages.map((lang) => {
				return (
					lang.slug !== i18n.language && (
						<li key={lang.slug}>
							<a onClick={() => handleChange(lang.slug)}>{lang.name}</a>
						</li>
					)
				)
			})}
		</ul>
	)
}

export default LanguageSwitcher
