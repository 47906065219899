// Core
import { useState, useRef, useEffect } from "react";

// Styles
//import styles from './PopupContent.module.scss'
import "../../styles/popup.scss";

// GSAP
import { TimelineLite } from "gsap/all";
import { useTranslation } from "react-i18next";

// Axios
import axios from '../../config/axios'


const PopupContent = ({ isFromTop, isTop, isFullWidth }) => {
  const { t, i18n} = useTranslation("");
  const [isOpen, setIsOpen] = useState(false);

  let PopupContentEl = null;
  let shadowEl = null;
  let containerEl = null;
  const [tl] = useState(new TimelineLite({ paused: true }));

  const [venueHireTNCHtmlStr, setVenueHireTNCHtmlStr] = useState();

  useEffect(() => {
    tl.from(PopupContentEl, 0.1, { autoAlpha: 0 })
      .from(shadowEl, 0.5, { autoAlpha: 0 })
      .from(
        containerEl,
        0.5,
        {
          autoAlpha: 0,
          y: isFromTop ? -50 : 50,
        },
        "-=.3"
      );
  }, []);

  
  useEffect(() => {
		axios.get(i18n.language + '/venue-hire-tnc.html').then((result) => {
			const resHtml = result.data
      
			setVenueHireTNCHtmlStr(resHtml)
		})
	}, [i18n.language])


  const handleToggle = (e) => {
    e.preventDefault();
    if (isOpen) {
      document.body.classList.remove("overflow-y-hidden");
      tl.reverse();
    } else {
      document.body.classList.add("overflow-y-hidden");
      tl.play();
    }
    setIsOpen(!isOpen);
  };

  return (
    <>

      {/* Read from HTML string */}
      <div dangerouslySetInnerHTML={{ __html: venueHireTNCHtmlStr }}></div>


      
      {/*<div className="title">{t("Terms & Condition")}</div>
      <ul className="numlist">
        <li>
          <span>{t("Event Time: 10:00 – 22:00")}</span>
        </li>
        <li>
          <span>
            {t(
              "Applicants shall take at their own cost to obtain all necessary consents, permits and approvals from the Government, copyright licensing bodies and/or any other relevant regulatory bodies for the proposed event, include but not limit to “Temporary Places of Public Entertainment Licence” from FEHD and the related licences from “Composers and Authors Society of Hong Kong Ltd”. We are not responsible for any losses. "
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The applicants are required to procure related insurance for the event, including but not limited to Public Liability Insurance with insured amount of HK$20,000,000 or above, covering certain specific insured companies, with cross liability clause in the joint names of the Landlord, Building Management Company, the Applicants and Applicant’s  contractors as insured should be extended to cover Landlord’s properties and third party liability. The insurance should include below name(s) of insured:"
            )}
          </span>
          <table>
            <tr>
              <td>{t("Name of Landlord")}</td>
              <td>{t("Building Mangement Company")}</td>
            </tr>
            <tr>
              <td></td>
              <td rowspan="4"></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </table>
        </li>
        <li>
          <span>
            {t(
              "It is compulsory for applicants to provide the copy of Public Liability Insurance and related licenses to our Leasing Dept - Marketing team by 3 working days prior to the event date."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Applicants shall not carry out any illegal or immoral activities at, in, on or upon the venue nor sell or promote any illegal products or products not specified in the approved application. We shall have the absolute right to remove from the venue any products, which are considered by us at its absolute discretion, unfit for the venue and which the applicants have failed to remove upon demand by us. Applicants shall indemnify us for all loss or damages arising from all and any activities or display of applicants."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The event proposal and event floor plan are requested to submit for the application. Central Market reserves the discretionary right to handover the event venues especially if the event set up is different to the submitted and approved one. "
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Applicants have to employ their own day shift / night shift security guards to watch over the products exhibited or decorations. Central Market will not be held liable for any damage or lost of property and belonging from the event."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Payment is non-refundable should there be any cancellation, delay, failure on event venue set up or inclement weather (Tropical Cyclone Warning Signal No.8 or above or Black Rainstorm Warning Signal)."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The above charges, terms and condition are subject to change without prior notice.  We reserve the discretionary right for final decisions."
            )}
          </span>
        </li>
      </ul>
      <div className="space"></div>
      <div className="title">{t("House Rules")}</div>
      <div className="sm-title">{t("Event Venue Setting & Dismantlement")}</div>
      <ul className="numlist">
        <li>
          <span>{t("Event Time: 10:00 – 22:00")}</span>
        </li>
        <li>
          <span>
            {t(
              "Applicants can start setting up their booths / venue deco from 10:00pm on the day before the start of their rental period (set-up time to be finalized by the Landlord). Set-up with clearance work must be completed by 9:00am on the day of start of their rental period."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Each worker have to be registered at our control room located at                (TBC)                 before starting their work on the installation date and wear the Working Permit during the installation and dismantle time."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "On has to charge a deposit HK$50 for each Working Permit. Upon completion of work every day, Working Permit shall be returned to control room and the deposit shall then be refunded."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Any worker is not allowed to carry out any works without wearing any Working Permit in the all/any part of the Central Market. "
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "If the Working Permit fails to be returned/is lost/damaged on the same day upon work completion, the deposit HK$50 of working permit would be forfeited by Central Market as an administration fee."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              'Prior to setting up the area, applicants must complete procedures for the handover of the area with staff from the On-site Management. A copy of the "Venue Handover and Takeover Record" must be signed on the spot before set-up is allowed to commence.'
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Event venue must be fully laid with carpet and all wires should be laid under the carpet with proper signage to avoid posing dangers to customers. 3M non-sticky removable adhesive tape is only allowed to be applied on the floor."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "All event materials should be transported to and from the event venue by carts or trolleys on nylon or rubber wheels via the designated loading bay, cargo lift, passage and route (use of pallet truck, mesh truck and truck with iron wheel are prohibited inside the mall). Passenger lifts and escalators should not be used for transportation of materials."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "All the set-up must be confined within the designated and authorized event space. All tools, materials are not allowed to be kept at any areas including the common area and rear corridor of the shopping mall, otherwise, On-site Management will have them removed without prior notification and all the cost incurred will be borne by the event Applicant."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Subject to the landlord’s approval, no sales activities are allowed at any area of Central Market."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Unauthorized flyers, advertisements and stickers etc. are not allowed to be displayed or stuck at any part of event venue. Display of handwritten and computer-printed sales and promotional materials are not allowed."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Subject to the landlord’s approval, no eating or drinking is allowed within the event venue."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Applicant shall keep the event venue in clean and tidy conditions at all times. Unauthorized stuff such as carton box is prohibited within the event venue. If any irregularities are found, On-site Management will employ the cleaning company to remove all debris and conduct cleaning, and all the cost incurred will be borne by the event Applicant."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "If the set-up does not meet the requirements up to the satisfaction of the authorised officer of On-Site Management in terms of layout, outlook and safety standards, applicants have to make changes on the spot as per instructions from the On-site Management. If no improvements are made, then we shall consider terminating the applicant’s right to hold / participate the event."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Applicants are required to complete the dismantling and return the event venue without any damaged on the night of the last day of the exhibition period. After completed the dismantling, applicants have to inform the authorised officer of On-Site Management to take over the event venue and both parties must sign on the spot the “Venue Handover and Takeover Record”, so that the handover process can be completed. We shall adhere to the signed “Venue Handover and Takeover Record” and handle the refund deposits. If the person-in-charge appointed by applicant to disassemble the event venue does not sign the “Venue Handover and Takeover Record” on the night of the last day of the exhibition period, or refuses to sign that form, our company reserves the right to withhold the deposits paid by applicants. On-Site Management has the right to request the person-in-charge appointed by applicant to sign the “Letter of Guaranty” if there is any loss or damage arise from the event."
            )}
          </span>
        </li>
      </ul>
      <div className="space"></div>
      <div className="title">{t("Guidelines on Power Supply")}</div>
      <ul className="numlist">
        <li>
          <span>
            {t(
              "Specifications of power supply layouts at the event venue have to adhere to government regulations on electrical installations."
            )}
          </span>
        </li>

        <li>
          <span>
            {t(
              "Any electrical installations shall be carried out, inspected, tested and certified by a registered electrical worker employed by the registered electrical contractor. The valid Work Completion Certificate (FORM WR1) shall be submitted to our Leasing department marketing team."
            )}
          </span>
        </li>

        <li>
          <span>
            {t(
              "Standard power arrangements will be set at no more than 16 Amp SPN. The power supply provided by management office is 13 Amp SPN socket outlet. Applicants are required to use 13 Amp SPN electrical plugs with on-off control for power connection to event venue. (Please make the necessary applications at the Management Office if in need of additional power supplies. All costs will be borne by the exhibitor)."
            )}
          </span>
        </li>

        <li>
          <span>
            {t(
              "Wires always placed on the floor and must be affixed with 3M non-sticky removable adhesive tape. All plugs have to come with on-and-off switches or timer switches and be affixed with 3M non-sticky removable adhesive tape to prevent them from being pulled out by others."
            )}
          </span>
        </li>

        <li>
          <span>
            {t(
              "The terms and conditions are subject to change without any prior notice. We reserve the discretionary right for final decisions."
            )}
          </span>
        </li>
      </ul>
      <div className="space"></div>
      <div className="sm-title">
        {t(
          "Remark: If there is any inconsistency or conflict between the English and Chinese versions of there Terms and Conditions, the English version shall prevail. "
        )}
        </div>*/}





    </>
  );
};

export default PopupContent;
