import PulseLoader from 'react-spinners/PulseLoader'
import styles from './Loader.module.scss'

const Loader = (props) => {
	let loading = props ? props.loading || true : true
	return (
		<div className={styles.loader}>
			<PulseLoader loading={loading} color={'#C8215D'} size={8} margin={5} />
		</div>
	)
}

export default Loader
