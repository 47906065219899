import { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { map, sortBy, orderBy } from 'lodash'
import { useMedia } from 'react-use';
import { useNavigate } from 'react-router-dom';

import PageTitle from '../../components/PageTitle/PageTitle'
import TabsGroup from '../../components/TabsGroup/TabsGroup'
import ButtonUnderline from '../../components/ButtonUnderline/ButtonUnderline'
import { GeneralCard } from '../../components/Card/Card'
import Filter from '../../components/Filter/Filter'

import getData from '../../utils/getData'
import { checkIsPeriod, checkIsOngoing } from '../../utils/getDate'
import getQueryString from '../../utils/getQueryString'
import styles from './Happenings.module.scss'

const Happenings = () => {
    const PAGE_OFFSET = 9;
    const [tabIndex, setTabIndex] = useState(0)
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [list, setList] = useState([])
    const { t } = useTranslation()
    const type = getQueryString('type')
    const navigate = useNavigate();
    const isMobile = useMedia('(max-width: 768px)');
    const dataSet = ['event', 'promotion'];

    const tabs = [{
        title: t('All'),
    }, {
        title: t('Promotions'),
    }, {
        title: t('Events')
    }]

    useEffect(async () => {
        async function getAllData() {
            let array = {}
            await Promise.all(
                dataSet.map(async(key) => {
                    const item = await getData(key)
                    setData((prevState) => ({...prevState, [key]: item.filter((i) => checkIsOngoing(i)) }))
                })
            )
        }
        getAllData()
    }, [])

    useEffect(() => {
        if (data) {

            // Sort data
            let eventData, promotionData
            if(data['event']) {

                eventData = orderBy( data['event'], 'display_order', 'asc')
                setList([...list, eventData])
            }
            if(data['promotion']) {
                promotionData = orderBy( data['promotion'], 'display_order', 'asc')
                setList([...list, promotionData])
            }
        }
    }, [data])

    //get correct list from parameter
    useEffect(() => {
        switch (type) {
            case 'promotion':
            case 'promotions':
                setTabIndex(1)
                break
            case 'event':
            case 'events':
                setTabIndex(2)
                break
        }
    }, [type, setTabIndex])

    useEffect(() => {
        let filteredData, string;
        /* let orderedData = map(data, (item, index) => {
            return orderBy(item, 'display_order', 'asc')
        }) */
        const eventData = orderBy(data['event'], 'display_order', 'asc')
        const promotionData = orderBy(data['promotion'], 'display_order', 'asc');
        switch (tabIndex) {
            case 0:
                filteredData = eventData.concat(promotionData)
                string = ''
                break;
            case 1:
                filteredData = promotionData
                string = 'promotion'
                break;
            case 2:
                filteredData = eventData
                string = 'event'
                break;
        }

        navigate({ pathname: '/happenings', search: `?type=${string}`})
        setList(filteredData)
    }, [data, tabIndex])

    const handleChange = useCallback((v) => {
        if (v === tabIndex) return
        setTabIndex(v)
    }, [setTabIndex, tabIndex])

    const handleLoadMore = () => {
        setPage(page + 1)
    }

    return (
        <div className="page-whatson page">
            <Helmet>
                <title> {t("What's On")} - {t('Central Market')} </title>
            </Helmet>
            <PageTitle title={t("Happenings")} />
            <div className="container-boxed half-padding">
                {isMobile ?
                    <Filter value={tabIndex} childs={tabs.map(item => item = item.title)} handleChange={handleChange}/>
                    :
                    <TabsGroup id="category"
                        childs={tabs}
                        tabIndex={tabIndex}
                        onChange={handleChange}
                        centertab />
                }
                <div className={styles.list}>
                    {list.slice(0, PAGE_OFFSET * page).map(({ id, category, title, slug, landing_page_image, start_date, end_date, location, type, ...props }, index) => (
                        <GeneralCard
                            key={index}
                            id={index}
                            title={title}
                            pic={landing_page_image}
                            url={`/${type}/${slug}`}
                            startDate={start_date}
                            endDate={end_date}
                            category={category}
                            location={location}
                            animate
                            isPromotion
                            small />
                    ))
                    }
                </div>
                {list.slice(0, PAGE_OFFSET * page).length < list.length &&
                    <ButtonUnderline handleClick={handleLoadMore} hasParent>{t('Load more events')}</ButtonUnderline>
                }
            </div>
        </div>
    )
}

export default Happenings