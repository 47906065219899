import { useNavigate } from 'react-router-dom'
import PreservedPillarDetail from '../PreservedPillarDetail/PreservedPillarDetail'
import PreservedPillarListing from '../PreservedPillarListing/PreservedPillarListingV2'

const PreservedPillar = (props) => {
	const { hash } = window.location

	// history handle
	const navigate = useNavigate()
	// redirect green to blueprint
	if((hash === '' || hash === '#') && props && props.section === 'green'){
		navigate("/blueprint")
	}
	return <>{hash ? <PreservedPillarDetail {...props}/> : <PreservedPillarListing {...props}/>}</>
}

export default PreservedPillar
