import React from "react";
import red from "@material-ui/core/colors/red";
import Checkbox from "@material-ui/core/Checkbox";
import {
  ValidatorComponent,
  TextValidator,
} from "react-material-ui-form-validator";
import $ from "jquery";
const red300 = red["500"];

const style = {
  right: 0,
  fontSize: "12px",
  color: red300,
  position: "absolute",
  marginTop: "-25px",
};

class CheckboxValidatorElement extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      onClick,
      name,
      value,
      text,
      validatorListener,
      ...rest
    } = this.props;

    const { isValid } = this.state;

    if (isValid) {
      return (
        <div className="checkboxValidator-container">
          <div className="checkBoxWithText">
            <Checkbox
              value={value}
              name={name}
              {...rest}
              ref={(r) => {
                this.input = r;
              }}
              onClick={onClick}
            />

            <div className="text">
              {this.props.children}
              {this.errorText()}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="checkboxValidator-container validator-error-class">
        <div className="checkBoxWithText">
          <Checkbox
            value={value}
            name={name}
            {...rest}
            ref={(r) => {
              this.input = r;
            }}
            onClick={onClick}
          />

          <div className="text">
            {this.props.children}
            {this.errorText()}
          </div>
        </div>
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div className="checkboxValidator-error validator-error Mui-error">
        {this.getErrorMessage()}
      </div>
    );
  }
}

export default CheckboxValidatorElement;
