// Core
import { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Date-fns
import DateFnsUtils from '@date-io/date-fns'
import { addDays } from 'date-fns'
import enLocale from 'date-fns/locale/en-US'
import format from 'date-fns/format'
import parse from 'date-fns/parse'

// Material-ui
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

// Compoents
import ButtonBoxed from '../../components/ButtonBoxed/ButtonBoxed'
import BannerCTA from '../../components/BannerCTA/BannerCTA'
import Loader from '../../components/Loader/Lodaer'
import Card from '../../components/Card/Card'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import ButtonUnderline from '../../components/ButtonUnderline/ButtonUnderline'
import { CalendarIcon, LeftArrowIcon, RightArrowIcon } from '../../components/Icon/Icon'
// import { LeftArrowIcon, RightArrowIcon } from '../../components/Icon/Icon'

// Styles
import styles from './EventCalendar.module.scss'
import '../../styles/datePicker.scss'

// i18n
import { useTranslation } from 'react-i18next'

// Utils
import getData from '../../utils/getData'
import getPurePrice from '../../utils/getPurePrice'
import getValidData from '../../utils/getValidData'
import checkIsStagingGround from '../../utils/checkIsStagingGround'

// Lodash
import _ from 'lodash'

// jQuery
// import $ from 'jquery'

export class LocalizedUtils extends DateFnsUtils {
	getDatePickerHeaderText(date) {
		return format(date, 'dd.MM.yyyy', { locale: enLocale })
	}
	getCalendarHeaderText(date) {
		return format(date, 'MM.yyyy', { locale: enLocale })
	}
	getWeekdays() {
		return ['S', 'M', 'T', 'W', 'T', 'F', 'S']
	}
}

// const eventData = []

// const eventList = eventData

const EventCard = (props) => {
	return <Card key={props.index} pic={props.event.slider[0].image} category={props.event['category']} url={`/event-calendar/${props.event['slug']}`} title={props.event['title']} startDate={props.event['start_date']} endDate={props.event['end_date']} location={props.event['location']} isShow={props.event['isShow']} isFree={!getPurePrice(props.event['fee'])} />
}

const EventCalendar = () => {
	const { i18n, t } = useTranslation()

	const [isStagingGroundAvailable, setIsStagingGroundAvailable] = useState(false)
	const [openDatepicker, setOpenDatepicker] = useState(false)
	const [openCategory, setOpenCategory] = useState(false)
	const [useEndDate, setuseEndDate] = useState(new Date())

	const { page } = useParams()
	const perPage = 6
	const perPageToday = 3

	const [search, setSearch] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingPage, setIsLoadingPage] = useState(false)
	const [isLoadingPageToday, setIsLoadingPageToday] = useState(false)
	const [runedQuerySearch, setRunedQuerySearch] = useState(false)

	const [data, setData] = useState({
		items: [],
		itemsFiltered: [],
		itemsUpcoming: [],
		itemsToday: [],
		itemsTodayShowing: [],
	})

	const [filter, setFilter] = useState({
		currentPage: page || 1,
		currentPageToday: 1,
		categories: [],
		category: '',
		search: '',
		date: new Date(),
	})

	const location = useLocation()
	useEffect(() => {
		if (!location || !filter || !filter.categories_raw || runedQuerySearch === true) {
			return
		}

		const { search } = location
		const query = new URLSearchParams(search)
		const category_id = query.get('category_id')
		// console.log(`handle query string: ${category_id} , filter:`, filter.categories_raw)

		const selected_categories = filter.categories_raw[category_id]
		if (selected_categories) {
			// console.log('handle query string found categories: ', selected_categories)
			handleUpdateFilter({ category: selected_categories })
			setRunedQuerySearch(true)
		}
	}, [location, filter, filter.categories_raw, runedQuerySearch])

	// Fetch data
	useEffect(() => {
		setIsLoading(true)

		async function fetchData() {
			try {
				// Get data
				let dataRaw = await getData('event')

				// Update date format
				dataRaw = _.map(dataRaw, function (item) {
					item.start_date = parse(item.start_date, 'dd/MM/yyyy', new Date())
					item.end_date = parse(item.end_date, 'dd/MM/yyyy', new Date())
					item.release_date = parse(item.release_date, 'dd/MM/yyyy', new Date())
					return item
				})

				// Get valid data
				// dataRaw = getValidData(dataRaw)

				// Set categories
				const availableCategories = _.map(_.uniqBy(dataRaw, 'category'), 'category')
				const categories_raw = {}
				dataRaw.forEach((item) => {
					const { category_id, category } = item
					categories_raw[category_id] = category
				})

				setFilter((prevState) => ({
					...prevState,
					categories: availableCategories,
					categories_raw: categories_raw,
				}))

				// Sort data
				const data = _.sortBy(dataRaw, [
					function (o) {
						return o.start_date
					},
				])

				// Set data
				setData((prevState) => ({
					...prevState,
					items: data,
				}))
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	// Filter data
	useEffect(() => {
		const itemsFiltered = _.filter(data.items, function (item) {
			// Chcekc release date
			if (new Date(item['release_date']).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) {
				return false
			}

			// Check category
			if (filter.category && item.category !== filter.category) {
				return false
			}

			// Check keywords
			if (filter.search && !item.title.toLowerCase().includes(filter.search.toLowerCase()) && !item.description.toLowerCase().includes(filter.search.toLowerCase())) {
				return false
			}
			// Check end date
			if (new Date(item['end_date']).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
				return false
			}

			return true
		})

		const itemsToday = _.filter(itemsFiltered, function (item) {
			const { is_selected } = item
			if(is_selected) return true

			const isToday = new Date(useEndDate).setHours(0, 0, 0, 0) >= new Date(item['start_date']).setHours(0, 0, 0, 0) && new Date(filter.date).setHours(0, 0, 0, 0) <= new Date(item['end_date']).setHours(0, 0, 0, 0)

			return isToday
		})

		const selectedToday = _.orderBy(itemsToday, ['is_selected'], ['desc'])
		/* const selectedToday = _.orderBy(itemsToday, (item) => item.slug === 'July-Highlights', ['desc']); */

		const itemsUpcoming = _.filter(itemsFiltered, function (item) {
			const { is_selected } = item
			if(is_selected) return false

			let isUpcoming
			if (useEndDate) {
				isUpcoming = new Date(useEndDate).setHours(0, 0, 0, 0) < new Date(item['start_date']).setHours(0, 0, 0, 0) && new Date().setHours(0, 0, 0, 0) < new Date(item['start_date']).setHours(0, 0, 0, 0)
			} else {
				isUpcoming = new Date().setHours(0, 0, 0, 0) < new Date(item['start_date']).setHours(0, 0, 0, 0)
			}
			return isUpcoming
		})

		setData((prevState) => ({
			...prevState,
			itemsFiltered: itemsUpcoming,
			itemsToday: selectedToday,
		}))
	}, [data.items, filter, useEndDate])

	const lopppAllData = () => {
		data.itemsFiltered.forEach((item) => {
			if (checkIsStagingGround(item)) {
				setIsStagingGroundAvailable(true)
			}
		})
		data.itemsToday.forEach((item) => {
			if (checkIsStagingGround(item)) {
				setIsStagingGroundAvailable(true)
			}
		})
	}

	// Get current pages data
	useEffect(() => {
		lopppAllData()
		let itemsUpcoming = data.itemsFiltered.slice(0, filter.currentPage * perPage)
		let itemsTodayShowing = data.itemsToday.slice(0, filter.currentPageToday * perPageToday)

		setData((prevState) => ({
			...prevState,
			itemsUpcoming: itemsUpcoming,
			itemsTodayShowing: itemsTodayShowing,
		}))

		// setTimeout(() => {
		setIsLoading(false)
		setIsLoadingPage(false)
		setIsLoadingPageToday(false)

		// window.dispatchEvent(new Event('resize'));
		var resizeEvent = window.document.createEvent('UIEvents')
		resizeEvent.initUIEvent('resize', true, false, window, 0)
		window.dispatchEvent(resizeEvent)
		// }, 500)

		// eslint-disable-next-line
	}, [data.itemsFiltered, filter.currentPage, filter.currentPageToday])

	const handleEndDate = (e) => {
		const today = new Date()
		const enddate = new Date()
		enddate.setDate(today.getDate() + parseInt(e.target.getAttribute('day')))
		setuseEndDate(enddate)
	}
	const handleChangeCategory = (e) => {
		handleUpdateFilter({ category: e.target.value })
		closeDialog()
	}

	const handleDateChange = (date) => {
		handleUpdateFilter({ date: date })
		setuseEndDate(date)
	}

	const handleChangeSearch = (e) => {
		setSearch(e.target.value)
		handleUpdateFilter({ search: e.target.value })
	}

	const handleSearch = (e) => {
		e.preventDefault()
		handleUpdateFilter({ search: search })
	}

	// Load more
	const handleLoadMore = () => {
		setIsLoadingPage(true)
		setFilter((prevState) => ({
			...prevState,
			currentPage: filter.currentPage + 1,
		}))
	}

	// Load more
	const handleLoadMoreToday = () => {
		setIsLoadingPageToday(true)
		setFilter((prevState) => ({
			...prevState,
			currentPageToday: filter.currentPageToday + 1,
		}))
	}

	const handleUpdateFilter = (filter) => {
		setIsLoading(true)
		setFilter((prevState) => ({
			...prevState,
			...filter,
			currentPage: 1,
			currentPageToday: 1,
		}))
	}

	const closeDialog = () => {
		setOpenDatepicker(false)
		setOpenCategory(false)
	}

	const handleScroll = () => {
		closeDialog()
		document.activeElement.blur()
	}

	window.addEventListener('scroll', handleScroll)

	return (
		<div className={styles.eventCalendar}>
			<Helmet>
				<title>
					{t('Event Calendar')} - {t('Central Market')}
				</title>
			</Helmet>
			<section>
				<div className={styles.bg}>
					<div className={styles.images}>
						<img src="/images-local/block-tb-bs.png" alt="" />
						<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
						<img src="/images-local/block-3-h.png" alt="" />
						<img src="/images-local/block-s.png" alt="" />
						<img src="/images-local/block-s.png" alt="" />
						<img src="/images-local/CM_KV_Play-version.png" alt="" />
						<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					</div>
				</div>
				<div className="container-boxed pt-16">
					<div className={styles.container}>
						<div className={styles.title}>
							<TextBorderFill>{t('Event Calendar')}</TextBorderFill>
						</div>
						<div className="event-calendar-date-input">
							<MuiPickersUtilsProvider utils={LocalizedUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="dd.MM.yyyy"
									margin="normal"
									id="date-picker-inline"
									className={styles.datePicker}
									value={filter.date}
									onChange={handleDateChange}
									open={openDatepicker}
									onClose={closeDialog}
									onOpen={() => setOpenDatepicker(true)}
									autoOk={true}
									KeyboardButtonProps={{
										'aria-label': 'change date',
										id: 'date-picker-icon',
									}}
									minDate={new Date()}
									maxDate={addDays(new Date(), 60)}
									keyboardIcon={<CalendarIcon />}
									leftArrowIcon={<LeftArrowIcon />}
									rightArrowIcon={<RightArrowIcon />}
								/>
							</MuiPickersUtilsProvider>
							<div className={styles.calendar_btn_container}>
								<ButtonBoxed text={t('Today')} action={handleEndDate} day="0" />
								<ButtonBoxed text={t('Upcoming 7 Days')} action={handleEndDate} day="7" />
								<ButtonBoxed text={t('Upcoming 30 Days')} action={handleEndDate} day="30" />
							</div>
						</div>

						<div className={`colored ${styles.filter}`}>
							<div className={styles.category}>
								{/* Dropdown category */}
								<Select value={filter.category} onChange={handleChangeCategory} open={openCategory} onClose={closeDialog} onOpen={() => setOpenCategory(true)} displayEmpty inputProps={{ 'aria-label': 'Without label' }} fullWidth>
									<MenuItem value="">{t('All category')}</MenuItem>
									{filter.categories.map((category, index) => {
										return (
											<MenuItem key={index} value={category}>
												{t(category)}
											</MenuItem>
										)
									})}
								</Select>
							</div>
							<div className={styles.search}>
								<form onSubmit={handleSearch}>
									<FormControl>
										{/* Input search */}
										<TextField onChange={handleChangeSearch} className={styles.half} id="search" label={t('Search')} />
										<IconButton type="submit" aria-label={t('Search')} onClick={handleSearch}>
											<img src="/images-local/ico-search.svg" alt="" />
										</IconButton>
									</FormControl>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section>
				{isLoading ? (
					<Loader />
				) : data.itemsTodayShowing.length ? (
					<>
						<div className={styles.bg}>
							<div className={styles.images}>
								<img src="/images-local/block-s.png" alt="" />
								<img src="/images-local/block-tb-bs.png" alt="" />
								<img src="/images-local/block-bs-tb.png" alt="" />
							</div>
						</div>
						<div className="container-boxed">
							<div className={styles.container}>
								<div className={styles.toDayEvent}>
									{data.itemsTodayShowing.map((event, index) => (
										<EventCard key={index} event={event} />
									))}

									{/* Load mroe */}
									{isLoadingPageToday ? (
										<Loader />
									) : filter.currentPageToday * perPageToday >= data.itemsToday.length || isLoading ? (
										''
									) : (
										<div onClick={handleLoadMoreToday} className={styles.btnLoadMore}>
											<ButtonUnderline>{t('Load more events')}</ButtonUnderline>
										</div>
									)}
								</div>
							</div>
						</div>
					</>
				) : (
					''
				)}
			</section>

			<section>
				<div className={styles.bg}>
					<div className={styles.images}>
						<img src="/images-local/cloud.png" alt="" />
						<img src="/images-local/block-3-v.png" style={{ opacity: 0 }} alt="" />
						<img src="/images-local/block-ts-bb.png" alt="" />
						<img src="/images-local/cloud.png" alt="" />
						<img src="/images-local/block-bs-tb.png" alt="" />
					</div>
				</div>
				<div className="container-boxed">
					<div className={styles.container}>
						<div>
							<div className={styles.title}>
								<TextBorderFill>{t('Upcoming events')}</TextBorderFill>
							</div>
							<div className={styles.upcomingEvent}>
								{isLoading ? <Loader /> : data.itemsUpcoming.length ? data.itemsUpcoming.map((event, index) => <EventCard key={index} event={event} />) : <div className={styles.noEvent}>{t('No Event')}</div>}

								{/* Load mroe */}
								{isLoadingPage ? (
									<Loader />
								) : filter.currentPage * perPage >= data.itemsFiltered.length || isLoading ? (
									''
								) : (
									<div onClick={handleLoadMore} className={styles.btnLoadMore}>
										<ButtonUnderline>{t('Load more events')}</ButtonUnderline>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className="mb-32">
				<BannerCTA subTitle={t('Central market’s highlight')} title={t('Join our staging ground special')} image="/images-local/pic-banner-1.png" buttonText={t('Apply now')} buttonUrl="/staging-ground/register/" />
			</div>

			<div className="container-boxed mb-10 text-grey">
				<p>{t('event_remind_2')}</p>
			</div>
		</div>
	)
}

export default EventCalendar
