// Core
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { useToggle } from 'react-use'

// Styles
import styles from './Card.module.scss'

// AOS
// import AOS from 'aos'
import 'aos/dist/aos.css'

// Utils
import getPeriod from '../../utils/getPeriod'

//i18n
import { useTranslation } from 'react-i18next'

export const GeneralCard = ({ isPromotion, isSquare, isContain, id, pic, url, title, category, location, startDate, endDate, animate, small }) => {

	const [load, setLoad] = useToggle(false)

	useEffect(() => {
		if(animate) {
			setLoad(true)
		}
	}, [])

	return (
		<div className={`${styles.card} ${isPromotion ? styles.promotion : ''} ${small ? styles.small : ''} ${animate ? styles.animateReady : ''} ${isContain ? styles.isContain : ''} ${animate && load ? styles.animated : ''}`} data-is-square={isSquare} data-id={id} data-component="card">
			<div className={styles.image}>
				<Link to={url}>
					<img src={pic} />
				</Link>
			</div>
			<div className={styles.content}>
				<ul className={styles.details}>
					{category ? <li className={styles.category}><span>{category}</span></li> : ''}
					<li className={styles.title}><Link to={url}>{title}</Link></li>
					{startDate ?
					<li>
						<SVG className={styles.stroke} src="/images-local/ico-calendar-without-date.svg" alt="" />
						{getPeriod(startDate, endDate)}
					</li> : null
					}

					{/* Location */}
					{location ? (
						<li>
							<SVG className={styles.stroke} src="/images-local/ico-location.svg" alt="" />
							{location}
						</li>
					) : null}
				</ul>
			</div>
		</div>
	)
}

const Card = (props) => {
	// // AOS Setting
	// AOS.init({
	// 	once: true,
	// 	duration: 1000,
	// })
	const { t } = useTranslation()

	return (
		<div className={styles.card} data-is-square={props.isSquare} data-id={props.id} data-component="card" data-is-active={props.isActive}>
			<div className={styles.badges}>
				{props.isFeatured ? <div className={styles.badge}>Feature store</div> : ''}

				{props.isStagingGround ? <div className={styles.badge}>Staging ground special</div> : ''}

				{/* {props.isFree ? <div className={styles.badge}>{t('Free')}</div> : ''} */}
			</div>
			{!props.isHidePic ? (
				<div className={styles.image}>
					{props.url ? (
						<Link to={props.url}>
							<img src={props.pic} alt="" />
						</Link>
					) : (
						<a>
							<img src={props.pic} alt="" />
						</a>
					)}
				</div>
			) : (
				''
			)}

			<div className={styles.content}>
				{props.category ? <span className={styles.category}>{props.category}</span> : ''}
				<ul className={styles.details}>
					{/* Date */}
					{props.date ? (
						<li>
							<SVG className={styles.fill} src="/images-local/ico-calendar-without-date.svg" alt="" />
							{props.date}
						</li>
					) : (
						''
					)}

					{/* Title */}
					<h3 className={styles.title}>
						{props.url ?
							props.downloadable ?
							<a href={props.url} target="_blank" download>{props.title}</a>
							:
							<a href={props.url} target="_blank">{props.title}</a>
						: props.title}</h3>
							{/* <Link to={props.url}>{props.title}</Link> */}

					{/* Period */}
					{props.startDate ? (
						<li>
							<SVG className={styles.stroke} src="/images-local/ico-calendar-without-date.svg" alt="" />
							{getPeriod(props.startDate, props.endDate)}
						</li>
					) : (
						''
					)}

					{/* Location */}
					{props.location ? (
						<li>
							<SVG className={styles.stroke} src="/images-local/ico-location.svg" alt="" />
							{props.location}
						</li>
					) : (
						''
					)}

					{/* Phone */}
					{props.phone ? (
						<li>
							<SVG className={styles.stroke} src="/images-local/ico-phone.svg" alt="" />
							<Link
								to="#"
								onClick={(e) => {
									window.location = 'tel:' + props.phone
									e.preventDefault()
								}}
							>
								{props.phone.substring(0, 4) + ' ' + props.phone.substring(4, props.phone.length)}
							</Link>
						</li>
					) : (
						''
					)}
				</ul>
			</div>
		</div>
	)
}

export default Card
