// Core
import { useState, useEffect, useRef } from 'react'

// Styles
import '../../styles/form.scss'

// Material UI
import { FormControl, FormControlLabel, FormLabel, FormHelperText, MenuItem, Radio } from '@material-ui/core'
import {  MuiPickersUtilsProvider } from '@material-ui/pickers'
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'

// Validator
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import CheckboxValidatorElement from '../../components/CheckboxValidatorElement/CheckboxValidatorElement'
import KeyboardDatePickerValidator from '../../components/KeyboardDatePickerValidator/KeyboardDatePickerValidator'
import RadioGroupValidator from '../../components/RadioGroupValidator/RadioGroupValidator'
import moment from 'moment'

// i18n
import { useTranslation } from 'react-i18next'

// Components
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import Popup from '../../components/Popup/Popup'
import PopupTAC from '../../components/PopupContent/venue_hire_t&c'
import $ from 'jquery'
import getSession from '../../utils/getSession'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader/Lodaer'
import { map } from 'lodash'

const HeritageTourRegister = () => {
	const { t } = useTranslation('')
	const [sampleData, setSampleData] = useState({
		applicant_name: '陳大文',
		phone: '12345678',
		organization_name: 'test 123',
		organization_phone: '12345678',
		email: 'hello@hello.com',
        pax: 'Testing',
		prefer_date: '2023-07-12',
        time_period: '11:00-12:00',
		language: 'cantonese',
	})
	const [formData, setFormData] = useState({
		applicant_name: '',
		phone: '',
		organization_name: '',
		organization_phone: '',
		email: '',
        pax: '',
		prefer_date: null,
        time_period: '',
		language: '',
	})
	const [declarationData, setDeclarationData] = useState({
		declaration1: '',
	})
    const timePeriods = ['11:00-12:00','14:00-15:00','15:00-16:00','17:00-18:00']
	const formRef = useRef(null)

	let navigate = useNavigate()

	const handleSampleData = () => {
		console.log(sampleData)

		setFormData((prevState) => ({
			...prevState,
			...sampleData,
		}))
	}
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		$(document).on('click', '.MuiPickersCalendarHeader-transitionContainer', function (e) {
			$('.MuiPickersToolbarButton-toolbarBtn').eq(0).click()
		})
	}, [])
	const handleChangeFormData = (event) => {

		const name = event.target.name
		const value = event.target.value
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}))
	}

	const handleChangeFormDataDate = (date, key) => {
		setFormData((prevState) => ({
			...prevState,
			[key]: date,
		}))
	}

	const handleChangeDeclaration = (event) => {
		const name = event.target.name
		if (declarationData[name] == 'checked') {
			setDeclarationData((prevState) => ({
				...prevState,
				[name]: '',
			}))
		} else {
			setDeclarationData((prevState) => ({
				...prevState,
				[name]: 'checked',
			}))
		}
	}

	const handleSubmit = (event) => {
		setIsLoading(true)
		event.preventDefault()
		let submitFormData = formData
		formData.prefer_date = moment(formData.prefer_date).format('YYYY-MM-DD');
		// console.log(JSON.stringify(formData));
		$.post(
			'/api/HeritageTour/SubmitHeritageTourBooking',

			submitFormData,
			function (response) {
				if (response.status_code == 200) {
					navigate('/thankyou')
				}
			}
		)
	}

	return (
		<div className={`${isLoading ? 'loading' : ''}` + ' main cm-form page-HeritageTourRegister'}>
			<div className="container-boxed half-padding">
				{window.location.href.includes('www.centralmarket.hk') || window.location.href.includes('www-staging.centralmarket.hk') ? (
					''
				) : getSession('devtool') === 'on' ? (
					<div className="w-full border border-primary border-dashed p-10">
						<div className="text-primary mb-2">Dev tool:</div>
						<a className="inline-block border border-primary text-primary transition-all duration-400 py-2 px-5 cursor-pointer hover:bg-primary hover:text-white" onClick={handleSampleData}>
							Autofill
						</a>
					</div>
				) : (
					''
				)}
				<ValidatorForm
					ref={formRef}
					onSubmit={handleSubmit}
					onError={() =>
						$([document.documentElement, document.body]).animate(
							{
								scrollTop: $('.Mui-error').eq(0).parent().offset().top - $('[class*=Header_nav_]').eq(0).height(),
							},
							1000
						)
					}
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<div className="title">
							<TextBorderFill>{t('Heritage tour applicantion form')}</TextBorderFill>
							<div className="desc">{t('venue_booking_form_required_fields')}</div>
						</div>
                        <section>
							<div className="title">{t('A. Particulars of the Applicant 2')}</div>
							<div className="fields">
								<div className="row boxed">
									<div className="field half">
										<TextValidator
											label={t('Name of the Applicant') + '*'}
											onChange={handleChangeFormData}
											name="applicant_name"
											value={formData.applicant_name}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Mobile') + '*'}
											onChange={handleChangeFormData}
											name="phone"
											value={formData.phone}
											validators={['required', 'isNumber', 'minNumber:10000000', 'maxNumber:99999999']}
											errorMessages={[t('Cannot leave it blank'), t('The format is incorrect'), t('The format is incorrect'), t('The format is incorrect')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Company Name') + '*'}
											onChange={handleChangeFormData}
											name="organization_name"
											value={formData.organization_name}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Company Phone no.') + '*'}
											onChange={handleChangeFormData}
											name="organization_phone"
											value={formData.organization_phone}
											validators={['required', 'isNumber', 'minNumber:10000000', 'maxNumber:99999999']}
											errorMessages={[t('Cannot leave it blank'), t('The format is incorrect'), t('The format is incorrect'), t('The format is incorrect')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Email') + '*'}
											onChange={handleChangeFormData}
											name="email"
											value={formData.email}
											validators={['required', 'isEmail']}
											errorMessages={[t('Cannot leave it blank'), t('The format is incorrect')]}
											fullWidth
										/>
									</div>
									<div className="field half">
										<TextValidator
											label={t('Pax') + '*'}
											onChange={handleChangeFormData}
											name="pax"
											value={formData.pax}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
											fullWidth
										/>
									</div>
                                    <div className="field half">
										<KeyboardDatePickerValidator
											disableToolbar
											onChange={(event) => {
												handleChangeFormDataDate(event, 'prefer_date')
											}}
											variant="inline"
											format="dd.MM.yyyy"
											minDate={new Date()}
											label={t('Preferable date') + '*'}
											name="prefer_date"
											value={formData.prefer_date}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											views={['year', 'month', 'date']}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
											autoOk={true}
											fullWidth
										/>
									</div>
                                    <div className="field half">
										<FormControl fullWidth>
											<SelectValidator
												label={t('Time period') + '*'}
												labelId="event-time-period"
												name="time_period"
												value={formData.time_period}
												onChange={handleChangeFormData}
												validators={['required']}
												errorMessages={[t('Cannot leave it blank')]}
											>
												{
													map(timePeriods, (value, index) => (
														<MenuItem key={index} value={value}>{value}</MenuItem>
													))
												}
											</SelectValidator>
										</FormControl>
									</div>
                                </div>
                                <div className="row">
                                    <div className="field">
										<FormLabel>{t('Language') + '*'}</FormLabel>
										{/*<div className="label-boxed">{t('1st Level')}</div>*/}
										<RadioGroupValidator
											aria-label="language"
											name="language"
											value={formData.language}
											onChange={handleChangeFormData}
											validators={['required']}
											errorMessages={[t('Cannot leave it blank')]}
										>
											<FormControlLabel
												value="cantonese"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Cantonese')}
											/>
											<FormControlLabel
												value="mandarin"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('Mandarin')}
											/>
											<FormControlLabel
												value="english"
												control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
												label={t('English')}
											/>
										</RadioGroupValidator>
									</div>
								</div>
							</div>
						</section>

						<section>
							<div className="title">{t('B. Declaration')}</div>
							<div className="fields">
								<div className="row">
									<div className="field">
										<CheckboxValidatorElement
											validators={['required']}
											errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
											name="declaration1"
											value={declarationData.declaration1}
											onClick={(event) => {
												handleChangeDeclaration(event)
											}}
										>
											{t('I confirm that the information herein is true and correct and agree to be bound by the ')}
											<Popup text={t('terms and conditions')} content={<PopupTAC />} />
											{t(' of the use of venues as laid down the Landlord.')}
										</CheckboxValidatorElement>
									</div>
								</div>
							</div>
						</section>
					</MuiPickersUtilsProvider>
					<div className="subbtn-container">{isLoading ? <Loader /> : <SubmitButton text={t('Submit')} />}</div>
				</ValidatorForm>
			</div>
		</div>
	)
}

export default HeritageTourRegister
