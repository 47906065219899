import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import PageTitle from '../../components/PageTitle/PageTitle'
import Image from '../../components/Image/Image'
// Styles
import styles from './PetFriendly.module.scss'

const PetFriendly = () => {
	let { t, i18n } = useTranslation()
	let { t: tMenu } = useTranslation(['menu'])

	return (
		<div className="page">
			<Helmet>
				<title> {tMenu("Pet Friendly Space")} - {t('Central Market')} </title>
			</Helmet>
			<PageTitle title={t("pet_friendly_title")} withBr/>
			<div className="container-boxed much-padding">
                <div className={styles.description}>
                    <p>{t('pet_friendly_desc')}</p>
                </div>
				<div className={styles.image}>
					<Image src="/images-local/pet-friendly.jpg" />
				</div>
				<div className={styles.instruction}>{t('Instruction for Pet Owners')}</div>
				<div className={styles.list}>
					{_.times(5, (index) => (
						<div className={styles.child}>
							<div className={styles.petImageContainer}>
								{index === 3 ?
								<div className={styles.petImage} style={{ backgroundImage: `url(/images-local/pet-${index + 1}-${i18n.language}.png)`}}/>
								:
								<div className={styles.petImage} style={{ backgroundImage: `url(/images-local/pet-${index + 1}.png)`}}/>
								}
							</div>
							<div className={styles.petDesc}>{t(`pet_instruction_${index+1}`)}</div>
						</div>
					))}
				</div>
				<div className={styles.description}>{t('pet_instruction_desc')}</div>
			</div>
		</div>
	)
}

export default PetFriendly
