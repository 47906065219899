import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/styles'
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { map } from 'lodash';
import MenuDropDown from "../MenuDropDown/MenuDropDown";

const StyledTabs = withStyles((theme) => ({
    root: {
        minHeight: 'auto !important',
        backgroundColor: 'inherit !important',
        width: '100% !important',
        height: 'auto !important',
        position: 'relative !important',
    },
    centered: {
        gap: '0 60px',
        width: '100% !important',
        backgroundColor: 'inherit !important',
        height: 'auto !important',
        position: 'relative !important',
    },
    indicator: {
        display: 'none'
    }
}))(Tabs);

const StyledTab = withStyles((theme) => ({
    root: props => ({
        color: '#686767 !important',
        letterSpacing: 0.5,
        opacity: '1 !important',
        borderBottom: '1px solid #686767 !important',
        width: 'auto !important',
        height: 'auto !important',
        backgroundColor: 'inherit !important',
        '&.Mui-selected': {
            color: '#c8215d !important',
            borderColor: '#c8215d !important',
            borderWidth: '4px !important'
        },
        ...(
            props.centertab ? {
                padding: '5px 5px !important',
                minHeight: 'auto !important',
                minWidth: 'inherit !important',
            } : {}),
    }),
}))(Tab)

const StyledTabList = withStyles((theme) => ({
    root: {
        counterReset: 'num'
    },
    indicator: {
        display: 'none'
    }
}))(TabList)

const useStyles = makeStyles(({
    root: {
        position: 'relative',
        '& .rs-dropdown':{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
        }
    }
}))

const StyledTabWithNum = withStyles((theme) => ({
    root: {
        position: 'relative',
        color: '#686767 !important',
        lineHeight: '1.25 !important',
        letterSpacing: 0.5,
        opacity: '1 !important',
        borderBottom: 'none !important',
        width: '100% !important',
        height: '85px !important',
        padding: '30px 0 6px 0 !important',
        backgroundColor: 'inherit !important',
        alignItems: 'flex-start !important',
        textAlign: 'left !important',
        maxWidth: 'none !important',
        flexGrow: 1,
        flexBasis: 0,
        '&:before': {
            poistion: 'absolute',
            counterIncrement: 'num',
            position: 'absolute',
            top: 0,
            left: 0,
            content: "'0' counter(num)",
            textAlign: 'left',
            textIndent: 20,
            width: '100%',
            borderBottom: '1px solid #686767',
            paddingBottom: 2,
        },
        '&.Mui-selected': {
            color: '#c8215d !important',
            '&:before': {
                borderBottom: '4px solid #c8215d !important',
            }
        },
        '&:hover': {
            color: '#c8215d !important',
            '&:before': {
                borderBottom: '4px solid #c8215d !important',
            }
        },
    },
    wrapper: {
        position: 'relative',
        justifyContent: 'flex-start',
        alignItems: 'flex-start !important',
        padding: '0 43% 0 20px',
        '& svg': {
            position: 'absolute',
            right: 0,
        }
    }
}))(Tab)

const a11yProps = ({ name, index }) => {
    return {
        id: `wrapped-tab-${name}-${index}`,
        'aria-controls': `wrapped-tabpanel-${name}-${index}`,
    };
}

const TabsGroup = ({ id, childs, centertab, tabIndex, onChange }) => {

    const handleChange = (e, v) => {
        onChange(v)
    }

    return (
        <StyledTabs centered={!!centertab} value={tabIndex} onChange={handleChange}>
            {childs && childs.length &&
                map(childs, ({ title, ...props }, index) => (
                    <StyledTab centertab={centertab} key={index} label={title} {...a11yProps({ name: id, index })} />
                ))

            }
        </StyledTabs>
    )
}

export const TabWithNumGroup = ({ id, childs, centertab, tabIndex, onChange }) => {

    const { t } = useTranslation()

    const handleChange = (e, v) => {
        onChange(v)
    }

    return (
        <StyledTabList onChange={handleChange}>
            {childs && childs.length &&
                map(childs, ({ title, value, items, ...props }, index) => (
                    <StyledTabWithNum key={index} label={title} icon={<ArrowDropDownIcon />} {...a11yProps({ name: id, index })} />
                ))
            }
        </StyledTabList>
    )
}

export default TabsGroup;