// Styles
import '../../styles/popup.scss'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

const PopupBuskingRegisterFormMap = (props) => {
	const { t } = useTranslation('')

	function htmldecode(str) {
		var txt = document.createElement('textarea')
		txt.innerHTML = str
		return txt.value
	}
	return (
		<>
			<div className="title" style={{textAlign:"center"}}>{props.title ? parse(htmldecode(props.title)) : ""}</div>
			<img src={props.mapImg} style={{width:"80%", margin:"auto"}}></img>
		</>
	)
}

export default PopupBuskingRegisterFormMap
