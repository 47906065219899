// Core
import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

// Style
import styles from './SiteSearch.module.scss'

// Material-ui
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

//i18n
import { useTranslation } from 'react-i18next'

const SiteSearch = (props) => {
	const { t } = useTranslation()
	const searchEl = useRef()
	const [query, setQuery] = useState(props.query || '')
	const navigate = useNavigate()

	const handleChange = (e) => {
		setQuery(e.target.value)
	}

	const handleSearch = (e) => {
		e.preventDefault()
		const keyword = searchEl.current.value
		navigate(`/search/${keyword}`)
	}

	return (
		<div className={`colored ${styles.search}`}>
			<form onSubmit={handleSearch}>
				<FormControl fullWidth>
					<TextField inputRef={searchEl} value={query} onChange={handleChange} label={t('Search')} fullWidth />
					<IconButton type="submit" aria-label={t('Search')} onClick={handleSearch}>
						<img src="/images-local/ico-search.svg" alt="" />
					</IconButton>
				</FormControl>
			</form>
		</div>
	)
}

export default SiteSearch
