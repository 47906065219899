// Core
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

// Compoents
import Card from '../../components/Card/Card'
import ButtonUnderline from '../../components/ButtonUnderline/ButtonUnderline'
import PageTitle from '../../components/PageTitle/PageTitle'
import Loader from '../../components/Loader/Lodaer'

// material-ui
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'

// Styles
import styles from './Press.module.scss'

// i18n
import { useTranslation } from 'react-i18next'

// Utils
import getData from '../../utils/getData'

// Lodash
import _ from 'lodash'

const Press = () => {
	const { t, i18n } = useTranslation('')
	const { page } = useParams()

	const perPage = 9

	const [search, setSearch] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingPage, setIsLoadingPage] = useState(false)

	const [data, setData] = useState({
		items: [],
		itemsFiltered: [],
		itemsCurrent: [],
	})

	const [filter, setFilter] = useState({
		currentPage: page || 1,
		categories: [],
		category: '',
		search: '',
		date: new Date(),
	})

	// Fetch data
	useEffect(() => {
		setIsLoading(true)

		async function fetchData() {
			try {
				let dataRaw = await getData('press_release')
				let availableCategories = _.uniq(
					dataRaw.map((item) => {
						return item.release_date.split('-')[0]
					})
				)

				const data = _.orderBy(
					dataRaw,
					[
						function (o) {
							console.log('start_date', o.release_date)
							return o.release_date
						},
					],
					['desc']
				)

				setData((prevState) => ({
					...prevState,
					items: data,
				}))

				setFilter((prevState) => ({
					...prevState,
					categories: availableCategories,
				}))
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	// Filter data
	useEffect(() => {
		let itemsFiltered = data.items

		// if (filter.category) {
		// 	itemsFiltered = itemsFiltered.filter((item) => item.release_date.includes(filter.category))
		// }

		if (filter.search) {
			itemsFiltered = itemsFiltered.filter((item) => item.title && item.title.toLowerCase().includes(filter.search.toLowerCase()))
		}

		console.log(itemsFiltered)

		setData((prevState) => ({
			...prevState,
			itemsFiltered: itemsFiltered,
		}))
	}, [data.items, filter])

	// Get current pages data
	useEffect(() => {
		let itemsCurrent = data.itemsFiltered.slice(0, filter.currentPage * perPage)

		setData((prevState) => ({
			...prevState,
			itemsCurrent: itemsCurrent,
		}))

		setTimeout(() => {
			setIsLoading(false)
			setIsLoadingPage(false)
		}, 200)
	}, [data.itemsFiltered, filter.currentPage])

	// Change category
	const handleChangeCategory = (e) => {
		setIsLoading(true)
		setFilter((prevState) => ({
			...prevState,
			category: e.target.value,
		}))
	}

	// Serach typing
	const handleChangeSearch = (e) => {
		setSearch(e.target.value)
	}

	// Search submit
	const handleSearch = (e) => {
		setIsLoading(true)
		e.preventDefault()
		setFilter((prevState) => ({
			...prevState,
			search: search,
		}))
	}

	// Load more
	const handleLoadMore = () => {
		setIsLoadingPage(true)
		setFilter((prevState) => ({
			...prevState,
			currentPage: filter.currentPage + 1,
		}))
	}

	return (
		<div className={styles.Press}>
			<Helmet>
				<title>
					{t('Press ')} - {t('Central Market')}
				</title>
			</Helmet>

			<PageTitle title={t("Press")} />
			<div className="container-boxed pb-16">

				<div className={`colored ${styles.filter}`}>
					{/* <div className={styles.category}>
						<Select value={filter.category} onChange={handleChangeCategory} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
							<MenuItem value="">{t('All category')}</MenuItem>
							{filter.categories.map((category, index) => {
								return (
									<MenuItem key={index} value={category}>
										{category}
									</MenuItem>
								)
							})}
						</Select>
					</div> */}
					<div className={styles.search}>
						<form onSubmit={handleSearch}>
							<FormControl>
								{/* Input search */}
								<TextField onChange={handleChangeSearch} className={styles.half} id="search" label={t('Search')} />
								<IconButton type="submit" aria-label={t('Search')} onClick={handleSearch}>
									<img src="/images-local/ico-search.svg" alt="" />
								</IconButton>
							</FormControl>
						</form>
					</div>
				</div>

				{/* Items */}
				{isLoading ? (
					<Loader />
				) : (
					<div className={styles.items}>
						{/* return <Card key={index} title={item.title} date={item.release_date} isHidePic={true} url={`/press/${item.slug}`} /> */}
						{data.itemsCurrent.map(({ filename, title, release_date }, index) => (
							<Card key={index} title={title} date={release_date} isHidePic url={filename}/>
						))}
					</div>
				)}

				{/* Load mroe */}
				{isLoadingPage ? (
					<Loader />
				) : filter.currentPage * perPage >= data.itemsFiltered.length || isLoading ? (
					''
				) : (
					<div onClick={handleLoadMore} className={styles.btnLoadMore}>
						<ButtonUnderline>{t('Load more press')}</ButtonUnderline>
					</div>
				)}
			</div>
		</div>
	)
}

export default Press
