import { TextField, IconButton } from "@material-ui/core";
import { useTranslation } from 'react-i18next'
import styles from './SearchBar.module.scss'

const SearchBar = ({ value, handleChangeSearch }) => {
	const { t, i18n } = useTranslation('')

    const handleChange = (e) => {
        handleChangeSearch(e.target.value)
    }

    return (
        <div className={styles.search}>
            <TextField value={value} onChange={handleChange} className={styles.half} id="search" label={t('Search')} />
            <IconButton type="submit" aria-label={t('Search')}>
                <img src="/images-local/ico-search.svg" alt="" />
            </IconButton>
        </div>
    )
}

export default SearchBar;