// Core
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// Styles
import styles from './ButtonBack.module.scss'

// i18n
import { useTranslation } from 'react-i18next'

// Utils
import getQueryString from '../../utils/getQueryString'

const ButtonBack = (props) => {
	const { t } = useTranslation()
	const navigate = useNavigate();
	const [disablenav, setDisablenav] = useState(getQueryString('disablenav'))
	const url = props.url || ''
	let setting = { to: url }
	if(props.goBack) {
		setting = {
			onClick: () => navigate(-1)
		}
	}
	return (
		<>
			{disablenav ? (
				''
			) : (
				<Link className={styles.button} {...setting}>
					<div className={styles.arrow}></div>
					{t('Back')}
				</Link>
			)}
		</>
	)
}

export default ButtonBack
