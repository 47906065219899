// Core
import { useEffect, useRef, useState } from 'react'
// import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet-async'

import style from './Revitalization.module.scss'

// Components
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import SVG from 'react-inlinesvg'
import NavArrow from '../../components/NavArrow/NavArrow'

// Swiper
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// i18n
import { useTranslation } from 'react-i18next'

// Axios
// import axios from 'axios'
import axios from '../../config/axios'

SwiperCore.use([Navigation, Pagination])

const Revitalization = (props) => {
	// const { query } = useParams()
	const { t, i18n } = useTranslation()

	const tabContainerEl = useRef()
	const tabDropContainerEl = useRef()

	const [data, setData] = useState({
		tabList: [],
		tabDetails: [],
		tabDetailsarr: {},
		arrowimgdown: '/images-local/ico-arrow-down.svg',
		arrowimgup: '/images-local/ico-arrow-up.svg',
		arrowimgplus: '/images-local/ico-plus.svg',
		arrowimgmin: '/images-local/ico-min.svg',
		dpdownStatus: {
			status: false,
			arrow: '/images-local/ico-arrow-down.svg',
		},
		activeTabItem: {},
		activeLinkid: '',
		activeTabItemBeforeChange: {},
	})

	// useEffect(() => {
	// 	console.log("🚀 ~ file: Revitalization.js ~ line 68 ~ Revitalization ~ query", props,query)
	// 	let pathname = props.location.pathname;
	// 	let pathname_standard = props.match.url;
	// 	pathname_standard = pathname_standard.replace(/[\[\]]/g, '\\$&');
	// 	console.log("🚀 ~ file: Revitalization.js ~ line 68 ~ useEffect ~ pathname", pathname,' | standard: ' ,pathname_standard)
	// 	const regex = new RegExp('(?<=\\'+pathname_standard+'\\/)([a-zA-Z\'-]*)'),
	// 		   results = regex.exec(pathname);
	// 	console.log("🚀 ~ file: Revitalization.js ~ line 71 ~ useEffect ~ results", results[0])

	// 	setData((prevState) => ({
	// 		...prevState,
	// 		activeLinkid: results[0],
	// 	}))

	// },[query])

	// Fetch data
	useEffect(() => {
		axios.get(i18n.language + '/revitalization.json').then((result) => {
			const res = result.data
			console.log('revitalization res ~ ', res)
			console.log('🚀 ~ file: Revitalization.js ~ line 73 ~ axios.get ~ props.location', props.location.pathname)

			let pathname = props.location.pathname
			if (pathname) {
				let pathname_standard = props.match.url
				pathname_standard = pathname_standard.replace(/[\\[\]]/g, '\\$&')
				console.log('🚀 ~ file: Revitalization.js ~ line 81 ~ axios.get ~ pathname_standard', props.match.url)

				// const regex = new RegExp("(?<=\\" + pathname_standard + ")(?>=\/)([a-zA-Z'-]*)"),
				// regex_results = regex.exec(pathname)

				const regex = new RegExp('(?:\\' + pathname_standard + '/)'),
					regex_results = pathname.replace(regex, '')

				const hash = props.location.hash

				console.log('🚀 ~ file: Revitalization.js ~ line 71 ~ useEffect ~ results', regex_results, hash)
				if (regex_results) {
					const selestedTab = res.tab.find((item) => item.link_id === regex_results)
					console.log('🚀 ~ file: Revitalization.js ~ line 87 ~ axios.get ~ selestedTab', selestedTab)
					if (selestedTab) {
						res.tab.forEach((item) => {
							if (item.link_id === regex_results) {
								item.active = true

								if (item.subtab) {
									let hash_name = hash.replace('#', '')
									console.log('🚀 ~ file: Revitalization.js ~ line 91 ~ axios.get ~ hash', hash_name)
									let selestedSubTab = selestedTab.subtab.find((item) => item.link_id === hash_name)
									if (!selestedSubTab) {
										console.log('no sub tab')
										selestedSubTab = selestedTab.subtab[0]
									}

									item.subtab.forEach((sun_item) => {
										if (sun_item.id === selestedSubTab.id) {
											sun_item.active = true
										} else {
											sun_item.active = false
										}
									})
								}
							} else {
								item.active = false
								if (item.subtab) {
									item.showSubTab = false
									item.subtab.forEach((item) => (item.active = false))
								}
							}
						})
					}
				}
			}

			let activeContentItem = res.tab.find((item) => item.active === true)
			let activesubTabItem = ''
			if (activeContentItem.subtab) {
				activesubTabItem = activeContentItem.subtab.find((item) => item.active === true)
			}

			console.log('🚀 ~ file: Revitalization.js ~ line 48 ~ axios.get ~ activeContentItem', activeContentItem)
			console.log('🚀 ~ file: Revitalization.js ~ line 48 ~ axios.get ~ activesubTabItem', activesubTabItem)
			setData((prevState) => ({
				...prevState,
				tabList: res.tab,
				tabDetailsarr: res.tab_content,
				tabDetails: res.tab_content[activesubTabItem ? activesubTabItem.id : activeContentItem.id],
				activeTabItem: activeContentItem,
			}))

			var resizeEvent = window.document.createEvent('UIEvents')
			resizeEvent.initUIEvent('resize', true, false, window, 0)
			window.dispatchEvent(resizeEvent)
		})
	}, [i18n.language, props])

	const changeTab = (targetid, type) => {
		console.log('🚀 ~ file: Revitalization.js ~ line 62 ~ changeTab ~ targetid', targetid)

		// Keep orginal tab
		setData((prevState) => ({
			...prevState,
			activeTabItemBeforeChange: data.activeTabItem,
		}))

		let tabList = []
		data.tabList.forEach((item, index) => {
			if (item.id === targetid) {
				item.active = true
				if (item.subtab) {
					item.showSubTab = true
				}
				setData((prevState) => ({
					...prevState,
					activeTabItem: item,
				}))
			} else {
				item.active = false
				if (item.subtab) {
					item.showSubTab = false
					item.subtab.forEach((item) => (item.active = false))
				}
			}
			tabList.push(item)
		})

		setData((prevState) => ({
			...prevState,
			tabList: tabList,
		}))

		let updateDetails = data.tabDetailsarr[targetid]
		if (updateDetails) {
			setData((prevState) => ({
				...prevState,
				tabDetails: updateDetails,
			}))

			if (type && type === 'mb') {
				toggleDropDown()
			}
		}
	}

	const changeSubTab = (e, targetid, type) => {
		e.stopPropagation()

		let updateTab = []
		data.tabList.forEach((item, index) => {
			if (item.active === true) {
				item.subtab.forEach((subItem, index) => {
					if (subItem.id === targetid) {
						subItem.active = true
					} else {
						subItem.active = false
					}
				})
				item.showSubTab = false
			}
			updateTab.push(item)
		})

		const updateDetails = data.tabDetailsarr[targetid]

		setData((prevState) => ({
			...prevState,
			tabList: updateTab,
			tabDetails: updateDetails,
		}))

		if (type && type === 'mb') {
			toggleDropDown()
		}
	}

	const subtabItems = (subTabList, urlId) => {
		return subTabList.map((item, index) => (
			<Link key={'subitem' + index} className={`${style.subtabItem} ${item.active === true ? style.subtabItemActive : ''}`} to={props.match.url + '/' + urlId + '#' + item.link_id}>
				{item.name}
			</Link>
		))
	}
	const arrowImg = (status) => {
		if (status && status === true) {
			return <SVG className={style.svgIcon} src={data.arrowimgup} alt="" />
		} else {
			return <SVG className={style.svgIcon} src={data.arrowimgdown} alt="" />
		}
	}
	const arrowImgMb = (status) => {
		if (status && status === true) {
			return <SVG className={style.svgIcon} src={data.arrowimgmin} alt="" />
		} else {
			return <SVG className={style.svgIcon} src={data.arrowimgplus} alt="" />
		}
	}

	const assignNav = (swiper, nav) => {
		swiper.params.navigation = {
			...swiper.params.navigation,
			prevEl: nav.prev.current,
			nextEl: nav.next.current,
		}
		swiper.navigation.update()
	}

	let imgSwiperNav = {
		prev: useRef(),
		next: useRef(),
	}

	const tabDetailsImage = (content_images) => {
		if (content_images.length < 1) {
			return
		}
		if (content_images.length > 1) {
			return (
				<div className={style.tabDetailsImgSwiper}>
					<div className={style.imgSwiperNav}>
						<NavArrow ref={imgSwiperNav} />
					</div>
					<div className={style.imageSwiper}>
						<Swiper
							className={style.imageSwiperPadding}
							loop={true}
							spaceBetween={30}
							slidesPerView={1}
							navigation={{ prevNav: '', nextEl: '' }}
							pagination={{
								modifierClass: 'normal-pagination-',
								clickable: true,
								renderBullet: function (index, className) {
									return `<span class="${className}"></span>`
								},
							}}
							onInit={(swiper) => {
								assignNav(swiper, imgSwiperNav)
							}}
						>
							{content_images.map((imgItem, i) => {
								return (
									<SwiperSlide key={i} className={style.i}>
										<img className={style.sliderDetailsImg} src={imgItem.img_path} alt="" />
										<div className={style.sliderRecap}>{parse(imgItem.recap)}</div>
									</SwiperSlide>
								)
							})}
						</Swiper>
					</div>
				</div>
			)
		} else {
			return (
				<div>
					<img className={style.detailsImg} src={content_images[0].img_path} alt="" />
					<div className={style.imgRecap}>{parse(content_images[0].recap)}</div>
				</div>
			)
		}
	}

	const tabNum = 100 / data.tabList.length
	const tabItem = data.tabList.map((item, index) =>
		item.subtab && item.subtab.length > 0 ? (
			<div key={index} className={`${item.active === true ? style.active : ''} ${style.tabItem}`} style={{ width: tabNum + '%' }} onClick={() => changeTab(item.id)}>
				<div className={style.tabIndex}> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </div>
				<div className={`${style.tabName} ${item.showSubTab && item.showSubTab > 0 ? style.haveSubItem : ''}`}>
					<span>{item.name}</span> {item.subtab && item.subtab.length > 0 ? arrowImg(item.showSubTab) : ''}
				</div>
				<div className={`${style.subTabContainer} ${item.showSubTab && item.showSubTab === true ? style.subTabactive : ''}`}>
					{item.subtab && item.subtab.length > 0 ? subtabItems(item.subtab, item.link_id) : ''}
				</div>
			</div>
		) : (
			<Link key={index} className={`${item.active === true ? style.active : ''} ${style.tabItem}`} style={{ width: tabNum + '%' }} to={props.match.url + '/' + item.link_id}>
				<div className={style.tabIndex}> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </div>
				<div className={`${style.tabName} ${item.showSubTab && item.showSubTab > 0 ? style.haveSubItem : ''}`}>
					<span>{item.name}</span> {item.subtab && item.subtab.length > 0 ? arrowImg(item.showSubTab) : ''}
				</div>
				<div className={`${style.subTabContainer} ${item.showSubTab && item.showSubTab === true ? style.subTabactive : ''}`}>
					{item.subtab && item.subtab.length > 0 ? subtabItems(item.subtab, item.link_id) : ''}
				</div>
			</Link>
		)
	)

	const tabSubItemDropDown = (sudItemList) => {
		return sudItemList.map((item, index) => (
			<div className={`${style.subDropDownitem} ${item.active === true ? style.dpactive : ''}`} onClick={(e) => changeSubTab(e, item.id, 'mb')}>
				<p className={style.suditemName}>{item.name}</p>
			</div>
		))
	}

	const tabItemDropDown = data.tabList.map((item, index) => (
		<div key={index} className={`${item.active === true ? style.active : ''} ${style.tabItem}`} onClick={() => changeTab(item.id, 'mb')}>
			<div className={style.tabItemDetails}>
				<p className={style.tabIndex}> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </p>
				<p className={style.tabName}> {item.name} </p>
			</div>
			<div className={style.sudDroDownico}>{item.subtab && item.subtab.length > 0 ? arrowImgMb(item.showSubTab) : ''}</div>
			<div className={`${style.subDropDown} ${item.showSubTab && item.showSubTab === true ? style.subTabDpactive : ''}`}>
				{item.subtab && item.subtab.length > 0 ? tabSubItemDropDown(item.subtab) : ''}
			</div>
		</div>
	))

	const tabDetailsSection = data.tabDetails.map((item, index) => (
		<div key={index} className={`${style.contentDetails} ${!item.title || item.title === '' ? style.notitle : ''}`}>
			<div className={style.contentDetailsTitle}>{item.title}</div>
			<div className={style.contentDetailsContent}>{parse(item.content)}</div>
			<div className={`${style.imageSection} ${item.content_images[0].img_path === '' ? style.noimage : ''}`}>{tabDetailsImage(item.content_images)}</div>
		</div>
	))

	const toggleDropDown = () => {
		console.log('toggleDropDown')

		let dptest = {
			status: data.dpdownStatus.status === true ? false : true,
			arrow: data.dpdownStatus.status === true ? data.arrowimgdown : data.arrowimgup,
		}

		setData((prevState) => ({
			...prevState,
			dpdownStatus: dptest,
		}))
	}

	const handleDocumentClick = (e) => {
		const isTabItemClicked = isDescendant(tabContainerEl.current, e.target.parentNode) || isDescendant(tabDropContainerEl.current, e.target.parentNode)
		const isSubTabShowing = data.activeTabItem.showSubTab
		let activeSubtab
		data.tabList[1].subtab.map((item) => {
			if (item.active) {
				activeSubtab = item.id
			}
			return true
		})

		console.log('isTabItemClicked', isTabItemClicked)

		if (!isTabItemClicked) {
			console.log('restore')

			if (data.dpdownStatus.status) {
				let dptest = {
					status: false,
					arrow: data.arrowimgdown,
				}

				setData((prevState) => ({
					...prevState,
					dpdownStatus: dptest,
				}))
			}

			if (isSubTabShowing) {
				changeTab(data.activeTabItemBeforeChange.id)
				if (activeSubtab) changeSubTab(e, activeSubtab)
			}
		}
	}

	const isDescendant = function (parent, child) {
		if (child) {
			let node = child.parentNode
			while (node) {
				if (node === parent) {
					return true
				}

				// Traverse up to the parent
				node = node.parentNode
			}
		}

		// Go up until the root but couldn't find the `parent`
		return false
	}

	useEffect(() => {
		document.addEventListener('touchend', handleDocumentClick)
		document.addEventListener('click', handleDocumentClick)

		return () => {
			document.removeEventListener('touchend', handleDocumentClick)
			document.removeEventListener('click', handleDocumentClick)
		}
		// eslint-disable-next-line
	}, [data])

	return (
		<div className={style.revitalization}>
			<Helmet>
				<title>
					{t('Central Market Revitalization')} - {t('Central Market')}
				</title>
			</Helmet>
			<div className={style.bg}>
				<div className="bg-elements overflow-hidden">
					<img src="/images-local/block-3-h.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-s.png" style={{ opacity: 0 }} alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-ts-bb.png" alt="" />
					<img src="/images-local/block-bs-tb.png" alt="" />
					<img src="/images-local/CM_KV_Play-version.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					<img src="/images-local/cloud.png" alt="" />
					<img src="/images-local/block-ts-bb.png" alt="" />
					<img src="/images-local/block-tb-bs.png" alt="" />
					<img src="/images-local/block-bb-ts.png" alt="" />
				</div>
			</div>
			<div className="container-boxed half-padding">
				<div className={style.container}>
					<div className={style.title}>
						<TextBorderFill>
							{t('Central Market')}
							<br />
							{t('Revitalization')}
						</TextBorderFill>
					</div>
					<div className={style.containerTabBar} ref={tabContainerEl}>
						{tabItem}
					</div>
					<div className={style.containerTabDropdown} ref={tabDropContainerEl}>
						<div className={style.activeItem} onClick={() => toggleDropDown()}>
							<div className={`${style.activeItemDetails} ${data.dpdownStatus.status === true ? style.active : ''}`}>
								<p className={style.tabIndex}>{data.activeTabItem.item} </p>
								<p className={style.tabName}>{data.activeTabItem.name} </p>
							</div>
							<img src={data.dpdownStatus.arrow} alt="" />
						</div>
						<div className={`${style.dropdownList} ${data.dpdownStatus.status === true ? style.active : ''}`}>{tabItemDropDown}</div>
					</div>
					<div className={style.containerTabDetails}>{tabDetailsSection}</div>
				</div>
			</div>
		</div>
	)
}

export default Revitalization
