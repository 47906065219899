import SVG from 'react-inlinesvg'

import styles from './Icon.module.scss'

export const CalendarIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.8252 2.7627H3.5752C3.12646 2.7627 2.7627 3.12646 2.7627 3.5752V19.8252C2.7627 20.2739 3.12646 20.6377 3.5752 20.6377H19.8252C20.2739 20.6377 20.6377 20.2739 20.6377 19.8252V3.5752C20.6377 3.12646 20.2739 2.7627 19.8252 2.7627Z"
				stroke="#C8215D"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M16.5752 1.1377V4.3877" stroke="#C8215D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M6.8252 1.1377V4.3877" stroke="#C8215D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M2.7627 7.6377H20.6377" stroke="#C8215D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M8.04395 11.6997H10.8877L9.2627 13.731C9.52989 13.731 9.79296 13.7968 10.0286 13.9228C10.2643 14.0487 10.4652 14.2308 10.6137 14.4529C10.7622 14.6751 10.8536 14.9304 10.8798 15.1963C10.9061 15.4622 10.8664 15.7305 10.7642 15.9774C10.662 16.2242 10.5005 16.4421 10.2941 16.6117C10.0876 16.7813 9.84248 16.8973 9.58045 16.9496C9.31841 17.0018 9.04754 16.9887 8.79181 16.9112C8.53608 16.8338 8.3034 16.6945 8.11435 16.5057"
				stroke="#C8215D"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M13.3252 12.9185L14.9502 11.6997V16.981" stroke="#C8215D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	)
}
export const LeftArrowIcon = () => {
	return (
		<svg width="24" height="5" viewBox="0 0 24 5" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M23 3.6178H4V4.82373H23V3.6178Z" fill="#C8215D" />
			<path d="M0 4.82373H4V0L0 4.82373Z" fill="#C8215D" />
		</svg>
	)
}
export const RightArrowIcon = () => {
	return (
		<svg width="24" height="5" viewBox="0 0 24 5" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 3.6178H19V4.82373H0V3.6178Z" fill="#C8215D" />
			<path d="M23 4.82373H19V0L23 4.82373Z" fill="#C8215D" />
		</svg>
	)
}

export const InlineTextIcon = ({ text, link, icon, primaryBtn }) => {
	const iconImage = (icon) => {
		switch (icon) {
			case 'calendar':
				return 'calendar-without-date';
			default:
				return icon;
		}
	};
	return (
		<div className={`${styles.iconText} ${primaryBtn ? styles.primary : ''}`}>
			<SVG className={styles.stroke} src={`/images-local/ico-${iconImage(icon)}.svg`} alt="" />
			{link ?
				<a className="links" target="_blank" href={link}>
					{text}
				</a>
			:
			text
			}
		</div>
	)
}