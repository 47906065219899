// Core
import React, { useState, useRef, useEffect } from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

// i18n
import { useTranslation } from 'react-i18next'

// Validator
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import TextareaValidator from '../../components/TextareaValidator/TextareaValidator'
import CheckboxValidatorElement from '../../components/CheckboxValidatorElement/CheckboxValidatorElement'
import KeyboardDatePickerValidator from '../../components/KeyboardDatePickerValidator/KeyboardDatePickerValidator'

// Components
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import ButtonUnderline from '../../components/ButtonUnderline/ButtonUnderline'
import Loader from '../../components/Loader/Lodaer'

// jQuery
import $ from 'jquery'

// Axios
import axios from 'axios'

import { API } from '../../config/constants'

// Utils
import getSession from '../../utils/getSession'

const BuskingPermitValidation = (props) => {
        const { t, i18n } = useTranslation()
        const { initData } = props

        const [isLoading, setIsLoading] = useState(false)
        const [errorMessage, setErrorMessage] = useState(false)
        const [initSubmited, setInitSubmited] = useState(false)

        const recaptchaRef = useRef()
        const [reCapChaLnag, setReCapChaLnag] = useState(null)

        // const formRef = useRef(null)
        const [formSubmitRef, setFormSubmitRef] = useState(null)

        const [formData, setFormData] = useState({ english_name_1st: '', email_1st: '', permit_code: '' })
        const handleChangeFormData = (event) => {
            setFormData((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value,
            }))
        }

        const handleForgetPermit = () => {
            props.onForget()
        }

        const handleSubmit = async(event) => {
            /* const re_token = await recaptchaRef.current.executeAsync()
            recaptchaRef.current.reset() */

            setIsLoading(true)
            setErrorMessage(null)

            event.preventDefault()
            console.log(formData)

            // console.log('token: ', token)

            const data = {...formData }

            axios
                .post(API.busking.permitValidate, data, { headers: { 'Accept-Language': i18n.language } })
                .then((response) => {
                    console.log(response)
                    setIsLoading(false)
                    if (response.data.status_code === 200) {
                        console.log(response.data.record[0])
                        sessionStorage.setItem('permit_code', response.data.record[0].permit_code)
                        sessionStorage.setItem('token', response.data.record[0].token)
                        props.onSuccess(formData)
                    } else {
                        setErrorMessage(response.data.errmsg)
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    setErrorMessage(t(error.toJSON().message))
                })
        }

        const scrollToError = () => {
            $([document.documentElement, document.body]).animate({
                    scrollTop: $('.Mui-error').eq(0).parent().offset().top - $('[class*=Header_nav_]').eq(0).height(),
                },
                1000
            )
        }

        const handleSampleData = () => {
            const sampleData = {
                english_name_1st: '12121212',
                email_1st: 'jackylee336@yahoo.com',
                permit_code: 'BP-FMAOCG',
            }

            console.log(sampleData)

            setFormData((prevState) => ({
                ...prevState,
                ...sampleData,
            }))
        }

        useEffect(() => {
            if (initData && formSubmitRef && !initSubmited) {
                // console.log('initData: ', initData, formSubmitRef)
                setFormData(initData)

                setTimeout(() => {
                    formSubmitRef.click()
                }, 100)

                setInitSubmited(true)
            }
        }, [initData, formSubmitRef, initSubmited])

        useEffect(() => {
            const lang = i18n.language
                // console.log(i18n.language)
            if (lang) {
                let reCapLang = ''
                switch (lang) {
                    case 'en':
                        reCapLang = 'en'
                        break

                    case 'tc':
                        reCapLang = 'zh-HK'
                        break

                    case 'sc':
                        reCapLang = 'zh-CN'
                        break

                    default:
                        reCapLang = 'en'
                        break
                }

                // console.log({lang, reCapLang})
                setReCapChaLnag(reCapLang)
            }
        }, [i18n.language])

        return ( <
            section className = { `permit-verification ${isLoading ? 'loading' : ''}` } > {
                window.location.href.includes('www.centralmarket.hk') || window.location.href.includes('www-staging.centralmarket.hk') ? (
                    ''
                ) : getSession('devtool') === 'on' ? ( <
                    div className = "w-full border border-primary border-dashed p-10" >
                    <
                    div className = "text-primary mb-2" > Dev tool: < /div> <
                    a className = "inline-block border border-primary text-primary transition-all duration-400 py-2 px-5 cursor-pointer hover:bg-primary hover:text-white"
                    onClick = { handleSampleData } >
                    Autofill <
                    /a> <
                    /div>
                ) : (
                    ''
                )
            } <
            ValidatorForm onSubmit = { handleSubmit }
            onError = { scrollToError } >
            <
            div className = "title" > { t('Permit verification') } < /div> <
            div className = "fields" >
            <
            div className = "row boxed" >
            <
            div className = "field half" >
            <
            TextValidator label = { t('Permit No.') + ' * ' }
            onChange = { handleChangeFormData }
            name = "permit_code"
            value = { formData.permit_code }
            validators = {
                ['required'] }
            errorMessages = {
                [t('Required Field')] }
            fullWidth / >
            <
            /div> <
            /div> <
            div className = "row boxed" >
            <
            div className = "field half" >
            <
            TextValidator label = { t('Main applicant name in english') + ' * ' }
            onChange = { handleChangeFormData }
            name = "english_name_1st"
            value = { formData.english_name_1st }
            validators = {
                ['required'] }
            errorMessages = {
                [t('Required Field')] }
            fullWidth / >
            <
            /div> <
            /div>

            <
            div className = "row boxed" >
            <
            div className = "field half" >
            <
            TextValidator label = { t('Main applicant’s Registered email') + '*' }
            onChange = { handleChangeFormData }
            name = "email_1st"
            value = { formData.email_1st }
            validators = {
                ['required', 'isEmail'] }
            errorMessages = {
                [t('Required Field'), t('Invalid information')] }
            fullWidth / >
            <
            /div> <
            /div>

            {
                /* <ReCAPTCHA
                						ref={recaptchaRef}
                						size="invisible"
                						sitekey={process.env.REACT_APP_RECAPCHA_SITE_KEY||''}
                						hl={reCapChaLnag}
                					/> */
            }

            <
            div className = "row my-6" >
            <
            a className = "cursor-pointer uppercase"
            onClick = { handleForgetPermit } >
            <
            ButtonUnderline > { t('Forget my permit no.') } < /ButtonUnderline> <
            /a> <
            /div> {
                errorMessage ? < div className = "row my-6 text-primary" > { errorMessage } < /div> : ''} <
                    /div> {
                        isLoading ? ( <
                            Loader / >
                        ) : ( <
                            SubmitButton text = { t('Next') }
                            ref = {
                                (el) => {
                                    setFormSubmitRef(el)
                                }
                            }
                            />
                        )
                    } <
                    /ValidatorForm> <
                    /section>
            )
        }

        export default BuskingPermitValidation