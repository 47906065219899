import styles from './TextLoop.module.scss'

//i18n
import { useTranslation } from 'react-i18next'

const TextLoop = (props) => {
	const { i18n } = useTranslation()
	const hashtag = {
		tc: '#中環街市#CentralMarket#PlaygroundForAll#親動融#Approachable#Energetic#Gregarious',
		sc: '#中环街市#CentralMarket#PlaygroundForAll#亲动融#Approachable#Energetic#Gregarious',
		en: '#CentralMarket#PlaygroundForAll#Approachable#Energetic#Gregarious',
	}

	const textArray = hashtag[i18n.language].split('#')
	textArray.shift()

	return (
		<div className={styles.sectionDiv}>
			<div className={styles.message}>
				{textArray.map((text) => {
					return <span className={styles.messageText}>#{text}</span>
				})}
			</div>
			<div className={styles.message}>
				{textArray.map((text) => {
					return <span className={styles.messageText}>#{text}</span>
				})}
			</div>
		</div>
	)
}

export default TextLoop
