// Core
import { Helmet } from 'react-helmet-async'

// Styles
import styles from './ComingSoon.scss'

//i18n
import { useTranslation } from 'react-i18next'

import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'

const ComingSoon = () => {
	let { t } = useTranslation()
	return (
		<div>
			<div className="container-boxed">
				<div className="coming-soon-page-title font-bold uppercase relative leading-tight text-primary">
					{t("Coming Soon")}
				</div>
			</div>
		</div>
	)
}

export default ComingSoon
