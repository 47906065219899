import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import changeLanguage from '../utils/changeLanguage'

var localStorageAvailable = function localStorageAvailable() {
	var hasLocalStorageSupport = null;
    if (hasLocalStorageSupport !== null) return hasLocalStorageSupport;

    try {
      hasLocalStorageSupport = window !== 'undefined' && window.localStorage !== null;
      var testKey = 'i18next.translate.boo';
      window.localStorage.setItem(testKey, 'foo');
      window.localStorage.removeItem(testKey);
    } catch (e) {
      hasLocalStorageSupport = false;
    }

    return hasLocalStorageSupport;
};

const lngDetector = new LanguageDetector()
lngDetector.addDetector({
  name: 'customDetector',
  cacheUserLanguage(lng, options) {
  },
  lookup(options){
	const supportedLanguages = ['en', 'tc', 'sc'];
	const defaultLang = 'tc';
	const langMap = {
		"en-US" : "en",
		"en-GB" : "en",
		"en" : "en",
		"chi-HK" : "tc",
		"chi" : "tc",
		"tc" : "tc",
		"chi-CN" : "sc",
		"CN" : "sc",
		"sc" : "sc"
	}

	// path
	if (typeof window !== 'undefined') {
		var language = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
		if (language instanceof Array && typeof language[0] === 'string') {
			if(language[0].replace('/', '') in langMap){
				return language[0].replace('/', '');
			}
		}
	}

	// local storage
	if (localStorageAvailable()) {
        var lng = window.localStorage.getItem(options.lookupLocalStorage);
        if (lng && lng in langMap){
			return langMap[lng]
		}
	}

	// navigator
	if (typeof navigator !== 'undefined') {

		if (navigator.language && langMap.hasOwnProperty(navigator.language)) {
			return langMap[navigator.language];
		}

		if (navigator.languages) {
			// chrome only; not an array, so can't use .push.apply instead of iterating
			for (var i = 0; i < navigator.languages.length; i++) {
				if (langMap.hasOwnProperty(navigator.languages[i])) {
					return langMap[navigator.languages[i]];
				}
			}
		}

		if (navigator.userLanguage && langMap.hasOwnProperty(navigator.userLanguage)) {
			return langMap[navigator.userLanguage];
		}
	}

	return defaultLang;
  }
})

i18n.on('languageChanged', function (lng) {
	changeLanguage(lng)
})

i18n.use(Backend)
	.use(initReactI18next)
	.use(lngDetector)
	.init({
		//lng : pathLang == 'en' ?  'sc' :langMap.hasOwnProperty(navigator.language) ? langMap[navigator.language] : "en",
		ns: ['translation', 'menu', 'month'],
		nsSeparator: ":",
		keySeparator: false,
		fallbackLng: 'tc',
		whitelist: ['en', 'tc', 'sc'],
		preload: ['en', 'tc', 'sc'],
		detection: {
			// order and from where user language should be detected
			order: ['customDetector'],

			// keys or params to lookup language from
			// lookupQuerystring: 'lng',
			//lookupCookie: 'i18next',
			lookupLocalStorage: 'i18nextLng',
			// lookupSessionStorage: 'i18nextLng',
			// lookupFromPathIndex: 0,
			checkWhitelist: true,

			// cache user language on
			// caches: ['localStorage', 'cookie'],
			// excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

			// optional htmlTag with lang attribute, the default is:
			htmlTag: document.documentElement,

			// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
			cookieOptions: { path: '/', sameSite: 'strict' },
		},
		interpolation: {
			escapeValue: false,
		},
		backend: {
			// for all available options read the backend's repository readme file
			loadPath: '/data/locales/{{lng}}/{{ns}}.json',
		},
	})

export default i18n
