// Core
import React from 'react'
import SVG from 'react-inlinesvg'
// Styles
import './Image.scss'

const Image = ({ src, mobileSrc="", alt, fullHeight }) => {
	return (
		<div className={`component-image ${fullHeight ? 'fullHeight' : ''}`}>
			<div className="pic">
				<img className={mobileSrc ? 'desktop-img' : ''} src={src} alt={alt} />
				{mobileSrc && <img className="mobile-img" src={mobileSrc} alt={alt} />}
				<div className="cover"></div>
			</div>
		</div>
	)
}

export const SVGImage = ({ src }) => {
	return (
		<>
			<SVG src={src} />
		</>
	)
}


export default Image
