import { forwardRef } from "react"
import { useTranslation } from 'react-i18next'

import styles from './ButtonReset.module.scss'

const ButtonReset = forwardRef(({ onClick }, ref) => {

    const { t } = useTranslation()

    const handleClick = () => {
        if(onClick) {
            onClick()
        }
    }

    return (
        <button onClick={handleClick} ref={ref} className={styles.resetContainer}>
            <img src="/images-local/ico-reset.svg" />
            <span className={styles.label}>{t('Clear filter')}</span>
        </button>
    )
})

export default ButtonReset