import React from 'react'

//i18n
import { useTranslation } from 'react-i18next'

const DetectIE = () => {
	const { t } = useTranslation()

	function msieversion() {
		var ua = window.navigator.userAgent
		var msie = ua.indexOf('MSIE ')

		// eslint-disable-next-line
		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
			// If Internet Explorer, return version number
			document.body.style.overflow = 'hidden'
			return true
		}

		return false
	}

	console.log('msieversion', msieversion())
	return (
		<>
			{msieversion() ? (
				<div className="fixed w-full h-full flex justify-center items-center z-50 text-white" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
					{t('ie_wran')}
				</div>
			) : (
				''
			)}
		</>
	)
}

export default DetectIE
