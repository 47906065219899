// Core
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import style from './Location.module.scss'

// Components
import PageTitle from '../../components/PageTitle/PageTitle'
import SVG from 'react-inlinesvg'

// i18n
import { useTranslation } from 'react-i18next'

// Axios
// import axios from 'axios'
import axios from '../../config/axios'

// Google Map
import GoogleMapReact from 'google-map-react';

const Location = () => {
	const { i18n, t } = useTranslation()

	const mapLang = i18n.language === 'sc' ? 'zh' : i18n.language

	const [data, setData] = useState({
		tabList: [],
		tabDetails: {
			image: '',
			box: [],
		},
		arrowimgdown: '/images-local/ico-arrow-down.svg',
		arrowimgup: '/images-local/ico-arrow-up.svg',
		dpdownStatus: {
			status: false,
			arrow: '/images-local/ico-arrow-down.svg',
		},
		activeTabItem: {},
		googleMapMarkers: [],
		transportType: {},
	})

	// Fetch data
	useEffect(() => {
		axios.get(i18n.language + '/location.json').then((result) => {
			const res = result.data
			console.log(res)
			const activeContentItem = res.tab.find((item) => item.active === true)
			console.log('item test', activeContentItem)
			setData((prevState) => ({
				...prevState,
				address: res.info_data.address,
				time: res.info_data.time,
				detail: res.info_data.detail,
				tabList: res.tab,
				tabContentarr: res.tab_content,
				tabDetails: res.tab_content[activeContentItem.id],
				activeTabItem: activeContentItem,
				googleMapMarkers: res.google_map_markers,
				transportType: res.transport_type
			}))

			const getGoogleMapMarketsList = () => {
				let googleMapMarkers = []
				data.googleMapMarkers.forEach((item, index) => {
					googleMapMarkers.push(item)
				})
				setData((prevState) => ({
					...prevState,
					googleMapMarkers: googleMapMarkers,
				}))
			}
		})
	}, [i18n.language])

	const transportationList = [
		{
			id: 'bus',
			title: data.transportType.bus,
			content: 'Bus detail',
		},
		{
			id: 'mtr',
			title: data.transportType.mtr,
			content: 'MTR detail',
		},
		{
			id: 'tram',
			title: data.transportType.tram,
			content: 'Tram detail',
		},
		{
			id: 'taxi',
			title: data.transportType.taxi,
			content: 'Taxi detail',
		},
	]

	const [currentTransportationId, setCurrentTransportationId] = useState('all')

	useEffect(() => {
		console.log('currentTransportationId=' + currentTransportationId);
		console.log(document.querySelectorAll('.gmap_pin_' + currentTransportationId));

		for (let pin of document.querySelectorAll('[class*="gmap_pin_"]')) {
			if(pin.className.includes('home')) continue;

			if (pin.className.includes(currentTransportationId))
				pin.parentNode.parentNode.style.display = "block";
			else
				pin.parentNode.parentNode.style.display = "none";
		}

	}, [currentTransportationId]);

	/* Google Map */
	const GoogleMapMarkerComponent = ({ markerIcon, order, typeClass }) => (
		<div style={{
			color: 'white',
			padding: '0',
			display: 'inline-flex',
			textAlign: 'center',
			alignItems: 'start',
			justifyContent: 'end',
			transform: 'translate(-50%, -100%)'
		}}>
			<div className={typeClass} style={{ visibility: 'visible', }}>
				<SVG preProcessor={(code) => code.replace("pin_order", order)} src={markerIcon} alt="" uniquifyIDs={true} />
			</div>
		</div>
	);


	const googleMapMarkerItems = data.googleMapMarkers.map((item, index) => (
		<GoogleMapMarkerComponent
			key={index}
			lat={item.lat}
			lng={item.lng}
			markerIcon={item.icon}
			order={item.order}
			typeClass={"gmap_pin_" + item.type}
		/>
	))

	function googleMapLang() {
		switch (localStorage.getItem('i18nextLng')) {
			case 'sc':
				return 'zh-CN';
			case 'tc':
				return 'zh-HK';
			case 'en':
				return 'en';
			default:
				return 'en';
		}
	}

	const GoogleMapProps = {
		center: {
			lat: 22.284222263720785,
			lng: 114.1558540115173
		},
		zoom: 18,
		apiKey: "AIzaSyDj4bNN0lYQgnczhqu6UKimQSxrULKiXCI"
	};

	const handleGoogleMapApiLoaded = (map, maps) => {

		//console.log(data.googleMapMarkers);
		// use map and maps objects
		if (map) {
			map.setOptions({
				disableDefaultUI: true,
			});

			initGoogleMapZoomControl(map, maps);
		}
	};

	const initGoogleMapZoomControl = (map, maps) => {
		document.querySelector(".zoom-control-in").onclick = function () {
			map.setZoom(map.getZoom() + 1);
		};

		document.querySelector(".zoom-control-out").onclick = function () {
			map.setZoom(map.getZoom() - 1);
		};
		map.controls[maps.ControlPosition.LEFT_BOTTOM].push(
			document.querySelector(".zoom-control")
		);

		map.controls[maps.ControlPosition.LEFT_TOP].push(
			document.querySelector(".overMapcontrol")
		);

		var tabItemArr = document.querySelectorAll('[class*="Location_tabItem"]');
		if (tabItemArr.length > 0) {
			tabItemArr[0].click();
			tabItemArr[2].click();
		}
	};

	console.log('time: ', data)
	return (
		<div className={`page ${style.location}`}>
			<Helmet>
				<title>
					{t('Location & Transportation')} - {t('Central Market')}
				</title>
			</Helmet>
			<PageTitle title={t("location_transportation_title")} withBr />
			<div className="container-boxed">
				<section className={style.transportation}>
					<div className={style.desc} dangerouslySetInnerHTML={{ __html: t('location_transportation_desc') }} />
					<div className={style.content}>
						<div className={style.map}>
							<div style={{ display: "none" }}>
								<div className={"controls zoom-control"}>
									<button className={"zoom-control-in"} title="Zoom In">+</button>
									<button className={"zoom-control-out"} title="Zoom Out">−</button>
								</div>
								<div className={"overMapcontrol"}>
									<div className={style.overMapRemarks}>
										<div className={style.item}>
											<img className={style.itemIcon} src="/images-local/map-bus.svg" alt="" />
											<span className={style.itemTitle}>
												{data.transportType.bus}
											</span>
										</div>
										<div className={style.item}>
											<img className={style.itemIcon} src="/images-local/map-mtr.svg" alt="" />
											<span className={style.itemTitle}>
												{data.transportType.mtr}
											</span>
										</div>
										<div className={style.item}>
											<img className={style.itemIcon} src="/images-local/map-tram.svg" alt="" />
											<span className={style.itemTitle}>
												{data.transportType.tram}
											</span>
										</div>
										<div className={style.item}>
											<img className={style.itemIcon} src="/images-local/map-taxi.svg" alt="" />
											<span className={style.itemTitle}>
												{data.transportType.taxi}
											</span>
										</div>
									</div>
								</div>
							</div>
							<GoogleMapReact
								bootstrapURLKeys={{
									key: GoogleMapProps.apiKey, /* Google Map API KEY HERE */
									language: googleMapLang(),
									region: 'HK'
								}}
								defaultCenter={GoogleMapProps.center}
								defaultZoom={GoogleMapProps.zoom}
								yesIWantToUseGoogleMapApiInternals
								onGoogleApiLoaded={({ map, maps }) => handleGoogleMapApiLoaded(map, maps)}
							>
								{googleMapMarkerItems}
							</GoogleMapReact>
						</div>
						<div className={style.info}>
							<div className={style.tabs}>
								{transportationList.map((item) => {
									return (
										<a key={item.id} className={item.id === currentTransportationId ? style.active : ''} title={item.title} onClick={() => setCurrentTransportationId(item.id)}>
											<SVG className={style.stroke} src={`/images-local/ico-${item.id}.svg`} />
										</a>
									)
								})}
							</div>
							<div className={style.detail}>
								{currentTransportationId !== 'all' &&
									<div className={style.title}>
										{currentTransportationId}
									</div>
								}


								{data.googleMapMarkers.map((item) => {

									let neededType = currentTransportationId;

									if (currentTransportationId === 'all')
										neededType = 'bus';

									if (item.type === neededType) {
										let operatorList = item.operator.split('|');
										let routeList = item.route.split('|');

										let operatorRouteDiv = operatorList.map((r, index) => (
											<>
												<div className={style.operator}>{r}</div>
												<div className={style.route}>{routeList[index]}</div>
											</>
										));

										let positionStr = item.order === '' ? item.position : item.order + '. ' + item.position;

										return (
											<>
												<div className={style.order} dangerouslySetInnerHTML={{ __html: positionStr }}></div>
												{operatorRouteDiv}
											</>
										);
									}

									return null;

								})}


							</div>
						</div>
					</div>
				</section>



			</div>
		</div>
	)
}

export default Location
