// Core
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import classnames from 'classnames'
import style from './ConactUs.module.scss'

// Components
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'

// i18n
import { useTranslation } from 'react-i18next'

// Axios
// import axios from 'axios'
import axios from '../../config/axios'

const ContactUs = () => {
	const { i18n, t } = useTranslation()

	const mapLang = i18n.language === 'sc' ? 'zh' : i18n.language

	const [data, setData] = useState({
		arrowimgdown: '/images-local/ico-arrow-down.svg',
		arrowimgup: '/images-local/ico-arrow-up.svg',
		dpdownStatus: {
			status: false,
			arrow: '/images-local/ico-arrow-down.svg',
		},
	})

	// Fetch data
	useEffect(() => {
		axios.get(i18n.language + '/location.json').then((result) => {
			const res = result.data
			setData((prevState) => ({
				...prevState,
				address: res.info_data.address,
				time: res.info_data.time,
				detail: res.info_data.detail,
			}))
		})
	}, [i18n.language])

	return (
		<div className={`${style.location} page`}>
			<Helmet>
				<title>
					{t('Contact us')} - {t('Central Market')}
				</title>
			</Helmet>
			<div className={style.bg}>
				<div className="bg-elements overflow-hidden">
					{/* <img src="/images-local/block-3-h.png" alt="" /> */}
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					{/* <img src="/images-local/block-s.png" alt="" /> */}
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
					{/* <img src="/images-local/block-ts-bb.png" alt="" /> */}
					{/* <img src="/images-local/block-bs-tb.png" alt="" /> */}
					<img src="/images-local/CM_KV_Play-version.png" alt="" />
					<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
				</div>
			</div>
			<div className="ly--outer--container relative">
				<div className="">
					<div className={style.container}>
						<div className={style.topContainer}>
							<div className={style.containerInfo}>
								<div className={style.contactInfo}>
									<div className={style.title}>
										<TextBorderFill>{t('Contact Us')}</TextBorderFill>
									</div>
									<div className={style.locationList}>

										<div className={style.item}>
											<img className={style.itemIcon} src="/images-local/ico-location.svg" alt="" />
											<p className="item-detail uppercase">
												<a
													href={`https://www.google.com/maps/place/Central+Market/@22.283944,114.1533591,17z/data=!3m1!4b1!4m5!3m4!1s0x3404007cb1ddff95:0x10263cb611def1fe!8m2!3d22.2839207!4d114.1555679?hl=${mapLang}`}
													target="_blank"
													rel="noreferrer"
												>
													{data.address}
												</a>
											</p>
										</div>

										{data.time &&
											<div className={style.item}>
												<img className={style.itemIcon} src="/images-local/ico-time.svg" alt="" />
												<div className="item-detail">
													{data.time.map((item, index) => <p className={style.timeItem} key={index}>{item.name}<span className={classnames("col_red", style.time)}>{item.open}</span></p>)}
												</div>
											</div>
										}
									</div>

									{data.detail &&
										<div className={style.itemInfoList}>
											{data.detail.map((item, index) =>
												<div key={index} className={style.itemInfo}>
													<span className={`text-xs labels ${style.label}`}>{item.name}</span>
													{item.tel &&
														<div className={classnames(style.item, style.inner)}>
															<img className={style.itemIcon} src="/images-local/ico-phone.svg" alt="" />
															<p className="item-detail">
																<a href={`tel: ${item.tel.replace(' ', '')}`}>{item.tel}</a>
															</p>
														</div>
													}

													{item.email &&
														<div className={classnames(style.item, style.inner)}>
															<img className={style.itemIcon} src="/images-local/ico-email.svg" alt="" />
															<p className="item-detail mail">
																<a className="links" href={`mailto: ${item.email}`}>{item.email}</a>
															</p>
														</div>
													}
												</div>
											)}
										</div>
									}
								</div>
							</div>
							<div className={style.fullCmImage} style={{ backgroundImage: `url('/images-local/find-us.png')` }}></div>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactUs
