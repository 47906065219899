import { useState, useEffect, useRef } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import SVG from 'react-inlinesvg'
import parse from 'html-react-parser'
import SwiperCore, { EffectFade, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import PageTitle from '../../components/PageTitle/PageTitle'
import ButtonBack from '../../components/ButtonBack/ButtonBack'
import { SliderArrow } from '../../components/NavArrow/NavArrow'
import Image from '../../components/Image/Image'
import { GeneralCard } from "../../components/Card/Card"
import TextBorderFill from "../../components/TextBorderFill/TextBorderFill"
import FloorPlan from '../../components/FloorPlan/FloorPlan'

// Utils
import getData from '../../utils/getData'
import getPeriod from '../../utils/getPeriod'

import styles from './ShopDetail.module.scss'

SwiperCore.use([EffectFade, Navigation])

const ShopDetail = () => {
    const { id } = useParams()
    const { t, i18n } = useTranslation('')
    const [data, setData] = useState({
        all: [],
        current: {},
        related: [],
        availableSlider: []
    })

    console.log('i18n', i18n)
    const [gallery, setGallery] = useState(null)
    const [map, setMap] = useState(null)
    let sliderNav = {
        prev: useRef(),
        next: useRef(),
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getData('shop')
                setData((prevState) => ({
                    ...prevState,
                    all: data,
                }))
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [i18n.language])

    // Filter data
    useEffect(() => {
        const current = data.all.find((el) => el['slug'] === id)
        if (current) {
            getPeriod(current.start_date, current.end_date)

            let related

            related = data.all.filter((el) => {
                if (el['slug'].toLowerCase() !== current['slug'].toLowerCase() && el['category'] === current['category']) {
                    if (el.description != null && el.description != "") {
                        return true
                    }
                }
                return false
            })

            if (related.length < 5) {
                var otherRelated = data.all.filter((el) => {
                    if (el['slug'].toLowerCase() !== current['slug'].toLowerCase()) {
                        if (el.description != null && el.description != "") {
                            return true
                        }
                    }
                    return false
                })
                related = related.concat(otherRelated)
            }

            if (related.length > 5) {
                related = related.slice(0, 5)
            }

            setData((prevState) => ({
                ...prevState,
                current: current,
                related: related,
            }))

            if (current?.slider)
                setGallery(current.slider[0].image)
        }
    }, [data.all, id, i18n.language])

    const htmldecode = (str) => {
        var txt = document.createElement('textarea')
        txt.innerHTML = str
        return txt.value
    }

    const assignNav = (swiper, nav) => {
        swiper.params.navigation = {
            ...swiper.params.navigation,
            prevEl: nav.prev.current,
            nextEl: nav.next.current,
        }
        swiper.navigation.update()
        swiper.update(true)
        swiper.slideToLoop(0, 0)
    }

    const handleChangeGallery = (src) => {
        setGallery(src)
    }

    return (

        <div className="page">
            <Helmet>
                <title>
                    {data.current['title'] || ''} - {t('Central Market')}
                </title>
            </Helmet>
            <div className={`${styles.main}`}>
                <div className={styles.ButtonBack}>
                    <ButtonBack goBack />
                </div>
                <div className={styles.mainContent}>
                    <div className={styles.bg}>
                        <img src="/images-local/block-3-h.png" alt="" />
                        <img className="bg-cloud" src="/images-local/cloud.png" alt="" />
                        <img src="/images-local/block-tb-bs.png" style={{ opacity: 0 }} alt="" />
                        <img src="/images-local/block-bs-tb.png" alt="" />
                    </div>
                    {data.current &&
                        <div className="container-boxed much-padding">
                            <div className={styles.content}>
                                <div className={styles.detail}>
                                    <div className={styles.info}>
                                        <div className={styles.title} style={{ fontFamily: i18n.language === 'tc' ? `'Roboto', 'Noto Sans TC', 'Noto Sans SC'` : `'Roboto', 'Noto Sans SC', 'Noto Sans TC'` }}>{data.current['title']}</div>
                                        <ul className={styles.details}>
                                            {data && data.current && data.current['office_hour'] ? (
                                                <li className={styles.time}>
                                                    <SVG className={styles.fill} src="/images-local/ico-time.svg" alt="" />
                                                    {data.current['office_hour']}
                                                </li>
                                            ) : ('')}
                                            {data && data.current && data.current['shop_no'] ? (
                                                <li className={styles.location}>
                                                    <SVG className={styles.fill} src="/images-local/ico-location.svg" alt="" />
                                                    {data.current['shop_no']}
                                                </li>
                                            ) : ('')}
                                            {data.current['telephone'] ? (
                                                <li className={styles.phone}>
                                                    <SVG className={styles.fill} src="/images-local/ico-phone.svg" alt="" />
                                                    {(data.current['telephone']) ? data.current['telephone'].match(/.{1,4}/g).join(' ') : ''}
                                                </li>
                                            ) : (
                                                ''
                                            )}

                                            {data.current['web_site_url'] ? (
                                                <li className={styles.link}>
                                                    <SVG className={styles.fill} src="/images-local/ico-link.svg" alt="" />
                                                    <a target="_blank" rel="noreferrer" href={data.current['web_site_url']}>
                                                        {data.current['web_site_url']}
                                                    </a>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                        {data.current['category'] ? (
                                            <div className={styles.list}>
                                                <label>{t('Category')}</label>
                                                <div className={styles.category}>
                                                    {data.current['category']}
                                                </div>
                                            </div>
                                        ) : null}
                                        {/* {data.current['other'] ? (
                                            <div className={styles.list}>
                                                <label>{t('Other')}</label>
                                                <div className={styles.category}>
                                                    {data.current['category']}
                                                </div>
                                            </div>
                                        ) : null} */}
                                    </div>
                                    <div className={styles.gallery}>
                                        <div className={styles.galleryPic}>
                                            <Image src={gallery} />
                                        </div>
                                        {data.current.slider && data.current.slider.length > 1 ?
                                            <div className={styles.slider}>
                                                <SliderArrow ref={sliderNav} isLeftRight />
                                                <Swiper
                                                    id="shop-detail-swiper"
                                                    speed={700}
                                                    spaceBetween={15}
                                                    slidesPerView={3}
                                                    pagination={false}
                                                    navigation={{ prevNav: '', nextEl: '' }}
                                                    onInit={(swiper) => {
                                                        assignNav(swiper, sliderNav)
                                                    }}
                                                /* onSlideChange={(swiper) => {
                                                    setActiveEventSlide(swiper.realIndex)
                                                }} */
                                                >
                                                    {data.current.slider.map(({ image }, i) => {
                                                        return (
                                                            <SwiperSlide key={i} onClick={() => handleChangeGallery(image)}>
                                                                <div className={styles.slideContainer}>
                                                                    <div className={styles.picContainer}>
                                                                        <Image src={image} />
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                    })}
                                                </Swiper>
                                            </div>
                                            : null}
                                    </div>
                                    {data && data.current && data.current['description'] ? <div className={styles.description}>
                                        <label>{t('About')}</label>
                                        <div>
                                            {parse(htmldecode(data.current['description']))}
                                        </div>
                                    </div> : ''}
                                </div>
                                <div className={styles.floor}>
                                    {/* <div className={styles.mapTitle}>{t('Location')}</div> */}
                                    <FloorPlan shop={data.current?.shop_no} name={data.current?.title} floor={data.current?.floor}/>
                                </div>

                                {data.related && data.related.length > 0 ?
                                    (
                                    <section className={styles.related}>
                                        <div className={styles.content}>
                                            <div className={styles.title}>
                                                <TextBorderFill>{t('You might also like')}</TextBorderFill>
                                            </div>
                                            <div className={styles.items}>
                                                {data.related.map((item, index) => {
                                                    return <GeneralCard isPromotion small key={index} title={item['title']} pic={item['logo']} location={item['shop_no']} url={`/shop-detail/${item.slug}`} />
                                                })}
                                            </div>
                                        </div>
                                    </section>
                                    ) : ("")
                                    }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ShopDetail