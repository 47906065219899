// Core
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import './styles/global.scss'

//i18n
import './config/i18n'
import { useTranslation } from 'react-i18next'

// Google Analytics
import ReactGA from 'react-ga'

// Material-ui
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

// Components
import Header from './components/Header/HeaderV2'
import Footer from './components/Footer/Footer'
import CookieBar from './components/CookieBar/CookieBar'
import DetectIE from './components/DetectIE/DetectIE'
import DevTool from './components/DevTool/DevTool'
import ButtonBackToTop from './components/ButtonBackToTop/ButtonBackToTop'

// Utils
import ScrollToTop from './utils/scrollToTop'
import getQueryString from './utils/getQueryString'
import getSession from './utils/getSession'

// Pages
import NotFound from './pages/NotFound/NotFound'
import Home from './pages/Home/HomeV2'
import EventHighlight from './pages/EventHighlight/EventHighlight'
import EventDetail from './pages/EventDetail/EventDetailV2'
import EventArchive from './pages/EventHighlight/EventArchive'
import Happenings from './pages/WhatsOn/Happenings'
import VenueBookingRegister from './pages/VenueBookingRegister/VenueBookingRegister'
import HeritageTour from './pages/OurHeritage/HeritageTour'
import HeritageTourRegister from './pages/HeritageTourRegister/HeritageTourRegister'
import ContactUs from './pages/ContactUs/ContactUs'
import Location from './pages/Location/Location'
import Revitalization from './pages/Revitalization/Revitalization'
import RevitalizationV2 from './pages/Revitalization/RevitalizationV2'
import VenueApplications from './pages/VenueApplications/VenueApplications'
import History from './pages/History/History'
import CentralHub from './pages/CentralHub/CentralHub'
import MembersOffer from './pages/MembersOffer/MembersOffer'
import MembersOfferDetail from './pages/MembersOfferDetail/MembersOfferDetail'
import ShopOffer from './pages/ShopOffer/ShopOffer'
import ShopOfferDetail from './pages/ShopOfferDetail/ShopOfferDetail'
import FAQ from './pages/FAQ/FAQ'
import Privacy from './pages/Privacy/Privacy'
import Disclaimer from './pages/Disclaimer/Disclaimer'
import Sitemap from './pages/Sitemap/Sitemap'
import FloorDirectoryMap from './pages/FloorDirectoryMap/FloorDirectory'
import ShopRestaurantDirectory from './pages/ShopRestaurantDirectory/ShopRestaurantDirectory'
import ShopDetail from './pages/ShopDetail/ShopDetailV2'
import StagingGroundRegister from './pages/StagingGroundRegister/StagingGroundRegister'
import StagingGroundThankYou from './pages/StagingGroundThankYou/StagingGroundThankYou'
import Search from './pages/Search/Search'
import Press from './pages/Press/Press'
import PressDetail from './pages/PressDetail/PressDetail'
import Cookie from './pages/Cookie/Cookie'
import Redirect from './pages/Redirect/Redirect'
import BuskingRegister from './pages/BuskingRegister/BuskingRegister'
import Vision from './pages/Vision/Vision'
import AboutChinaChemGroup from './pages/AboutChinaChemGroup/AboutChinaChemGroup'
import PreservedPillar from './pages/PreservedPillar/PreservedPillar'
import FormThankYouPage from './pages/FormThankYouPage/FormThankYouPage'
import PetFriendly from './pages/PetFriendly/PetFriendly'
import VrTour from './pages/VrTour/VrTour'
import Itinerary from './pages/Itinerary/Itinerary'
import DesktopApp from './pages/DesktopApp/DesktopApp'
import ComingSoon from './pages/ComingSoon/ComingSoon'

import GlobalSetting from './hooks/GlobalSetting'

// Splitting
import 'splitting/dist/splitting.css'
import 'splitting/dist/splitting-cells.css'

// Gsap
import { TimelineMax, TweenLite, Power1 } from 'gsap/all'

//init GA
ReactGA.initialize(process.env.REACT_APP_GA_UA_CODE, { debug: process.env.REACT_APP_DEBUG === 'true' })

function usePageViews(base) {
	let location = useLocation()
	useEffect(() => {
		const path = `${base}${location.pathname}${location.hash}${location.search}`
		ReactGA.pageview(path)
	}, [location, base])
}

const RouteSwitch = ({ mode, base }) => {
	usePageViews(base)
	return (
		<Routes>
			<Route index path="/" exact element={<Home />} />
			{/* dummy home page for different version preview*/}
			<Route path="/home-preview" exact element={<Home />} />
			<Route path="/search/:query" element={<Search />} />
			<Route path="/search" element={<Search />} />
			{/* <Route path="/event-calendar/:id" element={<EventDetail} />
			<Route path="/event-calendar/page/:page" element={<EventCalendar} /> */}
			{/* <Route path="/event-calendar" element={<EventCalendar} /> */}
			<Route path="/happenings" element={<Happenings />} />
			<Route path="/event/:id" element={<EventDetail/>} />
			<Route path="/promotion/:id" element={<EventDetail/>} />
			{/* <Route path="/event-calendar" element={<EventCalendar/>} /> */}
			<Route path="/event-highlights" element={<EventHighlight/>}/>
			<Route path="/event-archive/:id" element={<EventArchive/>} />
			<Route path="/contact-us" element={<ContactUs/>} />
			<Route path="/location-transportation" element={<Location/>} />
			<Route path="/history-timeline" element={<History/>} />
			<Route path="/central-hub" element={<CentralHub/>} />
			<Route path="/floor-directory" element={<FloorDirectoryMap/>} />
			<Route path="/shops-directory" element={<ShopRestaurantDirectory/>} />
			<Route path="/shop-detail/:id" element={<ShopDetail/>} forceRefresh={true} />
			{/* <Route path="/conservation" render={(props) => <PreservedPillar section={'conservation'} {...props} />} /> */}
			<Route path="/green" render={(props) => <PreservedPillar section={'green'} {...props} />} />
			<Route path="/press/:id" element={<PressDetail/>} />
			<Route path="/press" element={<Press/>} />
			{/* <Route path="/ura-news" element={<URANews} /> */}
			<Route path="/heritage-revitalisation" element={<Redirect page="/revitalization" />} />
			<Route path="/our-concept" element={<Vision/>} />
			<Route path="/about-chinachem-group" element={<AboutChinaChemGroup />} />
			{/* <Route path="/faq" element={<FAQ/>} /> */}
			<Route path="/privacy-policy" element={<Privacy/>} />
			<Route path="/disclaimer" element={<Disclaimer/>} />
			<Route path="/cookie" element={<Cookie/>} />
			<Route path="/sitemap" element={<Sitemap/>} />
			<Route path="/whats-new" element={<Redirect/>} />
			{/* <Route path="/about-central-market-revitalisation" element={<Redirect/>} /> */}
			<Route path="/heritage" element={<Redirect/>} />
			<Route path="/gallery" element={<Redirect/>} />
			<Route path="/faq" element={<Redirect/>} />
			<Route path="/zh" element={<Redirect page='/tc' />} />

			<Route path="/thankyou/:type" element={<FormThankYouPage/>} />
			<Route path="/thankyou" element={<FormThankYouPage/>} />
			<Route path="/privileges/:id" element={mode === 'internal-wip-p2-preview' ? <MembersOfferDetail/> : <NotFound/>} />
			<Route path="/privileges" element={mode === 'internal-wip-p2-preview' ? <MembersOffer/> : <NotFound/>} />
			<Route path="/shop-offer/:id" element={<ShopOfferDetail/>} />
			<Route path="/shop-offer" element={<ShopOffer/>} />
			<Route path="/heritage-tour" element={<HeritageTour/>} />
			<Route path="/vr-tour" element={<VrTour/>} />
			<Route path="/itinerary" element={<Itinerary/>} />
			{/* <Route path="/revitalization" element={<Redirect page="/conservation"/>}/> */}
			<Route path="/revitalization" element={<RevitalizationV2/>} />
			<Route path="/conservation" element={<PreservedPillar section={'conservation'} />} />
			<Route path="/blueprint" element={<PreservedPillar section={'blueprint'} />} />
			<Route path="/venue-applications" element={<VenueApplications/>} />
			<Route path="/venue-booking" element={<VenueBookingRegister/>} />
			<Route path="/staging-ground/register" element={<StagingGroundRegister/>} />
			<Route path="/staging-ground/thankyou" element={<StagingGroundThankYou/>} />
			<Route path="/busking/register" element={<BuskingRegister/>} />

			<Route path="/pet-friendly" element={<PetFriendly/>} />
			<Route path="/app" element={<DesktopApp/>} />

			<Route path="/coming-soon" element={<ComingSoon/>} />
			<Route element={<NotFound/>} />
		</Routes>
	)
}

RouteSwitch.defaultProps = {
	mode: '',
	base: '',
}

const App = () => {
	let { t, i18n } = useTranslation()

	const [disablenav, setDisablenav] = useState('')
	const [devTool, setDevTool] = useState('')
	const [mode, setMode] = useState('')

	const theme = createMuiTheme({
		typography: {
			fontFamily: '"Roboto", "Noto Sans SC", "Noto Sans TC"',
		},
		palette: {
			primary: {
				main: '#C8215D',
			},
		},
	})

	useEffect(() => {
		const floatAnimation = () => {
			const tlCloud = new TimelineMax({ repeat: -1 })
			const el = '.bg-cloud'
			tlCloud
				.to(el, 3, {
					y: '-=30',
					x: '+=20',
					ease: Power1.easeInOut,
				})
				.to(el, 2, {
					y: '+=30',
					x: '-=20',
					ease: Power1.easeInOut,
				})
				.to(el, 3, { y: '-=20', ease: Power1.easeInOut })
				.to(el, 3, { y: '+=20', ease: Power1.easeInOut })
				.to(el, 3, { y: '-=50', ease: Power1.easeInOut })
				.to(el, 3, { y: '+=50', ease: Power1.easeInOut })
				.to(el, 3, { y: '-=30', ease: Power1.easeInOut })
				.to(el, 3, { y: '+=30', ease: Power1.easeInOut })
				.to(el, 2, { y: '-=30', ease: Power1.easeInOut })
				.to(el, 2, { y: '+=30', ease: Power1.easeInOut })
			TweenLite.to(tlCloud, 40, { ease: Power1.easeInOut })
		}
		floatAnimation()

		setDisablenav(getQueryString('disablenav'))

		setDevTool(getSession('devtool'))
		setMode(getSession('mode'))

		if (window.location.href.includes('www.centralmarket.hk') || window.location.href.includes('www-staging.centralmarket.hk')) {
			setDevTool('')
			setMode('')
		}

		/* Handle Close of Dropdown MUI-Menu list */
		document.addEventListener('scroll', handleMuiMenuCloseDown)
	}, [])

	const handleMuiMenuCloseDown = (event) => {
		var muiMenuList = document.getElementsByClassName('MuiMenu-list')
		if (muiMenuList.length > 0) {
			var muiMenuListDiv = document.getElementsByClassName('MuiMenu-list')[0]
			if (muiMenuListDiv) muiMenuListDiv.parentElement.style.display = 'none'
		}
	}

	return (
		<Router
			basename={`/${i18n.language}`}
			onChange={() => {
				console.log('page onChange: ')
				window.scrollTo(0, 0)
			}}
			forceRefresh
		>
			<ScrollToTop />
			<ButtonBackToTop />
			<HelmetProvider>
				<Helmet>
					<title>{t('Central Market')}</title>
				</Helmet>
				<ThemeProvider theme={theme}>
					<DetectIE />
					<GlobalSetting>
						{devTool === 'on' ? <DevTool /> : ''}
						<div className="flex flex-col justify-between">
							{disablenav ? '' : <Header />}
							<div className="mb-auto">
								<RouteSwitch mode={mode} base={`/${i18n.language}`} />
							</div>
							{disablenav ? '' : <CookieBar />}
							{disablenav ? '' : <Footer />}
						</div>
					</GlobalSetting>
				</ThemeProvider>
			</HelmetProvider>
		</Router>
	)
}

export default App
