// Gsap
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const skewOnScroll = () => {
	let proxy = { skew: 0 },
		skewSetter = gsap.quickSetter('.skew', 'skewY', 'deg'),
		clamp = gsap.utils.clamp(-5, 5)

	ScrollTrigger.create({
		onUpdate: (self) => {
			let skew = clamp(self.getVelocity() / -300)
			if (Math.abs(skew) > Math.abs(proxy.skew)) {
				proxy.skew = skew
				gsap.to(proxy, {
					skew: 0,
					duration: 2,
					ease: 'power3',
					overwrite: true,
					onUpdate: () => skewSetter(proxy.skew),
				})
			}
		},
	})
	gsap.set('.skew', { transformOrigin: 'right center', force3D: true })
}

export default skewOnScroll
