import { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { find, includes, filter } from 'lodash'
import { createMuiTheme, FormControl, Select, TextField, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import MenuItem from '@material-ui/core/MenuItem'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';

import { LocalizedUtils } from '../EventCalendar/EventCalendar'
import PageTitle from '../../components/PageTitle/PageTitle'
import ButtonUnderline from '../../components/ButtonUnderline/ButtonUnderline'
import SearchBar from '../../components/SearchBar/SearchBar'
import { GeneralCard } from '../../components/Card/Card'
import ButtonReset from '../../components/ButtonReset/ButtonReset'

import getData from '../../utils/getData'

import styles from './EventHighlight.module.scss'

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersBasePicker: {
            container: {
                backgroundColor: '#FFF',
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    listItem: {
        minHeight: 35
    },
}));

const EventHighlight = () => {
    const PAGE_OFFSET = 9;
    const { t } = useTranslation(['translation', 'month'])
    const [keyword, setKeyword] = useState('')
    const [selectedYear, handleYearChange] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [iconYearOpen, setIconYearOpen] = useState(false)
    const months = useMemo(() => {
        const year = moment(selectedYear).format('YYYY')
        let array = [{ num: 1, name: 'January' }, { num: 2, name: 'February' }, { num: 3, name: 'March' }, { num: 4, name: 'April' }, { num: 5, name: 'May' }, { num: 6, name: 'June' }, { num: 7, name: 'July' }, { num: 8, name: 'August' }, { num: 9, name: 'September' }, { num: 10, name: 'October' }, { num: 11, name: 'November' }, { num: 12, name: 'December' }]
        if(year === '2021') {
            array = array.filter(({num}) => num >= 8)
        }
        return array
    }, [selectedYear])
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [list, setList] = useState([])
    const resetRef = useRef(null)
    const classes = useStyles()

    useEffect(async () => {
        async function fetchData() {
            try {
                const items = await getData('event_highlight')
                const today = moment();
                const filteredData = items.filter((item) => moment(item.event_date, 'YYYY-MM-DD').isBefore(today))
                setData(filteredData)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        let items = data
        if (keyword) {
            items = filter(items, (item) => item.event_name.toLowerCase().includes(keyword.toLowerCase()))
        }
        if (selectedYear) {
            const year = moment(selectedYear).format('YYYY')
            items = filter(items, ({ event_date }) => {
                const eventYear = moment(event_date, 'YYYY-MM-DD').year().toString()
                return year === eventYear
            })


            if (selectedMonth) {

                const month = ("0" + (selectedMonth.num)).slice(-2)
                items = filter(items, ({ id, event_date }) => {

                    const formattedEventDate = moment(event_date, 'YYYY-MM-DD')

                    const eventYear = formattedEventDate.year().toString()

                    let eventMonth = formattedEventDate.month() + 1

                    eventMonth = ("0" + eventMonth).slice(-2).toString()

                    return year === eventYear && month === eventMonth
                })
            }
        }

        if (!selectedYear && selectedMonth) {

            const month = ("0" + (selectedMonth.num)).slice(-2)
            items = filter(items, ({ id, event_date }) => {

                const formattedEventDate = moment(event_date, 'YYYY-MM-DD')

                let eventMonth = formattedEventDate.month() + 1

                eventMonth = ("0" + eventMonth).slice(-2).toString()

                return month === eventMonth
            })
        }
        setList(items)
    }, [data, keyword, selectedYear, selectedMonth, setList])

    const handleReset = useCallback(() => {
        if(resetRef.current) {
            handleYearChange(null)
            setSelectedMonth(null)
            setKeyword('')
        }
    }, [resetRef])

    const handleChangeSearch = (value) => {
        console.log('value', value)
        setKeyword(value)
    }

    const handleChangeMonth = useCallback((event) => {
        let month = find(months, { num: event.target.value })
        setSelectedMonth(month);
    });

    const handleLoadMore = () => {
        setPage(page + 1)
    }

    return (
        <div className="page-eventHighlight page">
            <Helmet>
                <title> {t("Event Highlights")} - {t('Central Market')} </title>
            </Helmet>
            <PageTitle title={t("Event Highlights")} />
            <div className="container-boxed">
                <div className={`colored ${styles.filter}`}>
                    <div className={styles.filterContainer}>
                        <div className={`${styles.selector} ${styles.year}`}>
                            {<svg className={`MuiSvgIcon-root MuiSelect-icon ${!!iconYearOpen ? 'MuiSelect-iconOpen' : ''}`} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>}
                                <ThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={LocalizedUtils} >
                                    <DatePicker
                                        disableToolbar
                                        views={["year"]}
                                        variant="inline"
                                        label={t('Year')}
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                        minDate={new Date("2021")}
                                        maxDate={new Date()}
                                        onOpen={() => setIconYearOpen(true)}
                                        onClose={() => setIconYearOpen(false)}
                                        autoOk
                                    />

                            </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </div>
                        <div className={styles.selector}>
                            <FormControl>
                                <InputLabel id="month-select-label">{t('Month')}</InputLabel>
                                <Select labelId="month-select-label" value={selectedMonth ? selectedMonth.num : null} onChange={handleChangeMonth} fullWidth>
                                    <MenuItem className={classes.listItem} value={null}></MenuItem>
                                    {months.map(({ num, name }, index) => {
                                        return (
                                            <MenuItem key={index} value={num}>
                                                {t(`month:${name}`)}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <SearchBar handleChangeSearch={handleChangeSearch} />
                    </div>
                    <ButtonReset onClick={handleReset} ref={resetRef}/>
                </div>
                <div className={styles.list}>
                    {list.slice(0, PAGE_OFFSET * page).map(({ id, event_name, event_date, event_time, image, event_highlight_detail, ...props }, index) => (
                        <GeneralCard
                            key={id}
                            id={id}
                            title={event_name}
                            pic={image}
                            url={`/event-archive/${id}`}
                            isPromotion
                            small />
                    ))
                    }
                </div>
                {list.slice(0, PAGE_OFFSET * page).length < list.length &&
                    <ButtonUnderline handleClick={handleLoadMore} hasParent>{t('Load more events')}</ButtonUnderline>
                }
            </div>
        </div>
    )
}

export default EventHighlight