import styles from './ButtonUnderline.module.scss'

const ButtonUnderline = ({ className, handleClick, hasParent, children }) => {
	const setting = {
		className: `${styles.button} ${className}`,
	}
	if(handleClick) {
		setting.onClick = handleClick
	}

	if(hasParent) {
		return (
			<div className={styles.parent}>
				<div {...setting}>{children}</div>
			</div>
		)
	}
	return <div {...setting}>{children}</div>
}

export default ButtonUnderline
