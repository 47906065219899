import { useLocation } from 'react-router-dom';
import styles from './PageTitle.module.scss';
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'

const PageTitle = ({ title, withBr, noPadding, left }) => {

    const { pathname } = useLocation()
    const overflow = ['/event-highlights', '/press'].includes(pathname)

    return (
        <div className={`${styles.pageTitle} ${noPadding ? styles.noPadding : ''} ${left ? styles.left : ''}`}>
            <TextBorderFill>

                {withBr ? <span dangerouslySetInnerHTML={{ __html: title }} />
                    :
                    title
                }
            </TextBorderFill>
            {!!!overflow ?
            <div className={styles.bg}>
                <img src="/images-local/block-3-h.png" alt="" />
                <img src="/images-local/block-tb-bs.png" alt="" />
                <img className="bg-cloud" src="/images-local/cloud_home.png" alt="" />
            </div>
            :
            null}
        </div>
    )
}

export default PageTitle