// Core
import { Helmet } from 'react-helmet-async'

// Styles
import styles from './DesktopApp.scss'

// Components
import SVG from 'react-inlinesvg'

//i18n
import { useTranslation } from 'react-i18next'

const DesktopApp = () => {
	const { t, i18n } = useTranslation('')

	return (
		<div className="desktopapp">
			<Helmet>
				<title>
					{t('app_download_header_title')} - {t('Central Market')}
				</title>
			</Helmet>
			<div className="container-boxed half-padding">
				<div className="container-app-info">
					<div className="container-app-image">
						<SVG
							preProcessor={(code) => code.replace("app_feature_points_and_rewards", t('app_feature_points_and_rewards'))
								.replace("app_feature_member_privilege", t('app_feature_member_privilege'))
								.replace("app_feature_event_registration", t('app_feature_event_registration'))
								.replace("app_feature_shop_directory", t('app_feature_shop_directory'))
								.replace("app-mobile-screen-locale", "app-mobile-screen-" + i18n.language)
							}
							src="/images-local/app-mobile-app-feature-new.svg"
							uniqueHash="box01"
							uniquifyIDs={true}
						/>
					</div>
					<div className="container-app-details">
						<div className="download-title">{t('app_download_title')}</div>
						<div className="download-desc" dangerouslySetInnerHTML={{ __html: t('app_download_desc') }} />
						<div className="download-qrcode">{t('app_download_qrcode')}</div>
						<div className="app-qr-code">
							<img src="/images-local/app-qr-code.svg" alt="" />
						</div>
						<div className="app-download-btn">
							<a className="href-button" href="https://centralclub.centralmarket.hk/download" target="_blank">
								{t('app_download_button')}
								<div className="arrow"></div>
							</a>
						</div>

					</div>
				</div>
				<div className="fish-shop-img">
					<img src="/images-local/app-fish_shop_lig2_1.png" alt="" />
				</div>
			</div>
		</div>
	)
}

export default DesktopApp
