import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/PageTitle/PageTitle'
import styles from './VenueApplication.module.scss';
import { map } from 'lodash';
import Image from '../../components/Image/Image';
import { Link } from 'react-router-dom';

const VenueApplications = () => {
    const { t, i18n } = useTranslation()

    const list = [{
        name: 'Venue Booking',
        imgSrc: '/images-local/venue-booking.png',
        mobileSrc: '/images-local/venue-booking-mobile.jpg',
        link: '/venue-booking'
    },{
        name: 'Staging Ground',
        imgSrc: '/images-local/staging-ground.png',
        mobileSrc: '/images-local/staging-ground-mobile.jpg',
        link: '/staging-ground/register'
    },/* {
        name: 'Busking Application',
        imgSrc: '/images-local/busking-application.png',
        mobileSrc: '/images-local/busking-application-mobile.jpg',
        link: '/busking/register'
    } */]

    return (
        <div className="page-venue-applications page">
			<Helmet>
				<title>
					{t('Venue applications')} - {t('Central Market')}
				</title>
			</Helmet>
            <PageTitle title={t("Venue applications")}/>
            <div className="container-boxed half-padding">
                <h3 className="text-center">{t('venue_application_title')}</h3>
                <div className={styles.description}>
                    <p>{t('venue_application_description')}</p>
                </div>
                <div className={styles.list}>
                    {map(list, ({name, imgSrc, mobileSrc, link}, index) => (
                        <div key={index} className={styles.child}>
                            <Link to={link}>
                                <Image src={imgSrc} mobileSrc={mobileSrc}/>
                                <div className={styles.name}>{t(name)}</div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default VenueApplications