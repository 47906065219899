// Core
import React, { useRef, useEffect } from 'react'
import {useMedia} from 'react-use';

// Gsap
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const SkewFade = (props) => {
	var containerEl = useRef()
	const isMobile = useMedia('(max-width: 768px)');

	useEffect(() => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: props.trigger ? props.trigger.current : '',
				toggleActions: 'play complete none none',
				force3D: true,
				// scrub: true,
				start: 'top center',
				// end: 'bottom 0%',
			},
		})

		const delay = props.delay / 1000

		switch (props.effect) {
			case 'skew-fade':
				// Skew
				const X = Math.random() * 10 + 10
				const Y = Math.random() * 10 + 10
				var random_boolean = Math.random() < 0.5
				if (random_boolean) {
					tl.from(containerEl.current, 1, { rotateX: X, rotateY: -Y }, '+=' + delay)
					// tl.to(containerEl.current, 1, { rotateX: -X, rotateY: -Y })
				} else {
					tl.from(containerEl.current, 1, { rotateX: -X, rotateY: -Y }, '+=' + delay)
					// tl.to(containerEl.current, 1, { rotateX: X, rotateY: -Y })
				}

				// Fade up
				tl.from(containerEl.current, 1, { y: 200, autoAlpha: 0 }, '-=1')
				break

			case 'fade-up':
				// Fade up
				tl.from(containerEl.current, 1, { y: 200, autoAlpha: 0 }, '+=' + delay)
				break

			default:
				// Face
				tl.from(containerEl.current, 1, { autoAlpha: 0 }, '+=' + delay)
				break
		}
		// eslint-disable-next-line
	}, [])

	return isMobile ? <div>{props.children}</div> : <div ref={containerEl}>{props.children}</div>
}

export default SkewFade
