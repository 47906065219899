import React from 'react'
import { Link } from 'react-router-dom'
import './CookieBar.scss'
import { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'

//i18n
import { useTranslation } from 'react-i18next'

const CookieBar = () => {
	const { t } = useTranslation()

	const [isAcceptCookie, setIsAcceptCookie] = useState(false)

	useEffect(() => {
		const userSetting = localStorage.getItem('isAcceptCookie')
		if (userSetting) {
			setIsAcceptCookie(userSetting)
		}
	}, [])

	const handleCloseCookieBar = () => {
		localStorage.setItem('isAcceptCookie', true)
		setIsAcceptCookie(true)
	}
	return (
		<>
			{isAcceptCookie ? (
				''
			) : (
				<div className="cookie-bar">
					<div>
						{t('cookie_bar_text')}{' '}
						<Link className="link" to="/cookie">
							{t('cookie_bar_cta')}
						</Link>
					</div>
					<div className="close" onClick={handleCloseCookieBar}>
						<SVG src="/images-local/close.svg" />
					</div>
				</div>
			)}
		</>
	)
}

export default CookieBar
