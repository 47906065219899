import React from "react";
import red from "@material-ui/core/colors/red";
import {
  Checkbox,
  Select,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  TextareaAutosize,
  MenuItem,
  Radio,
} from "@material-ui/core";
import {
  ValidatorComponent,
  TextValidator,
} from "react-material-ui-form-validator";
import $ from "jquery";
const red300 = red["500"];

const style = {
  right: 0,
  fontSize: "12px",
  color: red300,
  position: "absolute",
  marginTop: "-25px",
};

class RadioGroupValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {errorMessages,validatorListener, ...rest } = this.props;
    const { isValid } = this.state;
    if (isValid) {
      return (
        <div>
          <RadioGroup
            {...rest}
            ref={(r) => {
              this.input = r;
            }}
          >
            {this.props.children}
          </RadioGroup>

          <div className="validator-error-container">{this.errorText()}</div>
        </div>
      );
    }
    return (
      <div className="validator-error-class">
        <RadioGroup
          {...rest}
          ref={(r) => {
            this.input = r;
          }}
        >
          {this.props.children}
        </RadioGroup>

        <div className="validator-error-container">{this.errorText()}</div>
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div className="RadioGroupValidator-error validator-error Mui-error">
        {this.getErrorMessage()}
      </div>
    );
  }
}

export default RadioGroupValidator;
