import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import Button from '../../components/Button/Button'
import { useTranslation } from 'react-i18next'

import parse from 'html-react-parser'

const FormThankTouPage = (props) => {
	const { type } = useParams()
	const { t } = useTranslation('')
	const location = useLocation()
	const navigator = useNavigate()
	const [message, setMessage] = useState()

	const [buskingPermitData, setBuskingPermitData] = useState(null)

	useEffect(() => {
		if (location && location.state) {
			setBuskingPermitData(location.state.buskingPermitData)
		}
	}, [location])

	const handleRegAgain = () => {
		navigator('/busking/register', { buskingPermitData: buskingPermitData })
	}

	function htmldecode(str) {
		var txt = document.createElement('textarea')
		txt.innerHTML = str
		return txt.value
	}

	useEffect(() => {
		switch (type) {
			case 'forget-permit-code':
				setMessage(`
        ${t('Resent permit code application has been received. ')}
				<br />
				${t('You will be notified via email.')}
        `)
				break

			case 'busking-permit-approval':
				setMessage(`
        ${t('Your busking permit application has been received and is under review.')}
				<br />
				${t('Your permit number will be sent via email. Stay tuned for more information! ')}
        `)
				break

			default:
				setMessage(`
        ${t('Your application has been received and is under review.')}
				<br />
				${t('You will be notified via email. Stay tuned for more information!')}
        `)
				break
		}
	}, [type])

	return (
		<div className="container-boxed my-10 mb-96 ">
			<div className="text-primary font-medium text-3xl">{t('Thank You!')}</div>
			<div className="text-grey my-10">{parse(htmldecode(message))}</div>

			<div className="flex flex-col space-y-4 xs:space-x-6 xs:space-y-0 xs:flex-row">
				<Button text={t('Back to home')} />
				{buskingPermitData && <Button action={handleRegAgain}>{t('thank_you_book_other')}</Button>}
			</div>
		</div>
	)
}

export default FormThankTouPage
