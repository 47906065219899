/* eslint-disable */
import React from 'react'
import TextField from '@material-ui/core/TextField'
/* eslint-enable */
import { ValidatorComponent } from 'react-form-validator-core'
import { TextareaAutosize } from '@material-ui/core'
export default class TextareaValidator extends ValidatorComponent {
	renderValidatorComponent() {
		/* eslint-disable no-unused-vars */
		const {
			error,
			errorMessages,
			validators,
			requiredError,
			helperText,
			validatorListener,
			withRequiredValidator,
			containerProps,
			value,

			...rest
		} = this.props
		const { isValid } = this.state

		return (
			<div className={`TextareaValidator-container ${this.state.isValid ? '' : 'error'}`}>
				<TextareaAutosize {...rest} error={!isValid || error ? '': null} value={value} />
				{this.errorText()}
			</div>
		)
	}
	errorText() {
		const { isValid } = this.state

		if (isValid) {
			return null
		}

		return <div className="TextareaValidator-error validator-error Mui-error">{this.getErrorMessage()}</div>
	}
}
