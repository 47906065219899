import i18n from 'i18next'

const changeLanguage = (lng, fromSwitcher) => {
	const languages = i18n.options.whitelist
	const pathName = window.location.pathname.replace(/\/+$/, '').split('/')
	const pathHash = window.location.hash
	pathName.shift()

	if (pathName[0] === lng) return

	if (languages.includes(pathName[0])) {
		pathName[0] = lng
	} else {
		pathName.unshift(lng)
	}

	const newPathName = '/' + pathName.join('/')

	if (fromSwitcher) {
		window.location.replace(newPathName + pathHash)
	} else {
		window.history.replaceState(null, null, newPathName + pathHash)
	}
}

export default changeLanguage
