import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import styles from '../Header/Header.module.scss'

import { Dropdown } from 'rsuite'

const MenuDropDown = ({ title, childs, ...props }) => {

    const { t, i18n } = useTranslation();
    return (
        <Dropdown title={title} {...props}>
            {childs.map(({ name, link }, index) => (
                <Dropdown.Item key={index}>
                    <Link to={link} title={name}>{t(name)}</Link>
                </Dropdown.Item>
            ))}
        </Dropdown>
    )
}

export default MenuDropDown