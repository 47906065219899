import parse from 'date-fns/parse'

const getPeriod = (start, end) => {
	let startDate
	let endDate
	let period

	if (typeof start == 'string' && start.indexOf('/') > -1) {
		startDate = parse(start, 'dd/MM/yyyy', new Date())
		endDate = parse(end, 'dd/MM/yyyy', new Date())
	} else {
		startDate = new Date(start)
		endDate = new Date(end)
	}

	if (startDate.getTime() === endDate.getTime()) {
		return ('0' + endDate.getDate()).slice(-2) + '.' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '.' + endDate.getFullYear()
	}

	period =
		('0' + startDate.getDate()).slice(-2) +
		'.' +
		('0' + (startDate.getMonth() + 1)).slice(-2) +
		' - ' +
		('0' + endDate.getDate()).slice(-2) +
		'.' +
		('0' + (endDate.getMonth() + 1)).slice(-2) +
		'.' +
		endDate.getFullYear()
	return period
}

export default getPeriod
