// Core
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { Helmet } from 'react-helmet-async'
import parse from 'html-react-parser'
// import { Link } from 'react-router-dom'

// Styles
import styles from './PressDetail.module.scss'

// Components
// import Button from '../../components/Button/Button'
import ButtonBack from '../../components/ButtonBack/ButtonBack'
// import BannerCTA from '../../components/BannerCTA/BannerCTA'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
// import Card from '../../components/Card/Card'
// import Image from '../../components/Image/Image'
import DetailSlider from '../../components/DetailSlider/DetailSlider'

//i18n
import { useTranslation } from 'react-i18next'

// Utils
import getData from '../../utils/getData'
import getPeriod from '../../utils/getPeriod'
// import getPurePrice from '../../utils/getPurePrice'

const PressDetail = () => {
	const { id } = useParams()
	const { t, i18n } = useTranslation()

	const [data, setData] = useState({
		all: [],
		current: {},
		related: [],
	})

	// Fetch data
	useEffect(() => {
		async function fetchData() {
			try {
				const data = await getData('press_release')
				setData((prevState) => ({
					...prevState,
					all: data,
				}))
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	// Filter data
	useEffect(() => {
		const current = data.all.find((el) => el['slug'] === id)
		if (current) {
			getPeriod(current.start_date, current.end_date)

			let related

			related = data.all.filter((el) => el['category'] === current['category'] && el['slug'] !== current['slug']).slice(0, 3)

			if (!related.length) {
				related = data.all.slice(0, 3)
			}

			console.log(current)

			setData((prevState) => ({
				...prevState,
				current: current,
				related: related,
			}))
		}
	}, [data.all, id, i18n.language])

	function htmldecode(str) {
		var txt = document.createElement('textarea')
		txt.innerHTML = str
		return txt.value
	}

	return (
		<>
			<Helmet>
				<title>
					{data.current['title'] || ''} - {t('Central Market')}
				</title>
			</Helmet>
			<div className={styles.main}>
				<div className={styles.ButtonBack}>
					<ButtonBack url="/press" />
				</div>
				<section className={styles.mainContent}>
					<div className={styles.bg}>
						<img src="/images-local/block-3-h.png" alt="" />
						<img className="bg-cloud" src="/images-local/cloud.png" alt="" />
						<img src="/images-local/block-tb-bs.png" style={{ opacity: 0 }} alt="" />
						<img src="/images-local/block-bs-tb.png" alt="" />
					</div>
					<div className={styles.content}>
						<div className={styles.detail}>
							<div className={styles.info}>
								<div className={styles.sectionTitle}>
									<TextBorderFill>{t('Press')}</TextBorderFill>
								</div>
								<div className={styles.title}>{data.current['title']}</div>
								<div className={styles.date}>
									<SVG className={styles.fill} src="/images-local/ico-calendar-without-date.svg" alt="" />
									{data.current['release_date']}
								</div>
							</div>
							{data.current['description'] ? <div className={styles.description}>{parse(htmldecode(data.current['description']))}</div> : ''}

							{/* <div className={styles.pic}>
								<DetailSlider pagination-position="bottom" data={data.current['slider']} />
							</div> */}
						</div>
					</div>
				</section>
			</div>
		</>
	)
}

export default PressDetail
