import { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import SwiperCore, { EffectFade, Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import parse from 'html-react-parser'
import { htmldecode } from '../../utils/'
import Image from '../../components/Image/Image'
import Button from '../../components/Button/Button'
import ButtonBack from '../../components/ButtonBack/ButtonBack'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import { GeneralCard } from '../../components/Card/Card'
import { Video } from '../../components/VideoAutoPlay/VideoAutoPlay'
import { InlineTextIcon } from '../../components/Icon/Icon'
import NavArrow, { SliderArrow } from '../../components/NavArrow/NavArrow'

import styles from './EventDetail.module.scss'


import getData from '../../utils/getData'
import getPeriod from '../../utils/getPeriod'
import getPurePrice from '../../utils/getPurePrice'
import { getValidEventData } from './helpers/helps'

import { useGlobalSetting } from '../../hooks/GlobalSetting'

SwiperCore.use([EffectFade, Navigation, Pagination, Autoplay])

const EventDetail = () => {
	const location = useLocation()
	const navigate = useNavigate();
	const { id } = useParams()
	const { isEventPromotionPage, setIsEventPromotionPage } = useGlobalSetting()
	const { t, i18n } = useTranslation()
	let sliderNav = {
		prev: useRef(),
		next: useRef(),
	}
	const dataSet = ['promotion', 'event'];

	const [data, setData] = useState({
		all: [],
		current: {},
		related: [],
	})

	const isNum = (value) => {
		return /^\d+$/.test(value)
	}

	const assignNav = (swiper, nav) => {
		swiper.params.navigation = {
			...swiper.params.navigation,
			prevEl: nav.prev.current,
			nextEl: nav.next.current,
		}
		swiper.navigation.update()
		swiper.update(true)
		swiper.slideToLoop(0, 0)
	}

	useEffect(() => {
		async function fetchData() {
			let array = []
			dataSet.forEach(async (key) => {
				try {
					const item = await getData(key)
					array = [...array, ...item]
					setData((prevState) => ({ ...prevState, all: array }))
				} catch (error) {
					console.log(error)
				}
			})
		}
		fetchData()
		setIsEventPromotionPage(true)
	}, [i18n.language])

	// Filter data
	useEffect(() => {
		const current = data.all.find((el) => el['slug'] === id)

		//data filter logic
		// 1. filter out all expired event ( today date not in the range of start & end date *include start, end ) => valid event list
		// 2. get the first 3 records in valid event list with same category
		// 3.

		if (current) {
			/* if (new Date(current['end_date']).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0))
			return navigate(`/event-archive/${id}`); */

			getPeriod(current.start_date, current.end_date)

			let related

			/* data.all.forEach((item) => {
				console.log(item['slug'].toLowerCase())
				console.log(current['slug'].toLowerCase())
				console.log(item['slug'].toLowerCase() !== current['slug'].toLowerCase())
			}) */

			//1 filter out expired event and not current

			const valid_event_list = getValidEventData(data.all, current)

			//get same category in valid event list
			related = valid_event_list.filter((item) => {
				const same_cat = item['category'] === current['category']
				return same_cat
			})

			if (related.length === 0) {
				related = valid_event_list.slice(0, 3)
			} else {
				related = related.slice(0, 3)
			}

			setData((prevState) => ({
				...prevState,
				current,
				related,
			}))
		}
	}, [data.all, id, i18n.language])

	return (
		<>
			<Helmet>
				<title>
					{data.current['title'] || ''} - {t('Central Market')}
				</title>
			</Helmet>
			<div className={`${styles.main} page`}>
				<div className={styles.ButtonBack}>
					<ButtonBack goBack />
				</div>
				<div class={styles.bg}>
					<img src="/images-local/block-3-h.png" alt="" />
					<img src="/images-local/block-s.png" alt="" />
					<img src="/images-local/cloud_home.png" alt="" />
					<img src="/images-local/block-bs-tb.png" />
				</div>
				{data.current &&
					<section className={styles.mainContent}>
						<div className="container-boxed much-padding">
							<div className={styles.content}>
								{data.current.slider && data.current.slider.length > 1 ?
									<div className={styles.slides}>
										<SliderArrow ref={sliderNav} bottom />
										<Swiper
											id="event-detail-swiper"
											effect="fade"
											speed={700}
											slidesPerView={1}
											pagination={false}
											navigation={{ prevNav: '', nextEl: '' }}
											onInit={(swiper) => {
												assignNav(swiper, sliderNav)
											}}
											/* onSlideChange={(swiper) => {
												setActiveEventSlide(swiper.realIndex)
											}} */
											autoplay={{ delay: 5000, stopOnLastSlide: false, pauseOnMouseEnter: true, disableOnInteraction: false }}
										>
											{data.current.slider.map(({ image, image_alt_tag }, i) => {
												return (
													<SwiperSlide key={i}>
														<div className={styles.slideContainer}>
															<div className={styles.picContainer}>
																<Image src={image} alt={image_alt_tag} />
															</div>
														</div>
													</SwiperSlide>
												)
											})}
										</Swiper>
									</div>
									:
									data.current.slider && data.current.slider.length > 0 ?
										<div className={styles.banner}>
											<img src={data.current.slider[0].image} />
										</div>
										: null
								}
								<div className={styles.detail}>
									<div className={styles.info}>
										<div className={styles.category}>{data.current.category}</div>
										<div className={styles.title}>{data.current.title}</div>
									</div>
									<ul className={styles.group}>
										{data.current['start_date'] && (
											<li>
												<InlineTextIcon icon="calendar" text={getPeriod(data.current['start_date'], data.current['end_date'])} />
											</li>
										)}
										{data.current['event_time'] && data.current['event_time'].length ? (
											<li>
												<InlineTextIcon icon="time" text={Array.isArray(data.current['event_time'])
													? data.current['event_time'].map((item, index) => {
														return (
															<>
																{item.time}
																{index === data.current['event_time'].length - 1 ? '' : <br />}
															</>
														)
													})
													: data.current['event_time']} />
											</li>
										) : null}
										{data.current['location'] && (
											<li>
												<InlineTextIcon icon="location" text={data.current.location} />
											</li>
										)}

										{data.current['fee'] && (
											<li>
												<InlineTextIcon icon="fee" text={isNum(getPurePrice(data.current['fee'])) ? (getPurePrice(data.current['fee']) ? '$' + getPurePrice(data.current['fee']) : t('Free')) : data.current['fee']} />
											</li>
										)}

										{data.current['web_site_url'] && (
											<li>
												<InlineTextIcon icon="link" text={data.current['web_site_url']} link={data.current['web_site_url']} />
											</li>
										)}
									</ul>
									{data.current.video_url && <Video className={styles.video} id="video" src={data.current.video_url} settings={{ controls: true }} />}
									<div className={styles.description}>{parse(htmldecode(data.current['description']))}</div>
									{data.current.link && <Button text={data.current.link_button} actualUrl={data.current.link} />}
								</div>
							</div>
						</div>
					</section>
				}

				{data.related.length > 0 && (
				<section className={styles.related}>
					<div className={styles.bg}>
						<img src="/images-local/block-3-v.png" alt="" />
					</div>
						<div className={styles.content}>
							<div className={styles.title}>
								<TextBorderFill fontSize="70" center>{t('You might also like')}</TextBorderFill>
							</div>
							<div className={styles.items}>
								{data.related.map(({ slug, category, title, start_date, end_date, location, landing_page_image, type }, index) => {
									return <GeneralCard isPromotion small key={slug} category={category} title={title} startDate={start_date} endDate={end_date} location={location} pic={landing_page_image} url={`/${type}/${slug}`} />
								})}
							</div>
						</div>
				</section>
				)}
			</div>
		</>
	)
}

export default EventDetail