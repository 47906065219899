// Styles
import '../../styles/popup.scss'
// Core
import { useState, useRef, useEffect } from "react";


// GSAP
import { TimelineLite } from "gsap/all";
import { useTranslation } from "react-i18next";

// Axios
import axios from '../../config/axios'
const PopupContent = () => {
	const { t, i18n } = useTranslation('popup-busking-data-privacy')
	const [popupPriceHtmlStr, setPopupPriceHtmlStr] = useState();
	
	useEffect(() => {

		axios.get(i18n.language + '/popup-busking-data-privacy.html').then((result) => {
			const resHtml = result.data
      
			setPopupPriceHtmlStr(resHtml)
		})
	}, [i18n.language])


	return (
		<>
			{/* Read from HTML string */}
			<div dangerouslySetInnerHTML={{ __html: popupPriceHtmlStr }}></div>

		</>
	)
}

export default PopupContent
