// Core
import styles from "./TextBorderFill.module.scss";
import { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom'

import classNames from "classnames";

// jQuery
import $ from "jquery";

// Gsap
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TextBorderFill = (props) => {
  const {className, white} = props;
  const { pathname } = useLocation()

  const textRef = useRef();
  const borderEl = useRef();
  const fillEl = useRef();

  useEffect(() => {
    updateFillElWidth();
    updateFillAnimation();

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
      updateFillElWidth()
  }, [pathname])

  const updateFillElWidth = () => {
    //if (borderEl.current && fillEl.current) {
    setTimeout(() => {
      if (borderEl.current && fillEl.current) {
        fillEl.current.style.width = $(borderEl.current).width() + "px";
        // console.log('borderEl.current.offsetWidthb', borderEl.current.offsetWidth)
        // console.log('fillEl.current.offsetWidth', fillEl.current.offsetWidth)
      }
    }, 0);
    //}
  };

  const updateFillAnimation = () => {
    let target = textRef.current;

    const tl = gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: target,
        start: "center 90%",
        end: "center 10%",
        scrub: true,
        onUpdate: (self) => {
          // console.log('progress:', self.progress.toFixed(3))
        },
      },
    });
    tl.from(target, { width: 0 });
  };

  const onResize = () => {
    updateFillElWidth();
    // console.log('resized')
  };

  return (
    <div className={classNames(styles.text,className, white ? styles.white : '' )}>
      <div ref={borderEl} className={styles.border}>
        {props.children}
      </div>
      <div ref={textRef} className={styles.mask}>
        <div ref={fillEl} className={styles.fill}>
          {props.children}
        </div>
      </div>
    </div>
  );
};
export default TextBorderFill;
