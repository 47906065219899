import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Draggable from 'react-draggable'
/* import { Draggable } from 'react-beautiful-dnd'; */
import { createPopper } from '@popperjs/core';
import { computePosition, autoUpdate, arrow, offset } from "@floating-ui/dom";
import { map } from 'lodash'
import { useMedia } from 'react-use';
import $ from 'jquery'
import { FontAwesomeButton } from '../../components/Button/Button'
import Loader from '../../components/Loader/Lodaer'
import { ReactComponent as GroundFloor } from './GF.svg'
// import { ReactComponent as FirstFloor } from './1F_new.svg'
import { ReactComponent as FirstFloor } from './1F_new_v2.svg'
//import { ReactComponent as SecondFloor } from './2F.svg'
import { ReactComponent as SecondFloor } from './2F_v2.svg' 

import styles from './FloorPlan.module.scss'

const floorPosition = [
  { x: -40, y: -70 },
  { x: 0, y: -60 },
  { x: 0, y: -60 }
]

const mobileFloorPosition = [
  { x: -40, y: 0 },
  { x: 0, y: 5 },
  { x: 0, y: 0 }
]

const FloorPlan = ({ shop, name, floor }) => {
  const isMobile = useMedia('(max-width: 768px)');
  const [scale, setScale] = useState(isMobile ? 2 : 1.5)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const viewRef = useRef(null)
  const zoomInRef = useRef(null)
  const zoomOutRef = useRef(null)
  const svgRef = useRef(null)
  const toolTipEl = useRef(null)
  const pathEl = useRef(null)
  const arrowEl = useRef(null)
  const placement = "top";
  const arrowLen = useMemo(() => {
    if(arrowEl.current)
    return arrowEl.current.offsetWidth
  });
  const floatingOffset = Math.sqrt(2 * arrowLen ** 2) / 2;

  useEffect(() => {

    if (!svgRef.current || !shop) return;
    let mapShopsIds;

    const viewElement = viewRef.current;
    const svgElement = svgRef.current;
    const mapShops = $(svgElement).find('path[data-id]')

    const getMapShops = async () => {
      mapShopsIds = map(mapShops, (s, o) => {
        return $(s).data('id')
      })
    }

    const addPin = () => {
      if (!shop) return

      //$('.pin').remove()
      let currentFloorPosition = floorPosition[0]

      if (floor === '1/F') currentFloorPosition = floorPosition[1]
      if (floor === '2/F') currentFloorPosition = floorPosition[2]

      let selectShop = shop
      const viewCenterX = $(viewElement).width() / 2
      const viewCenterY = $(viewElement).height() / 2

      if (shop.includes(' - ')) {
        const shopArray = shop.split(' - ')
        selectShop = shopArray[0]
      }

      if (shop.includes(' & ')) {
        const shopArray = shop.split(' & ')
        selectShop = shopArray[0]
      }

      // const toolTip = document.querySelector('#tooltip')
      const pathElement = $(svgElement).find(`path[data-id*="${selectShop}"], rect[data-id*="${selectShop}"]`)[0];
      //const arrowEl = document.querySelector('#arrow')
      pathEl.current = pathElement;
      /* const popperT = createPopper(pathElement, toolTip, {
          placement: "top",
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top', 'bottom'],
              },
            },
            {
              name: 'arrow',
              enabled: true,
              options: {
                padding: 5, // 5px from the edges of the popper
                placement: 'auto',
                allowedAutoPlacements: ['top', 'bottom'],
              },
            },
          ],
      });
      setPopper(popperT) */
      setPosition(currentFloorPosition)
      updatePosition()
      setTimeout(() => {
        cleanup()
      }, 100)
      //pathElement.style.fill = '#c8215d'
      /* const { x, y, width, height } = pathElement.getBBox();
      const distanceX = viewCenterX - x
      const distanceY = viewCenterY - y

      const pinElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      const arrowElement = document.createElementNS("http://www.w3.org/2000/svg", "svg")
      pinElement.setAttribute("class", "pin"); */
      /* pinElement.innerHTML = `<use xlink:href="#pin" width="20px" height="40px"></use>
      <defs>
        <symbol id="pin" viewBox="0 0 20 40">
          <path d="M8,0C3.6,0,0,3.6,0,8c0,4.4,8,16,8,16s8-11.6,8-16C16,3.6,12.4,0,8,0z M8,11c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S9.7,11,8,11z" fill="#c8215d"></path>
        </symbol>
      </defs>` */

      /* pinElement.innerHTML = `

      <defs>
      <filter id="dropshadow" height="120%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3"></feGaussianBlur>
        <feOffset dx="0" dy="0" result="offsetblur"></feOffset>
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.48"></feFuncA>
        </feComponentTransfer>
        <feMerge>
          <feMergeNode></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
      <g>
        <rect x="10" y="10" width="100" height="60" fill="#555" rx="6" ry="6"/>
        <polygon points="0,0 40,0 15,25" fill="#555" filter="url(#dropshadow)"></polygon>
        <g id="tooltip">
          <rect x="0" y="0" width="0" height="0" fill="#555" rx="6" ry="6"/>
          <text x="60" y="35" font-size="12" text-anchor="middle" fill="#fff">${shop}</text>
        </g>
      </g>
      `

      pinElement.setAttribute("x", x + width / 3);
      pinElement.setAttribute("y", y + (height / 10) - 25);
      pinElement.setAttribute("width", "150");
      pinElement.setAttribute("height", "80"); */
      //pinElement.setAttribute("viewBox", "0 0 20 40");
      //svgElement.appendChild(pinElement);
      //setPosition({x: distanceX, y: distanceY})
    }
    //await getMapShops()
    addPin()

    return {}

  }, [shop]);

  const updatePosition = () => {
    computePosition(pathEl.current, toolTipEl.current, {
      placement,
      middleware: [offset(floatingOffset), arrow({ element: arrowEl.current })]
    }).then(({ x, y, middlewareData, placement }) => {
      Object.assign(toolTipEl.current.style, {
        left: `${x}px`,
        top: `${y}px`,
        position: 'absolute'
      });

      const side = placement.split("-")[0];

      const staticSide = {
        top: "bottom",
        right: "left",
        bottom: "top",
        left: "right"
      }[side];

      if (middlewareData.arrow) {
        const { x, y } = middlewareData.arrow;
        Object.assign(arrowEl.current.style, {
          left: x != null ? `${x}px` : "",
          top: y != null ? `${y}px` : "",
          // Ensure the static side gets unset when
          // flipping to other placements' axes.
          right: "",
          bottom: "",
          position: 'absolute',
          [staticSide]: `${-arrowLen / 2}px`,
          transform: "rotate(45deg)",
          background: "#c8215d"
        });
      }

      //setPosition({ x, y})
    });
  }

  const cleanup = () => {
    autoUpdate(
      pathEl.current,
      toolTipEl.current,
      updatePosition)
  }

  const zoomInOutHandle = useCallback((zoomIn = true) => {
    let newScale = scale
    if (zoomIn) {
      newScale += 0.5
      if (newScale >= 3) newScale = 3
    }
    else {
      newScale -= 0.5
      if (newScale <= 1) newScale = 1
    }
    setScale(newScale)
    setTimeout(() => {
      cleanup()
    }, 500)
  })

  //console.log('popper', popper)

  const controlledDrag = (e, { x, y }) => {
    console.log('e', e)
    console.log('x, y', x, y)
    setPosition({ x, y })
    cleanup()
  }

  let TargetComponent = GroundFloor
  if (floor === '1/F') TargetComponent = FirstFloor
  if (floor === '2/F') TargetComponent = SecondFloor

  return (
    <div className={styles.floorMap} ref={viewRef}>
      <div className={styles.viewer}>
        <div className={styles.zoomController}>
          <FontAwesomeButton afterClick={() => zoomInOutHandle()} ref={zoomInRef} icon="fa-solid fa-plus" />
          <FontAwesomeButton afterClick={() => zoomInOutHandle(false)} ref={zoomOutRef} icon="fa-solid fa-minus" />
        </div>
        <Draggable handle=".handle" position={{ x: position?.x, y: position?.y }} onStop={controlledDrag}>
          <div className="handle">
            <div className={styles.bg} style={{ transform: `scale(${scale})` }}>
              <TargetComponent ref={svgRef} />
            </div>
          </div>
        </Draggable>
        <div ref={toolTipEl} id="tooltip" role="tooltip" data-show>
          <span className="no">{shop}</span><span className="name">{name}</span>
          <div ref={arrowEl} id="arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  )
}

export default FloorPlan