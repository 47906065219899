import { useState, createContext, useContext } from 'react'

const GlobalContext = createContext()
export const useGlobalSetting = () => useContext(GlobalContext)

const GlobalSetting = ({ children }) => {
    const [isEventPromotionPage, setIsEventPromotionPage] = useState(false)

    const lockScreen = () => {
        document.getElementsByTagName('html')[0].style.overflow = "hidden";
    }

    const unlockScreen = () => {
        document.getElementsByTagName('html')[0].style.overflow = "";
    }

    return (
        <GlobalContext.Provider value={{
            lockScreen,
            unlockScreen,
            isEventPromotionPage,
            setIsEventPromotionPage,
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalSetting