// Styles
import '../../styles/popup.scss'
import parse from 'html-react-parser'

import { htmldecode } from '../../utils/'

const PopupOfferTNC = (props) => {
	const {title} = props

	const tncTitle = title ? parse(htmldecode(props.title).replace(/<p[^>]*>/g, "")) : ''

	return (
		<div className="popup--tnc">
			<div className="title">{tncTitle}</div>
			<div>
				{props.description ? parse(htmldecode(props.description)) : ""}
			</div>
		</div>
	)
}

export default PopupOfferTNC
