import { useCallback, useRef, useState, useEffect, createContext, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import SVG from "react-inlinesvg";
import { useToggle } from 'react-use';
import SearchBar from '../../components/SearchBar/SearchBar'
import PageTitle from '../../components/PageTitle/PageTitle'
import ButtonReset from '../../components/ButtonReset/ButtonReset'
import Loader from '../../components/Loader/Lodaer'
import { GeneralCard } from '../../components/Card/Card'
import { InlineTextIcon } from '../../components/Icon/Icon'
import getData from '../../utils/getData.js'

import styles from './ShopRestaurantDirectory.module.scss'

const ShopDirectoryContext = createContext();
const useShopDirectory = () => useContext(ShopDirectoryContext)
const ShopDirectoryWrap = ({ children, data, categoriesList, config, updateConfig }) => {

    return (
        <ShopDirectoryContext.Provider value={{
            data,
            categoriesList,
            config,
            updateConfig
        }}>
            {children}
        </ShopDirectoryContext.Provider>
    )
}



const LevelSelector = () => {
    const floors = ['G/F', '1/F', '2/F']
    const { config: { keyword, level }, updateConfig } = useShopDirectory()
    const { t } = useTranslation()

    const handleSelect = useCallback((selected) => {
        let value = ''
        if(selected !== level) value = selected
        updateConfig({ level: value })
    }, [level])

    return (
        <div>
            <label>{t('Level')}</label>
            <ul className={styles.floors}>
                {floors.map((floor, index) => {
                    return (
                        <li key={index} className={!keyword && level === floor ? styles.active : ''}>
                            <a onClick={() => handleSelect(floor)}>{floor}</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const CategorySelector = () => {
    const { categoriesList=[], config: { keyword, categories=[] }, updateConfig } = useShopDirectory()
    const { t } = useTranslation()

    const updateSelected = useCallback((cate) => {
        let selectedCategories = categories
        if(selectedCategories.includes(cate)) {
            _.remove(selectedCategories, (item) => item === cate)
        }
        else {
            selectedCategories.push(cate)
        }
        updateConfig({
            categories: selectedCategories
        })
    })

    return (
        <div>
            <label>{t('Category')}</label>
            <ul className={styles.category}>
                {categoriesList.map(({ name, id }, index) => {
                    return (
                        <li id={id} key={index} className={!keyword && categories.includes(id) ? styles.active : ''}>
                            <a onClick={() => updateSelected(id)}>{name}</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const OtherSelector = () => {
    const { config: { keyword, other=[] }, updateConfig } = useShopDirectory()
    const { t } = useTranslation()
    const lists = [{ id:1, name: 'CM Point' }]

    const updateSelected = useCallback((value) => {
        let selectedOther = other
        if(selectedOther.includes(value)) {
            _.remove(selectedOther, (item) => item === value)
        }
        else {
            selectedOther.push(value)
        }
        updateConfig({
            other: selectedOther
        })
    })
    return (
        <div>
            <label>{t('Others')}</label>
            <ul className={styles.category}>
                {lists.map(({ name, id }, index) => {
                    return (
                        <li id={id} key={index} className={!keyword && other.includes(id) ? styles.active : ''}>
                            <a onClick={() => updateSelected(id)}>{name}</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const ShopRestaurantDirectory = () => {
    const resetRef = useRef(null)
    const searchRef = useRef(null)
    const [config, setConfig] = useState({
        keyword: '',
        level: '',
        categories: [],
        other: []
    })
    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [categories, setCategories] = useState([])
	const [isLoading, setIsLoading] = useState(false)
    const [enableMobileFilter, toggleMobileFilter] = useToggle(false)
	const { t: tMenu } = useTranslation(['menu'])
    const { t } = useTranslation()

    useEffect(() => {
        async function fetchData() {
			try {
				let data = await getData('shop')

				const categoriesList = await _.map(_.uniqBy(data, 'category'), ({category, category_id}) => ({  id: category_id, name: category }))

				data = _.sortBy(data, [
					function (o) {
						return o.slug
					},
				])

				setData(data)
                setCategories(categoriesList)
                setList(data)
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
    }, [])

    useEffect(async() => {
        const { keyword, level, categories, other } = config

        let newData = data

        if(level)
        newData = await _.filter(newData, ({ floor }) => floor === level);

        if(categories.length)
        newData = await _.filter(newData, ({ category_id }) => categories.includes(category_id));

        if(keyword) {
            newData = await _.filter(data, ({ title }) => title.toLowerCase().includes(keyword.toLowerCase()));
        }

        setList(newData)
        setIsLoading(false)
    }, [config])

    const updateConfig = useCallback((obj) => {
        setIsLoading(true)
        setConfig({
            ...config,
            ...obj
        })
    }, [config])

    const handleReset = useCallback((type) => {
        updateConfig({
            keyword: '',
            level: '',
            categories: [],
            other: []
        })
    })

    const handleChangeSearch = useCallback((value) => {
        updateConfig({keyword: value})
    })

    return (
        <div className="page-shops page">
            <Helmet>
                <title> {tMenu("Shops & Restaurant")} - {t('Central Market')} </title>
            </Helmet>
            <PageTitle title={t("Shops & Restaurant Directory")} withBr/>
            <ShopDirectoryWrap data={data} config={config} categoriesList={categories} updateConfig={updateConfig}>
                <div className="container-boxed half-padding">
                    <div className={`${styles.filterBox} ${enableMobileFilter ? styles.enabled : ''}`}>
                        <div className={styles.close} onClick={() => toggleMobileFilter(false)}>
                            <SVG width={15} height={15} src="/images-local/close.svg" preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="#C8175D"')}/>
                        </div>
                        <div className={`colored ${styles.filter}`}>
                            <div className={styles.filterContainer}>
                                <SearchBar value={config.keyword} handleChangeSearch={handleChangeSearch} />
                            </div>
                            <ButtonReset onClick={handleReset} ref={resetRef}/>
                        </div>

                        <div className={styles.advanceFilter}>
                            <LevelSelector />
                            <CategorySelector />
                        </div>
                    </div>
                    <div className={styles.mobileFilter} onClick={toggleMobileFilter}>
                        <InlineTextIcon icon="filter" text={t('Filter')} primaryBtn/>
                    </div>
                    {/* Items */}
                    <div className={`${styles.items} ${list.length === 0 ? styles.empty : ''}`}>
                        {isLoading ? (
                            <Loader />
                        ) : list.length ? (
                            list.map((item, index) => {
                                return (
                                    // <ScrollShow effect="fade-up">
                                    <GeneralCard
                                        key={index}
                                        title={item.title}
                                        category={item.category}
                                        location={item.shop_no}
                                        phone={item.telephone}
                                        pic={item.logo}
                                        url={`/shop-detail/${item.slug}`}
                                        isPromotion
                                        isContain
                                    />
                                    // </ScrollShow>
                                )
                            })
                        ) : (
                            <div className={styles.noResult}>{t('No result')}</div>
                        )}
                    </div>
                </div>
            </ShopDirectoryWrap>
        </div>
    )
}

export default ShopRestaurantDirectory