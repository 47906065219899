import { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import SwiperCore, { EffectFade, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from '../../components/Image/Image'
import PageTitle from '../../components/PageTitle/PageTitle'
import ButtonBack from '../../components/ButtonBack/ButtonBack'
import { SliderArrow } from '../../components/NavArrow/NavArrow'

import styles from './EventArchive.module.scss'

import getData from '../../utils/getData'

SwiperCore.use([EffectFade, Navigation])

const EventArchive = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [data, setData] = useState(null)
    const [swiperIndex, setSwiperIndex] = useState(0)
    let sliderNav = {
        prev: useRef(),
        next: useRef(),
    }

    useEffect(() => {
        async function fetchData() {
            const result = await getData('event_highlight')
            const current = result.find((el) => el['id'] == id)
            setData(current)
        }

        fetchData()
    }, [])

    const assignNav = (swiper, nav) => {
        swiper.params.navigation = {
            ...swiper.params.navigation,
            prevEl: nav.prev.current,
            nextEl: nav.next.current,
        }
        swiper.navigation.update()
        swiper.update(true)
        swiper.slideToLoop(0, 0)
    }

    const handleChangeGallery = (index) => {
        setSwiperIndex(index)
    }

    return (

        <div className="page-eventArchive page">
            <Helmet>
                <title> {t("Event Highlights")} - {t('Central Market')} </title>
            </Helmet>
            <div className={`${styles.main} page`}>
                <div className={styles.ButtonBack}>
                    <ButtonBack url={'/event-highlights'} />
                </div>
                <PageTitle title={t("Event Highlights")} />
                <div className="container-boxed much-padding">

                    {data &&
                        <div className={styles.content}>
                            <div className={styles.title}>{data.event_name}</div>

                            <div className={styles.gallery}>
                                {data?.event_highlight_detail.length > 0 ?
                                    data.event_highlight_detail[swiperIndex].video_id ?
                                        <iframe
                                        src={`https://www.youtube.com/embed/${data?.event_highlight_detail[swiperIndex]?.video_id}?&autohide=1&showinfo=0&rel=0&modestbranding=1&fs=0`}
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; picture-in-picture" allowfullscreen></iframe>
                                        :
                                        <div className={styles.pic} style={{ backgroundImage: `url(${data?.event_highlight_detail[swiperIndex]?.detail_image})` }} />
                                    :
                                    null
                                }
                            </div>
                            {data?.event_highlight_detail && data.event_highlight_detail.length > 0 &&
                                <div className={styles.slides}>
                                    {data.event_highlight_detail.length > 1 && <SliderArrow ref={sliderNav} isLeftRight />}
                                    <Swiper
                                        id="event-archive-swiper"
                                        speed={700}
                                        spaceBetween={25}
                                        slidesPerView={3}
                                        pagination={false}
                                        navigation={{ prevNav: '', nextEl: '' }}
                                        onInit={(swiper) => {
                                            assignNav(swiper, sliderNav)
                                        }}
                                    >
                                        {data.event_highlight_detail.map(({ detail_image, video_id }, i) => {
                                            return (
                                                <SwiperSlide key={i} onClick={() => handleChangeGallery(i)}>
                                                    <div className={styles.slideContainer}>
                                                        <div className={styles.picContainer}>
                                                            <div className={`${styles.mask} ${swiperIndex === i ? styles.active : ''}`} />
                                                            <div className={styles.pic} style={{ backgroundImage: video_id ? `url(https://img.youtube.com/vi/${video_id}/maxresdefault.jpg)` : `url(${detail_image})` }} />
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EventArchive