import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash';
import Image from '../../components/Image/Image';
import PageTitle from '../../components/PageTitle/PageTitle'
import HeritageTourForm from './HeritageTourRegister'
import styles from '../VenueApplications/VenueApplication.module.scss';

const HeritageTour = () => {
    const { t, i18n } = useTranslation()
    const { t: tMenu } = useTranslation()

    const list = [{
        imgSrc: '/images-local/heritage-tour-1.jpg',
    }, {
        imgSrc: '/images-local/heritage-tour-2.jpg',
    }, {
        imgSrc: '/images-local/heritage-tour-3.jpg',
    },]

    return (
        <div className="page-venue-applications page">
            <Helmet>
                <title>
                    {tMenu('Heritage Tour')} - {t('Central Market')}
                </title>
            </Helmet>
            <PageTitle title={tMenu("Heritage Tour")} />
            <div className="container-boxed half-padding">
                <h3 className="text-center">{t('heritage_tour_title')}</h3>
                <div className={styles.description}>
                    <p dangerouslySetInnerHTML={{ __html: t('heritage_tour_description')}} />
                </div>
                <div className={styles.list}>
                    {map(list, ({ imgSrc }, index) => (
                        <div key={index} className={styles.child}>
                            <Image src={imgSrc} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-10">
                <HeritageTourForm />
            </div>
        </div>
    )
}

export default HeritageTour