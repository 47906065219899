// Core
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet-async'

import style from './Cookie.scss'

// Components
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'

// i18n
import { useTranslation } from 'react-i18next'


const Cookie = () => {
	const { t } = useTranslation()
	return (

<div className={"cookie"}>
<Helmet>
	<title>
		{t('cookie_title')} - {t('Central Market')}
	</title>
</Helmet>
<div className="container-boxed">
	<div className={"container"}>
		<div className={"title"}>
			<TextBorderFill>{t('cookie_title')}</TextBorderFill>
		</div>
		<div className={"detailsContainer"}>
			<div className={"details"}>{t('cookie_content')}</div>
		</div>

	</div>
</div>
</div>
	)
}

export default Cookie
