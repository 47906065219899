// Styles
import '../../styles/popup.scss'
import { useTranslation } from 'react-i18next'

const PopupContent = () => {
	const { t } = useTranslation('')

	return (
		<>
			<div className="content">
				<div className="text-sm">{t('text_what_is_permit_code')}</div>
			</div>
		</>
	)
}

export default PopupContent
