import styles from './Button.module.scss'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = (props) => {
	const url = props.href || ''
	const actualUrl = props.actualUrl || ''
	const action = props.action || null

	if(url==='' && actualUrl==='' && action!= null){
		return(
			<a className={`${styles.button} ${props.white ? styles.white : ''} ${props.hoverForceRed ? styles.hoverForceRed : '' }`} onClick={action}>
				{props.children}
				<div className={styles.arrow}></div>
			</a>

		)
	}else if(actualUrl){
		return (
			<a href={actualUrl} target="_blank" className={`${styles.button} ${props.white ? styles.white : ''} ${props.hoverForceRed ? styles.hoverForceRed : '' }`} onClick={action}>
				{props.text}
				<div className={styles.arrow}></div>
			</a>
		)
	}

	return (
		<Link to={url} className={`${styles.button} ${props.white ? styles.white : ''} ${props.hoverForceRed ? styles.hoverForceRed : '' }` } onClick={action}>
			{props.text}
			<div className={styles.arrow}></div>
		</Link>
	)

}

export const FontAwesomeButton = ({ icon, color="#c8215d", afterClick, ref }) => {
	const handleClick = () => {
		if(afterClick) {
			afterClick()
		}
	}
	return (
		<button ref={ref} className={styles.fontAwesomeButton} onClick={handleClick}>
			<FontAwesomeIcon icon={icon} color={color}/>
		</button>
	)
}

export default Button
