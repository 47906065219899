// Core

// Styles
//import styles from './PopupContent.module.scss'
import "../../styles/popup.scss";

// GSAP
import { useTranslation } from "react-i18next";
const PopupContent = () => {
  const { t } = useTranslation("");

  return (
    <>
      <div className="title">
        {t("RULES AND REGULATIONS FOR THE USE OF THE VENUE")}
      </div>
      <ul className="numlist">
        <li>
          <span>
            {t(
              "The performance shall take place in the designated performance area which measures approximately 5.5 meters x 6 meters. The number of performers shall not be more than 10. The performance area is an outdoor open area."
            )}
          </span>
          <img src="\images\popup-content-img1.png" alt="" />
        </li>
        <li>
          <span>
            {t(
              "Performers and the content of their performances shall be similar to what has been auditioned."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Applicants are required to fill in the provided form and submit information about the nature of their performance, the content and equipment used. The Central Market Authority may request the applicant to further clarify or provide additional information."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The judging committee will consider the application according to whether the performance is suitable for a general audience and whether the performance date can be arranged."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Young performers below the age of 18 must be accompanied by adults during the performance."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Performers shall report to the venue management staff at least 15 minutes before the commencement of the performance. Failure to report within the first 20 minutes of the performance session will be considered as an abstention, and the venue management staff have the right to assign the performance area to other performers or cancel the activities for the session."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The sound volume produced by the performers during the performances shall meet the requirements stipulated by the Environmental Protection Department and the performers shall observe the Noise Control Guidelines for Holding Open Air Entertainment Activities. In the places most affected and sensitive to noise, from the front of the outer wall measured at 1 meter, the noise from activities (including venue layout, rehearsal activities, formal conduct and stage disassembly, etc.) during the day and evening, that is, from 7 am to 11 pm, shall not exceed the background noise at that time by more than 10 decibels ( A). The noise level should be measured by Leq (15 min) — 15-minute A-weighted equivalent continuous sound pressure, that is, the average A-weighted energy of the noise level within 15 minutes."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The equipment shall be placed within the performance area. Venue management staff may stipulate the permissible sound level or request the performers to lower the volume according to the actual situation. The use of generators or large audio equipment is prohibited. Venue management staff have the right to stop the performance of performers who have offended the above rules."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Performers are not allowed to display or advertise anything that is not related to the performance or use objects with advertising implications during the performance, nor distribute or sell any items or merchandise, including snacks and drinks, at the venue."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Performers are not allowed to actively ask for money or rewards."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The Applicant hereby confirms that the intellectual property rights subsisting in the musical works, lyrics or sound recordings used or performed in the performance are owned by members of the Composers and Authors Society of Hong Kong Limited, the Phonographic Performance (South East Asia) Limited or the Hong Kong Recording Industry Alliance Limited. Since Central Market shall be responsible for payment of the related licence fees to the above organisations, the Applicant is not required to obtain separate licences from the said organisations for using the works for public performances within the Central Market premises under this Scheme."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "If, however, the intellectual property rights subsisting in the musical works, lyrics or sound recordings used or performed in the performance are owned by a third party or parties other than members of the above organisations, the Applicant shall obtain all necessary licences or permissions from such third parties entitling him or the performers to use or perform such works in public."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "If the applicant or performer violates the provisions of this document, the production or performance of the work infringes the rights and interests of others (including intellectual property rights), or contains defamatory meaning, or for other reasons, causes others to take legal action, litigation, or litigation against the Central Market. Applicants shall be fully responsible for making claims, making requests, or causing Central Market to bear legal liabilities and expenses, and ensure that the Central Market is not required to make any indemnities."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "If necessary, the applicant must prove that he/she has obtained the permission of the relevant performer and therefore has the right to imitate or impersonate the performer in public performances."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Performers are not allowed to display anything in the venue or promote anything other than the performance or anything that contains advertising during the performance, or distribute any posters, placards or notices, nor are they allowed to post any posters, placards or notices on any trees or plants in the venue. "
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Performers must keep the venue clean during and after the performance. All equipment, props, tables, chairs and sundries brought in must be removed immediately after the performance, and the performance area must be returned to the venue management staff on time to allow them. to arrange performances for other performers."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The applicant and the performers are not allowed to cause a nuisance or obstruction to others or traffic in any public place; they may not cause a noise nuisance, and may not conduct any indecent, obscene, or offensive performances. Should any such activities be discovered, the venue management has the right to terminate the performance immediately."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Applicants must ensure that their performance does not contain any defamatory meaning or infringement of the rights and interests of others (including intellectual property rights), and does not involve religious or political activities."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The Central Market and its employees or agents shall not be under any liability whatsoever for or in respect of :"
            )}
            <ul className="charlist">
              <li>
                <span>
                  {t(
                    "Any loss or damage to any property of the applicant or performer or its employees or agents, and"
                  )}
                </span>
              </li>
              <li>
                <span>
                  {t(
                    "The applicant and performer or its employees or agents have any injuries or deaths"
                  )}
                </span>
              </li>
            </ul>
          </span>
        </li>
        <li>
          <span>
            {t(
              "The Applicant shall indemnify the Central Market against any loss of or damage to any property of the Central Market or of any of its employees or agents or any injury to or death of any employees or agents of the Central Market arising out of the Negligence of the Applicant or the performers or any of their employees or agents."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "All performances shall be cancelled or terminated in cases of maintenance work or large-scale activities to be conducted at the Central Market, temporary unavailability of the Central Market, or inclement weather conditions such as when a tropical cyclone signal of number 3 or above, thunderstorm warning or rainstorm warning signal is issued. "
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "Once the performance time is confirmed, it cannot be changed. If the applicant has to cancel for his own reasons, he should notify the Central Market at least five working days before the original application date."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "The Central Market has the right to decide on the final use of the venue, and performers may not object to the decision."
            )}
          </span>
        </li>
        <li>
          <span>
            {t(
              "After evaluation, the Central Market has the right to reject any application or applicant, and the applicant may not object."
            )}
          </span>
        </li>
      </ul>
    </>
  );
};

export default PopupContent;
