// Axios
import axios from 'axios'

// Utils
import getQueryString from './getQueryString'

const getData = (name, lang) => {
	const isPreviewMode = getQueryString('preview')
	return new Promise((resolve, reject) => {
		let API_BASE = '/data/data-local/'

		const CMS_DATA = ['event', 'event_highlight', 'banner', 'promotion', 'shop', 'press_release', 'event_offer', 'shop_offer', 'announcement', 'venue_booking']

		if (CMS_DATA.includes(name)) {
			API_BASE = '/data/'
		}

		const language = lang || localStorage.getItem('i18nextLng')
		const sessionName = `${name}_${language}`
		// let data = sessionStorage.getItem(sessionName) || ''
		let data = ''
		if (data) {
			resolve(JSON.parse(data))
			// window.dispatchEvent(new Event('resize'))

			var resizeEvent = window.document.createEvent('UIEvents')
			resizeEvent.initUIEvent('resize', true, false, window, 0)
			window.dispatchEvent(resizeEvent)
		}

		if (name.includes('_home')) {
			async function getSlicedData() {
				try {
					const dataName = name.replace('_home', '')
					data = await getData(dataName)
					// data = await data.filter((el) => el['is_featured']).slice(0, 5)
					sessionStorage.setItem(sessionName, JSON.stringify(data))
					resolve(data)
					// window.dispatchEvent(new Event('resize'))

					var resizeEvent = window.document.createEvent('UIEvents')
					resizeEvent.initUIEvent('resize', true, false, window, 0)
					window.dispatchEvent(resizeEvent)
				} catch (error) {
					reject(error)
				}
			}
			getSlicedData()
		} else {
			if (isPreviewMode && API_BASE === '/data/') {
				name = name + '_preview'
			}
			axios
				.get(API_BASE + language + '/' + name + '.json')
				.then((result) => {
					data = result.data
					sessionStorage.setItem(sessionName, JSON.stringify(data))
					resolve(data)
					// window.dispatchEvent(new Event('resize'))

					var resizeEvent = window.document.createEvent('UIEvents')
					resizeEvent.initUIEvent('resize', true, false, window, 0)
					window.dispatchEvent(resizeEvent)
				})
				.catch(function (error) {
					reject(error)
				})
		}
	})
}

export default getData
