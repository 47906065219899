// Core
import React, { useState, useRef, useEffect } from 'react'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, TrainRounded } from '@material-ui/icons'
import { Checkbox, Select, RadioGroup, FormControlLabel, FormControl, FormLabel, FormHelperText, InputLabel, TextareaAutosize, MenuItem, Radio } from '@material-ui/core'

// Validator
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import TextareaValidator from '../../components/TextareaValidator/TextareaValidator'
import CheckboxValidatorElement from '../../components/CheckboxValidatorElement/CheckboxValidatorElement'
import KeyboardDatePickerValidator from '../../components/KeyboardDatePickerValidator/KeyboardDatePickerValidator'
import RadioGroupValidator from '../../components/RadioGroupValidator/RadioGroupValidator'

// Components
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import ButtonUnderline from '../../components/ButtonUnderline/ButtonUnderline'
import Popup from '../../components/Popup/Popup'
import PopupTerms from '../../components/PopupContent/PopupTerms'
import PopupDataPrivacy from '../../components/PopupContent/PopupDataPrivacy'
import Loader from '../../components/Loader/Lodaer'

// jQuery
import $ from 'jquery'

// Axios
import axios from 'axios'
import { API } from '../../config/constants'

// Utils
import getSession from '../../utils/getSession'

import faker from 'faker'

import { ValidatorComponent } from 'react-material-ui-form-validator'

const BuskingPermitRegisterForm = (props) => {
	const { t, i18n } = useTranslation()

	const formRef = useRef(null)
	const [numberOfLinks, setNumberOfLinks] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)

	const [formData, setFormData] = useState({
		chinese_name_1st: '',
		english_name_1st: '',
		gender_1st: '',
		age_1st: '',
		hkid_1st: '',
		mobile_1st: '',
		email_1st: '',
		chinese_name_2nd: '',
		english_name_2nd: '',
		gender_2nd: '',
		age_2nd: '',
		hkid_2nd: '',
		mobile_2nd: '',
		email_2nd: '',
		group_name: '',
		organization: '',
		type: '',
		type_others: '',
		detail: '',
		reference_1: '',
		reference_2: '',
		reference_3: '',
		reference_4: '',
		reference_5: '',
	})
	const [numbersOfApplicants, setNumbersOfApplicants] = useState('1')

	const [declarationData, setDeclarationData] = useState({
		declaration1: '',
		declaration2: '',
	})

	const [numbersOfReference, setNumbersOfReference] = useState(1)
	const [referenceList, setReferenceList] = useState([])
	useEffect(() => {
		const temp = []
		for (let i = 1; i <= numbersOfReference; i++) {
			temp.push(i)
		}
		setReferenceList(temp)
	}, [numbersOfReference])

	let ageList = []
	for (let i = 18; i <= 65; i++) {
		ageList.push(i)
	}

	const handleChangeFormData = (event) => {
		// HKID auto uppercase
		if (event && event.target && event.target.name.includes('hkid_')) {
			event.target.value = event.target.value.toUpperCase()
		}

		// Auto http://
		if (event && event.target && event.target.name.includes('reference_')) {
			if (event.target.value.length > 6) {
				if (event.target.value.substring(0, 7) != 'http://' && event.target.value.substring(0, 8) != 'https://') {
					event.target.value = 'http://' + event.target.value
				}
			}
		}

		setFormData((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}))
	}

	useEffect(() => {
		ValidatorForm.addValidationRule('isChecked', (value) => {
			if (value !== true) {
				return false
			}
			return true
		})
	})

	const handleSubmit = (event) => {
		setIsLoading(true)
		event.preventDefault()
		axios
			.post(
				API.busking.permitSubmit,
				formData,
				{ headers: { 'Accept-Language': i18n.language } }
			)
			.then((response) => {
				setIsLoading(false)
				if (response.data.status_code === 200) {
					props.onSuccess()
				} else {
					setErrorMessage(response.data.errmsg)
				}
			})
			.catch((error) => {
				setIsLoading(false)
				setErrorMessage(t(error.toJSON().message))
			})
	}

	const scrollToError = () => {
		$([document.documentElement, document.body]).animate(
			{
				scrollTop: $('.Mui-error').eq(0).parent().offset().top - $('[class*=Header_nav_]').eq(0).height(),
			},
			1000
		)
	}

	const handleSampleData = () => {
		const sampleData = {
			chinese_name_1st: '陳大文',
			english_name_1st: faker.name.findName(),
			gender_1st: 'M',
			age_1st: '29',
			hkid_1st: 'Y' + Math.floor(1000 + Math.random() * 9000),
			mobile_1st: Math.floor(10000000 + Math.random() * 90000000),
			email_1st: faker.internet.email(),
			chinese_name_2nd: '陳小美',
			english_name_2nd: faker.name.findName(),
			gender_2nd: 'F',
			age_2nd: '25',
			hkid_2nd: 'Y' + Math.floor(1000 + Math.random() * 9000),
			mobile_2nd: Math.floor(10000000 + Math.random() * 90000000),
			email_2nd: faker.internet.email(),
			group_name: 'Chan Chan',
			organization: 'Chang Chan Limited',
			type: '1',
			type_others: '',
			detail: 'We are chan chan',
			reference_1: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
			reference_2: '',
			reference_3: '',
			reference_4: '',
			reference_5: '',
		}

		setNumbersOfApplicants('2')

		setFormData((prevState) => ({
			...prevState,
			...sampleData,
		}))
	}

	const handleChangeDeclaration = (event) => {
		const name = event.target.name
		setDeclarationData((prevState) => ({
			...prevState,
			[name]: !declarationData[name],
		}))
	}

	return (
		<section className={`busking-permit-register ${isLoading ? 'loading' : ''}`}>
			{window.location.href.includes('www.centralmarket.hk') || window.location.href.includes('www-staging.centralmarket.hk') ? (
				''
			) : getSession('devtool') === 'on' ? (
				<div className="w-full border border-primary border-dashed p-10">
					<div className="text-primary mb-2">Dev tool:</div>
					<a className="inline-block border border-primary text-primary transition-all duration-400 py-2 px-5 cursor-pointer hover:bg-primary hover:text-white" onClick={handleSampleData}>
						Autofill
					</a>
				</div>
			) : (
				''
			)}
			<ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={scrollToError}>
				<div className="title">
					{t('A. Particulars of the Applicant')}
					<span className="text-primary text-lg font-bold">&nbsp;{t('form_busking_max_num_of_applicants_info')}</span>
				</div>

				<div className="fields">
					<div className="text-primary text-lg font-bold w-full">{t('1. Number of applicants')}</div>
					<div className="row boxed">
						<div className="field m-0">
							<RadioGroup name="numbers_of_applicants" value={numbersOfApplicants} onChange={(e) => setNumbersOfApplicants(e.target.value)} required>
								<FormControlLabel value="1" control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />} label="1" />
								<FormControlLabel value="2" control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />} label="2" />
							</RadioGroup>
						</div>
					</div>
				</div>

				<div className="fields">
					<div className="text-primary text-lg font-bold w-full">{t('2. Main Applicant')}</div>
					<div className="row boxed">
						<div className="field half">
							<TextValidator
								label={t('Name in Chinese (same as HKID)').includes('*') ? t('Name in Chinese (same as HKID)') : t('Name in Chinese (same as HKID)') + '*'}
								onChange={handleChangeFormData}
								name="chinese_name_1st"
								value={formData.chinese_name_1st}
								validators={['required']}
								errorMessages={[t('Cannot leave it blank')]}
								fullWidth
							/>
						</div>
						<div className="field half">
							<TextValidator
								label={t('Name in English (same as HKID)').includes('*') ? t('Name in English (same as HKID)') : t('Name in English (same as HKID)') + '*'}
								onChange={handleChangeFormData}
								name="english_name_1st"
								value={formData.english_name_1st}
								validators={['required']}
								errorMessages={[t('Cannot leave it blank')]}
								fullWidth
							/>
						</div>
						<div className="field half two-fields">
							<SelectValidator
								label={t('Gender').includes('*') ? t('Gender') : t('Gender') + '*'}
								name="gender_1st"
								value={formData.gender_1st}
								onChange={handleChangeFormData}
								validators={['required']}
								errorMessages={[t('Cannot leave it blank')]}
							>
								<MenuItem value="M">{t('Male')}</MenuItem>
								<MenuItem value="F">{t('Female')}</MenuItem>
							</SelectValidator>
							<SelectValidator
								label={t('Age').includes('*') ? t('Age') : t('Age') + '*'}
								name="age_1st"
								value={formData.age_1st}
								onChange={handleChangeFormData}
								validators={['required']}
								errorMessages={[t('Cannot leave it blank')]}
							>
								{ageList.map((age) => (
									<MenuItem key={age} value={age}>
										{age}
									</MenuItem>
								))}
							</SelectValidator>
						</div>
						<div className="field half">
							<TextValidator
								label={
									t('HKID Card No. (Prefix and first 4 digits)').includes('*') ? t('HKID Card No. (Prefix and first 4 digits)') : t('HKID Card No. (Prefix and first 4 digits)') + '*'
								}
								onChange={handleChangeFormData}
								name="hkid_1st"
								value={formData.hkid_1st}
								validators={['required', 'matchRegexp:^([A-Z]{1,2})([0-9]{4})$']}
								errorMessages={[t('Cannot leave it blank'), t('The format is incorrect')]}
								fullWidth
							/>
						</div>
						<div className="field half">
							<TextValidator
								label={t('Mobile').includes('*') ? t('Mobile') : t('Mobile') + '*'}
								onChange={handleChangeFormData}
								name="mobile_1st"
								value={formData.mobile_1st}
								validators={['required', 'isNumber', 'minNumber:10000000', 'maxNumber:99999999']}
								errorMessages={[t('Cannot leave it blank'), t('The format is incorrect'), t('The format is incorrect'), t('The format is incorrect')]}
								fullWidth
							/>
						</div>
						<div className="field half">
							<TextValidator
								label={t('Email').includes('*') ? t('Email') : t('Email') + '*'}
								onChange={handleChangeFormData}
								name="email_1st"
								value={formData.email_1st}
								validators={['required', 'isEmail']}
								errorMessages={[t('Cannot leave it blank'), t('The format is incorrect')]}
								fullWidth
							/>
						</div>
						<div className="field half">
							<TextValidator
								label={t('Performance group name')}
								onChange={handleChangeFormData}
								name="group_name"
								value={formData.group_name}
								validators={['required']}
								errorMessages={[t('Cannot leave it blank')]}
							 	fullWidth
							/>
						</div>
						<div className="field half">
							<TextValidator
								label={t('Name of Organization').includes('*') ? t('Name of Organization') : t('Name of Organization') + '*'}
								onChange={handleChangeFormData}
								name="organization"
								value={formData.organization}
								validators={['required']}
								errorMessages={[t('Cannot leave it blank')]}
								fullWidth
							/>
						</div>
					</div>
				</div>

				{numbersOfApplicants === '2' ? (
					<div className="fields">
						<div className="text-primary text-lg font-bold w-full">{t('3. Second Applicant')}</div>
						<div className="row boxed">
							<div className="field half">
								<TextValidator
									label={t('Name in Chinese (same as HKID)').includes('*') ? t('Name in Chinese (same as HKID)') : t('Name in Chinese (same as HKID)') + '*'}
									onChange={handleChangeFormData}
									name="chinese_name_2nd"
									value={formData.chinese_name_2nd}
									validators={['required']}
									errorMessages={[t('Cannot leave it blank')]}
									fullWidth
								/>
							</div>
							<div className="field half">
								<TextValidator
									label={t('Name in English (same as HKID)').includes('*') ? t('Name in English (same as HKID)') : t('Name in English (same as HKID)') + '*'}
									onChange={handleChangeFormData}
									name="english_name_2nd"
									value={formData.english_name_2nd}
									validators={['required']}
									errorMessages={[t('Cannot leave it blank')]}
									fullWidth
								/>
							</div>
							<div className="field half two-fields">
								<SelectValidator
									label={t('Gender').includes('*') ? t('Gender') : t('Gender') + '*'}
									name="gender_2nd"
									value={formData.gender_2nd}
									onChange={handleChangeFormData}
									validators={['required']}
									errorMessages={[t('Cannot leave it blank')]}
								>
									<MenuItem autoFocus="true" value="M">
										{t('Male')}
									</MenuItem>
									<MenuItem autoFocus="true" value="F">
										{t('Female')}
									</MenuItem>
								</SelectValidator>
								<SelectValidator
									label={t('Age').includes('*') ? t('Age') : t('Age') + '*'}
									name="age_2nd"
									value={formData.age_2nd}
									onChange={handleChangeFormData}
									validators={['required']}
									errorMessages={[t('Cannot leave it blank')]}
								>
									{ageList.map((age) => (
										<MenuItem key={age} value={age}>
											{age}
										</MenuItem>
									))}
								</SelectValidator>
							</div>
							<div className="field half">
								<TextValidator
									label={
										t('HKID Card No. (Prefix and first 4 digits)').includes('*')
											? t('HKID Card No. (Prefix and first 4 digits)')
											: t('HKID Card No. (Prefix and first 4 digits)') + '*'
									}
									onChange={handleChangeFormData}
									name="hkid_2nd"
									value={formData.hkid_2nd}
									validators={['required', 'matchRegexp:^([A-Z]{1,2})([0-9]{4})$']}
									errorMessages={[t('Cannot leave it blank'), t('The format is incorrect')]}
									fullWidth
								/>
							</div>
							<div className="field half">
								<TextValidator
									label={t('Mobile').includes('*') ? t('Mobile') : t('Mobile') + '*'}
									onChange={handleChangeFormData}
									name="mobile_2nd"
									value={formData.mobile_2nd}
									validators={['required', 'isNumber', 'minNumber:10000000', 'maxNumber:99999999']}
									errorMessages={[t('Cannot leave it blank'), t('The format is incorrect'), t('The format is incorrect'), t('The format is incorrect')]}
									fullWidth
								/>
							</div>
							<div className="field half">
								<TextValidator
									label={t('Email').includes('*') ? t('Email') : t('Email') + '*'}
									onChange={handleChangeFormData}
									name="email_2nd"
									value={formData.email_2nd}
									validators={['required', 'isEmail']}
									errorMessages={[t('Cannot leave it blank'), t('The format is incorrect')]}
									fullWidth
								/>
							</div>
						</div>
					</div>
				) : (
					''
				)}

				<div className="title">{t('B. Proposed performance')}</div>

				<div className="fields">
					<div className="row">
						<div className="field">
							<FormLabel>{t('Performance Type').includes('*') ? t('Performance Type') : t('Performance Type') + '*'}</FormLabel>
							<FormControl fullWidth>
								<RadioGroupValidator
									aria-label="nature-of-company"
									onChange={handleChangeFormData}
									name="type"
									value={formData.type}
									validators={['required']}
									errorMessages={[t('This field is required')]}
								>
									<FormControlLabel value="1" control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />} label={t('Singing')} />
									<FormControlLabel value="2" control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />} label={t('Dancing')} />
									<FormControlLabel
										value="3"
										control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
										label={t('Instrument Performance')}
									/>
									{/*<FormControlLabel value="4" control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />} label={t('Magic performance')} />*/}
									<div className="break"></div>
									<FormControlLabel
										className="radio-others"
										value="5"
										control={<Radio icon={<CheckBoxOutlineBlankIcon />} color="primary" checkedIcon={<CheckBoxIcon />} />}
										label={t('Others') + ':'}
									/>

									{formData.type === '5' ? (
										<div className="others">
											<TextValidator
												fullWidth
												className="radio-others-input"
												onChange={handleChangeFormData}
												name="type_others"
												value={formData.type_others}
												validators={['maxStringLength:30', 'required']}
												errorMessages={[t('Max. 30 characters'), t('Cannot leave it blank')]}
											/>
											<FormHelperText>{t('(Please specify* Max. 30 characters)')}</FormHelperText>
										</div>
									) : (
										<div className="others">
											<TextValidator
												fullWidth
												className="radio-others-input"
												onChange={handleChangeFormData}
												name="type_others"
												value={formData.type_others}
												validators={formData.type === 'others' ? ['maxStringLength:30', 'required'] : ['maxStringLength:30']}
												errorMessages={formData.type === 'others' ? [t('Max. 30 characters'), t('Cannot leave it blank')] : [t('Max. 30 characters')]}
											/>
											<FormHelperText>{t('(Please specify* Max. 30 characters)')}</FormHelperText>
										</div>
									)}
								</RadioGroupValidator>
							</FormControl>
						</div>
					</div>

					<div className="row boxed">
						<div className="field">
							<FormLabel>{t('Details of the Performance')}*</FormLabel>
							<FormHelperText>{t('Please include n words description')}</FormHelperText>

							<TextareaValidator
								aria-label="minimum height"
								rowsMin={5}
								name="detail"
								value={formData.detail}
								onChange={handleChangeFormData}
								validators={['required', 'maxStringLength:500']}
								errorMessages={[t('Cannot leave it blank'), t('Max. n characters')]}
							/>
						</div>
						<div className="field half">
							<FormLabel>{t('Performance Reference').includes('*') ? t('Performance Reference') : t('Performance Reference') + '*'}</FormLabel>
							<FormHelperText>{t('video link/ social account')}</FormHelperText>

							{referenceList.map((item, i) => {
								return (
									<div className="clips" key={i}>
										<div className="field">
											<TextValidator
												label={t('URL ') + item}
												onChange={handleChangeFormData}
												name={'reference_' + item}
												value={formData['reference_' + item]}
												id={`${i+1}`}
												fullWidth
												placeholder="http://"
												validators={['required', 'matchRegexp:[a-zA-z]+://[^\\s]*']}
												errorMessages={[t('Cannot leave it blank'), t('The format is incorrect')]}
											/>
										</div>
									</div>
								)
							})}
							{numbersOfReference < 5 ? (
								<div className="add-btn" onClick={() => setNumbersOfReference((current) => current + 1)}>
									+
								</div>
							) : null}
							{numbersOfReference > 1 ? (
								<div
									className="mi-btn"
									onClick={() => {
										setFormData((prevState) => ({
											...prevState,
											['reference_' + numbersOfReference]: '',
										}))
										setNumbersOfReference((current) => current - 1)
									}}
								>
									-
								</div>
							) : null}
						</div>
					</div>
				</div>

				<div className="title">{t('C. Declaration')}</div>

				<div className="fields">
					<div className="text-grey text-sm font-medium w-full">
						{t(
							'I hereby declare that the above venue is hired by our organization for organizing non-profit-making activities only.  I undertake to observe the conditions of use or regulations on the use of the venue and shall indemnify the Central Market against all actions, claims and demands by any person who suffers or sustains any loss, damage, injury or death arising out of or as a result of the use of the venue by me due to my negligence or on the part of the person authorized by me during the Hire Period.  I shall also meet the cost of repair needed for any damage caused and for repair or reinstatement or replacement of any equipment, fixture, fittings, facilities or other property damaged or destroyed, stolen or removed during the activities.  My right to use the venue shall be liable to suspension if I breach the conditions of use or regulations.'
						)}
					</div>
					<div className="row">
						<div className="field mt-6">
							<CheckboxValidatorElement
								validators={['isChecked']}
								errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
								name="declaration1"
								value={declarationData.declaration1}
								onClick={(event) => {
									handleChangeDeclaration(event)
								}}
							>
								{t('I have read the ')}
								<div className="linkText">
									<Popup text={t('terms and conditions')} content={<PopupTerms />} />
								</div>
								{t(' and conditions and agree to abide by it.')}
							</CheckboxValidatorElement>

							<CheckboxValidatorElement
								validators={['isChecked']}
								errorMessages={[t('Please make sure you read and accept all the terms before you submit the form')]}
								name="declaration2"
								value={declarationData.declaration2}
								onClick={(event) => {
									handleChangeDeclaration(event)
								}}
							>
								{t('I undertake that all information provided in the application process are true, correct, accurate and complete, and agree to abide by the conditions of the ')}
								<div className="linkText">
									<Popup text={t('Data Privacy and Security Policy Statement')} content={<PopupDataPrivacy />} />
								</div>
								{t('.')}
							</CheckboxValidatorElement>
						</div>
					</div>
				</div>
				{errorMessage ? <div className="row my-6 text-primary">{errorMessage}</div> : ''}
				{isLoading ? <Loader /> : <SubmitButton text={t('Submit')} />}
			</ValidatorForm>
		</section>
	)
}

export default BuskingPermitRegisterForm
