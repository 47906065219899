import React, { forwardRef } from 'react'

import styles from "./SubmitButton.module.scss";
import { Link } from "react-router-dom";

const SubmitButton = (props, ref)=> {
  return (
    <button ref={ref} className={styles.button}>
      {props.text}
      <div className={styles.arrow}></div>
    </button>
  );
};

export default forwardRef(SubmitButton);
