import { useEffect, useState, useLayoutEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import React from 'react'
import { createBrowserHistory } from "history";
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import Loader from '../../components/Loader/Lodaer'
import TextBorderFill from '../../components/TextBorderFill/TextBorderFill'
import './PreservedPillarDetail.scss'

//i18n
import { useTranslation } from 'react-i18next'

// Utils
import { isFireFox } from '../../utils/'
import getData from '../../utils/getData'
import getQueryString from '../../utils/getQueryString'


const PreservedPillarDetail = (props) => {
	const [article_id, setId] = useState(null)
	const [section, setSection] = useState(null)
	const navigate = useNavigate()
	const history = createBrowserHistory()
	const [disablenav, setDisablenav] = useState(getQueryString('disablenav'))

	const { t, i18n } = useTranslation('')
	const { page } = useParams()

	const [data, setData] = useState({
		article: [],
	})

	//const floors = ['G/F', '1/F', '2/F']

	const [floors, setFloors] = useState([])

	const mapImagePrefix = '/images-local/conservation/map/'

	const [isLoading, setIsLoading] = useState(false)

	const [filter, setFilter] = useState({
		currentPage: page || 1,
		floor: sessionStorage.getItem('preserved-pillar-floor') || floors[0],
	})

	const { hash } = window.location

	const [ locationKeys, setLocationKeys ] = useState([])

	// handle component didmount
	useEffect(() => {
		if(isFireFox){
			window.scrollTo(0,0);
		}
	}, []);

	// history handle
	useEffect(() => {
		return history.listen(({location, action}) => {
			if (action === 'PUSH') {
				setLocationKeys([ location.key ])
			}

		  	if (action === 'POP') {
				if (locationKeys[1] === location.key) {
					setLocationKeys(([ _, ...keys ]) => keys)

					// Handle forward event

				} else {
					setLocationKeys((keys) => [ location.key, ...keys ])
					// Handle back event
					navigate(props & props.section ? props.section : "conservation")
				}
			}
		})
	  }, [ locationKeys])

	//Fetch data
	useEffect(() => {
		async function fetchData() {
			try {
				const data_article = await getData('preserved_pillar')

				setData((prevState) => ({
					...prevState,
					article: data_article,
				}))

				if (hash !== '') {
					// add back _1
					var tmpHash = hash + '_1';

					const hashCode = tmpHash.replace('#', '').split('_')

					// redirect xx_stall to stall
					if(hashCode[0] != undefined &&
						(hashCode[0].includes("fish-stall")
						|| hashCode[0].includes("poultry-stall")
						|| hashCode[0].includes("pork-stall")
						|| hashCode[0].includes("beef-stall")
						|| hashCode[0].includes("vegetable-stall")
						|| hashCode[0].includes("fruit-stall")
						)){
						hashCode[0] = 'stall'
						navigate("/conservation#stall")
					}

					setId(hashCode[0])
					setSection(hashCode[1])

					var currArticle
					data_article.forEach((item) => (item.name.toLowerCase() === hashCode[0].toLowerCase() ? (currArticle = item) : null))

					var tempFloors = []
					currArticle.floors.forEach((item) => {
						tempFloors.push(item.floor)
					})
					setFloors(tempFloors)
					handleChangeFloor(tempFloors[0])
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
	}, [i18n.language])

	if (hash !== '') {
		setTimeout(() => {
			/*// add back _1 and Capitalize first letter
			var tmpHash = hash + '_1';
			tmpHash = tmpHash.charAt(0).toUpperCase() + tmpHash.slice(1);*/

			const hashCode = hash.replace('#', '').split('_')
			// redirect xx_stall to stall
			if(hashCode[0] != undefined &&
				(hashCode[0].includes("fish-stall")
				|| hashCode[0].includes("poultry-stall")
				|| hashCode[0].includes("pork-stall")
				|| hashCode[0].includes("beef-stall")
				|| hashCode[0].includes("vegetable-stall")
				|| hashCode[0].includes("fruit-stall")
				)){
				hashCode[0] = 'stall'
				navigate("/conservation#stall")
			}

			setId(hashCode[0])
			setSection(hashCode[1])
		}, 0)
	}

	// Filter data
	useEffect(() => {
		setIsLoading(true)

		if (article_id !== null && data.article.length) {
			var currArticle = ''
			data.article.forEach((item) => (item.name.toLowerCase() === article_id.toLowerCase() ? (currArticle = item) : null))

			let itemsFiltered = currArticle.floors.filter((item) => {
				if (filter.floor && item.floor !== filter.floor) {
					return false
				}

				return true
			})

			setData((prevState) => ({
				...prevState,
				itemsFiltered: itemsFiltered,
				currArticle: currArticle,
			}))
		}
	}, [data.items, filter])

	// Get current pages data
	useEffect(() => {
		if (article_id !== null) {
			let itemsCurrent = data.itemsFiltered

			setData((prevState) => ({
				...prevState,
				itemsCurrent: itemsCurrent,
			}))

			setTimeout(() => {
				setIsLoading(false)
			}, 200)
		}
	}, [data.itemsFiltered, filter.currentPage])

	const handleChangeFloor = (floor) => {
		sessionStorage.setItem('preserved-pillar-floor', floor)
		setFilter((prevState) => ({
			...prevState,
			floor: floor,
		}))
	}

	return (
		<div className="page-preserved-pillar-detail">
			{disablenav ? (
				''
			) : (
				<Link to={props && props.section ? `/${props.section}/` : "/conservation/"} className="back-btn">
					{t('Back')}
				</Link>
			)}
			{data && article_id != null
				? data.article.map((item, i) =>
						item.name.toLowerCase() === article_id.toLowerCase() ? (
							<div className="container-boxed" key={i}>
								<div className="page-title">
									<TextBorderFill>{props && props.section ? t(props.section) : ''}</TextBorderFill>
								</div>
								<div className="page-sub-title">
									<TextBorderFill>{item.title}</TextBorderFill>
								</div>
								<div className="map-section">
									<ul className="floors">
										{floors &&
											floors.map((floor, index) => {
												return (
													<li key={index} id={'floor-' + floor} className={filter.floor === floor ? 'active' : ''}>
														<a onClick={() => handleChangeFloor(floor)}>{floor}</a>
													</li>
												)
											})}
									</ul>
									<div className="map">
										{isLoading ? (
											<Loader />
										) : data || data.itemsCurrent ? (
											data.itemsCurrent.map((item, index) => {
												return (
													<div key={index}>
														<img src={mapImagePrefix + item.floor.replace('/', '') + '.png'} alt={item.image_alt_tag} />
														<img src={item.pin_image} alt={item.pin_image_alt_tag} />
													</div>
												)
											})
										) : (
											<img src="/images-local/G_floor.png" alt="G Floor" />
										)}
									</div>
								</div>
								<div className="article-section">
									<div className="content-detail">
										<div className="content-text-info">
											{item && item.audio ? (
												<div className="audio-title">{t('Voice Navigation')}</div>
												): (
													""
											)}
											{item && item.audio ? (
												<AudioPlayer
													src={item.audio}
													layout="horizontal-reverse"
													customAdditionalControls={[]}
													showSkipControls={false}
													showJumpControls={false}
													customProgressBarSection={[RHAP_UI.CURRENT_TIME, <div>/</div>, RHAP_UI.DURATION, RHAP_UI.PROGRESS_BAR]}
													showDownloadProgress={false}
													customIcons={{
														play: <img src="/images-local/Play.svg" alt="Play" />,
														pause: <img src="/images-local/Stop.svg" alt="Stop"/>,
														volume: <img src="/images-local/SpeakerHigh.svg" alt="Volume" />,
														volumeMute: <img src="/images-local/SpeakerX.svg" alt="Mute" />,
													}}
												/>
											): (
												""
											)}
											{item.description &&
												item.description.map((script, i) => {
													return <p key={i} className={script.type} dangerouslySetInnerHTML={{ __html: script.content }}></p>
												})}
										</div>
									</div>
								</div>
							</div>
						) : (
							''
						)
				  )
				: ''}
		</div>
	)
}

export default PreservedPillarDetail
