import React from 'react'
import SVG from 'react-inlinesvg'

import styles from './ButtonBackToTop.module.scss'

const ButtonBackToTop = () => {
	const handleClick = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}
	return (
		<div className={styles.btn} onClick={handleClick}>
			<SVG className={styles.fill} src="/images-local/ico-arrow-top.svg" alt="" />
		</div>
	)
}

export default ButtonBackToTop
