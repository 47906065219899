// Core
import React, { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'

// Style
import styles from './PopupSearch.module.scss'

// GSAP
import gsap from 'gsap'

// Compotentss
import SiteSearch from '../SiteSearch/SiteSearch'

const PopupSearch = (props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [tl] = useState(new gsap.timeline({ paused: true }))
	let searchEl = null
	let shadowEl = null
	let containerEl = null

	useEffect(() => {
		tl.from(searchEl, 0.1, { autoAlpha: 0 }).from(shadowEl, 0.5, { autoAlpha: 0 }).from(
			containerEl,
			0.5,
			{
				autoAlpha: 0,
				y: -50,
			},
			'-=.3'
		)
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (props.isClicked) {
			handleToggle()
		}
		// eslint-disable-next-line
	}, [props.isClicked])

	const handleToggle = (e) => {
		if (isOpen) {
			// document.body.classList.remove('overflow-y-hidden')
			tl.reverse()
		} else {
			// document.body.classList.add('overflow-y-hidden')
			tl.play()
		}
		setIsOpen(!isOpen)
	}

	return (
		<div className={styles.search} ref={(e) => (searchEl = e)}>
			<div className={styles.shadow} ref={(e) => (shadowEl = e)} onClick={handleToggle}></div>
			<div className={styles.container} ref={(e) => (containerEl = e)}>
				<SiteSearch />
			</div>
		</div>
	)
}

export default PopupSearch
