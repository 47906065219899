import { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import SwiperCore, { Navigation, Pagination, History } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SVG from 'react-inlinesvg'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { TweenMax, Bounce } from 'gsap/all'

import PageTitle from '../../components/PageTitle/PageTitle'
import { SliderArrow } from '../../components/NavArrow/NavArrow'

import style from './CentralHub.module.scss'
import axios from '../../config/axios'

import $ from 'jquery'

SwiperCore.use([Navigation, Pagination, History])

const CentralHub = () => {
    const { i18n, t } = useTranslation()
    const sliderEl = useRef()
    const [activeSlde, setActiveSlde] = useState()
    const [data, setData] = useState({
        tabList: [],
        tabDetails: {
            image: '',
            box: [],
        },
        arrowimgdown: '/images-local/ico-arrow-down.svg',
        arrowimgup: '/images-local/ico-arrow-up.svg',
        dpdownStatus: {
            status: false,
            arrow: '/images-local/ico-arrow-down.svg',
        },
    })

    let imgSwiperNav = {
        prev: useRef(),
        next: useRef(),
    }

    const tabNum = 100 / data.tabList.length
    const tabItem = data.tabList.map((item, index) => (
        <div key={index} className={`${item.active === true ? style.active : ''} ${style.tabItem}`} style={{ width: tabNum + '%' }} onClick={() => changeTab(item.id)}>
            <p className={style.tabIndex}> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </p>
            <p className={style.tabName}> {item.name} </p>
        </div>
    ))

    const tabItemDropDown = data.tabList.map((item, index) => (
        <div key={index} className={`${item.active === true ? style.active : ''} ${style.tabItem}`} onClick={() => changeTab(item.id)}>
            <p className={style.tabIndex}> {index + 1 < 10 ? '0' + (index + 1).toString() : (index + 1).toString()} </p>
            <p className={style.tabName}> {item.name} </p>
        </div>
    ))

    // Fetch data
    useEffect(() => {
        axios.get(i18n.language + '/location.json').then((result) => {
            const res = result.data
            const activeContentItem = res.tab.find((item) => item.active === true)
            setData((prevState) => ({
                ...prevState,
                address: res.info_data.address,
                time: res.info_data.time,
                detail: res.info_data.detail,
                tabList: res.tab,
                tabContentarr: res.tab_content,
                tabDetails: res.tab_content[activeContentItem.id],
                activeTabItem: activeContentItem,
                googleMapMarkers: res.google_map_markers,
                transportType: res.transport_type
            }))
        })
    }, [i18n.language])

    useEffect(() => {
		$(document).on('mouseover', '[data-map-btn]', function () {
			TweenMax.to($(this), 0.1, { ease: Bounce.easeOut, scale: 1.2, transformOrigin: '50% 100%' })
			const id = parseInt($(this).attr('data-map-btn'))
			//sliderEl.current?.swiper.slideTo(id)
			setActiveSlde(id)
			sliderEl.current?.swiper.slideToLoop(id, 400)
		})

		$(document).on('mouseout', '[data-map-btn]', function () {
			TweenMax.to($(this), 0.1, { ease: Bounce.easeOut, scale: 1, transformOrigin: '50% 100%' })
			setActiveSlde()
		})
	}, [])

    const assignNav = (swiper, nav) => {
        swiper.params.navigation = {
            ...swiper.params.navigation,
            prevEl: nav.prev.current,
            nextEl: nav.next.current,
        }
        swiper.navigation.update()
        swiper.update(true)
    }

    const changeTab = (targetid) => {

        setActiveSlde(0)

        $('[data-compoent="locationItem"]').scrollTop(0)
        sliderEl.current?.swiper.slideTo(0)

        let tabList = []
        let activeTabItem
        data.tabList.forEach((item, index) => {
            if (item.id === targetid) {
                item.active = true
                activeTabItem = item
            } else {
                item.active = false
            }
            tabList.push(item)
        })
        let updateDetails = data.tabContentarr[targetid]

        if (data.dpdownStatus.status === true) {
            let dptest = {
                status: data.dpdownStatus.status === true ? false : true,
                arrow: data.dpdownStatus.status === true ? data.arrowimgdown : data.arrowimgup,
            }

            setData((prevState) => ({
                ...prevState,
                dpdownStatus: dptest,
            }))
        }

        setData((prevState) => ({
            ...prevState,
            tabList: tabList,
            tabDetails: updateDetails,
            activeTabItem: activeTabItem,
        }))

        setActiveSlde()

        sliderEl.current?.swiper.slideTo(0)
        sliderEl.current?.swiper.slideToLoop(0, 0)
    }

    const toggleDropDown = () => {
        let dptest = {
            status: data.dpdownStatus.status === true ? false : true,
            arrow: data.dpdownStatus.status === true ? data.arrowimgdown : data.arrowimgup,
        }

        setData((prevState) => ({
            ...prevState,
            dpdownStatus: dptest,
        }))
    }

    return (
        <div className="page">
        <Helmet>
            <title>
                {t('central_hub_title')} - {t('Central Market')}
            </title>
        </Helmet>
        <PageTitle title={t("central_hub_title")} />
            <div className="container-boxed much-padding">
                <section className={style.hub}>
                    <div className={style.desc}>{t('central_hub_desc')}</div>
                    {data &&
                        <div className={style.content}>
                            <div className={style.containerTabBar}>{tabItem}</div>
                            <div className={style.containerTabDropdown}>
                                <div className={style.activeItem} onClick={() => toggleDropDown()}>
                                    <div className={`${style.activeItemDetails} ${data.dpdownStatus.status === true ? style.active : ''}`}>
                                        <p className={style.tabIndex}>{data?.activeTabItem?.item} </p>
                                        <p className={style.tabName}>{data?.activeTabItem?.name} </p>
                                    </div>
                                    <img src={data.dpdownStatus.arrow} alt="" />
                                </div>
                                <div className={`${style.dropdownList} ${data.dpdownStatus.status === true ? style.active : ''}`}>{tabItemDropDown}</div>
                            </div>
                            <div className={style.containerTabDetails}>
                                <div className="block md:hidden">
                                    <SVG className={`${style.mapImage}`} src={data.tabDetails.image_mobile} uniquifyIDs={true} />
                                </div>
                                <div className="hidden md:block">
                                    <SVG className={`${style.mapImage}`} src={data.tabDetails.image} uniquifyIDs={true} />
                                </div>
                                <div className={style.placeDetailsBoxes}>

                                    <div className={style.box}>
                                        <SliderArrow ref={imgSwiperNav} isLeftRight isCenter/>
                                        <Swiper
                                            className={classnames(style.imageSwiperPadding, "find--us__slider")}
                                            spaceBetween={5}
                                            slidesPerView={1.0}
                                            loop={true}
                                            navigation={{ prevNav: '', nextEl: '' }}
                                            breakpoints={{
                                                768: {
                                                    slidesPerView: 1,
                                                },
                                            }}
                                            ref={sliderEl}
                                            onInit={(swiper) => {
                                                assignNav(swiper, imgSwiperNav)
                                                //swiper.slideToLoop(1, 0)
                                                swiper.slideToLoop(4, 0)
                                            }}
                                        >
                                            {data.tabDetails.box.map((item, i) => (
                                                <SwiperSlide key={i} className={`${style.i} ${style.boxItem}`} data-status={`${i === activeSlde ? 'active' : ''}`}>
                                                    <div className={style.boxDetails}>
                                                        <div className={style.boxDetailsImg}>
                                                            <div className="block md:hidden">
                                                                {/*<img className={style.placeImage} src={item.image} alt="" />*/}
                                                                <img className={style.placeImage} src={`${item.image.replace('.png', '-large.png')}`} alt="" />
                                                            </div>
                                                            <div className="hidden md:block">
                                                                <img className={style.placeImage} src={`${item.image.replace('.png', '-large.png')}`} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className={style.boxDetailsItem}>
                                                            <p className={style.placeName}>{item.title}</p>
                                                            <p data-compoent="locationItem" className={style.placeDetail}>
                                                                {item.details}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}

                                            {/* <div className={style.navWrapper}>
                                                <div className="ratio"></div>
                                                <div className="swiper-button-prev" ref={imgSwiperNav.prev} ></div>
                                                <div className="swiper-button-next" ref={imgSwiperNav.next} ></div>
                                            </div> */}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </section>
            </div>
        </div>
    )
}

export default CentralHub